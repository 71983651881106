import { createLogic } from 'redux-logic';
import {
  setBannerState,
  getPaymentTokenPending,
  getPaymentTokenSuccess,
  onError,
} from '../actions/AppActions';
import intl from '../actions/lang/en.json';

export const generatePaymentTokenLogic = createLogic({
  type: getPaymentTokenPending().type,
  latest: true,
  process({ httpClient, action: { payload: { showError } } }, dispatch, done) {
    httpClient(dispatch, false).post('/payment-token')
      .then(res => {
        dispatch(getPaymentTokenSuccess(res.data));
        done();
      })
      .catch(err => {
        if (showError) {
          dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.PaymentMethods.Cannot_Add_New_Credit_Card_Error } }));
          dispatch(onError({ err, type: '/profile/payment-sign-in-token endpoint error' }));
        }
        done();
      });
  }
});

export default [
  generatePaymentTokenLogic,
];
