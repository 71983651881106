import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getAppError = createDeepEqualSelector(
  state => state.app.get('globalError')?.toJS(),
  res => res
);

export const getBannerError = createDeepEqualSelector(
  state => state.app.get('banner')?.toJS(),
  res => (res?.type?.toLowerCase() === 'error' ? res : {})
);

export const getError = createDeepEqualSelector(
  state => state.app.get('error')?.toJS(),
  res => res
);