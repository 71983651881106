import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { string, func, number } from 'prop-types';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: grey;
  align-items: center;
  justify-content: space-around;
`;

const InlineLink = styled.span`
  color: #28599b;
  cursor: pointer;
  text-decoration: underline;
`;

const ActionableLink = ({ retryAction }) =>
  retryAction
    ? <InlineLink onClick={retryAction} onKeyPress={retryAction} role="link" tabIndex="0">Click here to try again</InlineLink>
    : <Link to="/">Click here to return to the home page</Link>;

ActionableLink.propTypes = {
  retryAction: func
};

const NavigationMessagePage = ({ message, retryAction, showActionableDelay = 3000 }) => {
  const [showRetryLink, setShowRetryLink] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowRetryLink(true), showActionableDelay);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Background>
      <h3>{message}</h3>
      {showRetryLink && <p>Still here? <ActionableLink retryAction={retryAction} /></p>}
    </Background>
  );
};

NavigationMessagePage.propTypes = {
  message: string.isRequired,
  retryAction: func,
  showActionableDelay: number,
};

export default NavigationMessagePage;
