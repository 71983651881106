import { createLogic } from 'redux-logic';
import moment from 'moment';
import intl from '../actions/lang/en.json';
import { shouldFetchResults, getRowRange, padResults } from '../utils/tables';
import { formatMoney, formatTotalDue } from '../utils/orderHelpers';
import {
  getAccountBalancePending,
  getAccountBalanceSuccess,
  getAccountBalanceError,
  getAccountStatementsPending,
  getAccountStatementsSuccess,
  getAccountStatementsError,
  getAccountTransactionsPending,
  getAccountTransactionsSuccess,
  getAccountTransactionsError,
  setBannerState,
  downloadStatementPending,
  toggleProcessedItem,
  tableAppendRowData,
  tableUpdateSettings,
  fetchInvoicePdfPending,
  fetchInvoicePdfSuccess,
  fetchInvoicePdfError,
  fetchStatementPdfPending,
  fetchStatementPdfSuccess,
  fetchStatementPdfError,
} from '../actions/AppActions';

// Needed for mock data. ///////
import mockResponse from '../mocks/invoices.json';

const mockHttpClientClient = {
  get() {
    return new Promise((resolve) => {
      resolve(mockResponse);
    });
  }
};
////////////////////////////////

const DEFAULT_INVOICES_PAGE_SIZE = 100;

const Errors = intl.Errors.BillingAcctDetails;

export const getBalanceLogic = createLogic({
  type: getAccountBalancePending().type,
  latest: true,

  process({ httpClient, action: { payload: { id, accountNumber } } }, dispatch, done) {
    httpClient(dispatch).get(`/billing-accounts/${accountNumber}/balance`)
      .then(resp => resp.data)
      .then(balance => ({
        ...balance,
        dueDate: moment(balance.dueDate).format('M/DD/YYYY'),
        lastStatementDate: moment(balance.lastStatementDate).format('M/DD/YYYY'),
      }))
      .then(balance => ({ data: balance, id }))
      .then(payload => {
        dispatch(getAccountBalanceSuccess(payload));
        done();
      })

      .catch(err => {
        const message = Errors.Get_Balance_Error;
        const bannerState = {
          data: { type: 'error', message }
        };

        dispatch(setBannerState(bannerState));
        dispatch(getAccountBalanceError(err));
        done();
      });
  }
});

async function getPaginatedInvoices(action, page=1, pageSize = DEFAULT_INVOICES_PAGE_SIZE) {
  const allInvoices = [];
  let {invoices, recordSetTotal, recordSetCount, recordSetStartNumber} = (await action({linesPerPage: pageSize, pageNumber: page})).data;
  allInvoices.push(...invoices);

  while (recordSetCount + recordSetStartNumber < recordSetTotal + 1) {
    page = page + 1;
    const {data} = await action({linesPerPage: pageSize, pageNumber: page});
    recordSetTotal = data.recordSetTotal;
    recordSetCount = data.recordSetCount;
    recordSetStartNumber = data.recordSetStartNumber;
    invoices = data.invoices || [];
    allInvoices.push(...invoices);
  }

  return allInvoices;
}

export const getTransactionsLogic = createLogic({
  type: getAccountTransactionsPending().type,
  latest: true,
  process({ httpClient, action: { payload: { id, accountNumber, page = 1, fromDate, toDate, setSubmitting, tableName, achForm } }, getState }, dispatch, done) {
    const table = tableName || 'allTransactions';
    const state = getState();
    const rows = state.app.getIn(['accountTransactions', id, 'allTransactions', 'rows']);
    const settings = state.app.getIn(['tableSettings', table]);
    if (!shouldFetchResults(rows, settings, setSubmitting)) {
      dispatch(getAccountTransactionsSuccess({ id, first: false }));
      return false;
    }
    if (setSubmitting) {
      dispatch(tableUpdateSettings({ tableName: table, settings: { isLoading: true, currentPage: 1 } }));
      dispatch(tableUpdateSettings({ tableName: 'openInvoices', settings: { isLoading: true, currentPage: 1 } }));
      dispatch(tableUpdateSettings({ tableName: 'creditTransactions', settings: { isLoading: true, currentPage: 1 } }));
    } else {
      dispatch(tableUpdateSettings({ tableName: table, settings: { isLoading: true } }));
    }

    getPaginatedInvoices((params) =>  httpClient(dispatch).get(
      `/billing-accounts/${accountNumber}/invoices`,
      {
        params: {
          ...params,
          endDate: toDate,
          startDate: fromDate,
        }
      }))
      .then(invoices => ({
        totalItems: invoices?.length || 0,
        rows: invoices?.map(invoice => ({
          ...invoice,
          salesChannel: invoice.salesChannel && invoice.salesChannel.includes('LifewayStores.com') ? 'Lifeway Store' : invoice.salesChannel,
          customerReference: invoice.purchaseOrder,
          invoiceDate: moment(`${invoice.invoiceDate} 00:00:00`).utcOffset('-0400').format('M/D/YYYY'),
          totalDueDisplay: formatTotalDue(invoice.totalDue, invoice.transactionType),
          totalOriginalDisplay: formatTotalDue(invoice.totalAmount, invoice.transactionType),
          totalAmount: formatMoney(invoice.totalAmount),
          checked: false
        }))
      }))
      .then(invoices => {
        const payload = { id, data: invoices };
        if (!rows || !rows.size || setSubmitting) {
          payload.data.rows = padResults(payload.data);
          dispatch(getAccountTransactionsSuccess({ ...payload, first: true }));
        } else {
          payload.index = ['accountTransactions', id, table, 'rows'];
          payload.range = getRowRange(page, rows);
          dispatch(tableAppendRowData(payload));
          dispatch(getAccountTransactionsSuccess({ id, first: false }));
        }
        dispatch(tableUpdateSettings({ tableName: table, settings: { isLoading: false } }));
        dispatch(tableUpdateSettings({ tableName: 'openInvoices', settings: { isLoading: false } }));
        dispatch(tableUpdateSettings({ tableName: 'creditTransactions', settings: { isLoading: false } }));

        if (!achForm && setSubmitting) {
          setSubmitting(false);
        }

        done();
      })
      .catch(error => {
        const { status } = error.response;
        if(status === 404 || status === 500) {
            dispatch(getAccountTransactionsError({ id, message: Errors.Get_Transactions_Error }));
            setSubmitting && setSubmitting(false);        
        }
        done();
      });
  }
});

export const getBillingAccountStatementDates = createLogic({
  type: getAccountStatementsPending().type,
  latest: true,

  processOptions: {
    dispatchMultiple: true
  },

  process({ httpClient, action: { payload: { id, organizationId, accountNumber } } }, dispatch, done) {
    httpClient(dispatch).get(`/organizations/${organizationId}/billing-account/statements/${accountNumber}`)
      .then(resp => resp?.data?.map(statement => ({
        ...statement,
        longhandDate: moment(statement?.statementDate, 'YYYY/MM/DD').format('MMMM YYYY'),
      })
      ).slice(0, 12)
      )
      .then(statementDates => ({ data: statementDates, id }))
      .then(payload => {
        dispatch(getAccountStatementsSuccess(payload));
        done();
      })

      .catch(err => {
        const message = Errors.Get_Statements_Error;
        const bannerState = {
          data: { type: 'error', message }
        };

        dispatch(setBannerState(bannerState));
        dispatch(getAccountStatementsError(err));
        done();
      });
  }
});

export const downloadStatementLogic = createLogic({
  type: downloadStatementPending().type,
  latest: true,

  processOptions: {
    dispatchMultiple: true
  },

  process({ httpClient, action: { payload: { accountNumber, date } } }, dispatch, done) {
    const longDate = moment(date, 'YYYY-MM-DD').format('MMMM YYYY');
    dispatch(toggleProcessedItem(longDate));
    httpClient(dispatch).get(`/billing-accounts/${accountNumber}/statement-dates/${date}/statement`)
      .then(resp => {
        const blob = new Blob([resp.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `statement-${date}.pdf`;
        link.click();
        dispatch(toggleProcessedItem());
        done();
      });
  }
});

const pdfRequestConfig = {
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/pdf'
  },
};

const toObjectUrl = (data, contentType) => {
  const blob = new Blob([data], { type: contentType });
  return URL.createObjectURL(blob);
};

const contentType = (res) => res?.headers?.['content-type'];

export const fetchInvoicePdfLogic = createLogic({
  type: fetchInvoicePdfPending().type,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const invoiceId = action?.payload?.invoiceId;
    httpClient(dispatch).get(`/invoices/${invoiceId}`, pdfRequestConfig)
      .then(res => {
        dispatch(fetchInvoicePdfSuccess({ invoiceId, pdf: toObjectUrl(res?.data, contentType(res)) }));
      })
      .catch(error => {
        const { status } = error.response;
        if(status === 404 || status === 500) {
          dispatch(fetchInvoicePdfError(error));
        }
      })
      .finally(() => done());
  },
});

export const fetchStatementPdfLogic = createLogic({
  type: fetchStatementPdfPending().type,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const accountNumber = action?.payload?.accountNumber;
    const date = action?.payload?.date;
    const organizationId = action?.payload?.organizationId;
    const billingAccountNumber = accountNumber;
    const statementDate = date;
    httpClient(dispatch).get(`/organization/${organizationId}/billing-accounts/${billingAccountNumber}/statement-dates/${statementDate}/statement`, pdfRequestConfig)
      .then(res => {
        if(contentType(res) === 'application/json'){
          const textDecoder = new TextDecoder('utf-8');
          const decodedText = textDecoder.decode(res.data);
          const pdfUrl = JSON.parse(decodedText);
          dispatch(fetchStatementPdfSuccess({ accountNumber, date, pdf: pdfUrl.preSignedUrl }));
        } else if(contentType(res) === 'application/pdf'){
            dispatch(fetchStatementPdfSuccess({ accountNumber, date, pdf: toObjectUrl(res?.data, contentType(res)) }));
        }
      })
      .catch(error => {
        const { status } = error.response;
        if(status === 404 || status === 500) {
        dispatch(fetchStatementPdfError(error));
        }
      })
      .finally(() => done());
  },
});

export default [
  getBalanceLogic,
  getTransactionsLogic,
  getBillingAccountStatementDates,
  downloadStatementLogic,
  fetchInvoicePdfLogic,
  fetchStatementPdfLogic,
];
