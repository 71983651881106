import React, { useState, useEffect } from 'react';
import { func, object } from 'prop-types';
import { useSelector } from 'react-redux';
import userIcon from '../../../../../img/sprite/userIcon.svg';
import userIconLoggedOut from '../../../../../img/sprite/userIconLoggedOut.svg';
import {
  AccountMenu,
  AccountMenuButton,
  AccountMenuItems,
  AccountMenuItem,
  AccountMenuItemText,
  AccountMenuItemLink
} from '@lifewayit/components/dist/lib';
import { ORG_PATHS, PATH_DASHBOARD, PATH_LOGIN_REDIRECT, PATH_REDEEM_CODE } from "../../../constants";
import { RedeemButton } from '../../RedeemButton';
import DownChevron from '../../../../../img/sprite/down-chevron.svg';
import Button from '../../Button';
import { injectParams } from '../../../utils/pathHelpers';
import { getCurrentUserName } from '../../../selectors/PersonProfileSelectors';
import { useLocation } from 'react-router';
import { getViewTransactionHistoryAccess } from '../../../selectors/OrgManagementSelectors';

const AccountDropdown = ({
  orgId,
  menuLinks,
  orgMenuLinks,
  onLogOut,
  isLoggedIn,
  loading,
  linkedOrganizations,
  setViewAccountsModal,
  LinkElement,
  isMobile
}) => {
  const LinkComponent = (props) => {
    if (LinkElement) {
      const { href, children, style, ...rest } = props;
      const { color, fontWeight } = style;
      
      return (
        <LinkElement to={href} style={style}>
          <a {...rest} style={{color, fontWeight, whiteSpace: 'normal'}}>
            {children}
          </a>
        </LinkElement>
      )
    } else {
      return (
        <AccountMenuItemLink href={to} className={className} style={style} active={active} props={props}>{children}</AccountMenuItemLink>
        )
      }
    }
    
    const MenuViews = {
      SELECT: 'SELECT',
      INDIVIDUAL: 'INDIVIDUAL',
      ORG: 'ORG',
    };
    
    const { pathname } = useLocation();
    
    const [view, setView] = useState(MenuViews.INDIVIDUAL);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [activeBillingAccount, setActiveBillingAccount] = useState(selectedOrg?.organizationProfile?.billingAccounts?.[0]?.id);
    
    const hasAccounts = selectedOrg?.organizationProfile?.billingAccounts?.length > 0;

  useEffect(() => {
		if (orgId && !loading) {
			const currentOrg = linkedOrganizations.find(org => org.organizationId === orgId);
			setSelectedOrg(currentOrg);
      setView(MenuViews.ORG);
		} else {
      setView(MenuViews.INDIVIDUAL);
    }
	}, [orgId, loading]);

  //Set billing account id for links when switching between different org views
  useEffect(() => {
    if (selectedOrg) {
      setActiveBillingAccount(selectedOrg?.organizationProfile?.billingAccounts?.[0]?.id)
    }
  }, [selectedOrg]);

  const name = useSelector(getCurrentUserName);

  const showBackBtn = linkedOrganizations?.length > 0;

  const firstThreeOrgs = linkedOrganizations?.slice(0,3);

  const showMoreOrgs = linkedOrganizations?.length > 3;

  const handleTransactionHistoryAccess = useSelector(state => getViewTransactionHistoryAccess(state, selectedOrg?.organizationId, selectedOrg?.organizationProfile?.billingAccounts?.map(account => account.accountNumber)));
  
  const hasTransactionHistoryAccess = handleTransactionHistoryAccess.some(permission => permission.hasAccess === true);

  const handleSelectOrg = (org) => {
    if (org) {
      setView(MenuViews.ORG);
      setSelectedOrg(org);
    }
  };

  useEffect(() => {
    // Split the URL by '/' to get an array of segments
    const segments = pathname.split('/');

    // Find the index of 'billing-activity' in the array
    const baIndex = pathname.includes('billing-activity') ? segments.indexOf('billing-activity') : segments.indexOf('order-history')

    if (baIndex !== -1 && baIndex < segments.length - 1) {
      // Extract the parameter value that follows 'billing-activity'
      const param = segments[baIndex + 1];
      setActiveBillingAccount(param);
    }
  }, [pathname]);

  const handleOrgUrl = (url) => {
    if (url?.includes('order')) {
      return injectParams(url, { organizationId: selectedOrg?.organizationId, billingAccountId: hasAccounts ? activeBillingAccount : ''  })
    } else if (url?.includes('billing')) {
      return injectParams(url, { organizationId: selectedOrg?.organizationId, billingAccountId: hasAccounts ? activeBillingAccount : ''  })
    } else {
      return url.replace(':organizationId', selectedOrg?.organizationId)
    }
  };

  //Org Menu Links if user does not have View Transaction History permission
  const updatedOrgMenuLinks = {
    ...orgMenuLinks,
    linkGroup: orgMenuLinks.linkGroup.map(group => ({
      ...group,
      links: group.links.filter(link => link.name !== 'Billing')
    }))
  };
  const defaultPath = PATH_DASHBOARD;
  const orgDefaultPath = ORG_PATHS.DASHBOARD.replace(':organizationId', selectedOrg?.organizationId);


  return (
    <AccountMenu id="account-menu" style={isMobile ? { justifyContent: 'flex-end' } : {}}>
      <AccountMenuButton
        className={!isMobile ? 'border border-black rounded-full bg-white account-menu-btn' : 'bg-transparent'}
        aria-label="View Account Menu"
        style={isMobile ? 
          { width: 'auto', height: '42px', display: 'flex', zIndex: '200', padding: '9px 0px 0px', justifyContent: 'space-between', margin: '0', alignItems: 'center', textDecoration: 'none' }
          : { width: '240px', height: '42px', display: 'flex', zIndex: '200', padding: '0 7px', justifyContent: 'space-between', margin: '0', alignItems: 'center', textDecoration: 'none' }
        }
        >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {isLoggedIn && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              {isLoggedIn && <svg style={{ width: '28px', height: '28px' }}><use href={`#${userIcon.id}`} xlinkHref={`#${userIcon.id}`} /></svg>}
              {!isMobile && name.firstName !== undefined && <p style={{ color: '#3a4847', fontSize: '16px', textDecoration: 'none' }}>Hello, {name.firstName}</p>}
            </div>
          )}
          {!isLoggedIn && !isMobile && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              {!isLoggedIn && <svg style={{ width: '28px', height: '28px' }}><use href={`#${userIconLoggedOut.id}`} xlinkHref={`#${userIconLoggedOut.id}`} /></svg>}
              <span style={{ textAlign: 'left' }}>
                <p style={{ color: '#3a4847', fontSize: '14px', textDecoration: 'none' }}>Hi, Sign In</p>
                <p style={{ color: '#3a4847', fontSize: '12px', textDecoration: 'none' }}>Orders & Account</p>
              </span>
            </div>
          )}
          {!isMobile && (
            <div style={{ position: 'absolute', right: '10px' }}>
              <svg><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
            </div>
            )}
        </div>
      </AccountMenuButton>
      <div style={{ maxWidth: '305px' }}>
        {isLoggedIn && (
          <AccountMenuItems
            className="shadow-lg"
            style={isMobile ?
              { zIndex: '200', width: 'auto', minWidth: '240px', borderRadius: '12px', position: 'absolute', top: '44px', right: '0px', textDecoration: 'none' } :
              { zIndex: '200', width: '100%', borderRadius: '12px', position: 'absolute', top: '44px', right: '10px',textDecoration: 'none' }
            }
          >
            {showBackBtn && view !== MenuViews.SELECT && (
              <div style={{ width: '100%', borderBottom: 'solid 1px #e0e0e0', padding: isMobile ? '15.5px 0'  : '16px 0', alignItems: 'center', cursor: 'pointer' }} onClick={() => setView(MenuViews.SELECT)}>
                <svg style={{ transform: 'rotate(90deg)', paddingRight: '3px' }}><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
                <button type="button" style={{ fontWeight: 'bold', background: 'white', border: 'none', fontSize: '14px', color: '#476692', textDecoration: 'none' }}>
                  Switch Account
                </button>
              </div>
            )}
            {view === MenuViews.SELECT && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', borderBottom: 'solid 1px #e0e0e0' }}>
                  <button
                    type="button"
                    role='button'
                    aria-label="My Account"
                    style={{
                      textAlign: 'left',
                      width: '100%',
                      padding: '16px 0',
                      background: 'white',
                      margin: '0',
                      fontSize: '16px',
                      color: '#476692',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                    }}
                    onClick={() => setView(MenuViews.INDIVIDUAL)}
                  >
                    My Account
                  </button>
                  <svg style={{ transform: 'rotate(-90deg)', paddingRight: '3px' }}><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
                </div>
                {loading && <div style={{ color: '#476692', paddingTop: '8px' }}>Loading...</div>}
                {!loading && firstThreeOrgs && firstThreeOrgs?.map((org, index) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      borderBottom: 'solid 1px #e0e0e0',
                    }}
                    key={index}
                  >
                    <button
                      style={{
                        textAlign: 'left',
                        width: '100%',
                        padding: '16px 0',
                        background: 'white',
                        margin: '0',
                        fontSize: '16px',
                        color: '#476692',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        fontStyle: 'normal'
                      }}
                    onClick={() => handleSelectOrg(org)}
                  >
                      {org?.organizationName}
                  </button>
                    <svg style={{ transform: 'rotate(-90deg)', paddingRight: '3px' }}><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
                  </div>
                ))}
                {!loading && !linkedOrganizations && (
                  <div className="pt-3">Unable to load your organizations at this time.</div>
                )}
                {showMoreOrgs && (
                  <button
                    type="button"
                    onClick={() => setViewAccountsModal()}
                    style={{ 
                      fontSize: '14px',
                      color: '#476692',
                      textDecoration: 'none',
                      marginTop: '6px',
                    }}
                  >
                    View More Accounts
                  </button>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', margin: '12px 0 14px 0', gap: '24px' }}>
                  <AccountMenuItem>
                    {({ close }) => (
                      <RedeemButton dropdown={true} onClick={close} />
                    )}
                  </AccountMenuItem>
                    <button type="button" onClick={onLogOut} style={{ fontSize: '14px', color: '#476692', background: 'transparent' }}>
                      <AccountMenuItem className="hover:underline no-underline">
                      Sign Out
                      </AccountMenuItem>
                    </button>
                </div>
              </>
            )}
            {view === MenuViews.INDIVIDUAL && (
                <>
                  <AccountMenuItem
                    style={{
                      margin: '0',
                      border: 'none',
                      fontSize: '16px',
                      color: '#476692',
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                      padding: '8px 0 7px 0',
                      textDecoration: 'none'
                    }}
                    as={LinkComponent}
                    className="hover:underline no-underline"
                    href={defaultPath}
                  >
                    My Account
                  </AccountMenuItem>
                  <AccountMenuItem
                    as={LinkComponent}
                    href={PATH_DASHBOARD}
                    style={{ fontSize: '14px',
                      color: pathname === PATH_DASHBOARD ? '#414042' : '#476692',
                      fontWeight: pathname === PATH_DASHBOARD ? 'bold' : 'normal',
                      textDecoration: 'none'
                    }}
                    className="hover:underline no-underline"
                  >
                      Account Home
                  </AccountMenuItem>
                  {menuLinks.linkGroup.map((group, index) => {
                  const isLastChild = menuLinks.linkGroup.length -1 === index;
                  return (
                    <>
                      <AccountMenuItemText
                        key={index}
                        style={{
                          margin: '0',
                          borderTop: group.title === 'My Settings' || group.title === 'Settings' ? '1px solid #e3e3e3' : 'none',
                          borderBottom: 'none',
                          fontSize: '11px',
                          color: '#999999',
                          textDecoration: 'none',
                          fontStyle: 'normal',
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                          padding: isLastChild ? '14px 0 8px 0' : '8px 0'
                        }}
                      >
                        {group.title}
                      </AccountMenuItemText>
                      {group.links.map((link, index) => {
                        const isLastChild = group.links.length -1 === index;
                        return(
                          <AccountMenuItem
                            key={index}
                            as={LinkComponent}
                            className="hover:underline no-underline"
                            href={handleOrgUrl(link.url)}
                            style={{
                              textDecoration: 'none',
                              fontSize: '14px',
                              color: (pathname === link.url) ? '#414042' : pathname.includes('manage-access') && link.url.includes('manage-access') ? '#414042' : '#476692',
                              paddingBottom: isLastChild ? '14px' : '8px',
                              fontWeight: (pathname === link.url )? 'bold' : pathname.includes('manage-access') && link.url.includes('manage-access') ? 'bold' : 'normal',
                            }}
                          >
                            {link.name}
                          </AccountMenuItem>
                      )})}
                    </>
                  )})}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                      margin: '12px 0 14px 0',
                      gap: '24px'
                    }}
                  >
                    <AccountMenuItem>
                      {({ close }) => (
                        <RedeemButton dropdown={true} onClick={close} />
                      )}
                    </AccountMenuItem>
                    <button type="button" onClick={onLogOut} style={{ fontSize: '14px', color: '#476692', background: 'transparent' }}>
                      <AccountMenuItem className="hover:underline no-underline">
                      Sign Out
                      </AccountMenuItem>
                    </button>
                  </div>
                </>
            )}
            {loading && view === MenuViews.ORG ? <div style={{ color: '#476692', paddingTop: '8px' }}>Loading...</div> : 
            view === MenuViews.ORG && (
              <>
                <AccountMenuItem
                  style={{
                    textWrap: 'balance',
                    margin: '0',
                    border: 'none',
                    fontSize: '16px',
                    color: '#476692',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    padding: '8px 0 7px 0',
                    }}
                  as={LinkComponent}
                  className="hover:underline no-underline"
                  href={orgDefaultPath}
                >
                  {selectedOrg?.organizationName}
                </AccountMenuItem>
                <AccountMenuItem
                  as={LinkComponent}
                  className="hover:underline no-underline"
                  href={ORG_PATHS.DASHBOARD.replace(
                    ':organizationId',
                    selectedOrg?.organizationId
                  )}
                  style={{ fontSize: '14px',
                  color: pathname === orgDefaultPath ? '#414042' : '#476692',
                  fontWeight: pathname === orgDefaultPath ? 'bold' : 'normal',
                  textDecoration: 'none'
                }}
                >
                    Account Home
                </AccountMenuItem>
                {(!hasTransactionHistoryAccess ? updatedOrgMenuLinks : orgMenuLinks).linkGroup.map((group, index) => {
                const isLastChild = (!hasTransactionHistoryAccess ? updatedOrgMenuLinks : orgMenuLinks).linkGroup.length -1 === index;
                return (
                  <>
                    <AccountMenuItemText
                      key={index}
                      style={{
                        margin: '0',
                        borderTop: group.title === 'My Settings' || group.title === 'Settings' ? '1px solid #e3e3e3' : 'none',
                        borderBottom: 'none',
                        fontSize: '11px',
                        color: '#999999',
                        textDecoration: 'none',
                        fontStyle: 'normal',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        padding: isLastChild ? '14px 0 8px 0' : '8px 0'
                      }}
                    >
                      {group.title}
                    </AccountMenuItemText>
                    {group.links.map((link, index) => {
                      const isLastChild = group.links.length -1 === index;
                      return (
                      <AccountMenuItem
                        key={index}
                        as={LinkComponent}
                        className="hover:underline no-underline"
                        href={handleOrgUrl(link.url)}
                        style={{
                          fontSize: '14px',
                          color: pathname === handleOrgUrl(link.url) ? '#414042' : '#476692',
                          paddingBottom: isLastChild ? '14px' : '8px',
                          fontWeight: pathname === handleOrgUrl(link.url) ? 'bold' : 'normal',
                          textDecoration: 'none'
                        }}
                      >
                        {link.name}
                      </AccountMenuItem>
                    )})}
                  </>
                )})}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', margin: '12px 0 14px 0', gap: '24px' }}>
                  <AccountMenuItem>
                    {({ close }) => (
                      <RedeemButton dropdown={true} onClick={close} />
                    )}
                  </AccountMenuItem>
                  <button type="button" aria-label="Sign Out" onClick={onLogOut} style={{ fontSize: '14px', color: '#476692', background: 'transparent' }}>
                    <AccountMenuItem className="hover:underline no-underline">
                    Sign Out
                    </AccountMenuItem>
                  </button>
                </div>
              </>
            )}
          </AccountMenuItems>
        )}
        {!isLoggedIn && (
          <AccountMenuItems className="shadow-lg no-underline" style={{ zIndex: '200', width: '80%', borderRadius: '12px', position: 'absolute', top: '44px', right: '10px', textAlign: 'center' }}>
            <AccountMenuItem>
              <Button type="button">
                <AccountMenuItemLink href={PATH_LOGIN_REDIRECT} style={{ color: 'white', textDecoration: 'none' }}>
                  Sign In
                </AccountMenuItemLink>
              </Button>
            </AccountMenuItem>
            <AccountMenuItem>
              <AccountMenuItemLink href="account.lifeway.com/register" style={{ fontSize: '14px', color: '#476692', textDecoration: 'none' }} className="hover:underline no-underline">
                Create Account
              </AccountMenuItemLink>
            </AccountMenuItem>
            <AccountMenuItem>
              <AccountMenuItemLink href={PATH_REDEEM_CODE} style={{ fontSize: '14px', color: '#476692', textDecoration: 'none' }} className="hover:underline no-underline">
                Redeem Code
              </AccountMenuItemLink>
            </AccountMenuItem>
          </AccountMenuItems>
        )}
      </div>
    </AccountMenu>
  );
};
AccountDropdown.propTypes = {
  onLogOut: func.isRequired,
  menuLinks: object,
  orgMenuLinks: object
};

export default AccountDropdown;