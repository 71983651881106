import React, { useEffect } from 'react';
import { string, object } from 'prop-types';
import NavigationMessagePage from '../NavigationMessagePage';
import { saveToLocalStorage } from '../../utils/locationHelper';

const EmbeddedPDF = ({ pdf, location }) => {
  useEffect(() => {
    saveToLocalStorage(location);
  }, []);

  return pdf
    ? <embed src={pdf} type="application/pdf" width="100%" height="100%" role="document"></embed>
    : <NavigationMessagePage message="creating pdf..." showActionableDelay={5000} />;
};

EmbeddedPDF.propTypes = {
  pdf: string,
  location: object,
};

export default EmbeddedPDF;
