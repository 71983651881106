import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import SigninRedirectPage from './oauth/SigninRedirectPage';

const MatchWhenAuthed = ({ component, isLoggedIn, ...props}) => {
  const render = isLoggedIn ? component : () => <SigninRedirectPage />;
  return <Route {...props} render={render} />;
};

const { func, bool } = PropTypes;

MatchWhenAuthed.propTypes = {
  component: func.isRequired,
  isLoggedIn: bool,
};

export default MatchWhenAuthed;
