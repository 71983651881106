import moment from 'moment';

export const getInitials = text =>
  text && text
    .split(/\s+/)
    .map(word => word[0])
    .filter(letter => letter != null)
    .join('')
    .substring(0, 4)
    .toUpperCase();

export const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const formatAccount = (account) => ({
  ...account,
  initialsColor: '#414042',
  label: account.organizationName,
  initials: getInitials(account.organizationName),
});

export const formatAccounts = (accounts) =>
  accounts?.map((account) => formatAccount(
    account
  ));

export const sortAlphabetically = (accounts) => {
  const sorted = [...accounts];
  sorted.sort((org1, org2) => {
    const orgName1 = org1.organizationName?.toLowerCase();
    const orgName2 = org2.organizationName?.toLowerCase();

    return orgName1.localeCompare(orgName2);
  });

  return sorted;
}

export const formatDate = date =>
  date
    ? moment.utc(date).utcOffset('+0100').format('MM/DD/YYYY')
    : undefined;

export const cleanPhoneCategory = (phone, acceptedPhoneTypes) => ({
  ...phone,
  category: phone.category && acceptedPhoneTypes.includes(phone.category)
    ? phone.category
    : '',
});
