import React, { useState , useEffect } from 'react';
import { getPaymentTokenPending, updateSubscriptionPaymentError } from '../../actions/AppActions';

import styled from 'styled-components';
import cssVars from '../../../../css/css-variables';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { getError } from '../../selectors/ErrorSelectors';
import Modal from '../Modal';
import HorizontalRule from '../HorizontalRule';
import Button from '../Button';
import SelectPaymentMethodTypes from './SelectPaymentMethodTypes';
import SelectPaymentMethodHeader from './SelectPaymentMethodHeader';
import SelectPaymentMethodCard from './SelectPaymentMethodCard';
import InfoCircleIcon from '../../../../img/sprite/info-circle.svg';
import OverLayMessage from '../OrgManagement/achFormComponents/OverLayMessage';
import MessageBanner from '../MessageBanner';


const SelectPaymentMethodModal = ({
  creditCards,
  isOrgSubs,
  billingAccs,
  payment,
  isImpersonationSession,
  show,
  closeOnClick,
  handleCancel,
  onSubmit,
  width,
  fullScreenOnMobile,
  paymentToken,
  subscriptionDetails,
  toggleTransfer,
  setTransferFormStep,
  setActivePaymentMethod,
  setSubscription,
  subscription,
  paymentsLoading,
}) => {
  const dispatch = useDispatch();
  const error = useSelector(getError);
  const errorMessage = error?.message;

  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PaymentTypes.CREDITCARD
  );
  const currentSubPaymentMethod = subscription?.paymentMethod;
  const subCreditCard = creditCards?.find(
    (creditCard) => creditCard?.lastFour === payment
  );
  const subBillingAcc = billingAccs?.find(
    (billingAcc) => billingAcc?.accountNumber === payment
    );
  
  const [activeCard, setActiveCard] = useState(subCreditCard ? subCreditCard  : subBillingAcc ? subBillingAcc : {});

  useEffect(() => {
     if (subBillingAcc) {
      setActiveCard(subBillingAcc);
    }
  }, [subBillingAcc, currentSubPaymentMethod]);

  const processingItem = useSelector((state) => state.app.processingItem);
  const isItemProcessing = (creditCards?.find((creditCard) => creditCard?.id === processingItem)) ||
  (billingAccs?.find((ba) => ba?.id === processingItem));


  const emptyPaymentText = (type) => {
    if (type === PaymentTypes.CREDITCARD) {
      return 'credit cards';
    } else if (type === PaymentTypes.BILLINGACCOUNT) {
      return 'billing accounts';
    }
  };

  const isIndividualSub =
    subscriptionDetails?.customer?.customerType === 'Individual';

  const handleOnClose = () => {
    setActiveCard(subCreditCard ? subCreditCard  : subBillingAcc ? subBillingAcc : {});
	  handleClearError();
    handleCancel();
  };

	function handleClearError() {
    dispatch(updateSubscriptionPaymentError({}));
  }

  const handleOnClick = (paymentType) => {
    if (paymentType === PaymentTypes.CREDITCARD) {
      setSelectedPaymentType(PaymentTypes.CREDITCARD);
    }
    if (paymentType === PaymentTypes.BILLINGACCOUNT) {
      setSelectedPaymentType(PaymentTypes.BILLINGACCOUNT);
    }
  };

  return (
    <>
      <Modal
        show={show}
        toggle={handleOnClose}
        closeOnClick={closeOnClick}
        width={width}
        fullScreenOnMobile={fullScreenOnMobile}
        style={{ padding: '0.5rem' }}
      >
        <>
        {isItemProcessing && <OverLayMessage>Saving Information. Please wait...</OverLayMessage>}
        <ModalContainer>
          <ModalHeader>Update Payment Method</ModalHeader>
		  {errorMessage && (
          <MessageBanner
            error
            message={errorMessage}
            clearBanner={handleClearError}
          />
        )}
          <PaymentMethodTypeContainer>
            <SelectPaymentMethodHeader
              selectedPaymentType={selectedPaymentType}
              handleOnClick={handleOnClick}
              isOrgSubs={isOrgSubs}
              isIndividualSub={isIndividualSub}
            />
          </PaymentMethodTypeContainer>
          {activeCard?.accountNumber !== undefined &&
            selectedPaymentType === PaymentTypes.BILLINGACCOUNT && !isOrgSubs && (
              <InfoBanner>
                <InfoSVG>
                  <use href={`#${InfoCircleIcon.id}`} />
                </InfoSVG>{' '}
                Paying by a billing account requires a transfer of ownership to
                the organization.
              </InfoBanner>
            )}
          <HorizontalRule
            borderTopWidth="1px"
            marginTop="1.1rem"
            marginBottom="0rem"
            color="#eeeeee"
          />
          {selectedPaymentType === PaymentTypes.CREDITCARD &&
            creditCards?.length === 0 && <MessageBanner>There are no available {emptyPaymentText(selectedPaymentType)} to select</MessageBanner>}
          {selectedPaymentType === PaymentTypes.BILLINGACCOUNT &&
            billingAccs?.length === 0 && <MessageBanner>There are no available {emptyPaymentText(selectedPaymentType)} to select</MessageBanner>}
          <GridContainer isItemProcessing={isItemProcessing}>
              <PaymentGrid>
                <SelectPaymentMethodTypes
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  creditCards={creditCards}
                  billingAccs={billingAccs}
                  subCreditCard={subCreditCard}
                  subPayment={payment}
                  isImpersonationSession={isImpersonationSession}
                  selectedPaymentType={selectedPaymentType}
                  paymentToken={paymentToken}
                  isIndividualSub={isIndividualSub}
                  subscription={subscription}
                />
              </PaymentGrid>
          </GridContainer>
          <BtnContainer>
            <Button variant="default" onClick={handleOnClose}>
              Cancel
            </Button>
            {
            activeCard?.hasOwnProperty('billingAccountId') && !isOrgSubs
            ? (
              <TransferBtn
                onClick={() => {
                  toggleTransfer();
                  setTransferFormStep(2);
                  setActivePaymentMethod(activeCard);
                  setSubscription();
                }}
                disabled={!activeCard?.billingAccountId}
              >
                Review/Transfer
              </TransferBtn>
              ) : (
                <SubmitBtn
                  onClick={() => onSubmit(activeCard)}
                  disabled={isItemProcessing}
                >
                  Update
                </SubmitBtn>
              )}
          </BtnContainer>
        </ModalContainer>
        </>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  const { app } = state;

  return {
    defaultPaymentMethod: app
      .getIn(['currentUser', 'paymentMethods', 'defaultPaymentMethod'])
      ?.toJS(),
    isImpersonationSession: app.get('isImpersonationSession'),
    paymentsLoading: app.getIn(['currentUser', 'isPaymentMethodsLoading']),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPaymentToken: (payload) => dispatch(getPaymentTokenPending(payload)),
  };
}
const { object, arrayOf, func, string, bool } = PropTypes;

SelectPaymentMethodModal.propTypes = {
  creditCards: arrayOf(object),
  billingAccs: arrayOf(object),
  payment: object,
  isImpersonationSession: bool,
  show: bool,
  toggle: func,
  closeOnClick: bool,
  handleCancel: func,
  onSubmit: func,
  width: string,
  fullScreenOnMobile: bool,
  paymentToken: string,
  setSubscription: func,
  paymentsLoading: bool,
};

export const PaymentTypes = {
  CREDITCARD: 'CREDITCARD',
  BILLINGACCOUNT: 'BILLINGACCOUNT',
};

const ModalContainer = styled.aside`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${cssVars.colors.Background};
  position: relative;
  width: 100%;
  height: 30.5rem;
  border-radius: 15px;

  padding: 1.5rem 1.5rem 1rem 1.5rem;

  text-align: left;
  font-size: 1rem;
`;

export const InfoSVG = styled.svg`
  margin-right: 8px;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const InfoBanner = styled.div`
  padding: 0.625em 1em;
  align-items: center;
  display: flex;
  align-items: center;

  font-size: ${cssVars.fontSize.smallest};
  line-height: 16px;

  background-color: ${cssVars.colors.Notification};
  color: ${cssVars.colors.NotificationText};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const PaymentMethodTypeContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ModalHeader = styled.h1`
  font-size: 18px;
  font-weight: bold;
  color: ${cssVars.colors.lifewayGray};
  margin-top: 0;
  margin-bottom: 11px;
  text-decoration: none solid rgb(65, 64, 66);
  line-height: 14px;
`;

export const BtnContainer = styled.div`
  display: flex;
  height: fit-content;
  justify-content: end;
  align-self: flex-end;
  margin-top: auto;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const SubmitBtn = styled(Button)`
  margin-left: 1rem;
`;

const TransferBtn = styled(Button)`
  margin-left: 1rem;
`;

const GridContainer = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 19rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ${({ isItemProcessing }) => isItemProcessing &&  'opacity: 0.5;'}
`;

export const PaymentGrid = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  margin-bottom: 10px;

  > div {
    min-height: 110px;
  }

  @media (${props => props.isTransfer ? 'max-width: 940px' : 'max-width: 630px'}) {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  @media (max-width: 720px) and (min-width: 630px) {
    ${props => !props.isTransfer && 'grid-template-columns: 1fr 1fr;'}
  }

  @media (max-width: 1114px) and (${props => props.isTransfer ? 'min-width: 941px' : 'min-width: 830px'}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 830px) and (min-width: 720px) {
    ${props => !props.isTransfer && 'grid-template-columns: 1fr 1fr;'}
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPaymentMethodModal);
