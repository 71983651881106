import React from 'react';
import AccountDropdownMenu from './AccountDropdownMenu';
import { menuLinks, orgMenuLinks } from '../account-menu/menuLinks';
import { Link } from 'react-router-dom';


const AccountMenuWrapper = ({
	orgId,
	loading,
	onLogOut,
	isLoggedIn,
	linkedOrganizations,
	showMenu,
	setViewAccountsModal,
	isMobile
}) => {
		return (
			<AccountDropdownMenu
				isMobile={isMobile}
				showMenu={showMenu}
				onLogOut={onLogOut}
				isLoggedIn={isLoggedIn}
				linkedOrganizations={linkedOrganizations}
				loading={loading}
				setViewAccountsModal={setViewAccountsModal}
				menuLinks={menuLinks}
				orgMenuLinks={orgMenuLinks}
				LinkElement={Link}
				orgId={orgId}
			/>
		)
}

export default AccountMenuWrapper;