import React, { useMemo } from 'react';
import styled from 'styled-components';
import { string, bool, object } from 'prop-types';
import { classnames } from '../utils/css';
import { randomColor } from '../utils/profileHelpers';
import { ENABLE_REBRANDING_PHASE_1 } from '../constants';
import css from '../../../css/css-variables';

const Circle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 3.2em;
  height: 3.2em;
  padding-top: 1px;

  background-color: rgb(255, 255, 255);

  font-size: 2em;
  letter-spacing: -2px;
  padding-right: 2px; /* to compensate for the letter-spacing */
  color: ${({ initialsColor }) => initialsColor || '#E6B952'};
  text-decoration: none;

  border-radius: 50%;
  border: 4px solid;

  margin-left: 0.8em;
  margin-right: 0.6em;
  margin-top: 0.3em;

  &.centered {
    margin-bottom: 0.3em;
  }

  @media (max-width: 720px) {
    font-size: 1.75em;
  }

  &.small {
    border-width: 2px;
    font-size: 0.75em;
    font-weight: 700;
    letter-spacing: 0px;
    padding-right: 1px;
  }

  &:before {
    content: attr(data-letters);
  }
`;

const InitialsCircle = ({
  initials,
  initialsColor,
  small,
  centered,
  ringColor,
  styles,
}) => {
  const defaultColor = useMemo(() => randomColor(), []);
  const color = initialsColor ?? defaultColor;
  const borderColor = determineBorderColor();

  function determineBorderColor() {
    if (ringColor) return ringColor;
    else {
      return ENABLE_REBRANDING_PHASE_1 ? css.colors.DartFrog : '#66AFE9';
    }
  }

  return (
    <Circle
      className={classnames({ small, centered })}
      data-qa-hook="initialsCircle"
      data-letters={initials}
      style={{
        color: color,
        borderColor: borderColor,
        ...styles,
      }}
    />
  );
};

export default InitialsCircle;

InitialsCircle.propTypes = {
  initials: string,
  initialsColor: string,
  small: bool,
  centered: bool,
  ringColor: string,
  styles: object,
};
