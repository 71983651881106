import React from 'react';
import styled from 'styled-components';
import loadingAnimation from '../../../img/sprite/loading.svg';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
`;

const Svg = styled.svg`
  fill: gray;
  width: 80px;
  height: 160px;
`;
const LoadingAnimation = () => (<Wrapper>
  <Svg className="loading" aria-hidden={true} data-qa-hook="invite-loading">
    <use href={`#${loadingAnimation.id}`} />
  </Svg>
</Wrapper>);

export default LoadingAnimation;
