import moment from 'moment';
import React from 'react';
import { PATH_ORG_MGMT, ORG_PATHS } from '../constants';
import { matchPath } from 'react-router';
import pendingProgress from '../../../img/progress-pending.svg';
import processingProgress from '../../../img/progress-processing.svg';
import completedProgress from '../../../img/progress-complete.svg';
import cancelledProgress from '../../../img/progress-cancelled.svg';
import onHoldProgress from '../../../img/circle-pause-regular.svg';

export const showOrderStatus = (status) => {
  switch (status) {
    case 'Pending':
      return 'Partially Shipped';
    case 'Processing':
      return 'Partially Shipped';
    default:
      return status;
  }
};

export const progressIcon = (status) => {
  switch (status) {
    case 'Pending':
      return pendingProgress;
    case 'Processing':
    case 'Partially Shipped':
      return processingProgress;
    case 'Completed':
    case 'Closed':
      return completedProgress;
    case 'Cancelled':
      return cancelledProgress;
    case 'On Hold':
      return onHoldProgress;
  }
};

export function getChannelName(channel) {
  let channelName;
  switch (channel) {
    case 'LW' : channelName = 'Lifeway.com'; break;
    case 'SG' : channelName = 'Sales Group'; break;
    case 'MG' : channelName = 'Ministry Grid'; break;
    case 'CO' : channelName = 'Church Ordering'; break;
    default: channelName = 'Other';
  }
  return channelName;
}

export function getSourceName(source) {
  let sourceName;
  switch (source) {
    case 'WEB FORM' : sourceName = 'Web'; break;
    case 'EMAIL' : sourceName = 'Email'; break;
    case 'TELEPHONE-INBOUND' :
    case 'TELEPHONE - INBOUND' :
    case 'TELEPHONE-OUTBOUND' :
    case 'VOICE MAIL' :
    case 'IN PERSON' :
    case 'PHONE' : sourceName = 'Phone'; break;
    case 'EDI' :
    case 'FAX' : sourceName = 'Fax'; break;
    default: sourceName = 'Other';
  }
  return sourceName;
}

export function getShippingMethod(method) {
  let priority;
  switch (method.toUpperCase()) {
    case 'HIGH' : priority = 'High Priority'; break;
    case 'NORMAL' : priority = 'Normal'; break;
    case 'RUSHI' : priority = 'Immediate Rush'; break;
    case 'RUSHN' : priority = 'Next Day Rush'; break;
    case 'STANDARD' : priority = 'Standard Priority'; break;
    default: priority = 'Unknown';
  }
  return priority;
}

export function getOrderStatusName(status) {
  let statusName;
  switch (status) {
    case 'Closed' : statusName = 'Completed'; break;
    case 'Submitted' :
    case 'OnHold' :
    case 'On Hold' :
    case 'Active' :
    case 'Researching' :
    case 'Released' :
    case 'IN_PROGRESS' :
    case 'Send to Fulfillment' : statusName = 'Pending'; break;
    case 'Rejected' : statusName = 'Cancelled'; break;
    default: statusName = status;
  }
  return statusName;
}

export function getObjectByTableName(tableName) {
  let orderObject;
  switch (tableName) {
    case 'processedOrders' : orderObject = 'personal'; break;
    case 'pendingOrders' : orderObject = 'personalPending'; break;
    case 'processedOrdersOrg' : orderObject = 'org'; break;
    case 'pendingOrdersOrg' : orderObject = 'orgPending'; break;
    case 'orders' : orderObject = 'user'; break;
    case 'orgOrders' : orderObject = 'organization'; break;
    default : orderObject = 'other';
  }
  return orderObject;
}

export function getTableNameByObject(obj) {
  let tableName;
  switch (obj) {
    case 'personal' : tableName = 'processedOrders'; break;
    case 'personalPending' : tableName = 'pendingOrders'; break;
    case 'org' : tableName = 'processedOrdersOrg'; break;
    case 'orgPending' : tableName = 'pendingOrdersOrg'; break;
    case 'user' : tableName = 'orders'; break;
    case 'organization' : tableName = 'orgOrders'; break;
    default : tableName = 'other';
  }
  return tableName;
}

export function formatOrderDate(date) {
  return moment.utc(date).utcOffset('0400').format('M/D/YYYY');
}

// amount can be number or string
export function formatMoney(amount, settings = { commas: true, symbol: '$', showSymbol: true }) {
  let amt = parseFloat(amount).toFixed(2);
  if (isNaN(amt)) return '';
  amt = amt.toString();
  amt = settings.commas ? amt.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : amt;
  amt = settings.showSymbol ? `${settings.symbol}${amt}` : amt;
  return amt;
}

export function formatTotalDue(amount, type) {
  const parsedAmt = parseFloat(amount).toFixed(2);
  if (parsedAmt === '0.00') {
    return '$0.00';
  }
  if (type === 'Credit Memo') {
    return `- $${parsedAmt.substring(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  return `$${parsedAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

export function orderDateToISO(date, endOfDay = false) {
  const p = date.split('/');
  const parts = [];
  p.forEach(part => { parts.push(parseInt(part, 10)); });
  const month = (parts[0] < 10) ? `0${parts[0]}` : parts[0];
  const day = (parts[1] < 10) ? `0${parts[1]}` : parts[1];
  return `${parts[2]}-${month}-${day} `+ (endOfDay ? '23:59:59' : '00:00:00');
}

//2017-04-05T06:41:21.000-05:00
export function orderDateToIsoUTC(date, endOfDay = false) {
  let d = orderDateToISO(date, endOfDay);
  d = d.substr(0, 10) + 'T' + d.substr(11);
  d += '0-4:00';
  return d;
}

const matchOrgBillingAccountRoute = (route) =>
  matchPath(route, { path: ORG_PATHS.ACCOUNT_ACTIVITY }) ||
  matchPath(route, { path: ORG_PATHS.ORDER_HISTORY });

export function getBillingIdFromPathname(pathname) {
  const orgBAMatch = matchOrgBillingAccountRoute(pathname);
  if (orgBAMatch) return orgBAMatch.params.billingAccountId;

  if (pathname.indexOf(PATH_ORG_MGMT) === -1) return null;
  return pathname.split('/')[3];
}

export function getBillingAccountById(id, accounts) {
  let acct;
  accounts.forEach(a => {
    if (a.billingAccountId === id) acct = a;
  });
  return acct;
}

const matchOrgRoute = (route) =>
  matchPath(route, { path: ORG_PATHS.BASE });

export function getOrgIdFromPathname(billingAccs, pathname) {
  const orgMatch = matchOrgRoute(pathname);
  if (orgMatch) return orgMatch.params.organizationId;

  if (pathname.indexOf(PATH_ORG_MGMT) === -1) return null;
  const billingId = getBillingIdFromPathname(pathname);
  let orgId;
  billingAccs.forEach(acct => {
    if (acct.id === billingId) orgId = acct.organizationId;
  });
  return orgId;
}

export function getOrderByOrderNumber(orders, orderNumber, billingId) {
  const tables = billingId ? ['org', 'orgPending'] : ['personal', 'personalPending'];
  let allOrders = [];
  for (let i = 0; i < tables.length; i += 1) {
    const index = billingId ? orders[tables[i]][billingId] : orders[tables[i]];
    if (index) {
      allOrders = allOrders.concat(index.rows || []);
    }
  }
  const order = allOrders.find(o => o._id === orderNumber) || null;
  return order;
}

export function getOrderObjectById(orders, orderNumber, billingId) {
  const tables = billingId ? ['org', 'orgPending'] : ['personal', 'personalPending'];
  let obj;
  for (let i = 0; i < tables.length; i += 1) {
    const index = billingId ? orders[tables[i]][billingId] : orders[tables[i]];
    if (index && index.rows) {
      const order = index.rows.find(o => o._id === orderNumber) || null;
      if (order) obj = tables[i];
    }
  }
  return obj;
}

export function getShippingLink({ shippingTrackingId, shippingType}) {
  const FEDEX = 'https://fedex.com/Tracking?action=track&language=english&tracknumbers=';
  const UPS = 'https://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&tracknums_displayed=25&sort_by=status&InquiryNumber1=';
  const USPS = 'https://tools.usps.com/go/TrackConfirmAction?tLabels=';

  switch (shippingType) {
    case 'UPS' :
      return `${UPS}${shippingTrackingId}`;
    case 'FEDEX':
      return`${FEDEX}${shippingTrackingId}`;
    case 'USPS':
      return`${USPS}${shippingTrackingId}`;
    default:
      return undefined;
  }
}

/*
 * The functions below help to generate fake order data
 * {
 *  "orderNumber": "78331b51-fa7e-4866-97ae-2a882e5b41af",
 *  "orderDate": "2017/03/05T17:31:22",
 *  "orderTotal": "$12.74",
 *  "source":"WEB",
 *  "status":"Processing"
 * }
 */

export function guidToDisplayID(str) {
  // strip non alpha-numeric chars like '-'
  let id = str?.replace(/[^a-z0-9]/gi, '')?.substr(0, 12);

  // split into chunks of 4 chars
  id = id?.match(/.{1,4}/g);

  // return XXXX-XXXX-XXXX
  return id?.join('-')?.toUpperCase();
}

export function getSource() {
  const opts = ['WEB', 'PHONE', 'FAX'];
  return opts[Math.floor(Math.random() * opts.length)];
}

export function timestampToOracleDate(ts) {
  return moment.utc(ts).format('YYYY/MM/DDTHH:mm:ss');
}

export function getOrderTotal() {
  const amount = getRandomFloat(15, 400).toFixed(2);
  return `$${amount}`;
}

export function getOrderStatus() {
  const opts = ['Processing', 'Partially Fulfilled', 'Completed', 'Cancelled'];
  return opts[Math.floor(Math.random() * opts.length)];
}

export function getPendingOrderStatus() {
  const opts = ['Submitted', 'OnHold', 'Active', 'Researching', 'Rejected', 'Released', 'Send to Fulfillment'];
  return opts[Math.floor(Math.random() * opts.length)];
}

export function getSubmittedBy() {
  const opts = ['Jason D.', 'Sai R.', 'Michael S.', 'Shelley R.', 'Brandon H.', 'Stephen M.'];
  return opts[Math.floor(Math.random() * opts.length)];
}

export function getPersonalOrderData(settings = {}) {
  const orders = [];
  const resultsLength = (settings.resultsLength !== undefined) ? settings.resultsLength : 100;
  const startDate = settings.startDate || undefined;
  let date = moment(startDate).subtract(2, 'days').valueOf();

  for (let i = 0; i < resultsLength; i += 1) {
    const order = {};
    if (i % getRandomInt(2, 4) === 0) date -= 8.64e+7;
    order.orderNumber = guid();
    order.displayId = guidToDisplayID(order.orderNumber);
    order.orderDate = timestampToOracleDate(date);
    order.orderTotal = getOrderTotal();
    order.source = getSource();
    order.status = getOrderStatus();
    orders.push(order);
  }
  return orders;
}

export function getPersonalPendingOrderData(settings = {}) {
  const resultsLength = settings.resultsLength || 100;
  const orders = getPersonalOrderData(settings);
  for (let i = 0; i < resultsLength; i += 1) {
    orders[i].status = getPendingOrderStatus();
    orders[i].source = undefined;
  }
  return orders;
}

export function getOrgOrderData(settings = {}) {
  const resultsLength = settings.resultsLength || 100;
  const orders = getPersonalOrderData(settings);
  for (let i = 0; i < resultsLength; i += 1) {
    orders[i].submittedBy = getSubmittedBy();
  }
  return orders;
}

export const httpPersonalOrders = {
  get(settings) {
    return new Promise(resolve => {
      setTimeout(() => {
        const orders = getPersonalOrderData(settings);
        const totalItems = (settings.totalItems !== undefined) ? settings.totalItems : 200;
        resolve({ data: { totalItems, rows: orders } });
      }, getRandomFloat(0, 2) * 1000);
    });
  }
};

export const httpPersonalPendingOrders = {
  get(settings) {
    return new Promise(resolve => {
      setTimeout(() => {
        const orders = getPersonalPendingOrderData(settings);
        const totalItems = (settings.totalItems !== undefined) ? settings.totalItems : 200;
        resolve({ data: { totalItems, rows: orders } });
      }, getRandomFloat(0, 2) * 1000);
    });
  }
};

export const httpOrgOrders = {
  get(settings) {
    return new Promise(resolve => {
      setTimeout(() => {
        const orders = getOrgOrderData(settings);
        const totalItems = (settings.totalItems !== undefined) ? settings.totalItems : 200;
        resolve({ data: { totalItems, rows: orders } });
      }, getRandomFloat(0, 2) * 1000);
    });
  }
};

export const sortOrdersByDate = (orders) => {
  return orders.sort((a, b) => {
    // Parse the orderDate strings into Date objects for comparison
    const dateA = new Date(a.orderDate);
    const dateB = new Date(b.orderDate);

    // Compare the dates to determine their order
    return dateB - dateA;
  });
};

export const sortItemNumbers = (itemNumbers) => {

  const compareItemNumbers = (a, b) => {
    if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    } else {
      return 0
    }
  };

  return itemNumbers?.sort(compareItemNumbers);
};