import { createLogic } from 'redux-logic';
import { submitNewsletterFormError, submitNewsletterFormSuccess } from '../actions/AppActions';
import { SUBMIT_NEWSLETTER_FORM_PENDING } from '../constants';

export const submitNewsLetterFormLogic = createLogic({
  type: SUBMIT_NEWSLETTER_FORM_PENDING,
  latest: true,
  process({ httpClient, action: { payload }}, dispatch, done) {
    const { values, actions } = payload;
    httpClient(dispatch)
      .post('/newsletter-form', values)
      .then(res => {
        dispatch(submitNewsletterFormSuccess(res.data));
        actions?.setSubmitting?.(false);
        actions?.onClose?.();
        done();
      })
      .catch(error => {
        dispatch(submitNewsletterFormError({ err: error}));
        actions?.setSubmitting?.(false);
        done();
      });
  }
});

export default [submitNewsLetterFormLogic];