import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogicMiddleware } from 'redux-logic';
import httpClient from '../utils/httpClient';
import rootReducer from '../reducers/index';
import logic from '../logic';

const logicMiddleware = createLogicMiddleware(logic, { httpClient });

const createStoreWithMiddleware = applyMiddleware(logicMiddleware, thunkMiddleware)(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
}
