import React from 'react';
import styled from 'styled-components';
import InitialsCircle from '../../InitialsCircle';
import { Name } from './ProfileLink';
import ArrowIcon from '../../../../../img/sprite/down-chevron.svg';
import { func, string } from 'prop-types';

const DownArrow = styled.div`
  & svg {
    width: 1em;
    height: 1em;
    color: #16395f;
    transform: translateY(-1px);
  }
`;
const InitialsNameContainer = styled.div`
  min-width: 0px;
  display: flex;
  align-items: center;
`;

const ActiveProfileButton = ({ onClick, initials, name }) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      style={{ justifyContent: 'space-between' }}
      aria-label="View Account Options"
    >
      <InitialsNameContainer>
        <InitialsCircle
          initials={initials}
          initialsColor="#414042"
          small={true}
          centered={true}
          ringColor="#3D6995"
          styles={{ margin: 0 }}
        />
        <Name>{name}</Name>
      </InitialsNameContainer>
      <DownArrow role="presentation">
        <svg>
          <use href={`#${ArrowIcon.id}`} xlinkHref={`#${ArrowIcon.id}`} />
        </svg>
      </DownArrow>
    </Button>
  );
};

export default ActiveProfileButton;

ActiveProfileButton.propTypes = {
  onClick: func,
  initials: string,
  name: string,
};

const Button = styled.button`
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  width: 250px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0 10px 0 0;
  font-family: sofia-pro, "helvetica neue", helvetica, arial, sans-serif;
`;