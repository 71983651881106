import { createLogic } from 'redux-logic';
import { stopSubmit, setSubmitFailed, reset } from 'redux-form';
import {
  PROFILE_ADD_PHONE_PENDING, PATH_PROFILE,
  PROFILE_UPDATE_PHONE_PENDING, PROFILE_DELETE_PHONE_PENDING,
  PROFILE_DELETE_PHONE_CONFIRM, PROFILE_GET_PHONE_PENDING
} from '../constants';

import {
  addPhoneSuccess, addPhoneError, deletePhoneSuccess, deletePhoneError,
  updatePhoneSuccess, updatePhoneError, triggerModal, deletePhonePending,
  toggleModal, toggleForm, toggleProcessedItem, setBannerState, getPhonePending, getPhoneSuccess, getPhoneError
} from '../actions/AppActions';

import { formatPhoneNumberForSubmit } from '../utils/phoneUtils';
import history from '../utils/history';

export const addPhonePendingLogic = createLogic({
  type: PROFILE_ADD_PHONE_PENDING,
  latest: true,

  process({ httpClient, action, getState }, dispatch, done) {
    const form = action?.payload?.form;
    const state = getState();
    const intl = state.intl.messages;

    const postData = {
      countryCode: action.payload.subscriberNumber_cc,
      subscriberNumber: action.payload.subscriberNumber_cc && action.payload.subscriberNumber
        ? formatPhoneNumberForSubmit(action.payload.subscriberNumber_cc, action.payload.subscriberNumber)
        : '',
      category: action.payload.category,
      defaultPhone: Boolean(action.payload.defaultPhone)
    };

    return httpClient(dispatch).post('/phone-numbers', postData)
      .then(resp => {
        dispatch(stopSubmit(form));
        dispatch(addPhoneSuccess(resp.data));
        // close form
        history.push(PATH_PROFILE);
        // clear form data after it has collapsed
        dispatch(reset(form));
        done();
      })
      .catch(error => {
        const err = error?.response?.data ?? error;
        dispatch(stopSubmit(form, { _error: intl.Errors.Profile.Add_Phone_Error }));
        dispatch(addPhoneError(err));
        dispatch(setSubmitFailed(form));
        done();
      });
  }
});

export const getPhonePendingLogic = createLogic({
  type: PROFILE_GET_PHONE_PENDING,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient(dispatch).get('/phone-numbers')
      .then(res => {
        dispatch(getPhoneSuccess(res.data));
        done();
      })
      .catch(error => {
        dispatch(getPhoneError(error));
        done();
      });
  }
});

export const updatePhonePendingLogic = createLogic({
  type: PROFILE_UPDATE_PHONE_PENDING,
  latest: true,

  process({ httpClient, action, getState }, dispatch, done) {
    const form = action?.payload?.form;
    const state = getState();
    const intl = state.intl.messages;
    const countryCode = action.payload.subscriberNumber_cc || action.payload.countryCode;

    const putData = {
      countryCode,
      id: action.payload.id,
      subscriberNumber: action.payload.makeDefault
        ? action.payload.subscriberNumber
        : formatPhoneNumberForSubmit(countryCode, action?.payload?.subscriberNumber ?? ''),
      category: action.payload.category,
      defaultPhone: Boolean(action.payload.defaultPhone)
    };

    httpClient(dispatch).put('/phone-numbers', putData)
      .then(resp => {
        dispatch(stopSubmit(form));
        dispatch(updatePhoneSuccess({ existingId: putData.id, updatedPhoneNumber: resp.data }));
        // close form
        history.push(PATH_PROFILE);
        done();
      })
      .catch(error => {
        const err = error?.response?.data ?? error;

        dispatch(stopSubmit(form, { _error: intl.Errors.Profile.Update_Phone_Error }));
        dispatch(updatePhoneError(err));
        dispatch(setSubmitFailed(form));

        if (action?.payload?.makeDefault) {
          const bannerState = {
            data: { type: 'error', message: intl.Errors.Profile.Update_Phone_Error }
          };
          dispatch(toggleProcessedItem());
          dispatch(setBannerState(bannerState));
          done();
        }

        done();
      });
  }
});

export const deletePhoneConfirmLogic = createLogic({
  type: PROFILE_DELETE_PHONE_CONFIRM,
  latest: true,

  processOptions: {
    dispatchMultiple: true,
  },

  process({ action }, dispatch) {

    const modalProps = {
      show: true,
      message: `Are you sure you want to delete the following number: ${action.payload.formatted}?`,
      okLabel: 'Yes',
      okDataHook: 'deletePhoneYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'deletePhoneNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(toggleProcessedItem(action.payload.id));
        dispatch(deletePhonePending(action.payload.id));
        dispatch(toggleModal());
      },
      cancelClick: () => {
        history.push(PATH_PROFILE);
        dispatch(toggleModal());
      }
    };
    dispatch(toggleForm(null));
    dispatch(triggerModal(modalProps));
  }
});

export const deletePhonePendingLogic = createLogic({
  type: PROFILE_DELETE_PHONE_PENDING,
  latest: true,

  process({ httpClient, action, getState }, dispatch, done) {
    const state = getState();
    const intl = state.intl.messages;
    const phoneNumberId = action?.payload;

    httpClient(dispatch).delete(`/phone-numbers/${phoneNumberId}`)
      .then(() => {
        history.push(PATH_PROFILE);
        dispatch(deletePhoneSuccess(phoneNumberId));
        done();
      })
      .catch(error => {
        const bannerState = {
          data: { type: 'error', message: intl.Errors.Profile.Delete_Phone_Error }
        };
        history.push(PATH_PROFILE);
        dispatch(toggleProcessedItem());
        dispatch(deletePhoneError(error));
        dispatch(setBannerState(bannerState));
        done();
      });
  }
});

export default [
  addPhonePendingLogic,
  updatePhonePendingLogic,
  deletePhoneConfirmLogic,
  deletePhonePendingLogic,
  getPhonePendingLogic
];
