import { func, bool, string, array } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import AccountMenuWrapper from './account-menu/AccountMenuWrapper';
import AccountDropdown from './account-menu/AccountDropdown';
import ProfileInitials from './account-menu/ProfileInitials';
import HamburgerMenu from './HamburgerMenuButton';

const HeaderLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5em;
  & > *:not(:first-child) {
    margin-left: 26px;
  }
  z-index: 101;
`;

const MobileLinksWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 290px;
  margin: 0.375rem 0.625rem 0 0;
  & > * {
    flex: 0 0 auto;
    margin-left: 1.25rem;
  }
  & > .text-button {
    margin-right: 0.25rem;
  }
`;

const HeaderLinks = ({
  isLoggedIn,
  isMobile,
  onLogOut,
  linkedOrganizations,
  loading,
  setViewAccountsModal,
  openedNavPanel,
  toggleNavPanel,
  orgId,
}) => {
  if (isMobile && isLoggedIn) {
    return (
      <MobileLinksWrapper>
        <AccountMenuWrapper
          onLogOut={onLogOut}
          isLoggedIn={isLoggedIn}
          linkedOrganizations={linkedOrganizations}
          loading={loading}
          isMobile={isMobile}
          setViewAccountsModal={setViewAccountsModal}
          orgId={orgId}
        />
      </MobileLinksWrapper>
    );
  } else if (isMobile && !isLoggedIn) {
    return (
      <MobileLinksWrapper>
      </MobileLinksWrapper>
    );
  } else if (!isMobile && !isLoggedIn) {
    return (
      <HeaderLinkWrapper>
      </HeaderLinkWrapper>
    )
  } else if (!isMobile && isLoggedIn) {
    return (
      <HeaderLinkWrapper>
        <AccountMenuWrapper
          onLogOut={onLogOut}
          isLoggedIn={isLoggedIn}
          linkedOrganizations={linkedOrganizations}
          loading={loading}
          isMobile={isMobile}
          setViewAccountsModal={setViewAccountsModal}
          orgId={orgId}
        />
      </HeaderLinkWrapper>
    );
  } else {
    return <HeaderLinkWrapper />;
  }
};

HeaderLinks.propTypes = {
  isLoggedIn: bool,
  isMobile: bool,
  toggleNavPanel: func,
  openedNavPanel: string,
  onLogOut: func,
  linkedOrganizations: array,
  loading: string,
  setViewAccountsModal: func
};

export default HeaderLinks;
