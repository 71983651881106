import discoverLogo from '../../../img/discover.png';
import americanExpressLogo from '../../../img/americanExpress.png';
import mastercardLogo from '../../../img/mastercard.png';
import visaLogo from '../../../img/visa.png';

export const cardType = token => {
  switch (token?.slice(1, 3)) {
    case '34':
      return 'American Express';
    case '44':
      return 'Visa';
    case '54':
      return 'MasterCard';
    case '64':
      return 'Discover';
    default:
      return 'Other';
  }
};

export function getCreditCardLogo({ token, cardType: _cardType }) {
  if (_cardType) {
    if (
      _cardType.toUpperCase() === 'AMEX' ||
      _cardType.toUpperCase() === 'AMERICAN_EXPRESS' ||
      _cardType.toUpperCase() === 'AMERICAN EXPRESS'
    )
      return americanExpressLogo;
    if (_cardType.toUpperCase() === 'DISCOVER') return discoverLogo;
    if (_cardType.toUpperCase() === 'MASTERCARD') return mastercardLogo;
    if (_cardType.toUpperCase() === 'VISA') return visaLogo;
    else return visaLogo;
  } else {
    const type = cardType(token);
    if (type === 'American Express') return americanExpressLogo;
    else if (type === 'Discover') return discoverLogo;
    else if (type === 'MasterCard') return mastercardLogo;
    else if (type === 'Visa') return visaLogo;
    else return visaLogo;
  }
}

/*
  The credit card is still valid through the end of the expiration month,
  but Date() expects months to start at 0 not 1.

  Thus the first date that the card is expired is `new Date(year, (month - 1) + 1)`
  which is the same as `new Date(year, month)`
*/
export const isExpiredAt = (cc, date) =>
  new Date(cc.expirationYear, cc.expirationMonth) <= new Date(date);

export const isExpired = cc => isExpiredAt(cc, new Date());

export const formatBillingAccountPaymentMethod = ({
  accountNumber: account,
  billingAccountId,
  organizationId,
  id,
} = {}) => ({
  id,
  account,
  billingAccountId,
  organizationId,
});

export const formatCreditCardPaymentMethod = ({
  token,
  cardHolderName,
  cardType,
  lastFour,
  cardNumberMask,
  expirationMonth,
  expirationYear,
  id,
} = {}) => ({
  card: {
    id,
    cardholderName: cardHolderName,
    expMonth: expirationMonth,
    expYear: expirationYear,
    token,
    cardType,
    cardMask: lastFour || cardNumberMask,
  },
});

export const formatAddress = (address = {}, user = {}) => {
  return {
    firstName: address.firstName || user.firstName,
    lastName: address.lastName || user.lastName,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.countrySubdivision,
    zip: address.postalCode,
    country: address.countryCode,
    addressType: address.type,
    phone: address.phoneNumber,
    id: address.id,
    organization: address.organizationName,
  };
};
