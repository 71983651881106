import { IMAGE_BASE_URL } from '@lifewayit/components';

const footerData = {
  newsletter: {
    formId: 'ID_Newsletter_25',
    buttonText: 'Sign Up',
    hiddenFields: [
      {
        componentNodeName: 'item0',
        hiddenValue: 'LW_update',
        hiddenName: 'subscriptionName',
      },
      {
        componentNodeName: 'item1',
        hiddenValue: 'T',
        hiddenName: 'toCampaign',
      },
      {
        componentNodeName: 'item2',
        hiddenValue: 'Resources',
        hiddenName: 'formOwner',
      },
      {
        componentNodeName: 'item3',
        hiddenValue: 'Integrated Digital',
        hiddenName: 'formOwnerArea',
      },
      {
        componentNodeName: 'item4',
        hiddenValue: 'Other',
        hiddenName: 'formBrand',
      },
      {
        componentNodeName: 'item5',
        hiddenValue: 'ID_Newsletter_25',
        hiddenName: 'formName',
      },
    ],
    headingText: "Don't Miss a Thing!",
    campaignId: '1234567890',
    componentNodeName: 'newsletter',
    formLabel: 'Email Address',
    'sling:resourceType': 'lifeway/components/section/configuration/newsletter',
    formLastNameLabel: 'Last Name',
    successMessage: 'Congratulations',
    formFirstNameLabel: 'First Name',
  },
  followsocialmedia: {
    componentNodeName: 'followsocialmedia',
    icon: '[fa-facebook]',
    linkUrl: '[https://www.facebook.com/]',
    'sling:resourceType':
      'lifeway/components/section/configuration/followsocialmedia',
    mediaApps: [
      {
        linkAriaLabel: 'Lifeway Facebook',
        componentNodeName: 'item0',
        icon: 'fa-facebook-f',
        linkUrl: 'https://www.facebook.com/lifeway/',
      },
      {
        linkAriaLabel: 'Lifeway Twitter',
        componentNodeName: 'item1',
        icon: 'fa-twitter',
        linkUrl: 'https://twitter.com/lifeway',
      },
      {
        linkAriaLabel: 'Lifeway Instagram',
        componentNodeName: 'item2',
        icon: 'fa-instagram',
        linkUrl: 'https://www.instagram.com/lifeway',
      },
      {
        linkAriaLabel: 'Lifeway LinkedIn',
        componentNodeName: 'item3',
        icon: 'fa-linkedin-in',
        linkUrl:
          'https://www.linkedin.com/company/lifeway-christian-resources/',
      },
    ],
  },
  bottomfooter: {
    lwAccountImage: `${IMAGE_BASE_URL}/lw_account_logo_white?fmt=png-alpha`,
    creditCardProviders: [
      {
        provider: 'fa-cc-paypal',
        componentNodeName: 'item0',
      },
      {
        provider: 'fa-cc-visa',
        componentNodeName: 'item1',
      },
      {
        provider: 'fa-cc-mastercard',
        componentNodeName: 'item2',
      },
      {
        provider: 'fa-cc-amex',
        componentNodeName: 'item3',
      },
      {
        provider: 'fa-cc-discover',
        componentNodeName: 'item4',
      },
    ],
    componentNodeName: 'bottomfooter',
    certificationText: ' Your trusted source of Christian resources since 1891',
    paymentMethodsText: 'Payment Options: ',
    lifeWayAccountTooltipText:
      "Lifeway offers billing accounts with 30 day payment terms, upon credit approval, for churches and organizations who purchase products or services from any of our sales channels. Having a LifeWay account enables you to view your church or organization's invoices and purchase history and to create replenishment orders based on previous purchases. Call 1-800-458-2772 to set up a Lifeway Account.",
    certificationImageURL:
      'http://www.bbb.org/nashville/business-reviews/religious-goods/lifeway-christian-resources-in-nashville-tn-11175',
    'sling:resourceType':
      'lifeway/components/section/configuration/bottomfooter',
    copyrightText: 'Lifeway Christian Resources',
    certifications: [
      {
        componentNodeName: 'item0',
        certificationImageURL:
          'https://www.bbb.org/us/tn/nashville/profile/religious-goods/lifeway-christian-resources-0573-11175#bbbseal',
        certificationImage:
          'https://seal-nashville.bbb.org/logo/rbhzbus/lifeway-christian-resources-11175.png',
      },
    ],
    certificationScript:
      "<script type=\"text/javascript\">var bbbprotocol = ( (\"https:\" == document.location.protocol) ? \"https://\" : \"http://\" ); (function(){var s=document.createElement('script');s.src=bbbprotocol + 'seal-nashville.bbb.org' + unescape('%2Flogo%2Flifeway-christian-resources-11175.js');s.type='text/javascript';s.async=true;var st=document.getElementsByTagName('script');st=st[st.length-1];var pt=st.parentNode;pt.insertBefore(s,pt.nextSibling);})();</script>",
    certificationImage: '/content/dam/lifeway/static/seal-for-11175.png',
  },
  upperfooter: {
    componentNodeName: 'upperfooter',
    'sling:resourceType':
      'lifeway/components/section/configuration/upperfooter',
    columnsList: [
      {
        columnLinksList: [
          {
            componentNodeName: 'item0',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about',
            columnLinkText: 'About Us',
          },
          {
            componentNodeName: 'item1',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/contact-information',
            columnLinkText: 'Contact Us',
          },
          {
            componentNodeName: 'item2',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/careers',
            columnLinkText: 'Careers',
          },
          {
            componentNodeName: 'item3',
            manualLinkTarget: '_self',
            columnInternalUrl: 'http://blog.lifeway.com/newsroom/',
            columnLinkText: 'Newsroom',
          },
          {
            componentNodeName: 'item4',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/licensing-permission-requests',
            columnLinkText: 'Licensing & Permissions',
          },
          {
            componentNodeName: 'item5',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/privacy-policy',
            columnLinkText: 'Privacy Policy',
          },
          {
            componentNodeName: 'item6',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/terms-of-use',
            columnLinkText: 'Terms of Use',
          },
          {
            componentNodeName: 'item7',
            manualLinkTarget: '_self',
            columnInternalUrl:
              'https://support.lifeway.com/how-do-i-submit-a-product-for-sale-with-lifeway-christian-resources/',
            columnLinkText: 'Product Submission',
          },
          {
            componentNodeName: 'item8',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/writing-for-lifeway',
            columnLinkText: 'Writing for Lifeway',
          },
          {
            componentNodeName: 'item9',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/authorized-dealers',
            columnLinkText: 'Find an Authorized Dealer',
          },
        ],
        componentNodeName: 'item0',
        columnTitle: 'About Lifeway',
      },
      {
        columnLinksList: [
          {
            componentNodeName: 'item0',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://my.lifeway.com/order-history',
            columnLinkText: 'My Orders',
          },
          {
            componentNodeName: 'item1',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://my.lifeway.com/media-content',
            columnLinkText: 'My Downloads',
          },
          {
            componentNodeName: 'item2',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/shipping-information',
            columnLinkText: 'Shipping Rates',
          },
          {
            componentNodeName: 'item3',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/return-policy',
            columnLinkText: 'Return Policy',
          },
          {
            componentNodeName: 'item4',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/shop/gift-cards',
            columnLinkText: 'Gift Cards',
          },
          {
            componentNodeName: 'item5',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/about/contact-information',
            columnLinkText: 'Help',
          },
          {
            openChatLink: 'true',
            componentNodeName: 'item6',
            manualLinkTarget: '_self',
            isSpecialLink: 'on',
            columnLinkText: 'Live Chat!',
          },
        ],
        componentNodeName: 'item1',
        columnTitle: 'My Account',
      },
      {
        columnLinksList: [
          {
            componentNodeName: 'item1',
            manualLinkTarget: '_self',
            columnInternalUrl: 'http://www.ministrygrid.com/',
            columnLinkText: 'Ministry Grid',
          },
          {
            componentNodeName: 'item3',
            manualLinkTarget: '_self',
            columnInternalUrl: 'http://www.lifewayworship.com/',
            columnLinkText: 'Lifeway Worship',
          },
          {
            componentNodeName: 'item5',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://kideventpro.lifeway.com/login.aspx',
            columnLinkText: 'Kid Event Pro',
          },
          {
            componentNodeName: 'item6',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/shop/bible-studies/digital',
            columnLinkText: 'Downloads',
          },
          {
            componentNodeName: 'item8',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://live.lifeway.com/',
            columnLinkText: 'Simulcast Management',
          },
          {
            componentNodeName: 'item9',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.lifeway.com/en/shop/books/ebooks',
            columnLinkText: 'Lifeway  eBooks',
          },
          {
            componentNodeName: 'item10',
            manualLinkTarget: '_self',
            columnInternalUrl: 'https://www.smallgroup.com',
            columnLinkText: 'SmallGroup.com',
          },
        ],
        componentNodeName: 'item2',
        columnTitle: 'Digital Resources',
      },
    ],
  },
};

export default footerData;