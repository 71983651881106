import { any } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DefaultBadge = ({ children }) => {
  return <Badge>Default {children}</Badge>;
};

export const Badge = styled.span`
  background-color: #eee;
  padding: 3px 6px;
  color: #414042;
  font-size: 13px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 10px;
  letter-spacing: 0.6px;
  opacity: 0.8;
`;

DefaultBadge.propTypes = {
  children: any
};
export default DefaultBadge;
