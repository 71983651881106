import { createLogic } from 'redux-logic';
import { LOCATION_CHANGE } from 'react-router-redux';

export const locationChangeLogic = createLogic({
  type: LOCATION_CHANGE,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const state = getState();
    const userId = state.app.getIn(['currentUser', 'id']);
    if ('digitalData' in window) {
      const { pathname } = action.payload.location;
      if (pathname) {
        digitalData.page.pageInfo.pageName = pathname.substring(1).replace(/\/?[a-f\d-]{36}/gi, '').replace(/\//g, ':');
      }
    }
    if ('_satellite' in window && userId) _satellite.track('pageload');
    done();
  }
});

export default [
  locationChangeLogic
];
