import {
	PATH_AUTOSHIP,
	PATH_DIGITAL_MEDIA,
	PATH_ORDER_HISTORY,
	PATH_PAYMENT_METHODS,
	PATH_PROFILE,
	PATH_SUBSCRIPTIONS,
	PATH_USER_ADDRESS_MANAGEMENT,
  ORG_PATHS,
  PATH_GIFT_CARDS,
  PATH_MANAGE_ACCESS
} from "../../../constants";

export const menuLinks = {
  linkGroup: [
    {
      title: 'My Orders',
      links: [
        {
          name: 'Order History',
          url: PATH_ORDER_HISTORY
        },
        {
          name: 'Autoship',
          url: PATH_AUTOSHIP
        },
        {
          name: 'Subscriptions',
          url: PATH_SUBSCRIPTIONS
        },
        {
          name: 'Digital Library',
          url: PATH_DIGITAL_MEDIA
        },
        {
          name: 'Manage Access',
          url: PATH_MANAGE_ACCESS
        }
      ]
    },
    {
      title: 'My Settings',
      links: [
        {
          name: 'Profile',
          url: PATH_PROFILE
        },
        {
          name: 'Addresses',
          url: PATH_USER_ADDRESS_MANAGEMENT
        },
        {
          name: 'Payment Methods',
          url: PATH_PAYMENT_METHODS
        },
        {
          name: 'Gift Cards',
          url: PATH_GIFT_CARDS
        }
      ]
    }
  ]
}

export const orgMenuLinks = {
  linkGroup: [
    {
      title: 'Orders',
      links: [
        {
          name: 'Order History',
          url: ORG_PATHS.ORDER_HISTORY
        },
        {
          name: 'Autoship',
          url: ORG_PATHS.AUTOSHIP
        },
        {
          name: 'Subscriptions',
          url: ORG_PATHS.SUBSCRIPTIONS
        },
        {
          name: 'Billing',
          url: ORG_PATHS.BILLING_ACTIVITY
        }
      ]
    },
    {
      title: 'Settings',
      links: [
        {
          name: 'Organization Profile',
          url: ORG_PATHS.PROFILE
        },
        {
          name: 'Addresses',
          url: ORG_PATHS.ADDRESS_MANAGEMENT
        },
        {
          name: 'Groups (Staff)',
          url: ORG_PATHS.GROUPS
        }
      ]
    }
  ]
}