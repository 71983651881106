import resizeLogic from './resizeLogic';
import fetchUserLogic from './fetchUserDataLogic';
import authenticationLogic from './authenticationLogic';
import appToggleLogic from './appToggleLogic';
import phoneNumberLogic from './phoneNumberLogic';
import postPersonalInfoLogic from './postPersonalInfoLogic';
import shippingAddressesLogic from './shippingAddressesLogic';
import updateAccountLogic from './updateAccountLogic';
import creditCardsLogic from './creditCardsLogic';
import paymentMethodsToggleLogic from './paymentMethodsToggleLogic';
import countryServiceLogic from './countryServiceLogic';
import billingAccountsLogic from './billingAccountsLogic';
import giftCardLogic from './giftCardLogic';
import tableLogic from './tableLogic';
import orderLogic from './orderLogic';
import billingAcctDetailsLogic from './billingAcctDetailsLogic';
import invoiceLogic from './invoiceLogic';
import digitalMediaLogic from './mediaContentLogic';
import redeemCodeLogic from './redeemCodeLogic';
import pinLogic from './pinLogic';
import subscriptionsLogic from './subscriptionLogic';
import licenseAccessLogic from './licenceAccessLogic';
import productLogic from './productLogic';
import analyticsLogic from './analyticsLogic';
import braintreeLogic from './braintreeLogic';
import paymentsLogic from './paymentsLogic';
import paymentTokenLogic from './paymentTokenLogic';
import switchOrgsLogic from './switchOrgsLogic';
import linkedOrganizationsLogic from './linkedOrganizationsLogic';
import invitationLogic from './invitationLogic';
import achPaymentMethodsLogic from './achPaymentMethodsLogic';
import autoshipLogic from './autoship';
import cartLogic from './cartLogic';
import intercomLogic from './intercomLogic';
import contentSquareLogic from './contentSquareLogic';
import formCaptureLogic from './formCaptureLogic';
import contentfulLinksLogic from './contentfulLinksLogic';

export default [
  ...contentfulLinksLogic,
  ...resizeLogic,
  ...appToggleLogic,
  ...phoneNumberLogic,
  // TODO: consolidate logic into single file.
  ...fetchUserLogic,
  ...postPersonalInfoLogic,
  ////////////////////////////////////////////
  ...authenticationLogic,
  ...shippingAddressesLogic,
  ...updateAccountLogic,
  ...creditCardsLogic,
  ...paymentMethodsToggleLogic,
  ...countryServiceLogic,
  ...billingAccountsLogic,
  ...giftCardLogic,
  ...tableLogic,
  ...orderLogic,
  ...billingAcctDetailsLogic,
  ...invoiceLogic,
  ...digitalMediaLogic,
  ...redeemCodeLogic,
  ...pinLogic,
  ...subscriptionsLogic,
  ...autoshipLogic,
  ...licenseAccessLogic,
  ...productLogic,
  ...analyticsLogic,
  ...braintreeLogic,
  ...paymentsLogic,
  ...paymentTokenLogic,
  ...switchOrgsLogic,
  ...linkedOrganizationsLogic,
  ...invitationLogic,
  ...achPaymentMethodsLogic,
  ...cartLogic,
  ...intercomLogic,
  ...contentSquareLogic,
  ...formCaptureLogic
];
