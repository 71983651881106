import { createLogic } from 'redux-logic';
import { stopSubmit } from 'redux-form';
import { groupBy } from 'lodash';
import {
  GET_PENDING_ORDERS_PENDING, GET_ORDER_DETAILS_PENDING,

  GET_PROCESSED_ORDERS_PENDING, GET_ORDER_SEARCH_PENDING, GET_ORDERS_PENDING, GET_PENDING_ORDER_IMAGES_PENDING, GET_PROCESSED_ORDER_IMAGES_PENDING, GET_ORDER_IMAGES_PENDING, GET_SEARCHED_ORDER_IMAGES_PENDING, ENABLE_URL_PAGINATION
} from '../constants';
import {
  setBannerState, tableUpdateSettings, getOrderDetailsSuccess,
  getOrderDetailsError, getPendingOrdersSuccess, getPendingOrdersError,
  getProcessedOrdersSuccess, getProcessedOrdersError, tableAppendRowData,
  getOrderSearchSuccess, getOrderSearchError, getProductsPending, getOrdersSuccess, getOrdersError, getPendingOrderImagesPending, getPendingOrderImagesSuccess, getProcessedOrderImagesSuccess, getProcessedOrderImagesPending, getPendingOrderImagesError, getProcessedOrderImagesError, getOrderImagesSuccess, getOrderImagesError, getOrderImagesPending, getSearchedOrderImagesSuccess, getSearchedOrderImagesError, getSearchedOrderImagesPending, setIsOrderImagesLoading, getDashboardOrdersSuccess, getDashboardOrdersError, getDashboardOrderImagesSuccess, getDashboardOrderImagesError, getDashboardOrderImagesPending

} from '../actions/AppActions';
import {
  formatOrderDate, getObjectByTableName, orderDateToIsoUTC, getShippingMethod,
  formatMoney,
  getSourceName,
  getOrderStatusName,
  sortItemNumbers
} from '../utils/orderHelpers';
import { shouldFetchResults, padResults, getRowRange, padOrderResults } from '../utils/tables';
import { initTableState } from '../reducers/AppReducer';

const ordersUrl = billingId => billingId ? `/billing-accounts/${billingId}/orders` : '/orders';
const detailOrdersUrl = billingId => billingId ? `/billing-accounts/${billingId}/orders?includeDetails=true` : '/orders?includeDetails=true';

export const getPendingOrderImagesLogic = createLogic({
  type: GET_PENDING_ORDER_IMAGES_PENDING,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    const { ordersTable, billingId } = action?.payload;
    const orders = ordersTable?.data?.data;
    const tableName = billingId ? 'pendingOrdersOrg' : 'pendingOrders';
    const orderObject = getObjectByTableName(tableName);
    const index = billingId ? ['orders', orderObject, billingId] : ['orders', orderObject];
    await Promise.all(
      orders?.map(async (order) => {
        const orderId = order?._id;
        const orderImages = await getOrderImages({ dispatch, httpClient, order, billingId });
        return { rows: orderImages, id: orderId };
      })
    )
    .then(mappedOrders => {
      dispatch(getPendingOrderImagesSuccess({ data: mappedOrders, index }));
      done();
    })
    .catch(error => {
      dispatch(getPendingOrderImagesError(error));
      done();
    });
  }
});

export const getProcessedOrderImagesLogic = createLogic({
  type: GET_PROCESSED_ORDER_IMAGES_PENDING,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    const { ordersTable, billingId } = action?.payload;
    const orders = ordersTable?.data?.data;
    const tableName = billingId ? 'processedOrdersOrg' : 'processedOrders';
    const orderObject = getObjectByTableName(tableName);
    const index = billingId ? ['orders', orderObject, billingId] : ['orders', orderObject];
    await Promise.all(
      orders?.map(async (order) => {
        const orderId = order?._id;
        const orderImages = await getOrderImages({ dispatch, httpClient, order, billingId });
        return { rows: orderImages, id: orderId };
      })
    )
    .then(mappedOrders => {
      dispatch(getProcessedOrderImagesSuccess({ data: mappedOrders, index }));
      done();
    })
    .catch(error => {
      dispatch(getProcessedOrderImagesError(error));
      done();
    });
  }
});

export const getOrderImagesLogic = createLogic({
  type: GET_ORDER_IMAGES_PENDING,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    const { ordersTable, billingId } = action?.payload;
    const orders = ordersTable?.data?.data;
    const tableName = billingId ? 'orgOrders' : 'orders';
    const orderObject = getObjectByTableName(tableName);
    const index = billingId ? ['orders', orderObject, billingId] : ['orders', orderObject];
    await Promise.all(
      orders?.map(async (order) => {
        const orderId = order?._id;
        const orderImages = await getOrderImages({ dispatch, httpClient, order, billingId });
        return { rows: orderImages, id: orderId };
      })
    )
    .then(mappedOrders => {
      dispatch(getOrderImagesSuccess({ data: mappedOrders, index }));
      dispatch(setIsOrderImagesLoading());
      done();
    })
    .catch(error => {
      dispatch(setIsOrderImagesLoading());
      dispatch(getOrderImagesError(error));
      done();
    });
  }
});

export const getSearchedOrderImagesLogic = createLogic({
  type: GET_SEARCHED_ORDER_IMAGES_PENDING,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    const { ordersTable, billingId } = action?.payload;
    const orders = ordersTable?.data?.data;
    const tableName = 'orderSearch';
    const index = ['searchResults', tableName];
    await Promise.all(
      orders?.map(async (order) => {
        const orderId = order?._id;
        const orderImages = await getOrderImages({ dispatch, httpClient, order, billingId });
        return { rows: orderImages, id: orderId };
      })
    )
    .then(mappedOrders => {
      dispatch(getSearchedOrderImagesSuccess({ data: mappedOrders, index }));
      dispatch(setIsOrderImagesLoading());
      done();
    })
    .catch(error => {
      dispatch(setIsOrderImagesLoading());
      dispatch(getSearchedOrderImagesError(error));
      done();
    });
  }
});

export const getOrderImages = async ({dispatch, httpClient, order}) => {
  try {
    const orderData = order?.details;
    if (orderData?.orderLines?.length) {

      const itemNumbers = orderData?.orderLines?.map((l) => l.itemId);
      const sortedItemNumbers = sortItemNumbers(itemNumbers);
      const imageResponse = await httpClient(dispatch).post('/product-images', sortedItemNumbers);
      const imagesData = await imageResponse?.data;
      return imagesData;
    } else {
      return '';
    }
  } catch (error) {
    console.error('Error fetching order images:', error);
    return '';
  }
};

const mapOrderHistoryResponseToTable = async (orderResponse) => {
  try {
    const orders = orderResponse?.data?.data;
    const mappedOrders = await Promise.all(
      orders?.map(async (order) => {
        return {
          ...order,
          orderDate: new Date(order?.orderDate).toLocaleDateString(
            undefined,
            { timeZone: 'UTC', month: 'short', day: 'numeric', year: 'numeric' }
          ),
          orderTotal: `$${order?.orderTotal?.toFixed(2)}`,
          orderSource: getSourceName(order?.orderSource),
          orderStatus: getOrderStatusName(order?.orderStatus),
        };
      })
    );

    return {
      totalItems: orderResponse?.data?.meta?.totalSize || 0,
      rows: mappedOrders,
    };
  } catch (error) {
    console.error('Error mapping order response to table:', error);
    return {
      totalItems: 0,
      rows: [],
    };
  }
};

export const getOrdersLogic = createLogic({
  type: GET_ORDERS_PENDING,
  latest: true,
  process({ httpClient, action, getState }, dispatch, done) {
    const { billingId, page = 1, refresh = false } = action?.payload || {};
    const tableName = billingId ? 'orgOrders' : 'orders';
    const orderObject = getObjectByTableName(tableName);
    const state = getState();
    const settings = state.app.getIn(['tableSettings', tableName]);
    const index = billingId ? ['orders', orderObject, billingId] : ['orders', orderObject];
    const rows = state.app.getIn(index.concat(['rows']));
    if (!refresh && !shouldFetchResults(rows, settings)) {
      done();
      return false;
    }
    shouldFetchResults(rows, settings) && dispatch(tableUpdateSettings({ tableName, settings: { isLoading: true } }));
    httpClient(dispatch).get(
      billingId ? `/billing-accounts/${billingId}/orders?includeDetails=true` : '/orders?includeDetails=true',
      {
        params: {
          size: 10,
          page
        }
      }
    )
    .then(response => {
      return (
        dispatch(setIsOrderImagesLoading()),
        dispatch(getOrderImagesPending({ordersTable: response, billingId})),
        mapOrderHistoryResponseToTable(response, dispatch, httpClient, billingId)
      );
    })
      .then(ordersTable => {
        if (refresh || !rows?.size) {
          dispatch(getOrdersSuccess({ index, data: { ...ordersTable, rows: ENABLE_URL_PAGINATION ? padOrderResults(ordersTable, page, 10) : padResults(ordersTable) } }));
        } else {
          dispatch(tableAppendRowData({ index: index.concat(['rows']), range: getRowRange(page, rows), data: { rows: ordersTable.rows } }));
        }
        dispatch(tableUpdateSettings({ tableName, settings: { isLoading: false } }));
        done();
      })
      .catch(error => {
        const bannerState = {
          data: {
            type: 'error',
            message: state?.intl?.Errors?.OrderHistory.Get_Pending_Orders_Error
          }
        };
        dispatch(setBannerState(bannerState));
        dispatch(getOrdersError(error));
        done();
      });
  }});

export const getSearchResultsLogic = createLogic({
  type: GET_ORDER_SEARCH_PENDING,
  latest: true,
  validate({ action }, allow, reject) {
    const { to, from, formName, billingId, ...searchParams } = action?.payload || {};
    if (searchParams && Object.keys(searchParams)?.length) {
      allow({ ...action, payload: { ...action.payload, ...searchParams } });
    } else {
      reject(stopSubmit(action?.payload?.formName, {}));
    }
  },
  process({ httpClient, action, getState }, dispatch, done) {
    const { billingId, page = 1 } = action?.payload || {};
    const orderURL = detailOrdersUrl;
    const tableName = 'orderSearch';
    const state = getState();
    const settings = state.app.getIn(['tableSettings', tableName]);
    const index = ['searchResults', tableName];
    const rows = state.app.getIn(index.concat(['rows']));
    const { q, status, source, date, to, from } = action?.payload || {};
    if (!shouldFetchResults(rows, settings)) {
      done();
      return false;
    }
    dispatch(tableUpdateSettings({ tableName, settings: { isLoading: true } }));
    httpClient(dispatch).get(
      orderURL(billingId),
      {
        params: {
          size: 10,
          page,
          keywords: q,
          orderStatus: (status === 'Completed' ? 'Closed' : status),
          orderMethod: source,
          ...(date && {
            beforeDate: orderDateToIsoUTC(to),
            afterDate: orderDateToIsoUTC(from),
          })
        },
      }
    )
    .then(response => {
      return (
        dispatch(setIsOrderImagesLoading()),
        dispatch(getSearchedOrderImagesPending({ordersTable: response, billingId})),
        mapOrderHistoryResponseToTable(response, dispatch, httpClient, billingId)
      );
    })
      .then(ordersTable => {
        if (!rows?.size) {
          dispatch(getOrderSearchSuccess({ index, data: { ...ordersTable, rows: padResults(ordersTable) } }));
        } else {
          dispatch(tableAppendRowData({ index: index.concat(['rows']), range: getRowRange(page, rows), data: { rows: ordersTable.rows } }));
        }
        dispatch(tableUpdateSettings({ tableName, settings: { isLoading: false } }));
        dispatch(stopSubmit(action?.payload?.formName, {}));
        done();
      })
      .catch(err => {
        const bannerState = {
          data: {
            type: 'error',
            message: state?.intl?.Errors?.OrderHistoryGet_Order_Search_Error,
          }
        };
        dispatch(setBannerState(bannerState));
        dispatch(getOrderSearchError(err));
        dispatch(stopSubmit(action?.payload?.formName, {}));
        done();
      });
  }
});

export const getOrderDetailsLogic = createLogic({
  type: GET_ORDER_DETAILS_PENDING,
  latest: true,
  process({ action, httpClient, getState }, dispatch, done ) {
    const { billingId, orderNumber } = action.payload;
    const state = getState();
    const intl = state.intl.messages;
    const orderTable = billingId ? 'orderItemsOrg' : 'orderItems';
    dispatch(tableUpdateSettings({ tableName: orderTable, tableIndex: 'default', settings: { isLoading: true } }));
      httpClient(dispatch).get(`${ordersUrl(billingId)}/${orderNumber}`)
        .then(res => {
          const order = res?.data;
          let _lastAddress;
          order?.orderLines?.forEach((line, i) => {
            const l = order.orderLines[i];
            if (l.shipToAddress1) _lastAddress = l.shipToAddress1;
            if (!l.shipToAddress1 && _lastAddress) l.shipToAddress1 = _lastAddress;
            if (!order.orderLines[0].shipToAddress1 && l.shipToAddress1) order.orderLines[0].shipToAddress1 = l.shipToAddress1;
          });
          const grouped = groupBy(order?.orderLines, item => item.shipToAddress1);

          Object.keys(grouped).forEach(address => {
            const temp = state.app.getIn(['tableSettings', orderTable, address]);
            if (!temp) {
              dispatch(tableUpdateSettings({ tableName: orderTable, tableIndex: address, settings: initTableState().toJS() }));
            }
          });
          return order;
        })
        .then(order => {
          const ord = order;
          const totalTax = order?.orderTax || 0;
          const orderTotal = (order?.orderTotal || 0);
          ord.orderDate = formatOrderDate(order?.orderDate);
          ord.subTotal = formatMoney(orderTotal - totalTax - order?.shippingAmount);
          ord.orderTotal = formatMoney(orderTotal);
          ord.totalTax = formatMoney(totalTax);
          ord.shippingAmount = formatMoney(order?.shippingAmount);
          ord.shippingMethod = order?.shippingMethod && getShippingMethod(order?.shippingMethod);
          ord.orderStatus = getOrderStatusName(order?.orderStatus);
          ord.orderSource = getSourceName(order?.orderSource);
          return ord;
        })
        .then(order => {
          const ord = order;
          const oLines = [];
          order?.orderLines.forEach((l, i) => {
            l.lineItems = [{ itemId: l.itemId, productTitle: l.productTitle, lineStatus: l.lineStatus, isDigital: l.isDigital }];
            if (l.items && l.items.length) l.lineItems = l.lineItems.concat(l.items);
            if (!l.items) l.items = l.lineItems;
            const prev = order.orderLines[i - 1];
            const last = oLines[oLines.length - 1];
            const lastLI = last && last.lineItems[last.lineItems.length - 1];
            if (prev && l.lineNum === prev.lineNum && l.lineSeq === prev.lineSeq) {
              const obj = {};
              if (l.itemId && l.itemId !== lastLI.itemId) obj.itemId = l.itemId;
              if (l.productTitle && l.productTitle !== lastLI.productTitle) obj.productTitle = l.productTitle;
              if (l.lineStatus) obj.lineStatus = l.lineStatus;
              last.lineItems.push(obj);
            } else {
              oLines.push(l);
            }
          });
          ord.orderLines = oLines.map(l => {
            const newDiscountAmount = l.discountAmount || l.unitListPrice - l.unitSellingPrice;
            return { ...l, discountAmount: newDiscountAmount };
          });
          return ord;
        })
        .then(order => {
          const payload = { order: { ...order, id: order._id } };

          dispatch(getOrderDetailsSuccess(payload));
          dispatch(tableUpdateSettings({ tableName: orderTable, tableIndex: 'default', settings: { isLoading: true } }));
          if (order?.orderLines?.length) {
            dispatch(getProductsPending({ type: 'orderItems', itemNumbers: order.orderLines.map(l => l.itemId) }));
          }
          done();
        })
        .catch(_ => {
          const message = intl.Errors.OrderHistory.Get_Order_Details_Error;
          dispatch(getOrderDetailsError(message));
          done();
        });
  }
});

export default [
  getOrdersLogic,
  getSearchResultsLogic,
  getOrderDetailsLogic,
  getPendingOrderImagesLogic,
  getProcessedOrderImagesLogic,
  getOrderImagesLogic,
  getSearchedOrderImagesLogic
];
