import React, { useState } from 'react';
import { func } from 'prop-types';

import Dropdown from './Dropdown';
import ActiveProfileButton from './ActiveProfileButton';
import AccountLinks from './AccountLinks';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ProfilesList from './ProfilesList';
import css from '../../../../../css/css-variables';
import NameMessage from './NameMessage';
import { getCurrentUserName } from '../../../selectors/PersonProfileSelectors';

const AccountDropdown = ({ onLogOut }) => {
  const selectedProfile = useSelector(state =>
    state.app.getIn(['selectedProfile'])?.toJS()
  );
  const [open, setOpen] = useState(false);
  const name = useSelector(getCurrentUserName);
  const handleToggleOpen = () => setOpen(currentlyOpen => !currentlyOpen);

  return (
    <>
      <Dropdown
        open={open}
        onClose={() => setOpen(false)}
        button={
          <ActiveProfileButton
            onClick={handleToggleOpen}
            name={selectedProfile.label}
            initials={selectedProfile.initials}
          />
        }
        id="account-menu"
        aria-label="Account Options"
      >
        <NameMessage>Hello, {name.firstName}</NameMessage>
        <Separator style={{ margin: 0 }} />
        <Columns>
          <AccountLinks
            onLogOut={onLogOut}
            onLinkClick={() => setOpen(false)}
          />
          <ProfilesListContainer>
            <ProfilesList onLinkClick={() => setOpen(false)} />
          </ProfilesListContainer>
        </Columns>
      </Dropdown>
    </>
  );
};
AccountDropdown.propTypes = {
  onLogOut: func.isRequired,
};

export default AccountDropdown;

const Columns = styled.div`
  display: flex;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${css.colors.LifewayComDecorationGray};
`;

const ProfilesListContainer = styled.div`
  max-height: 280px;
  width: 310px;
  overflow-y: auto;
`;