/*eslint eqeqeq:0*/
import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { Provider } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';
import esLocaleData from 'react-intl/locale-data/es';
import enLocaleData from 'react-intl/locale-data/en';
import FontFaceObserver from 'fontfaceobserver';
import styled from 'styled-components';
import ConnectedRouter from './containers/ConnectedRouter';
import configureStore from './store/configureStore';
import httpClient from './utils/httpClient';
import App from './components/App';
import { ViewportProvider } from './hooks/viewport';
import 'react-toastify/dist/ReactToastify.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@lifewayit/components/dist/index.css';
import addClassNames from './utils/addClassNames';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';


const queryClient = new QueryClient();

// Load 'en' by default
addLocaleData([
  ...esLocaleData,
  ...enLocaleData,
]);

const enMessages = require('./actions/lang/en.json');
//const esMessages = require('./actions/lang/es.json');

const lang = navigator.language.split('-')[0];
let messages;

switch (lang) {
  /*case "es":
    messages = esMessages;
    break;*/

  default:
    messages = enMessages;
    break;
}

const initialState = {
  intl: {
    locale: 'en',
    messages
  }
};

const store = configureStore(initialState);
httpClient._dispatch = store.dispatch;

// Take care of font-loading
(sessionStorage.getItem('foutFontsLoaded') ? Promise.resolve() : new FontFaceObserver('Source Sans Pro').load()).then(() => {
  document.documentElement.className += ` ${addClassNames('Font-SourceSansPro')}`;
  sessionStorage.setItem('foutFontsLoaded', 'y');
});

const TopContainer = styled.div`
  height: 100%;
  position: relative;
`;

const runApp = () => {
  ReactDOM.render(
    <TopContainer>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ConnectedRouter store={store}>
            <ViewportProvider>
              <Route path="/" component={App} />
            </ViewportProvider>
          </ConnectedRouter>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </TopContainer>,
    document.getElementById('wrapper')
  );
};

if (!global.Intl) {
  require.ensure([
    'intl',
    'intl/locale-data/jsonp/en.js'
  ], function (require) {
    require('intl');
    require('intl/locale-data/jsonp/en.js');
    runApp();
  });
} else {
  runApp();
}
