import { createLogic } from 'redux-logic';
import { LOCATION_CHANGE } from 'react-router-redux';
import { ENABLE_CONTENTSQUARE } from '../constants';

export const locationChangeLogic = createLogic({
  type: LOCATION_CHANGE,
  latest: true,

  validate({ action, getState }, allow, reject) {
    const location = getState().router.location;

    if (!ENABLE_CONTENTSQUARE) {
      reject(action);
    /* only call when location actually changes, not when router is initialized */
    } else if (location == null) {
      reject(action);
    } else {
      allow(action);
    }
  },

  process({ action, getState }, dispatch, done) {
    const location = getState().router.location;
    window._uxa?.push(['trackPageview', location.pathname]);

    done();
  }
});

export default [
  locationChangeLogic
];