import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import { LOCATION_CHANGE } from '../constants';
import historyUtil from '../utils/history';

// Set's initial history for site routing
const fixHistory = (history) => {
  history.location.search = history.location.search || '';
  history.location.hash = history.location.hash || '';
  return history;
};

// Set's new location for router
const handleLocationChange = ({ store, action, location }) => {
  store.dispatch({
    type: LOCATION_CHANGE,
    payload: { action, location }
  });
};

class ConnectedRouter extends Component {
  constructor (props, context) {
    super(props);
    this._history = fixHistory(props.history || createBrowserHistory({ basename: props.basename, keyLength: props.keyLength }));
    historyUtil.bind(this._history);

    handleLocationChange({ store: context.store || props.store, action: this._history.action, location: this._history.location });
  }

  UNSAFE_componentWillMount () {
    this.unlisten = this._history.listen((location, action) => handleLocationChange({ store: this.context.store || this.props.store, action, location }) );
  }

  componentWillUnmount () {
    this.unlisten();
  }

  render () {
    return (
      <Router
        history={this._history}
        {...this.props}
      />
    );
  }
}

ConnectedRouter.propTypes = {
  store: PropTypes.object,
  basename: PropTypes.string,
  keyLength: PropTypes.number,
  history: PropTypes.object
};

ConnectedRouter.contextTypes = {
  store: PropTypes.object
};

ConnectedRouter.defaultProps = {
  basename: '',
  keyLength: 6
};

export default ConnectedRouter;
