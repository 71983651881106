import { createLogic } from 'redux-logic';
import { LOCATION_CHANGE } from 'react-router-redux';
import { setSelectedProfile } from '../actions/AppActions';
import {
  FETCH_USER_DATA_SUCCESS,
  GET_BILLING_ACCS_SUCCESS,
  PATH_BILLING_ACCOUNT,
  ORG_PATHS,
  GET_LINKED_ORGS_SUCCESS
} from '../constants';
import { matchPath } from 'react-router';

export const selectCurrentProfileLogic = createLogic({
  type: [LOCATION_CHANGE, FETCH_USER_DATA_SUCCESS, GET_BILLING_ACCS_SUCCESS, GET_LINKED_ORGS_SUCCESS],
  latest: true,

  process({ getState }, dispatch, done) {
    const state = getState();
    const route = state.router.location.pathname;

    const baRouteMatch = matchPath(route, { path: PATH_BILLING_ACCOUNT });
    const orgRouteMatch = matchPath(route, { path: ORG_PATHS.BASE });

    if (baRouteMatch != null) {
      const currentBillingAccountId = baRouteMatch.params.billingAccountId;

      const billingAccounts = state.app.getIn(['currentUser', 'paymentMethods', 'billingAccs']).toJS();
      const activeBillingAccount = billingAccounts.find(
        account => account.billingAccountId === currentBillingAccountId
      );

      const {
        organizationId, initials, initialsColor, organizationName
      } = activeBillingAccount || {};

      dispatch(setSelectedProfile({
        id: organizationId,
        initials,
        initialsColor,
        label: organizationName
      }));
    } else if (orgRouteMatch != null) {
      const currentOrgId = orgRouteMatch.params.organizationId;

      const organizations = state.app.getIn(['currentUser', 'linkedOrgs']).toJS();
      const activeOrganization = organizations.find(
        organization => organization.organizationId === currentOrgId
      );

      const {
        organizationId, initials, initialsColor, organizationName
      } = activeOrganization || {};

      dispatch(setSelectedProfile({
        id: organizationId,
        initials,
        initialsColor,
        label: organizationName
      }));
    } else {
      const {
        id, initials, initialsColor, fullName
      } = state.app.get('currentUser').toJS();

      dispatch(setSelectedProfile({
        id,
        initials,
        initialsColor,
        label: fullName
      }));
    }

    done();
  }
});

export default [selectCurrentProfileLogic];
