import { PaymentTypes } from "./SelectPaymentMethodModal";

export const formatSubscriptionPaymentMethod = (paymentMethodType, paymentMethod) => {
  if (paymentMethodType === 'billing-account') {
    return {
      id: paymentMethod.id,
      account: paymentMethod.accountNumber,
      organizationId: paymentMethod.organizationId,
      billingAccountId: paymentMethod.billingAccountId,
      name: paymentMethod.organizationName,
    };
  } else if (paymentMethodType === 'Account') {
    return {
      id: paymentMethod.id,
      account: paymentMethod.accountNumber,
      organizationId: paymentMethod.organizationId,
      billingAccountId: paymentMethod.billingAccountId,
      name: paymentMethod.organizationName,
    }; 
    } else if (paymentMethodType === 'CreditCard') {
    return {
      card: {
        id: paymentMethod.id,
        token: paymentMethod.token,
        cardholderName: paymentMethod.cardHolderName,
        expMonth: paymentMethod.expirationMonth,
        expYear: paymentMethod.expirationYear,
        displayName: paymentMethod.displayName,
        cardMask: paymentMethod.cardNumberMask,
        cardType: paymentMethod.cardType,
      }
    };
  } else if (paymentMethodType === 'PayPal') {
    return {
      paypal: {
        id: paymentMethod.id,
        token: paymentMethod.token,
        emailAddress: paymentMethod.email,
        payerId: paymentMethod.payerId,
        displayName: paymentMethod.displayName,
      }
    };
  } else {
    throw new Error(`unsupported payment type of ${paymentMethodType}`);
  }
};

export const formatTransferPaymentMethod = (paymentMethodType, paymentMethod) => {
  if (paymentMethodType === 'BILLINGACCOUNT') {
    return {
      id: paymentMethod.id,
      account: paymentMethod.accountNumber,
      billingAccountId: paymentMethod.billingAccountId,
    };
  } else if (paymentMethodType === 'CREDITCARD') {
    return {
      card: {
        id: paymentMethod.id,
        token: paymentMethod.token,
        cardholderName: paymentMethod.cardHolderName,
        expMonth: paymentMethod.expirationMonth || paymentMethod.expMonth,
        expYear: paymentMethod.expirationYear || paymentMethod.expYear,
        displayName: paymentMethod.displayName,
        cardType: paymentMethod.cardType,
        cardMask: paymentMethod.cardNumberMask,
        lastFour: paymentMethod.lastFour,
      }
    };
  } else if (paymentMethodType === 'PAYPAL') {
    return {
      id: paymentMethod.id,
      token: paymentMethod.token,
      emailAddress: paymentMethod.email,
      payerId: paymentMethod.payerId
    };
  } else {
    throw new Error(`unsupported payment type of ${paymentMethodType}`);
  }
};

export const formatTransferBillingAddress = (address) => {
  return {
    firstName: address.firstName,
    lastName: address.lastName,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.countrySubdivision,
    zip: address.zip || address.postalCode,
    country: address.countryCode || address.country,
    addressType: address.type,
    phone: address.phoneNumber,
    id: address.id,
    organization: address.organizationName,
  };
};

export const emptyPaymentText = (type) => {
  return type === PaymentTypes.CREDITCARD
    ? 'credit cards'
    : 'billing accounts';
};
