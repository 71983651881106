import { createBrowserHistory } from 'history';

let _history = createBrowserHistory();

export default {
  bind: (history) => {
    _history = history;
  },
  push: (...args) => _history.push(...args),
  listen: (...args) => _history.listen(...args),
  replace: (...args) => _history.replace(...args),
  go: (...args) => _history.go(...args),
  back: () => _history.goBack()
};
