import { useRouteMatch } from 'react-router';
import moment from 'moment';
import { ORG_PATHS, PATH_AUTOSHIP } from '../constants';
import * as CC from './creditCard';

/*
  dateCreated is expected to be UTC
  on autoships it is in 'YYYY-MM-DD' format
*/
export const getDefaultName = (dateCreated) => {
  const createdDate = new Date(dateCreated)
    .toLocaleDateString(undefined, { timeZone: 'UTC' });

  return `Autoship Order ${createdDate}`;
};

export const getName = (autoship) => {
  if (autoship.name != null && autoship.name !== '') {
    return autoship.name;
  } else {
    return getDefaultName(autoship.dateCreated);
  }
};

export const getPlaceholderName = () =>
  getDefaultName(moment().format('YYYY-MM-DD'));

export const getOrganizationId = autoship => {
  if (autoship.customer.customerType === 'Organization') {
    return autoship.customer.id;
  } else {
    return undefined;
  }
};


const toCCExpiration = pm =>
({ expirationYear: pm?.expYear, expirationMonth: pm?.expMonth });

export const willPMExpireByNextOrder = (autoship) => {
  const { scheduledOrderDate, paymentMethod } = autoship ?? {};

  if (!scheduledOrderDate || paymentMethod?.paymentType !== 'CreditCard') {
    return false;
  }

  return CC.isExpiredAt(toCCExpiration(paymentMethod), moment(scheduledOrderDate, 'YYYY-MM-DD').toDate());
};

export const isPMExpired = (autoship) => {
  const { paymentMethod } = autoship;

  if (paymentMethod?.paymentType !== 'CreditCard') {
    return false;
  }

  return CC.isExpired(toCCExpiration(paymentMethod));
};

export const useBaseAutoshipRoute = () => {
  const personalMatch = useRouteMatch(PATH_AUTOSHIP);
  const orgMatch = useRouteMatch(ORG_PATHS.AUTOSHIP);

  if (orgMatch) {
    return {
      ...orgMatch,
      inOrg: true
    };
  } else if (personalMatch) {
    return {
      ...personalMatch,
      inOrg: false
    };
  } else {
    return undefined;
  }
};

export const toLegacyFormat = autoship => {
  if (autoship == null) {
    return autoship;
  }

  const {
    id,
    subscriptionType,
    customer,
    items,
    cartTotals,
    ...restAutoship
  } = autoship;

  return {
    ...restAutoship,
    _id: id,
    subscriptionType: subscriptionType?.maintype,
    ...(customer?.customerType === 'Organization'
      ? { associatedOrganization: { id: customer?.id, name: customer?.name } }
      : {}
    ),
    email: customer?.customerType === 'Organization'
      ? customer?.contactPerson?.email
      : customer?.email,
    userId: customer?.customerType === 'Organization'
      ? customer?.contactPerson?.userId
      : customer?.id,
    items: items?.map(({ purchasePrice, salePrice, extendedListPrice, extendedPurchasePrice, ...restItem }) => ({
      ...restItem,
      unitPrice: purchasePrice,
      extendedPrice: extendedPurchasePrice
    }))
  };
};


export const findAutoshipInAddresses = (autoshipId, addresses = []) => {
  let autoship = null;

  addresses.forEach(address => {
    if (!autoship) {
      autoship = address.subscriptions?.find(subscription => subscription.subscriptionId === autoshipId);
    }
  });

  return autoship;
};

export const addressContainsAutoship = (autoshipId, address = {}) => {
  const {subscriptions = []} = address;
  return !!subscriptions.find(subscription => subscription.subscriptionId === autoshipId);
};

export const isAutoshipLessThanThirtyDays = (autoship) => {
  var autoshipOrderDate = new Date(autoship.scheduledOrderDate).getTime();
  var today = new Date().getTime();
  var differenceinTime = autoshipOrderDate - today;
  var differenceInDays = Math.round(differenceinTime / (1000 * 3600 * 24));
  const isLessThanThirty = differenceInDays <= 30 && differenceInDays >= 0;
  return isLessThanThirty;
};

export const sortAutoships = (...autoship) => {
  return autoship.sort(function (a, b) {
    return new Date(a.scheduledOrderDate) - new Date(b.scheduledOrderDate);
  });
};
