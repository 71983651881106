import styled from 'styled-components';

const AccessibleText = styled.span`
  position: absolute;

  height: 1px; 
  width: 1px;
  padding: 0;
  border: 0; 

  clip: rect(0 0 0 0); 
  clip-path: inset(50%);

  overflow: hidden;
  white-space: nowrap;
`;

export default AccessibleText;
