import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import css from '../../../css/css-variables';


const HR = styled.hr`
  border-top-color: ${props => props.dark ? css.colors.lightBlack : css.colors.Border};
  border-top-width: ${({ borderTopWidth }) => borderTopWidth || '2px'};
  margin-top: ${({ marginTop }) => marginTop || css.gutter2};
  margin-bottom: ${({ marginBottom }) => marginBottom || css.gutter2};

  ${({color}) => color && `border-top-color: ${color};`}
`;

const HorizontalRule = ({ dark, marginTop, marginBottom, borderTopWidth, color }) => (
  <HR dark={dark} marginTop={marginTop} marginBottom={marginBottom} borderTopWidth={borderTopWidth} color={color} />
);

const { bool, string } = PropTypes;

HorizontalRule.propTypes = {
  dark: bool,
  marginTop: string,
  marginBottom: string,
  color: string
};

export default HorizontalRule;
