import React from 'react';
import { RedeemButton } from './RedeemButton';
import DownChevron from '../../../img/sprite/down-chevron.svg';
import Button from './Button';
import styled from 'styled-components';

const StyledButton = styled.button`
  font-size: 14px;
  color: #476692;
  text-decoration: none;

  &:hover {
    ${({noHover}) => noHover ? '' : 'text-decoration: underline'};
  }
`;

const OrgNameButton = styled.button`
  text-align: left;
  width: 100%;
  padding: 16px 0;
  margin: 0;
  font-size: 16px;
  color: #476692;
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: center;
  margin: 12px 0 14px 0; 
`;

function SideNavSelect ({
  setView,
  loading,
  showMoreOrgs,
  firstThreeOrgs,
  handleSelectOrg,
  setViewAccountsModal,
  linkedOrganizations,
  onLogOut
}) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', borderBottom: 'solid 1px #e0e0e0' }}>
        <Button
          aria-label="My Account"
          type='button'
          style={{
            textAlign: 'left',
            width: '100%',
            padding: '16px 0',
            background: 'inherit',
            margin: '0',
            fontSize: '16px',
            color: '#476692',
            textDecoration: 'none',
            fontWeight: 'bold',
            fontStyle: 'normal'
          }} 
          onClick={() => {setView('INDIVIDUAL')}}
        >
          My Account
        </Button>
        <svg style={{ transform: 'rotate(-90deg)', paddingRight: '3px' }}><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
      </div>
      {loading && <div style={{ color: '#476692', paddingTop: '8px' }}>Loading...</div>}
      {!loading && firstThreeOrgs && firstThreeOrgs?.map((org, index) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            borderBottom: 'solid 1px #e0e0e0'
          }}
          key={index}
        >
          <OrgNameButton
            type="button"
            role="button"
            aria-label="Select Org"
            onClick={() => handleSelectOrg(org)}
          >
            {org?.organizationName}
          </OrgNameButton>
          <svg style={{ transform: 'rotate(-90deg)', paddingRight: '3px' }}><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
        </div>
      ))}
      {showMoreOrgs && (
        <StyledButton
          type="button"
          onClick={() => setViewAccountsModal()}
          style={{ marginTop: '14px' }}
          noHover={true}
        >
          View More Accounts
        </StyledButton>
      )}
      <ButtonContainer>
        <RedeemButton isSideNav={true} style={{ fontSize: '14px' }} />
      </ButtonContainer>
    </>
  );
}

export default SideNavSelect;