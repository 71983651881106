import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import history from './history';
import Button from '../components/Button';
import { DATE_FORMAT, LIFEWAY_COM_URL } from '../constants';
import LinkToggle from '../components/LinkToggle';
import { resourceRedirectQuery } from './pathHelpers';
import css from '../../../css/css-variables';

const StyledButton = styled(Button)`
${({ disabled }) => !disabled &&
  `
    background-color: ${css.colors.ButtonPrimary};
    color: ${css.colors.ButtonTextPrimary};
    border-radius: 4px;
  `
}
  margin: 5px;
  text-decoration: none;
  font-size: .85em;
`;

//
//// Private
//

const linkBuilder = (urlGenerator) => (title, extra) => {
  const props = {
    external: true,
    href: urlGenerator(extra.rowData.productCode)
  };

  return <LinkToggle {...props}>{title}</LinkToggle>;
};


export function productDetailLink(lang = 'en') {
  const urlGenerator = (itemNumber) => `${LIFEWAY_COM_URL}/${lang}/product/${itemNumber}`;


  return linkBuilder(urlGenerator);
}

export function downloadLink(url, { rowData, rowData: { description } }) {
  return (
    <a
      href={url}
      download={description.split('.').shift()}
    >
      Download
    </a>
  );
}

export function resourceLink(label = 'Access', isSimulcast) {
  return (itemNumber, { rowData } ) => {
    const disabled = isDisabled(rowData);
    const isFutureDate = moment().diff(moment(rowData.startDate, DATE_FORMAT), 'days') < 0;
    const disabledLabel = isFutureDate ? 'Coming Soon' : 'Expired';

    const onClickCallback = () => {
      if (disabled) {
        return null;
      } else if ((rowData?.license?.isManager || rowData?.license?.isOwner) && isSimulcast) {
        history.push({ pathname: '/manage-access/simulcast', state: { licenseId: rowData?.license?.id } });
      } else {
        window.open(resourceRedirectQuery(itemNumber));
      }
    };

    return (
      <StyledButton
        onClick={onClickCallback}
        external
        disabled={disabled}
        dataQaBtn={{ label: label.toLowerCase(), value: itemNumber }}
      >
        {disabled ? disabledLabel : label}
      </StyledButton>
    );
  };
}

export function isDisabled(rowData) {
  const now = moment();
  const { startDate, endDate } = rowData;
  const start = startDate ? moment(startDate, DATE_FORMAT) : now.subtract(1, 'days');
  const end = endDate ? moment(endDate, DATE_FORMAT) : now.add(1, 'days');

  return (now.isBefore(start) || end.isBefore(now));
}

export const isExpiredMedia = (digitalMedia) => moment()?.diff(moment(digitalMedia?.endDate, DATE_FORMAT), 'days') > 0;
