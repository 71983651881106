import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LWLogo from '../../../img/sprite/lifeway.svg';
import Confused from '../../../img/confused-face.png';
import css from '../../../css/css-variables';
import { useIsLoggedIn } from '../hooks/authentication';

const Header = styled.header`
  background-color: ${css.colors.AccentLight};
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  padding: 24px;
`;

const LWImg = styled.svg`
  max-height: 56px;
  width: 175px;
  height: initial;
`;

const Main = styled.main`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 700px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Heading = styled.h1`
  margin: 0;
`;

const ConfusedFace = styled.img`
  @media (min-width: 768px) {
    margin-left: 16px;
  }
`;

const InnerContent = styled.div`
  margin: 48px 0;
`;

const HR = styled.hr`
  margin: 32px 0;
`;

const InviteCancelled = () => {
  const isLoggedIn = useIsLoggedIn();

  return (
    <div>
      <Header>
        <HeaderContainer>
          <a
            href="https://www.lifeway.com"
            target="_blank"
            rel="noopener noreferrer">
            <LWImg role="img">
              <use href={`#${LWLogo.id}`} />
            </LWImg>
          </a>
        </HeaderContainer>
      </Header>

      <Main>
        <HeadingWrapper>
          <Heading>We sorry! This invite is no longer available.</Heading>
          <ConfusedFace src={Confused} alt="confused face" />
        </HeadingWrapper>
        <HR />
        <InnerContent>
          <p>This invitation was canceled by an administrator.</p>
          <p>Please contact an administrator from your organization to learn more.</p>
        </InnerContent>
        <HR />
        {
          isLoggedIn ? (
            <Link to="https://my.lifeway.com/">Manage your account</Link>
          ) : (
            <p>
              Still need to create a Lifeway account login?{' '}
              <a href="https://account.lifeway.com/register">Create an account now.</a>
            </p>
          )
        }
      </Main>
    </div>
  );
};

export default InviteCancelled;
