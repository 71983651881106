export default function bbbScript() {
    window.bbbprotocol =
      document.location.protocol === 'https:' ? 'https://' : 'http://';
    (function () {
      let s = document.createElement('script');
      s.src =
        window.bbbprotocol +
        'seal-nashville.bbb.org' +
        unescape('%2Flogo%2Flifeway-christian-resources-11175.js');
      s.type = 'text/javascript';
      s.async = true;
      document.head.append(s);
    })();
  }