import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { bool, string } from 'prop-types';
import InitialsCircle from '../../InitialsCircle';

const ProfileInitials = ({ dark, className, ariaLabelAction, ...props }) => {
  const { initials, label } = useSelector(state =>
    state.app.getIn(['selectedProfile'])?.toJS()
  );

  const ariaLabel = [
    label ? `Viewing profile for ${label}` : null,
    ariaLabelAction,
  ]
    .filter(sentence => sentence != null)
    .join(' ');

  return (
    <Button
      aria-label={ariaLabel}
      type="button"
      className={className}
      {...props}
    >
      <InitialsCircle
        initials={initials}
        initialsColor="#414042"
        small={true}
        centered={true}
        ringColor="#3D6995"
        styles={{ margin: 0 }}
      />
    </Button>
  );
};

ProfileInitials.propTypes = {
  dark: bool,
  className: string,
  ariaLabelAction: string,
};

export default ProfileInitials;

const Button = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 50%;
  font-family: sofia-pro, "helvetica neue", helvetica, arial, sans-serif;
`;