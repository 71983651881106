import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BreadCrumbArrow from '../../../../img/sprite/breadcrumb-arrow.svg';
import { useViewport } from '../../hooks/viewport';

const HeaderBreadCrumbNav = ({breadCrumbs}) => {
    const isTruncated = breadCrumbs.length > 3;
    const { isMobile } = useViewport();
    let breadcrumbItems = null;

    if(isTruncated && isMobile){
        breadcrumbItems = [breadCrumbs[0], { title: "...", url: breadCrumbs[1].url }, ...breadCrumbs.slice(2)];
    }else{
        breadcrumbItems = breadCrumbs;
    }

    return (
        <BreadCrumbBar>
            <BreadCrumbList>
            {
                breadcrumbItems?.map((crumb, i) => {
                const isLink = (i !== breadCrumbs.length - 1);
                return (
                    <StyledBreadCrumb key={i}>
                        {isLink && <Link to={crumb?.url || '#'}>{crumb?.title}</Link>}
                        {isLink && <StyledBreadCrumbArrow><use href={`#${BreadCrumbArrow.id}`}/></StyledBreadCrumbArrow>}
                        {!isLink && crumb.title}
                    </StyledBreadCrumb>
                );
                })
            }
            </BreadCrumbList>
        </BreadCrumbBar>
    );
} 

export default HeaderBreadCrumbNav;

const BreadCrumbBar = styled.div`
    height: 3.5rem;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 5;
    background-color: #5a595c;
    white-space: nowrap;
    overflow: hidden;

    font-family: sofia-pro, "helvetica neue", helvetica, arial, sans-serif;

    @media(max-width: 720px) {
    border-bottom-width: 0px;
    height: 2.813em;
    z-index: 0;
    }
`;

const BreadCrumbList = styled.ul`
    display: flex;
    font-size: 14px;
    cursor: default;
    color: #ffffff;
    align-content: center;   
    text-decoration: none solid rgb(255, 255, 255);
    margin-left: 1.87rem;

    a {
        display: inline-block;
        color: #ffffff;
        text-decoration: none solid rgb(255, 255, 255);
        align-items: center;
    }

    li:first-of-type{
        a:first-of-type {
            font-weight: bold;
            }
    }

    @media (max-width: 720px) {
        width: 95vw;
        li:last-of-type {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }
        margin-left: 1em;
      }
		
    b {
        display: inline-block;
        align-items: center;
        margin-left: 0.417em;
        font-size: 12px;
        font-weight: normal;
        color: #ffffff
        text-decoration: none solid rgb(255, 255, 255);
    }
`;
BreadCrumbList.displayName = 'BreadCrumbList';

const StyledBreadCrumb = styled.li`
`;
StyledBreadCrumb.displayName = 'StyledBreadCrumb';

const StyledBreadCrumbArrow = styled.svg`
    height: 20px;
    width: 20px;
`;
