import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual, uniqBy } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getCurrentUserId = createSelector(
  (state) => state.app.getIn(['currentUser', 'id']), res => res
);

export const getLinkedOrgs = createDeepEqualSelector(
  (state) => state.app.getIn(['currentUser', 'linkedOrgs']),
  orgs => orgs.toJS()
);

export const getLinkedOrgMemberRoles = createDeepEqualSelector(
  (state) => state.app.getIn(['currentUser', 'linkedOrgsMemberRoles']),
  roles => roles.toJS()
);

export const getCustomerPermissions = createDeepEqualSelector(
  (state) => state.app.getIn(['currentUser', 'customerPermissions']).toJS(),
    res => res
);

export const getBillingAccountPaymentMethods = createDeepEqualSelector(
  (state) => state.app.getIn(['currentUser', 'paymentMethods', 'billingAccs']).toJS(), res => res
);

export const getSelectedProfileId = createSelector(
  (state) => state.app.getIn(['selectedProfile', 'id']), res => res
);

export const linkedOrgsLoading = createSelector(
  (state) => state.app.getIn(['currentUser', 'linkedOrgsLoading']), res => res
);

export const getOrganization = createSelector(
  (state, organizationId) => getLinkedOrgs(state),
  (state, organizationId) => organizationId,
  (orgs, organizationId) => {
    return orgs.find(org => org.organizationId === organizationId);
  }
);

export const getOrgBillingAccounts = createSelector(
  (state, organizationId) => getOrganization(state, organizationId),
  (org) => {
    const billingAccounts = org?.organizationProfile?.billingAccounts?.filter(ba => ba.active);
    return billingAccounts ?? [];
  }
);

export const getOrgShippingAddresses = createSelector(
  (state, organizationId) => getOrgBillingAccounts(state, organizationId),
  (billingAccounts) => {
    const addresses = billingAccounts?.map(ba => ba.addresses ?? []).flat();

    const shippingAddresses = addresses
      ?.filter(s => s.category === 'SHIPPING')
      ?.filter(s => s.active);

    return shippingAddresses ?? [];
  }
);

export const getBillingAccountShippingAddresses = createSelector(
  (state, organizationId, accountNumber) => getOrgBillingAccounts(state, organizationId),
  (state, organizationId, accountNumber) => accountNumber,
  (billingAccounts, accountNumber) => {
    const billingAccount = billingAccounts?.find(ba => ba.accountNumber === accountNumber);

    const shippingAddresses = billingAccount?.addresses
      ?.filter(s => s.category === 'SHIPPING')
      ?.filter(s => s.active);

    return shippingAddresses ?? [];
  }
);

export const getSelectedProfileOrg = createDeepEqualSelector(
  getSelectedProfileId,
  getLinkedOrgs,
  (selectedProfileId, linkedOrgs) => linkedOrgs.find(org => org.organizationId === selectedProfileId)
);

export const getSelectedProfileBillingAccounts = createDeepEqualSelector(
  getSelectedProfileOrg,
  getBillingAccountPaymentMethods,
  (selectedProfileOrg, billingAccountPaymentMethods) => selectedProfileOrg?.organizationProfile?.billingAccounts?.map(ba => {
    const paymentMethod = billingAccountPaymentMethods?.find(bapm => bapm.billingAccountId === ba.id);
    const userCanAccessBA = selectedProfileOrg?.userGroups?.length > 0 || !!paymentMethod;

    return {
      ...ba,
      userCanAccess: userCanAccessBA,
      organizationName: selectedProfileOrg.organizationName,
      organizationId: selectedProfileOrg.organizationId,
      billingAddress: ba?.addresses?.find(a => a.active && a.defaultAddress && a.category === 'BILLING'),
      paymentMethod
    };
  }) || []
);

export const getLinkedOrganizationPinRevalidations = createDeepEqualSelector(
  getLinkedOrgs,
  getBillingAccountPaymentMethods,
  getCurrentUserId,
  (linkedOrgs, billingAccs, currentUserId) => {
    const pinLinkedOrgs = linkedOrgs
      ?.filter(org => org.isAssociatedWithPin)
      ?.filter(org => {
        if (!org?.groups) return true;
        const inAGroup = org.groups.some(group => group.members?.some(m => m.personId === currentUserId));
        return !inAGroup;
      });

    const orgsToRevalidate = billingAccs
      ?.filter(ba => pinLinkedOrgs.some(o => o.organizationId === ba.organizationId))
      ?.filter(ba => ba.pinValidationRequired)
      ?.map(ba => ({
        organizationId: ba.organizationId,
        accountNumber: ba.accountNumber,
        id: ba.id
      }));

    return orgsToRevalidate ?? [];
  }
);

export const getOrgAccess = createDeepEqualSelector(
  getLinkedOrgs,
  getCurrentUserId,
  getBillingAccountPaymentMethods,
  (linkedOrgs, currentUserId, billingAccountPaymentMethods) => linkedOrgs.map(org => {
    const hasLegacyAccess = org.isAssociatedWithPin;
    const pinValidationRequired = billingAccountPaymentMethods?.filter(bapm => bapm.organizationId === org.organizationId).some(ba => ba.pinValidationRequired);
    const groups = org.groups?.filter(group => group.members?.some(m => m.personId === currentUserId)) ?? [];
    const roles = [
      ...new Set(
        groups?.map(group => group?.roles
          ?.map(r => r.name) ?? [])
          .reduce((x, y) => x.concat(y), [])
      )
    ];
    return {
      organizationId: org.organizationId,
      roles,
      hasLegacyAccess,
      pinValidationRequired
    };
  })
);

export const getAdminRoleAccess = createDeepEqualSelector(
  getLinkedOrgMemberRoles,
  getCurrentUserId,
  (linkedOrgsMemberRoles, currentUserId) =>
    linkedOrgsMemberRoles?.administrator?.some(member => member.customerId === currentUserId)
);

export const getSelectedOrganizationPurchaserRoles = createDeepEqualSelector(
  getLinkedOrgMemberRoles,
  (linkedOrgsMemberRoles) => {
    const purchaserRoles =  linkedOrgsMemberRoles?.organizationPurchaser;
    
    return purchaserRoles;
  }
);

export const getPurchaseAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (permissions, orgId) =>
    permissions.filter(permission => permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*')
    .find(permission => permission?.displayId === 'PurchaseOnBehalfOfOrganization')?.hasAccess
);


export const getManageRolesAndPermissionsAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (permissions, orgId) =>
    permissions.filter(permission => permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*')
    .find(permission => permission?.displayId === 'ManageRolesAndPermissions')?.hasAccess
);

export const getManageBillingAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (permissions, orgId) =>
    permissions.filter(permission => permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*')
    .find(permission => permission?.displayId === 'ManageBilling')?.hasAccess
);

export const getPayByACHAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (state, orgId, billingAccounts) => billingAccounts,
  (permissions, orgId, billingAccounts) => {
    return (permissions.filter(permission =>
      permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*'
      && billingAccounts?.includes(permission?.permissionScope?.account))
    .filter(permission => permission?.displayId === 'PayWithACH'));
  }
);
export const getManageACHPaymentMethodsAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (state, orgId, billingAccounts) => billingAccounts,
  (permissions, orgId, billingAccounts) => {
    return (permissions.filter(permission =>
      permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*'
      && billingAccounts?.includes(permission?.permissionScope?.account))
    .filter(permission => permission?.displayId === 'ManageACHPaymentMethods'));
  }
);

export const getViewTransactionHistoryAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (state, orgId, billingAccounts) => billingAccounts,
  (permissions, orgId, billingAccounts) => {
    return  permissions.filter(permission =>
      permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*'
      && billingAccounts?.includes(permission?.permissionScope?.account))
    .filter(permission => permission?.displayId === 'ViewTransactionHistory');
  }
);

export const getManageShippingAddressesAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (permissions, orgId) =>
    permissions.filter(permission => permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*')
    .find(permission => permission?.displayId === 'ManageShippingAddresses')?.hasAccess
);

export const getManageTaxExemptionsAccess = createDeepEqualSelector(
  (state) => getCustomerPermissions(state),
  (state, orgId) => orgId,
  (permissions, orgId) =>
    permissions.filter(permission => permission?.permissionScope?.organization === orgId || permission?.permissionScope?.organization === '*')
    .find(permission => permission?.displayId === 'ManageTaxExemptions')?.hasAccess
);

export const getSelectedOrgAccess = createDeepEqualSelector(
  getSelectedProfileId,
  getOrgAccess,
  (selectedProfileId, orgAccess) => orgAccess.find(access => access.organizationId === selectedProfileId)
);

export const getOrganizationInvitations = createSelector(
  (state) => state.invitations.getIn(['pendingOrganizationInvites']).toJS(), res => res
);

export const getSelectedOrganizationInvitations = createDeepEqualSelector(
  getSelectedProfileId,
  getOrganizationInvitations,
  (selectedProfileId, { loading, error, data }) => ({
    loading,
    error,
    data: data?.filter(invitation => invitation.lookupKey === selectedProfileId)
  })
);

export const getSelectedOrganizationPurchasersGroup = createSelector(
  (state, organizationId) => getOrganization(state, organizationId),
  (org) => {
    const purchasersGroupArray = org?.groups?.filter(group => group.name === 'Purchasers');
    const purchasersGroup = purchasersGroupArray.length > 0 ? purchasersGroupArray[0] : [];
    return purchasersGroup ?? [];
  }
);

export const getDefaultBillingAccountId = createSelector((state) =>
  state.app.getIn(['currentUser', 'defaultBillingAccountId']),
  (defaultBillingAccountId) => defaultBillingAccountId
);
