import { createLogic } from 'redux-logic';
import { LOCATION_CHANGE } from 'react-router-redux';
import { toggleForm, triggerModal, toggleModal, setAddressSuggestions, setCurrentGiftCard, setSearchResults } from '../actions/AppActions';
import history from '../utils/history';
import {
  PATH_PROFILE,
  PATH_ADD_PHONE,
  PATH_EDIT_PHONE,
  PATH_DELETE_PHONE,
  APP_NAVIGATE_AWAY,
  PATH_EDIT_PERSONAL_INFO,
  PATH_EDIT_EMAIL,
  PATH_ADD_SHIPPING_ADDRESS,
  PATH_USER_ADDRESS_MANAGEMENT,
  PATH_DELETE_SHIPPING_ADDRESS,
  PATH_EDIT_SHIPPING_ADDRESS,
  PATH_EDIT_PASSWORD,
  PATH_SUBSCRIPTIONS_EDIT_PAYMENT,
  PATH_SUBSCRIPTIONS,
  PATH_ADD_CREDIT_CARD,
  PATH_PAYMENT_METHODS,
  PATH_CREDIT_CARDS,
  PATH_EDIT_CREDIT_CARD,
  PATH_GIFT_CARDS,
  PATH_DIGITAL_MEDIA
} from '../constants';

export const formToggleLogic = createLogic({
  type: [LOCATION_CHANGE],
  latest: true,

  processOptions: {
    dispatchReturn: false,
    dispatchMultiple: true
  },

  process({ action, getState }, dispatch) {
    if (window.navigateAway) return true;
    let id;
    let pathname = action.payload.location.pathname;
    const segments = pathname.split('/');

    if ((pathname.indexOf('edit') > -1 || pathname.indexOf('delete') > -1) && segments.length > 4) {
      id = segments.pop();
      pathname = segments.join('/');
    }

    dispatch(setAddressSuggestions());
    dispatch(setSearchResults());
    if (pathname.indexOf(PATH_DIGITAL_MEDIA) > -1) {
      const el = document.querySelector('[data-qa-hook="contentPane"]');
      if (el) el.scrollTop = 0;
    }

    switch (pathname) {
      case PATH_GIFT_CARDS: {
        dispatch(setCurrentGiftCard());
        break;
      }
      case PATH_DELETE_PHONE:
      case PATH_SUBSCRIPTIONS:
      case PATH_USER_ADDRESS_MANAGEMENT:
      case PATH_DELETE_SHIPPING_ADDRESS:
      case PATH_PROFILE: {
        dispatch(toggleForm(null));
        break;
      }
      case PATH_EDIT_PERSONAL_INFO:
      case PATH_EDIT_EMAIL:
      case PATH_EDIT_PASSWORD:
      case PATH_ADD_SHIPPING_ADDRESS:
      case PATH_ADD_PHONE:
        dispatch(toggleForm(pathname));
        break;
      case PATH_EDIT_SHIPPING_ADDRESS:
      case PATH_SUBSCRIPTIONS_EDIT_PAYMENT:
      case PATH_EDIT_PHONE:
        dispatch(toggleForm(`${pathname}/${id}`));
        break;
      case PATH_CREDIT_CARDS:
        dispatch(toggleForm(null));
        break;
      case PATH_PAYMENT_METHODS:
        dispatch(toggleForm(null));
        break;
      case PATH_ADD_CREDIT_CARD:
        dispatch(toggleForm(pathname));
        break;
      case PATH_EDIT_CREDIT_CARD:
        dispatch(toggleForm(`${pathname}/${id}`));
        break;
      default:
        return false;
    }
    return false;
  }
});

export const pageLeaveLogic = createLogic({
  type: [APP_NAVIGATE_AWAY],
  latest: true,

  processOptions: {
    dispatchMultiple: true
  },

  process({ action, getState }, dispatch) {
    const pathname = location.pathname;
    const newPath = action.payload.pathname;

    // page leave while editing
    if (pathname.indexOf('edit') > -1 || pathname.indexOf('add/') > -1) {
      const modalProps = {
        show: true,
        message: 'You are about to exit the page without saving your changes. Please select the desired action: ',
        okLabel: 'Continue Editing',
        cancelLabel: 'Leave without Saving',
        buttonOrder: 'rtl',
        cancelClick: () => {
          dispatch(toggleModal());
          window.navigateAway = true;
          history.push(newPath);
          window.navigateAway = false;
        },
        cancelDataHook: 'leaveWithoutSave.button',
        okClick: () => {
          dispatch(toggleModal());
          window.navigateAway = false;
        },
        okDataHook: 'continueEditing.button'
      };
      dispatch(triggerModal(modalProps));
    }

    return false;
  }
});

export default [
  formToggleLogic,
  pageLeaveLogic
];
