import { createLogic } from 'redux-logic';
import moment from 'moment';
import {
  CREATE_AUTOSHIP_PENDING, PARSE_DATE_FORMAT
} from '../../constants';
import {
  getOrgAutoshipsPending,
  getPersonalAutoshipsPending,
  createAutoshipSuccess,
  createAutoshipError
} from '../../actions/AppActions';
import { getOrganization } from '../../selectors/OrgManagementSelectors';
import { formatAutoshipAddress, formatAutoshipPaymentMethod, getCurrentUser, getMatchingAddress, getMatchingPaymentMethod } from './utils';

const buildAutoshipCustomer = (user, organizationId, state) => {
  if (organizationId == null) {
    return {
      customerType: 'Individual',
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };
  } else {
    const org = getOrganization(state, organizationId);

    return {
      customerType: 'Organization',
      id: organizationId,
      name: org?.organizationName,
      contactPerson: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      }
    };
  }
};

const createNewAutoship = async ({ httpClient, getState, action }, dispatch) => {
  const {
    organizationId,
    name,
    items,
    orderDate,
    frequency,
    paymentMethodId,
    shippingAddressId,
    type,
  } = action.payload;
  const state = getState();

  const { paymentMethod, type: paymentMethodType } = getMatchingPaymentMethod(state, { paymentMethodId, organizationId });
  const billingAddress = paymentMethodType === 'billing-account'
    ? paymentMethod.billingAddress
    : paymentMethod.address;
  const shippingAddress = getMatchingAddress(state, organizationId, shippingAddressId);

  const user = getCurrentUser(state);

  const autoshipUrl = type === 'quarterly' ? 'autoships/quarterly' : 'autoships/custom';

  return httpClient(dispatch).post(autoshipUrl, {
    customer: buildAutoshipCustomer(user, organizationId, state),
    // subscription service throws an error if autoshipName is an empty string
    autoshipName: name !== '' ? name : undefined,
    channel: 'lifeway',
    items: items.map(item => ({
      productSku: item.itemNumber,
      quantity: item.quantity
    })),
    scheduledOrderDate: type === 'custom' ? moment(orderDate, PARSE_DATE_FORMAT).format('YYYY-MM-DD') : undefined,
    billingFrequency: frequency,

    paymentMethod: formatAutoshipPaymentMethod(paymentMethodType, paymentMethod),
    billingAddress: formatAutoshipAddress(billingAddress, user),
    shippingAddress: formatAutoshipAddress(shippingAddress, user)
  })
    .then((resp) => resp.data.subscriptionId)
    .catch(error => Promise.reject({ errorSource: 'error-create-new', error }));
};


export const createAutoship = createLogic({
  type: CREATE_AUTOSHIP_PENDING,

  process({ httpClient, getState, action }, dispatch, done) {
    const { organizationId, name } = action.payload;

    createNewAutoship({ httpClient, getState, action }, dispatch)
      .then((newAutoshipId) => {
        dispatch(createAutoshipSuccess(newAutoshipId, { organizationId, name }));

        if (action.meta.refetchAutoships) {
          if (organizationId) {
            dispatch(getOrgAutoshipsPending(organizationId));
          } else {
            dispatch(getPersonalAutoshipsPending());
          }
        }

        action.meta?.onSuccess?.(newAutoshipId);
      })
      .catch((e) => {
        const error = e.error ?? e;

        dispatch(createAutoshipError({ organizationId, name }, error));

        action.meta?.onError?.(error);
      })
      .finally(() => done());
  }
});

export default [
  createAutoship
];
