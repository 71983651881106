import { createLogic } from 'redux-logic';
import { stopSubmit, setSubmitFailed } from 'redux-form';
import moment from 'moment';
import history from '../utils/history';
import { POST_PERSONAL_INFO_PENDING, PATH_PROFILE } from '../constants';
import { postPersonalInfoSuccess } from '../actions/AppActions';

export const postPersonalInfoPendingLogic = createLogic({
  type: POST_PERSONAL_INFO_PENDING,
  latest: true,
  process({ httpClient, action: { payload } }, dispatch, done) {
    const { birthDate } = payload;
    const form = 'PersonalInfo';
    const userInfo = {
      ...payload,
      birthDate: birthDate ? moment.utc(birthDate, 'MM-DD-YYYY').toISOString() : ''
    };
    httpClient(dispatch).put('/person', userInfo)
      .then(res => {
        const data = res.data;
        const birthDate = data.birthDate;
        return {
          ...data,
          birthDate: birthDate ? moment.utc(birthDate).utcOffset('+0100').format('MM/DD/YYYY') : null
        };
      })
      .then(data => {
        dispatch(stopSubmit(form));
        dispatch(postPersonalInfoSuccess({ data }));
        history.push(PATH_PROFILE);
        done();
      })
      .catch(error => {
        const message =
          error.status < 500
            ? 'Please fill out all required form fields'
            : 'An error occurred while updating your personal information, please try again';
        dispatch(stopSubmit(form, { _error: message }));
        dispatch(setSubmitFailed(form));
        done();
      });
  }
});

export default [
  postPersonalInfoPendingLogic
];
