import { bool, func, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import InitialsCircle from '../../InitialsCircle';

const StyledLink = styled(Link)`
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  border-left: none;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0 10px 0 0;
  font-family: sofia-pro, "helvetica neue", helvetica, arial, sans-serif;
  text-decoration: none;
  margin: 1px 1px 1px 0px;
  transition-property: border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 30ms;
  &:hover {
    border: 2px solid #5279A1;
    border-left: none;
    margin: 0px;
  }
`;

export const Name = styled.span`
  color: #16395f;
  font-size: 13px;
  margin-left: 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Spacing = styled.div`
  padding: 0.2rem 0;
`;

const ProfileLink = ({
  onClick,
  initials,
  name,
  to = '',
  fullWidth = false,
}) => {
  return (
    <Spacing>
      <StyledLink
        onClick={onClick}
        to={to}
        style={{ width: fullWidth ? '100%' : '270px' }}
      >
        <InitialsCircle
          initials={initials}
          initialsColor="#414042"
          small={true}
          centered={true}
          ringColor="#3D6995"
          styles={{ margin: 0 }}
        />
        <Name>{name}</Name>
      </StyledLink>
    </Spacing>
  );
};

export default ProfileLink;

ProfileLink.propTypes = {
  onClick: func,
  initials: string,
  name: string,
  to: string,
  fullWidth: bool,
};