import { Formik, Form, Field } from 'formik';
import { func, object } from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import cssVariables from '../../../../css/css-variables';
import { FooterNewsletterHeading, FormMessageSection, FooterNewsletter, Button } from '@lifewayit/components/dist/lib';

const getInitialFormState = (data) => {
  if (data) {
    const {
      campaignId,
      formId,
      hiddenFields,
    } = data;

    const initialState = {
      firstName: '',
      lastName: '',
      emailAddress: '',
      formId,
      campaignId,
      referringUrl: typeof window !== 'undefined' ? window.location.href : '',
    };
    // reducing hidden fields to form data
    hiddenFields.reduce((accum, val) => {
      accum[val?.hiddenName] = val?.hiddenValue;
      return accum;
    }, initialState);
    return initialState;
  }
  };

const NewsLetterForm = ({data, handleFormSubmit}) => {
  const [message, setMessage] = useState(null);

  const initialFormState = getInitialFormState(data);

  const handleOnSubmit = (values, actions) => {
    const errors = !values.firstName || !values.lastName || !values.emailAddress;
    if (!values.firstName) {
      setMessage({text: 'The first name is required', type: 'error'});
    } else if (!values.lastName) {
      setMessage({text: 'The last name is required', type: 'error'});
    } else if (!values.emailAddress) {
      setMessage({text: 'The email address is required', type: 'error'});
    }
    actions.setSubmitting(false);
    try {
      if (!errors) {
        handleFormSubmit(values, actions);
        setMessage({
          text: 'Great info coming to your inbox soon!',
          type: 'success',
        });
      }
  } catch (error) {
    setSubmitting(false)
    setMessage({ text: error.message, type: 'error' });
  }
};

  return (
    <FooterNewsletter data-qa-hook="newsLetterForm">
      {message ? (
        <FormMessageSection
          message={message?.text}
          type={message?.type}
        />
      ) : (
        <FooterNewsletterHeading>
          {data?.headingText}
        </FooterNewsletterHeading>
      )}
      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialFormState}
        name={data?.formId}
        id={data?.formId}
        data-formid={data?.formId}
        data-campaignid={data?.campaignId}
      >
        {({ values, touched, isSubmitting }) => (
          <Form disabled={isSubmitting}>
            <InputSection>
              <div>
                <StyledInput
                  data-qa-hook="firstName"
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  fullWidth
                  errorBorder={checkError(
                    values.firstName,
                    touched.firstName
                  )}
                />
              </div>
              <div>
                <StyledInput
                  data-qa-hook="lastName"
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  fullWidth
                  errorBorder={checkError(values.lastName, touched.lastName)}
                />
              </div>
            </InputSection>
            <InputSection style={{ paddingTop: '10px' }}>
              <div>
                <StyledInput
                  data-qa-hook="emailAddress"
                  id="emailAddress"
                  name="emailAddress"
                  type="email"
                  placeholder="Email"
                  fullWidth
                  errorBorder={checkError(values.emailAddress, touched.emailAddress)}
                />
              </div>
            </InputSection>
          <Button
            data-qa-hook="signup-form-button"
            variant="blue"
            size="md"
            type="submit"
            className="mt-2 block rounded-full leading-relaxed text-center transition-all duration-200 solid-focus-ring button bg-blue text-white hover:text-white hover:bg-blue-hover focus:text-white focus:bg-blue-hover active:bg-blue-active px-4 pt-1 pb-1.5 text-base block rounded-full leading-relaxed text-center transition-all duration-200 solid-focus-ring"
          >
            {data?.buttonText}
          </Button>
          </Form>
        )}
      </Formik>
    </FooterNewsletter>
  );
};

NewsLetterForm.propTypes = {
  data: object,
  handleFormSubmit: func
};

export default NewsLetterForm;

const StyledInput = styled(({ errorBorder, fullWidth, ...props }) => (
  <Field {...props} />
))`
  display: block;
  padding: 0.5rem 0.75rem;
  border: 1px solid #cccccc;
  border-radius: 3px;
  margin-bottom: 0.5rem;
  color: #222;
  height: 35px;
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ errorBorder }) =>
    errorBorder && `border: 1px solid ${cssVariables.colors.ErrorBright};`}
`;

const InputSection = styled.fieldset`
  display: flex;
  flex-direction: row;

  text-align: left;

  & > * {
    flex: 1 1 0;
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const checkError = (value, touched) => !value && touched;
