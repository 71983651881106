import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import css from '../../../css/css-variables';

const { string, func, bool, object, node, oneOfType } = PropTypes;

const DisabledLink = styled.span`
  color: ${css.colors.Muted};
`;

const LinkToggle = (props) => {
  const {
    disabled,
    href,
    children,
    external,
    to,
    onClick = () => null,
  } = props;

  const sharedProps = {
    onClick,
    // wrap it in a span so we can test it.
    children: <span>{children}</span>,
    'data-qa-hook': props['data-qa-hook'] || 'app.link'
  };

  const attrs = {
    mockLink: {
      children
    },
    internal: {
      to,
      ...sharedProps,
    },
    external: {
      href: to || href,
      ...sharedProps,
      rel: 'noopener noreferrer',
      target: '_blank'
    }
  };

  if (disabled) return <DisabledLink {...attrs.mockLink} />;

  return external ? <a {...attrs.external} /> : <Link {...attrs.internal} />;
};

LinkToggle.propTypes = {
  disabled: bool,
  children: node,
  external: bool,
  to: oneOfType([string, object]),
  onClick: func,
  href: string
};
export default LinkToggle;
