import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';

export function getTablePagination(settings) {
  let size = settings.size || 5;
  const { currentPage, totalRows, rowsPerPage } = settings;
  const pad = Math.floor(size / 2);
  let pageList;
  let min;
  let max;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  size = (size > totalPages) ? totalPages : size;

  if (currentPage < pad + 1) {
    min = 1;
    pageList = range(min, size + 1);
  } else if (currentPage > pad && currentPage < totalPages - pad + 1) {
    min = currentPage - pad;
    pageList = range(min, currentPage + pad + 1);
  } else {
    min = totalPages - size + 1;
    pageList = range(min, totalPages + 1);
  }

  const showFirst = currentPage > pad + 1 || currentPage === totalPages && totalPages > 1;
  const showLast = currentPage < totalPages;
  min = (currentPage - 1) * rowsPerPage + 1;
  max = (currentPage * rowsPerPage);
  max = (max > totalRows) ? totalRows : max;

  return { totalPages, pageList, min, max, showFirst, showLast };
}

export function getRowRange(page, rows) {
  let min = 0;
  let max = 9;
  let length = 10;
  const r = rows.toJS();

  if (page > 1) {
    min = (page - 1) * 10;
    if (page * 10 > r.length) {
      // last page
      max = r.length - 1;
      length = r.length - min;
    } else {
      // all other pages
      max = min + 10 - 1;
      length = 10;
    }
  }
  return { min, max, length };
}

export function padOrderResults(results, currentPage, itemsPerPage) {
  const { rows } = results;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paddedRows = Array.from({ length: results.totalItems }, () => ({}));
  for (let i = 0; i < rows.length; i++) {
    paddedRows[startIndex + i] = rows[i];
  }
  return paddedRows;
}

export function padResults(results) {
  const { rows } = results;
  if (rows.length < results.totalItems) {
    for (let i = rows.length; i < results.totalItems; i += 1) {
      rows.push({});
    }
  }
  return rows;
}

export function getResultSlice(results, currentPage, rowsPerPage) {
  const begin = ((currentPage - 1) * rowsPerPage);
  const end = begin + rowsPerPage;
  return results?.length ? results.slice(begin, end) : [];
}

export function getNextPage(tableSettings) {
  const currentPage = tableSettings.get('currentPage');
  const rowsPerPage = tableSettings.get('rowsPerPage');
  return Math.ceil(rowsPerPage * currentPage / 10);
}

export function shouldFetchResults(rows, tableSettings, fromForm) {
  if (fromForm) return true;
  const currentPage = tableSettings.get('currentPage');
  const rowsPerPage = tableSettings.get('rowsPerPage');
  const rowArray = rows && rows.valueSeq().toJS() || [];
  const results = getResultSlice(rowArray, currentPage, rowsPerPage);
  return isEmpty(results[0]);
}

export function getLastResult(rows) {
  const rowArray = rows.valueSeq().toJS() || [];
  return rowArray.pop();
}

export function shouldTableUpdate(rows, uiState, nextProps) {
  const currentPage = uiState.get('currentPage');
  const rowsPerPage = uiState.get('rowsPerPage');
  const currentRows = getResultSlice(rows, currentPage, rowsPerPage);

  const nextPage = nextProps.uiState.get('currentPage');
  const nextRowsPerPage = nextProps.uiState.get('rowsPerPage');
  const nextRows = getResultSlice(nextProps.rows, nextPage, nextRowsPerPage);

  if (isEmpty(nextRows[0]) && !isEmpty(currentRows[0])) return false;
  return true;
}

export function toCamelCase(str) {
  return str
    .replace(/\s(.)/g, $1 => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, $1 => $1.toLowerCase());
}

export function toDotSeparator(str) {
  return str
    ?.replace(/\s(.)/g, $1 => $1.toLowerCase())
    ?.replace(/\s/g, '.')
    ?.replace(/^(.)/, $1 => $1.toLowerCase());
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
