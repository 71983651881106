import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import EmbeddedPDF from './EmbeddedPDF';
import { useInvoicePdf } from '../../hooks/pdf';
import { fetchInvoicePdfPending } from '../../actions/AppActions';

const InvoicePdfPage = ({ location }) => {
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const pdf = useInvoicePdf(invoiceId);

  useEffect(() => {
    dispatch(fetchInvoicePdfPending({ invoiceId }));
  }, []);

  return <EmbeddedPDF pdf={pdf} location={location} />;
};

InvoicePdfPage.propTypes = {
  location: object,
};

export default InvoicePdfPage;
