import { func, bool } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import css from '../../../../css/css-variables';
import AccessibleText from '../AccessibleText';

const StyledLinkNav = styled(Link)`
  display: flex;
  align-self: center;
  color: #616161;
  position: relative;

  color: ${css.colors.LifewayComIconGray};
  transition: color 0.15s ease .25s;

  &.open {
    color: ${css.colors.DarkGray};
    transition: color 0.25s ease 0s;
  }
`;

const HamburgerIcon = styled.div`
  position: relative;
  width: 35px;
  height: 30px;

  &::before,
  &::after {
    content: "";
  }

  &::before,
  & > .middle,
  &::after {
    position: absolute;
    height: 2px;
    left: 4px;
    right: 4px;

    border-radius: 2px;

    background-color: currentColor;

    transition:
      top 0.15s ease .25s,
      bottom 0.15s ease .25s,
      transform 0.25s ease 0s,
      opacity 0s .25s;
  }

  &::before {
    top: 5px;
  }

  & > .middle {
    top: calc(50% - 1px);
  }

  &::after {
    bottom: 5px;
  }


  .open & {
    &::before,
    .middle,
    &::after {
      transition:
        top 0.25s ease 0s,
        bottom 0.25s ease 0s,
        transform 0.25s ease .15s,
        opacity 0s .15s;
    }


    &::before {
      top: calc(50% - 1px);
      transform: rotate(-135deg);
    }

    & > .middle {
      opacity: 0;
    }

    &::after {
      bottom: calc(50% - 1px);
      transform: rotate(135deg);
    }
  }
`;

const HamburgerMenu = ({ toggleNav, isOpen }) => (
  <StyledLinkNav
    className={isOpen ? 'open' : ''}
    onClick={toggleNav}
    to={`${location.pathname}#`}
    data-qa-hook="mobileHamburgerNav.icon"
  >
    <HamburgerIcon>
      <div className="middle" />
    </HamburgerIcon>
    <AccessibleText>Navigation Menu</AccessibleText>
  </StyledLinkNav>
);
HamburgerMenu.propTypes = {
  toggleNav: func,
  isOpen: bool
};

export default HamburgerMenu;
