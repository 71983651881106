import { createLogic } from 'redux-logic';
import { APP_ON_RESIZE } from '../constants';
import { onUpdateBreakPoints } from '../actions/AppActions';

export const resizeLogic = createLogic({
  type: APP_ON_RESIZE,
  latest: true,
  processOptions: {
    dispatchReturn: true,
    successType: onUpdateBreakPoints
  },
  process() {
    return {
      md: window.innerWidth < 500,
    };
  }
});

export default [
  resizeLogic
];
