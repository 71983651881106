import { bool, object } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import css from '../../../../css/css-variables';
import Card from '../../Card';
import { getCreditCardLogo } from '../../utils/creditCard';
import CreditCardLogo from '../CreditCardLogo';
import ActiveCheckMarkIcon from '../../../../img/sprite/active-check.svg';
import ReactTooltip from 'react-tooltip';
import { Badge } from '../DefaultBadge';

const SelectPaymentMethodCard = ({
  creditCard,
  billingAcc,
  isCreditCard,
  activeCard,
  setActiveCard,
  isImpersonationSession,
  isIndividualSub,
  selected,
  handleClick,
  width,
  isDefault,
  isTransfer,
  innerRef
}) => {
  const processingItem = useSelector((state) => state.app.processingItem);
  const isItemProcessing = processingItem === creditCard?.id;
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const isExpired =
    creditCard?.expirationYear < year ||
    (creditCard?.expirationYear === year &&
      creditCard?.expirationMonth <= month);
  const isSubPaymentMethodExpired =
    creditCard?.expYear < year ||
    (creditCard?.expYear === year && creditCard?.expMonth <= month);
  const cardLogo = isCreditCard && getCreditCardLogo(creditCard);

  if (isCreditCard) {
    return (
      <StyledCreditCard
        hoverBorderColor={!isImpersonationSession && css.colors.LifewayYellow}
        isDefault={isDefault}
        disabled={isItemProcessing}
        onClick={
          !isDefault &&
          (() => {
            setActiveCard(creditCard);
            handleClick && handleClick(creditCard);
          })
        }
        data-for="editPaymentMethod"
        data-tip
        selected={selected && !isExpired}
        isTransfer={isTransfer}
        activeCardId={activeCard?.id}
        creditCardId={creditCard?.id}
        width={width}
        ref={innerRef}
      >
        {!isDefault && activeCard?.id === creditCard?.id && (
          <CreditCardCheck>
            <use href={`#${ActiveCheckMarkIcon.id}`} />
          </CreditCardCheck>
        )}
        <TopLine>
          <CreditCardLogo src={cardLogo} alt="Credit Card" />
          <EndingWith>
            ending in {creditCard?.cardNumberMask || creditCard?.lastFour}
          </EndingWith>
        </TopLine>
        <FirstLine style={{ marginTop: '0.5rem' }}>
          {creditCard?.displayName ||
            creditCard?.cardHolderName ||
            creditCard?.cardholderName}
        </FirstLine>
        {creditCard?.displayName &&
          creditCard?.cardHolderName &&
          creditCard?.cardholderName && (
            <SecondLine>
              {creditCard.cardHolderName || creditCard?.cardholderName}
            </SecondLine>
          )}

        {creditCard?.address && isTransfer && isCreditCard && (
          <>
            <AddressLine>{creditCard?.address?.line1}</AddressLine>
            <AddressLine>
              {creditCard?.address?.city},{' '}
              {creditCard?.address?.countrySubdivision}{' '}
              {creditCard?.address?.postalCode}
            </AddressLine>
          </>
        )}

        {(isExpired || isSubPaymentMethodExpired) && (
          <div>
            <Badge style={{ backgroundColor: '#F2DEDE', color: '#70181E' }}>
              Credit Card Expired
            </Badge>
          </div>
        )}

        {isImpersonationSession && (
          <ReactTooltip id="editPaymentMethod" effect="solid">
            <span>
              Unable to modify credit card during impersonated session.
            </span>
          </ReactTooltip>
        )}
      </StyledCreditCard>
    );
  }
  if (!isIndividualSub) {
    return (
      <StyledAccountCard
        hoverBorderColor={!isImpersonationSession && css.colors.LifewayYellow}
        style={{
          padding: '11px 16px',
          height: '137px',
          borderColor:
            activeCard?.id === billingAcc?.id ? css.colors.LifewayYellow : '',
        }}
        disabled={isItemProcessing || isDefault}
        onClick={
          !isDefault &&
          (() => {
            setActiveCard(billingAcc);
            handleClick && handleClick(billingAcc);
          })
        }
        data-for="editPaymentMethod"
        data-tip
        isTransfer={isTransfer}
        activeCardId={activeCard?.id}
        billingAcctId={billingAcc?.id}
        ref={innerRef}
      >
        {activeCard?.id === billingAcc?.id && (
          <CreditCardCheck>
            <use href={`#${ActiveCheckMarkIcon.id}`} />
          </CreditCardCheck>
        )}
        <BillingAccFirstLine>{billingAcc?.label}</BillingAccFirstLine>
        <BillingAccSecondLine>{billingAcc.accountNumber}</BillingAccSecondLine>
      </StyledAccountCard>
    );
  }
};

SelectPaymentMethodCard.propTypes = {
  creditCard: object,
  billingAcc: object,
  isDefault: bool,
  isImpersonationSession: bool,
};

const StyledCardBase = styled(Card)`
  padding: ${({ isTransfer }) => (isTransfer ? '0.75rem 1rem' : '11px 16px')};
  width: ${({ isTransfer, width }) =>
    !isTransfer ? width || '255px' : 'auto'};
  height: ${({ isTransfer }) => (!isTransfer ? '137px' : 'auto')};
`;

export const StyledCreditCard = styled(StyledCardBase)`
  border-color: ${({ activeCardId, creditCardId }) =>
    activeCardId === creditCardId ? css.colors.LifewayYellow : ''};
`;

const StyledAccountCard = styled(StyledCardBase)`
  border-color: ${({ activeCardId, billingAcctId }) =>
    activeCardId === billingAcctId ? css.colors.LifewayYellow : ''};
`;

const EndingWith = styled.div`
  font-weight: 600;
`;

const BillingAccFirstLine = styled.div`
  font-weight: 600;
  font-size: 0.875rem;
`;

const BillingAccSecondLine = styled.div`
  font-size: 14px;
  color: ${css.colors.DarkGray};
  text-decoration: none solid rgb(65, 64, 66);
  line-height: 24px;
  margin-top: 12px;
`;

const AddressLine = styled.div`
  font-size: 15px;
  line-height: 24px;
`;

export const FirstLine = styled.div`
  font-weight: 700;
  color: ${css.colors.CardLineText};
  line-height: 24px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SecondLine = styled.div`
  line-height: 24px;
  font-size: 15px;
`;

const TopLine = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
`;

export const CurrentPaymentBadge = styled.span`
  background-color: ${css.colors.BadgeBackground};
  padding: 3px 6px;
  color: #414042;
  font-size: 13px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 0.4rem;
  letter-spacing: 0.6px;
  opacity: 0.8;
  text-decoration: none solid rgb(65, 64, 66);
`;

export const CreditCardCheck = styled.svg`
  height: 19px;
  width: 19px;
  float: right;
`;

export default SelectPaymentMethodCard;
