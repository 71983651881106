import { createLogic } from 'redux-logic';
import { LOCATION_CHANGE } from 'react-router-redux';
import { ENABLE_INTERCOM } from '../constants';

export const locationChangeLogic = createLogic({
  type: LOCATION_CHANGE,
  latest: true,

  validate({ action, getState }, allow, reject) {
    const location = getState().router.location;

    if(!ENABLE_INTERCOM){
      reject(action);
    }else if (location == null) {
      reject(action);
    } else {
      allow(action);
    }
  },

  process({ action }, dispatch, done) {
    window.Intercom('update');

    done();
  }
});

export default [
  locationChangeLogic
];
