import { createLogic } from 'redux-logic';
import { stopSubmit, setSubmitFailed, destroy } from 'redux-form';
import history from '../utils/history';
import intl from '../actions/lang/en.json';
import {
  PATH_ORG_MGMT,
  BILLING_ACCT_ASSOC_FORM,
  ORG_PATHS
} from '../constants';
import {
  setBannerState,
  addBillingAcctAssocPending,
  addBillingAcctAssocSuccess,
  addBillingAcctAssocError,
  getBillingAccsPending,
  getBillingAccsSuccess,
  getBillingAccsError,
  updateBillingAcctAssocPending,
  updateBillingAcctAssocSuccess,
  updateBillingAcctAssocError,
  deleteBillingAcctAssocPending,
  deleteBillingAcctAssocSuccess,
  deleteBillingAcctAssocError,
  deleteBillingAcctAssocConfirm,
  toggleProcessedItem,
  toggleModal,
  triggerModal,
  getLinkedOrganizationsPending
} from '../actions/AppActions';
import { formatAccounts, formatAccount, randomColor } from '../utils/profileHelpers';
import { matchPath } from 'react-router';

export const getBillingAccountsLogic = createLogic({
  type: getBillingAccsPending().type,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient(dispatch).get('/linked-billing-accounts')
      .then(res => {
        dispatch(getBillingAccsSuccess(formatAccounts(res.data)));
        done();
      })
      .catch(error => {
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.BillingAccs.Get_Billing_Accs_Error } }));
        dispatch(getBillingAccsError(error));
        done();
      });
  }
});

export const linkBillingAccountLogic = createLogic({
  type: addBillingAcctAssocPending().type,
  latest: true,
  process({ httpClient, action: { payload }, getState }, dispatch, done) {
    const form = BILLING_ACCT_ASSOC_FORM;
    const { successCallbackFn, errorCallbackFn, bannerLocation,...body } = payload;

    httpClient(dispatch).post('/linked-billing-accounts', body)
      .then(res => {
        const account = formatAccount(res.data);
        dispatch(addBillingAcctAssocSuccess(account));

        dispatch(stopSubmit(form));
        dispatch(destroy(form));
        const userId = getState().app.getIn(['currentUser', 'id']);
        if (userId) {
          dispatch(getLinkedOrganizationsPending(userId));
          dispatch(getBillingAccsPending());
        }
        if (successCallbackFn) {
          successCallbackFn();
        }
        done();
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        let message;
        if (errorCode?.includes('pin')) {
          message = intl.Errors.BillingAccs.Invalid_PIN_Billing_Acct_Assoc_Error;
        } else if (errorCode?.includes('duplicate')) {
          message = intl.Errors.BillingAccs.Duplicate_Billing_Acct_Assoc_Error;
        } else {
          message = intl.Errors.BillingAccs.Add_Billing_Acct_Assoc_Error;
        }

        dispatch(addBillingAcctAssocError(error));
        dispatch(setBannerState({ data: { type: 'error', message,  location: bannerLocation || null } }));

        if (errorCallbackFn){
          errorCallbackFn();
        }
        dispatch(stopSubmit(form, { _error: message }));
        dispatch(setSubmitFailed(form));
        done();
      });
  }
});

export const relinkBillingAccountLogic = createLogic({
  type: updateBillingAcctAssocPending().type,
  latest: true,
  process({ httpClient, getState, action: { payload } }, dispatch, done) {
    const { accountNumber, pin, id, successCallbackFn, errorCallbackFn, bannerLocation } = payload;
    httpClient(dispatch).put('/linked-billing-accounts', { accountNumber, pin, billingAccountLinkId: id })
      .then(res => {
        dispatch(updateBillingAcctAssocSuccess({
          expectedId: id,
          updatedBillingAccount: formatAccount(res.data),
        }));
        if (successCallbackFn) {
          successCallbackFn();
        }

        // only push to PATH_ORG_MGMT if on an old org managment route
        const state = getState();
        if (matchPath(state.router.location.pathname, { path: ORG_PATHS.BASE }) === null) {
          history.push(PATH_ORG_MGMT);
        }

        done();
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        let message;

        dispatch(updateBillingAcctAssocError(error));

        switch (errorCode) {
          case 'bapm.pin.invalid':
            message = intl.Errors.BillingAccs.Invalid_PIN_Billing_Acct_Assoc_Error;
            break;
          case 'billingaccount.not.found':
            message = intl.Errors.BillingAccs.Unknown_Billing_Acct_Assoc_Error;
            break;
          default:
            message = intl.Errors.BillingAccs.Update_Billing_Acct_Assoc_Error;
        }

        dispatch(setBannerState({ data: { type: 'error', message,  location: bannerLocation || null } }));
        if (errorCallbackFn) {
          errorCallbackFn();
        }
        done();
      });
  }
});

export const unlinkBillingAccountLogic = createLogic({
  type: deleteBillingAcctAssocPending().type,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
    const { id } = action.payload;
    httpClient(dispatch).delete(`/linked-billing-accounts/${id}`)
      .then(() => {
        dispatch(deleteBillingAcctAssocSuccess(id));

        // only push to PATH_ORG_MGMT if on an old org managment route
        const state = getState();
        if (matchPath(state.router.location.pathname, { path: ORG_PATHS.BASE }) === null) {
          history.push(PATH_ORG_MGMT);
        }

        done();
      })
      .catch(error => {
        dispatch(deleteBillingAcctAssocError(error));
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.BillingAccs.Delete_Billing_Acct_Assoc_Error } }));

        // only push to PATH_ORG_MGMT if on an old org managment route
        const state = getState();
        if (matchPath(state.router.location.pathname, { path: ORG_PATHS.BASE }) === null) {
          history.push(PATH_ORG_MGMT);
        }

        done();
      });
  }
});

export const unlinkBillingAccountConfirmationLogic = createLogic({
  type: deleteBillingAcctAssocConfirm().type,
  latest: true,
  processOptions: {
    dispatchMultiple: true,
  },
  process({ action: { payload }, getState }, dispatch) {
    const { id, organizationName, billingSubscriptions } = payload;
    const modalProps = {
      show: true,
      subscriptions: billingSubscriptions,
      message: `${intl.Errors.PaymentMethods.Delete_Warning_Billing_Account} ${organizationName}?`,
      okLabel: 'Yes',
      okDataHook: 'deleteBillingAcctAssocYes.button',
      cancelLabel: 'No',
      cancelDataHook: 'deleteBillingAcctAssocNo.button',
      buttonOrder: 'rtl',
      okClick: () => {
        dispatch(toggleProcessedItem(id));
        dispatch(deleteBillingAcctAssocPending({ id }));
        dispatch(toggleModal());
      },
      cancelClick: () => {
        dispatch(toggleModal());

        const state = getState();

        // only push to PATH_ORG_MGMT if on an old org managment route
        if (matchPath(state.router.location.pathname, { path: ORG_PATHS.BASE }) === null) {
          history.push(PATH_ORG_MGMT);
        }
      }
    };
    dispatch(triggerModal(modalProps));
  }
});

export default [
  getBillingAccountsLogic,
  linkBillingAccountLogic,
  relinkBillingAccountLogic,
  unlinkBillingAccountLogic,
  unlinkBillingAccountConfirmationLogic
];
