import { createLogic } from 'redux-logic';
import { IS_IMPERSONATION_SESSION } from '../constants';
import { setIsImpersonationSession } from '../actions/AppActions';

export const isImpersonationSessionLogic = createLogic({
  type: setIsImpersonationSession().type,
  latest: true,
  process({ action: { payload: { isImpersonationSession } } }, _, done) {
    if (isImpersonationSession) {
      localStorage.setItem(IS_IMPERSONATION_SESSION, isImpersonationSession);
    } else {
      localStorage.removeItem(IS_IMPERSONATION_SESSION);
    }
    done();
  }
});

export default [
  isImpersonationSessionLogic,
];
