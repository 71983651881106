import PropTypes from 'prop-types';
import React from 'react';

const List = props => {
  const { className, children } = props;
  const qaHook = (props['data-qa-hook']) ? { 'data-qa-hook': props['data-qa-hook'] } : null;
  return (
    <ul className={className} {...qaHook}>{children}</ul>
  );
};

List.propTypes = {
  'data-qa-hook': PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default List;
