import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import cssStyles from '../../../css/css-variables';


const StyledContent = styled.article`
  background-color: ${cssStyles.colors.Background};
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 100;
  order: 1;
  min-height: 23.875em;
  width: 72%;

  @media only print {
    overflow: visible;
  }

  @media screen and (max-width: 720px) {
    z-index: 0;
  }
`;
StyledContent.displayName = 'StyledContent';

const Content = ({ children }) => {

  return (
   <StyledContent data-qa-hook="contentPane">
    {children}
  </StyledContent>
  );
};

Content.propTypes = {
  children: PropTypes.node,
};

export default Content;
