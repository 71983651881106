import PropTypes from 'prop-types';
import React from 'react';
import styled, { css as styledCss } from 'styled-components';
import css from '../../../css/css-variables';
import { useViewport } from '../hooks/viewport';
import SideNavWrapper from './SideNavWrapper';

const Nav = styled.nav`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  padding: 0 24px 12px;
  width: 16em;
  background-color: ${css.colors.Background};
  border-right-style: solid;
  border-right-color: ${css.colors.Border};
  border-right-width: 2px;
  order: 0;
  min-height: 23.875em;
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 0.75s;

  @media(max-width: 1024px) {
    display: none;
  }

  @media(max-width: 858px) {
    min-width: 13.750em;
  }

`;

const SideBar = props => {
  const {
    linkedOrganizations,
    location,
    linkedOrgsPending,
    orgId,
    onLogOut
  } = props;
  const { isMobile } = useViewport();

  return (
    <Nav className="nav">
      <SideNavWrapper
        key={orgId}
        linkedOrganizations={linkedOrganizations}
        linkedOrgsPending={linkedOrgsPending}
        orgId={orgId}
        onLogOut={onLogOut}
        isMobile={isMobile}
        location={location}
      />
    </Nav>
  );
};

const { bool, object, func, array, string } = PropTypes;

SideBar.propTypes = {
  isLoggedIn: bool,
  processMenuTree: func,
  location: object.isRequired,
  linkedOrganizations: array,
  linkedOrgsPending: string,
  orgId: string,
  onLogOut: func,
};

export default SideBar;
