import React from "react";
import { func } from "prop-types";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
import css from "../../../../../css/css-variables";
import {
  LIFEWAY_COM_URL,
  PATH_DIGITAL_MEDIA,
  PATH_ORDER_HISTORY,
  PATH_PROFILE,
  PATH_LOGGED_OUT,
} from "../../../constants";
import CartIcon from "../../../../../img/sprite/cart-icon.svg";

const AccountLinks = ({ onLogOut, onLinkClick }) => {
  const history = useHistory();

  const logout = () => {
    onLinkClick();
    onLogOut();
    history.push(PATH_LOGGED_OUT);
  };

  return (
    <>
      <LinksContainer>
        <LinksList>
          <li>
            <NavLink to={PATH_PROFILE} onClick={onLinkClick}>
              My Account
            </NavLink>
          </li>
          <li>
            <NavLink to={PATH_ORDER_HISTORY} onClick={onLinkClick}>
              My Order History
            </NavLink>
          </li>
          <li>
            <NavLink to={PATH_DIGITAL_MEDIA} onClick={onLinkClick}>
              My Digital Media
            </NavLink>
          </li>
          <li>
            <button type="button" onClick={logout}>
              <span>Logout</span>
            </button>
          </li>
        </LinksList>
        <Separator />
        <LinksList>
            <li>
              <a
                href={`${LIFEWAY_COM_URL}/en/checkout/shopping-cart`}
                onClick={onLinkClick}
              >
                <CartSVG role="presentation">
                  <use href={`#${CartIcon.id}`} xlinkHref={`#${CartIcon.id}`} />
                </CartSVG>
                <span>View My Cart</span>
              </a>
            </li>
            <li>
              <a href={LIFEWAY_COM_URL} onClick={onLinkClick}>
                Keep Shopping
              </a>
            </li>
        </LinksList>
      </LinksContainer>
    </>
  );
};

export default AccountLinks;

AccountLinks.propTypes = {
  onLogOut: func.isRequired,
  onLinkClick: func.isRequired,
};

const CartSVG = styled.svg`
  margin-right: 0.5em;
  height: 1.25em;
  width: 1.25em;
  position: relative;
  top: -1px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${css.colors.LifewayComDecorationGray};
  margin: 0.75rem 0;
`;

const LinksList = styled.ul`
font-family: sofia-pro, "helvetica neue", helvetica, arial, sans-serif;
& button,
  & a {
    width: 100%;
    padding: 0.2rem 0.5rem 0.2rem 0;
    border: 0 none;
    background-color: transparent;
    text-align: left;
    text-decoration: underline;
  }
  & li {
    margin: 0.75rem 0;
  }
  & li button,
  & li a {
    color: #414042;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    display: inline;
    padding-left: 4px;
    padding-right: 6px;
  }
  & li a.active {
    border-bottom: 2px solid #fdb528;
    font-weight: bold;
  }

  & li a:hover,
  & li button:hover span {
    border-bottom: 2px solid #fdb528;
  }
`;

const LinksContainer = styled.div`
  padding: 1rem 2.5rem;
  background-color: #fbfbfb;
  border-right: 1px solid ${css.colors.LifewayComDecorationGray};
`;
