import React, { useEffect } from 'react';
import NavigationMessagePage from '../NavigationMessagePage';
import history from '../../utils/history';
import { PATH_LOGGED_OUT } from '../../constants';
import userManager from '../../utils/userManager';

const SignoutRedirectCallbackPage = () => {
  useEffect(() => {
    userManager.signoutRedirectCallback()
      .then(() => {
        history.push(PATH_LOGGED_OUT);
      });
  }, []);

  const signoutRedirect = () => {
    userManager.signoutRedirect();
  };

  return <NavigationMessagePage message="signing out..." retryAction={signoutRedirect} />;
};

export default SignoutRedirectCallbackPage;
