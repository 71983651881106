import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { reducer as formReducer } from 'redux-form';
import routerReducer from './RouterReducer';
import app from './AppReducer';
import InvitationReducer from './InvitationReducer';

export default combineReducers({
  app,
  invitations: InvitationReducer,
  router: routerReducer,
  intl: intlReducer,
  form: formReducer,
});
