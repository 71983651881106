import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import SigninRedirectPage from './oauth/SigninRedirectPage';
import SigninRedirectCallbackPage from './oauth/SigninRedirectCallbackPage';
import SignoutRedirectCallbackPage from './oauth/SignoutRedirectCallbackPage';
import Wrapper from '../containers/Wrapper';
import userManager from '../utils/userManager';
import { setDefaultAuthorizationHeader } from '../utils/httpClient';
import { useDispatch } from 'react-redux';
import { isLoggedInToTrue, isLoggedInToFalse, setIsImpersonationSession } from '../actions/AppActions';
import { useIsLoggedIn } from '../hooks/authentication';
import { PATH_LOGIN_REDIRECT, AUTH_ROUTE, PATH_LOGOUT_CALLBACK, PATH_INVITE_CANCELLED, PATH_REDIRECT, PATH_LOGGED_OUT, CURRENT_ENV } from '../constants';
import StatementPdfPage from './pdf/StatementPdfPage';
import InvoicePdfPage from './pdf/InvoicePdfPage';
import ErrorPage from './ErrorPage';
import useAppError from '../hooks/useAppError';
import InviteCancelled from './InviteCancelled';
import { ExternalResourceRedirectPage } from '../utils/LazyLoad';
import { useLocation } from 'react-router';

const accessTokenExpired = () => {
  console.warn('Access token expired...');
  userManager
    .signinSilent()
    .then(() => {
      console.log('renewed token');
    })
    .catch(() => {
      console.log('error renewing token');
    });
};

const silentRenewError = (error) => {
  console.error('[OAUTH] Silent Renew Error：', error);
};

const userSignedOut = () => {
  userManager.signoutRedirect();
};

const accessTokenExpiring = () => {
  console.warn('Access token expiring...');
  userManager
    .signinSilent()
    .then(() => {
      console.log('renewed token');
    })
    .catch(() => {
      console.log('error renewing token');
    });
};

const userLoaded = (user) => {
  setDefaultAuthorizationHeader(`Bearer ${user?.access_token}`);
};

const logout = dispatch => () => {
  dispatch(setIsImpersonationSession(false));
  userManager.signoutRedirect();
};

// Function to check if the meta tag already exists
function metaTagExists(name, content) {
  const metaTags = document.getElementsByTagName('meta');
  for (let i = 0; i < metaTags.length; i++) {
    if (metaTags[i].getAttribute('name') === name && metaTags[i].getAttribute('content') === content) {
      return true;
    }
  }
  return false;
}

// Conditionally Implement Meta Tag
if (CURRENT_ENV !== 'prod' && !metaTagExists('robots', 'noindex,nofollow,noarchive')) {
  const meta = document.createElement('meta');
  meta.name = 'robots';
  meta.content = 'noindex,nofollow,noarchive';
  document.head.appendChild(meta);
}

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const error = useAppError();

  useEffect(() => {
    userManager.getUser()
      .then(user => {
        const signoutRoutes = [PATH_LOGGED_OUT, PATH_LOGOUT_CALLBACK];
        const onSignoutRoute = location?.pathname && signoutRoutes.includes(location.pathname);
        return user || onSignoutRoute
          ? user
          : userManager.signinSilent();
      })
      .then(user => {
        if (user) {
          setDefaultAuthorizationHeader(`Bearer ${user.access_token}`);
          dispatch(setIsImpersonationSession(user.profile?.act?.iss === 'https://lifeway.okta.com'));
          dispatch(isLoggedInToTrue());
        } else {
          dispatch(isLoggedInToFalse());
        }
      })
      .catch(() => {
        userManager.signinRedirect();
      });
  }, [isLoggedIn]);

  useEffect(() => {
    userManager.clearStaleState();

    userManager.events.addUserLoaded(userLoaded);
    userManager.events.addAccessTokenExpiring(accessTokenExpiring);
    userManager.events.addAccessTokenExpired(accessTokenExpired);
    userManager.events.addSilentRenewError(silentRenewError);
    userManager.events.addUserSignedOut(userSignedOut);

    return () => {
      userManager.events.removeUserLoaded(userLoaded);
      userManager.events.removeAccessTokenExpiring(accessTokenExpiring);
      userManager.events.removeAccessTokenExpired(accessTokenExpired);
      userManager.events.removeSilentRenewError(silentRenewError);
      userManager.events.removeUserSignedOut(userSignedOut);
    };
  }, []);

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return error
    ? <ErrorPage error={error} />
    :
    <Switch>
      <Route path={PATH_LOGIN_REDIRECT} exact component={SigninRedirectPage} />
      <Route path={AUTH_ROUTE} exact component={SigninRedirectCallbackPage} />
      <Route path={PATH_LOGOUT_CALLBACK} exact component={SignoutRedirectCallbackPage} />
      <Route path="/invoices/:invoiceId" component={InvoicePdfPage} />
      <Route path="/organization/:organizationId/billing-accounts/:accountNumber/statement-dates/:date/statement" render={props => <StatementPdfPage {...props}/>}/>
      <Route path={PATH_INVITE_CANCELLED} exact component={InviteCancelled} />
      <Route path={PATH_REDIRECT} exact component={ExternalResourceRedirectPage} />
      <Route render={props => <Wrapper {...props} onLogOut={logout(dispatch)} />} />
    </Switch>;
};

export default App;
