export const classnames = (...names) =>
  flatMap(names, name => {
    if (name == null || name === '') {
      return [];
    } else if (typeof name === 'string') {
      return [name];
    } else if (typeof name === 'object') {
      return convertObjClassName(name);
    } else {
      return [];
    }
  })
  .join(' ');

const convertObjClassName = (names) =>
  flatMap(
    Object.entries(names),
    ([name, enabled]) => enabled ? [name] : []
  );

const flatMap = (list, mapper) =>
  list.reduce((acc, item) => {
    const mapped = mapper(item);
    return acc.concat(mapped);
  }, []);
