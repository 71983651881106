import { createLogic } from 'redux-logic';
import { getContentfulLinksError, getContentfulLinksPending, getContentfulLinksSuccess, setBannerState } from '../actions/AppActions';

export const getContentfulLinksLogic = createLogic({
  type: getContentfulLinksPending().type,
  process({ httpClient }, dispatch, done) {
    return httpClient(dispatch).get('/contentful')
    .then(resp => {
      dispatch(getContentfulLinksSuccess(resp.data.fields));
      done();
    })
    .catch(error => {
      dispatch(getContentfulLinksError(error))
      done();
    });
  }
});

export default [
  getContentfulLinksLogic,
];