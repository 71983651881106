import { maxBy } from 'lodash';
import moment from 'moment';
import { LAST_AUTOSHIP_ELIGIBLE_CHO_DATES } from '../constants';

export const getMatchingPriceTier = (pricingTiers, quantity) => {
  const validTiers = pricingTiers?.filter(tier => tier.minimumQuantity <= quantity);
  return maxBy(validTiers, 'minimumQuantity') ?? pricingTiers?.[0];
};

export const setIsQuarterlyAutoshipEligible = (productDetails) => {
  if (
    productDetails.isAutoshipEligible &&
    productDetails.choQuarter &&
    productDetails.autoshipType === 'Fixed Frequency'
  ) {
    const now = moment();

    const matchingChoQuarters = productDetails.choQuarter.filter(quarter => {
      const quarterDate = LAST_AUTOSHIP_ELIGIBLE_CHO_DATES.find(eligibleDateQuarter =>
        eligibleDateQuarter.quarter === quarter
      );
      return quarterDate;
    });

    if (matchingChoQuarters && matchingChoQuarters.length > 0) {
      const eligibilityHasExpired = matchingChoQuarters.every(quarter => {
        const quarterDate = LAST_AUTOSHIP_ELIGIBLE_CHO_DATES.find(eligibleDateQuarter =>
          eligibleDateQuarter.quarter === quarter
        );
        return quarterDate && now.isAfter(quarterDate.date);
      });

      if (eligibilityHasExpired) {
        productDetails.isAutoshipEligible = false;
      }
    }
  }
  return productDetails;
};
