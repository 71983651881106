export const headerData = {
  topheaderbar: {
      componentNodeName: 'topheaderbar',
      topBanner: [
          {
              cta: 'Learn more.',
              componentNodeName: 'item0',
              message: 'Our new look is only the beginning. ',
              url: '/content/lifeway/us/en/about/whats-new',
              linkName: 'gb-whatsnew',
          },
          {
              cta: 'View Details',
              componentNodeName: 'item1',
              message: 'Free shipping on books, Bibles, gifts, VBS, and church supplies of $50 or more.',
              url: '/content/lifeway/us/en/about/shipping-information',
              linkName: 'gb-freeshippingvbs',
          },
      ],
      'sling:resourceType': 'lifeway/components/section/configuration/topheaderbar',
  },
  cookiemessage: {
      messageText: '<p>To ensure you receive the best experience on Lifeway.com, we use cookies to process information about your visit. For more information, see our&nbsp;<a href="/en/about/privacy-policy.html" target="_self">Privacy Policy</a>.</p>\r\n',
      buttonText: 'Okay',
      componentNodeName: 'cookiemessage',
      textIsRich: 'true',
      'sling:resourceType': 'lifeway/components/section/configuration/cookiemessage',
  },
  loginregisterlink: {
      linksList: [
          {
              urlTarget: '_blank',
              componentNodeName: 'item0',
              label: 'Order History',
              url: 'https://my.lifeway.com/profile',
          },
          {
              urlTarget: '_blank',
              componentNodeName: 'item1',
              label: 'Digital Media',
              url: 'https://my.lifeway.com/profile',
          },
          {
              urlTarget: '_blank',
              componentNodeName: 'item2',
              label: 'Redeem Code',
              url: 'https://my.lifeway.com/order-history',
          },
          {
              urlTarget: '_blank',
              componentNodeName: 'item3',
              label: 'Manage Account',
              url: 'https://my.lifeway.com/media-content',
          },
      ],
      signInRegUrl: 'https://account.lifeway.com/authorize',
      signInRegText: 'Sign In or Register',
      componentNodeName: 'loginregisterlink',
      accountMngUrl: 'https://my.lifeway.com',
      textBeforeName: 'Logged in as ',
      'sling:resourceType': 'lifeway/components/section/configuration/loginregisterlink',
      textAfterName: '. View Account Options',
      regUrl: 'https://account.lifeway.com/register',
      accountMngText: 'My Account',
  },
  shoppingcart: {
      cartlabel: 'Cart',
      componentNodeName: 'shoppingcart',
      'sling:resourceType': 'lifeway/components/section/configuration/shoppingcart',
  },
  topnavigationconfiguration: {
    linksList: [
      {
          componentNodeName: 'item0',
          linktext: 'Articles',
          columnInternalUrl: 'https://www.lifeway.com/en/articles',
      },
      {
          componentNodeName: 'item1',
          linktext: 'Español',
          columnInternalUrl: 'https://www.lifeway.com/en/shop/espanol',
      },
      {
          componentNodeName: 'item2',
          extraCss: 'gift-cards-link track-a4t__link',
          linkIcon: 'fa-gift-card',
          linktext: 'Gift Cards',
          columnInternalUrl: 'https://www.lifeway.com/en/shop/gift-cards',
          analyticsId: 'main-gift-cards-mobile',
      },
    ],
    componentNodeName: 'topnavigationconfigu',
    'sling:resourceType': 'lifeway/components/section/configuration/topnavigationconfiguration',
    logoutLinkText: 'Sign Out',
  },
  logo: {
    image: 'https://lw-aem.s3-us-west-2.amazonaws.com/lifeway_logo_gray_shadow.svg',
    componentNodeName: 'logo',
    'sling:resourceType': 'lifeway/components/section/configuration/logo',
    fileReference: '/content/dam/lifeway/scene7/static/logo-lw.png.cq5dam.web.1280.1280.png',
    url: '/content/lifeway/us/en',
  },
  globalmenu: {
    linksList: [
      {
          componentNodeName: 'item0',
          alt: 'Ministry Grid',
          tooltipPlacement: 'auto',
          logo: '/global-menu-train',
          text: 'Train',
          title: 'Ministry Grid',
          url: 'https://ministrygrid.lifeway.com/',
      },
      {
          componentNodeName: 'item1',
          alt: 'LifeWay Worship',
          tooltipPlacement: 'auto',
          logo: '/global-menu-worship',
          text: 'Worship',
          title: 'LifeWay Worship',
          url: 'https://www.lifewayworship.com',
      },
      {
          componentNodeName: 'item2',
          alt: 'SmallGroup.com',
          tooltipPlacement: 'auto',
          logo: '/global-menu-grow',
          text: 'Builder',
          title: 'SmallGroup.com',
          url: 'https://app.smallgroup.com/redirect/login',
      },
      {
          componentNodeName: 'item3',
          alt: 'Generosity',
          tooltipPlacement: 'auto',
          logo: '/global-menu-give',
          text: 'Giving',
          title: 'Generosity',
          url: 'https://lifewaygenerosity.com',
      },
      {
          componentNodeName: 'item4',
          alt: 'LifeWay Reader',
          tooltipPlacement: 'auto',
          logo: '/global-menu-read',
          text: 'Read',
          title: 'LifeWay Reader',
          url: 'https://read.lifeway.com/',
      },
      {
          componentNodeName: 'item5',
          alt: 'DevoHub',
          tooltipPlacement: 'auto',
          logo: '/global-menu-devote',
          text: 'Devote',
          title: 'DevoHub',
          url: 'https://devohub.lifeway.com/',
      },
      {
          componentNodeName: 'item6',
          alt: 'Digital Pass',
          tooltipPlacement: 'auto',
          logo: '/global-menu-view',
          text: 'View',
          title: 'Digital Pass',
          url: 'https://digitalpass.lifeway.com/',
      },
      {
          componentNodeName: 'item7',
          alt: 'Youth Ministry Booster',
          tooltipPlacement: 'auto',
          logo: '/global-menu-ymb',
          text: 'Youth',
          title: 'Youth Ministry Booster',
          url: 'https://www.youthministrybooster.com/',
      },
    ],
    iconLinkText: "View Lifeway's Digital Platforms",
    componentNodeName: 'globalmenu',
    'sling:resourceType': 'lifeway/components/section/configuration/globalmenu',
  },
  searchbox: {
    buttonText: 'Search',
    typeAheadAriaLabel: 'type-ahead search text suggestions',
    componentNodeName: 'searchbox',
    'sling:resourceType': 'lifeway/components/section/configuration/searchbox',
    placeholderText: 'Search by keyword, title, author, ISBN, etc.',
  },
  mainnav: [
    {
        headerLinkTarget: '_self',
        itemId: 'main-bibles',
        jcr_title: 'Bibles',
        showShopAll: true,
        headerLink: 'https://www.lifeway.com/en/shop/bibles',
        sling_resourceType: 'lifeway/components/page/headerconfigurationpage',
        itemIdMobile: 'm-bibles',
        leftComponents: [
            {
                itemId: 'main-bibles-translation',
                parentItemIdMobile: 'm-bibles',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Shop by Translation',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'NIV',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/niv',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'ESV',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/esv',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'CSB',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/csb',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'KJV',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/kjv',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'NKJV',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/nkjv',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'NLT',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/nlt',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'NASB',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/nasb',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item8',
                        linkTarget: '_self',
                        text: 'RVR',
                        urlLink: 'https://www.lifeway.com/en/shop/espanol/biblias/reina-valera',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item9',
                        linkTarget: '_self',
                        text: 'NVI',
                        urlLink: 'https://www.lifeway.com/en/shop/espanol/biblias/nueva-version-internacional',
                        parentItemIdMobile: 'm-bibles',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-bibles__shop-translation',
            },
            {
                itemId: 'main-bibles-categories',
                parentItemIdMobile: 'm-bibles',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Shop by Categories',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Study Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/study-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Journaling Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/journaling-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Large Print Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/large-print-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Devotional Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/devotional-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Gift Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/gift-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Pew Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/pew-bulk-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'Thinline Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/thinline-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item8',
                        linkTarget: '_self',
                        text: 'Compact Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/compact-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item9',
                        linkTarget: '_self',
                        text: 'Storybook Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/books/kids/bible-storybooks',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item10',
                        linkTarget: '_self',
                        text: 'Bible Imprinting',
                        urlLink: 'https://www.lifeway.com/en/special-emphasis/bible-personalization-imprinting',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item11',
                        linkTarget: '_self',
                        text: 'Bible Covers',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/bible-covers',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item12',
                        linkTarget: '_self',
                        text: 'Spanish Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/espanol/biblias',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item13',
                        linkTarget: '_self',
                        text: 'Special Offers',
                        urlLink: 'https://www.lifeway.com/en/special-emphasis/special-offers',
                        parentItemIdMobile: 'm-bibles',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_2046467712',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-bibles__shop-cats',
            },
            {
                itemId: 'main-bibles-for',
                parentItemIdMobile: 'm-bibles',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Bibles for ...',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Women',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/women',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Men',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/men',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Kids',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/kids',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Teens',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/teens',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Babies',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/baby-bibles',
                        parentItemIdMobile: 'm-bibles',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_1801999760',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-bibles__bibles-for',
            },
            {
                itemId: 'main-bibles-reference',
                parentItemIdMobile: 'm-bibles',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Bibles Reference',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Commentaries',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/commentaries',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Concordances',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/concordances',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Dictionaries',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/dictionaries',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Handbooks',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/handbooks',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Atlases',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/atlases',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Archaeology',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/archaeology',
                        parentItemIdMobile: 'm-bibles',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_574540769',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-bibles__bibles-ref',
            },
        ],
        rightComponents: [
            {
                bottomLinkURL: '/content/lifeway/us/en/product.P005829288.html',
                image: '/bibles-promo-img',
                parentItemIdMobile: 'm-bibles',
                additonalLinkTarget: '_self',
                linkTarget: '_self',
                bottomLinkText: 'Read Through the Bible This Year with CSB Every Day with Jesus Daily Bible',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                bottomLinkTarget: '_self',
                itemId: 'main-bibles-imgpromo',
                componentNodeName: 'headernavigationimag',
                headernavigationimage: true,
                urlLink: 'https://www.lifeway.com/en/product/csb-every-day-with-jesus-daily-bible-brown-leathertouch-P005829288',
                itemIdMobile: 'm-bibles-imgpromo',
            },
        ],
        moreMobileComponents: [
            {
                itemId: 'not used',
                parentItemIdMobile: 'm-bibles',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        text: 'Bible Commentaries',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/commentaries',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Bible Covers',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/bible-covers',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Bible Imprinting',
                        urlLink: 'https://www.lifeway.com/en/special-emphasis/bible-personalization-imprinting',
                        parentItemIdMobile: 'm-bibles',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Bibles on Sale',
                        urlLink: 'https://www.lifeway.com/en/special-emphasis/special-offers',
                        parentItemIdMobile: 'm-bibles',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'not used',
            },
        ],
    },
    {
        headerLinkTarget: '_self',
        itemId: 'main-biblestudies',
        jcr_title: 'Bible Studies',
        showShopAll: true,
        headerLink: 'https://www.lifeway.com/en/shop/bible-studies',
        sling_resourceType: 'lifeway/components/page/headerconfigurationpage',
        itemIdMobile: 'm-bstudies',
        leftComponents: [
            {
                itemId: 'main-biblestudies-for',
                parentItemIdMobile: 'm-bstudies',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Find Studies for ...',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Women',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/women',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Men',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/men',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Kids',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/kids',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Adults',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/adults',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Youth',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/teenagers',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Young Adults',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/young-adults',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'Sunday School',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/sunday-school',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item8',
                        linkTarget: '_self',
                        text: 'Español',
                        urlLink: 'https://www.lifeway.com/en/shop/espanol/estudios-biblicos',
                        parentItemIdMobile: 'm-bstudies',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-bstudies__b-s-for',
            },
            {
                itemId: 'main-biblestudies-authors',
                parentItemIdMobile: 'm-bstudies',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Authors',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Priscilla Shirer',
                        urlLink: 'https://www.lifeway.com/en/contributors/p/priscilla-shirer',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Beth Moore',
                        urlLink: 'https://www.lifeway.com/en/contributors/b/beth-moore',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Angie Smith',
                        urlLink: 'https://www.lifeway.com/en/contributors/a/angie-smith',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Lysa TerKeurst',
                        urlLink: 'https://www.lifeway.com/en/contributors/l/lysa-terkeurst',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Jen Wilkin',
                        urlLink: 'https://www.lifeway.com/en/contributors/j/jen-wilkin',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Tony Evans',
                        urlLink: 'https://www.lifeway.com/en/contributors/t/tony-evans',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'See more authors ...',
                        urlLink: 'https://www.lifeway.com/en/shop/christian-authors',
                        parentItemIdMobile: 'm-bstudies',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_2007099456',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-bstudies__b-s-authors',
            },
            {
                itemId: 'main-biblestudies-featured',
                parentItemIdMobile: 'm-bstudies',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Featured Studies',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Elijah by Priscilla Shirer',
                        urlLink: 'https://www.lifeway.com/en/product-family/elijah-bible-study',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Matchless by Angie Smith',
                        urlLink: 'https://www.lifeway.com/en/product-family/matchless-bible-study',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'U-Turns by Tony Evans',
                        urlLink: 'https://www.lifeway.com/en/product-family/uturns',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'The Good Life by Derwin Gray',
                        urlLink: 'https://www.lifeway.com/en/product-family/the-good-life-bible-study',
                        parentItemIdMobile: 'm-bstudies',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'TruthFilled by Ruth Chou Simons',
                        urlLink: 'https://www.lifeway.com/en/product-family/truthfilled-bible-study',
                        parentItemIdMobile: 'm-bstudies',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_1486074113',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-bstudies__b-s-featured',
            },
            {
                parentItemIdMobile: 'm-bstudies',
                linkList: [
                    {
                        itemId: 'main-biblestudies-imgetb',
                        image: 'https://lwtemplates.wpengine.com//img/nav-test-images/etb-nav@2x.jpg',
                        altText: 'Bible Studies - Explore The Bible Image',
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        text: 'Explore The Bible',
                        urlLink: 'https://www.lifeway.com/content/lifeway/us/en/shop/explore-the-bible',
                    },
                    {
                        itemId: 'main-biblestudies-imgbsfl',
                        image: 'https://lwtemplates.wpengine.com//img/nav-test-images/bsfl-nav@2x.jpg',
                        altText: 'Bible Studies - Bible Studies for Life Image',
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Bible Studies for Life',
                        urlLink: 'https://www.lifeway.com/content/lifeway/us/en/shop/bible-studies-for-life',
                    },
                    {
                        itemId: 'main-biblestudies-imgtgp',
                        image: 'https://lwtemplates.wpengine.com//img/nav-test-images/tgp-nav@2x.jpg',
                        altText: 'Bible Studies - The Gospel Project Image',
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'The Gospel Project',
                        urlLink: 'https://www.lifeway.com/content/lifeway/us/en/shop/the-gospel-project',
                    },
                ],
                componentNodeName: 'headernavigationimag',
                subtitle: 'Popular Studies for Every Church',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimagegroup',
                headernavigationimagegroup: true,
            },
        ],
        rightComponents: [
            {
                bottomLinkURL: '/content/lifeway/us/en/product-family/elijah-bible-study',
                image: '/encountering_god_nav_image_1?scl=1',
                parentItemIdMobile: 'm-bstudies',
                altText: 'Encountering God',
                linkTarget: '_self',
                bottomLinkText: 'Get the Latest Study from Kelly Minter',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                bottomLinkTarget: '_self',
                itemId: 'main-biblestudies-imgpromo',
                componentNodeName: 'headernavigationimag',
                headernavigationimage: true,
                urlLink: 'https://www.lifeway.com/en/product-family/elijah-bible-study',
                itemIdMobile: 'm-biblestudies-imgpromo',
            },
        ],
    },
    {
        headerLinkTarget: '_self',
        itemId: 'main-books',
        jcr_title: 'Books',
        showShopAll: true,
        headerLink: 'https://www.lifeway.com/en/shop/books',
        sling_resourceType: 'lifeway/components/page/headerconfigurationpage',
        itemIdMobile: 'm-books',
        leftComponents: [
            {
                itemId: 'main-books-categories',
                parentItemIdMobile: 'm-books',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Popular Categories',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Christian Living',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Fiction',
                        urlLink: 'https://www.lifeway.com/en/shop/books/fiction',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Devotionals',
                        urlLink: 'https://www.lifeway.com/en/shop/books/devotions-journals',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Journals',
                        urlLink: 'https://www.lifeway.com/en/shop/books/devotions-journals/journals',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Kids Storybooks',
                        urlLink: 'https://www.lifeway.com/en/shop/books/kids/bible-storybooks',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Theology',
                        urlLink: 'https://www.lifeway.com/en/shop/books/theology',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'eBooks',
                        urlLink: 'https://www.lifeway.com/en/shop/books/ebooks',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item8',
                        linkTarget: '_self',
                        text: 'Books in Bulk',
                        urlLink: 'https://www.lifeway.com/en/special-emphasis/bulk-book-deals',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item9',
                        linkTarget: '_self',
                        text: 'Special Offers',
                        urlLink: 'https://www.lifeway.com/en/special-emphasis/special-offers',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item10',
                        linkTarget: '_self',
                        text: 'Boxed Cards',
                        urlLink: 'https://www.lifeway.com/en/shop/christian-gifts/stationery-cards',
                        parentItemIdMobile: 'm-books',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-books__pop-cats',
            },
            {
                itemId: 'main-books-authors',
                parentItemIdMobile: 'm-books',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Authors',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Lysa TerKeurst',
                        urlLink: 'https://www.lifeway.com/en/contributors/l/lysa-terkeurst',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Tony Evans',
                        urlLink: 'https://www.lifeway.com/en/contributors/t/tony-evans',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Beth Moore',
                        urlLink: 'https://www.lifeway.com/en/contributors/b/beth-moore',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Max Lucado',
                        urlLink: 'https://www.lifeway.com/en/contributors/m/max-lucado',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Jennie Allen',
                        urlLink: 'https://www.lifeway.com/en/contributors/j/jennie-allen',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Priscilla Shirer',
                        urlLink: 'https://www.lifeway.com/en/contributors/p/priscilla-shirer',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'See more authors ...',
                        urlLink: 'https://www.lifeway.com/en/shop/christian-authors',
                        parentItemIdMobile: 'm-books',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_1571969141',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-books__authors',
            },
            {
                itemId: 'main-books-reference',
                parentItemIdMobile: 'm-books',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Bible Reference',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Commentaries',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/commentaries',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Concordances',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/concordances',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Dictionaries',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/dictionaries',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Handbooks',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/handbooks',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Atlases',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/atlases',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Archaeology',
                        urlLink: 'https://www.lifeway.com/en/shop/books/reference/archaeology',
                        parentItemIdMobile: 'm-books',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_1257164696',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-books__bible-ref',
            },
            {
                itemId: 'main-books-for',
                parentItemIdMobile: 'm-books',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Books for ...',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Women',
                        urlLink: 'https://www.lifeway.com/en/shop/books/women',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Men',
                        urlLink: 'https://www.lifeway.com/en/shop/books/men',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Kids',
                        urlLink: 'https://www.lifeway.com/en/shop/books/kids',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Parents',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/relationships/family/parenting',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Church Leaders',
                        urlLink: 'https://www.lifeway.com/en/shop/books/church-ministry',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Book Clubs',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-book-club',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'Español',
                        urlLink: 'https://www.lifeway.com/en/shop/espanol/libros',
                        parentItemIdMobile: 'm-books',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_10466352',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-books__books-for',
            },
            {
                itemId: 'main-books-topic',
                parentItemIdMobile: 'm-books',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Topic',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Spiritual Growth',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/spiritual-growth',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Prayer',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/prayer',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Marriage',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/relationships/family/marriage',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Finance',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/life-issues/personal-finance',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Health',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/life-issues/health',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Leadership',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/leadership',
                        parentItemIdMobile: 'm-books',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'Life Issues',
                        urlLink: 'https://www.lifeway.com/en/shop/books/christian-living/life-issues',
                        parentItemIdMobile: 'm-books',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_325515599',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-books__by-topic',
            },
            {
                itemId: 'main-books-imgsale',
                image: '/books-on-sale',
                parentItemIdMobile: 'm-books',
                componentNodeName: 'headernavigationimag',
                subtitle: 'Books on Sale',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/shop/books/christian-books-on-sale',
                fourColumn: true,
                itemIdMobile: 'main-books-imgsale',
            },
            {
                itemId: 'main-books-imgnewreleases',
                image: '/thewingfather',
                parentItemIdMobile: 'm-books',
                componentNodeName: 'headernavigationimag_1180959627',
                subtitle: 'New Releases',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/shop/books/new-releases',
                fourColumn: true,
            },
            {
                itemId: 'main-books-imglwrecommends',
                image: '/the-marriage-challenge',
                parentItemIdMobile: 'm-books',
                componentNodeName: 'headernavigationimag_696784150',
                subtitle: 'Lifeway Recommends',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/special-emphasis/recommended-reading',
                fourColumn: true,
            },
        ],
        rightComponents: [
            {
                itemId: 'main-books-imgpromo',
                image: '/tony-uturns',
                parentItemIdMobile: 'm-books',
                altText: 'Align Your Choices with God\u2019s Word',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag',
                linkTarget: '_self',
                headernavigationimage: true,
                bottomLinkText: 'Align Your Choices with God\u2019s Word',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/product/u-turns-P005800796',
                itemIdMobile: 'main-books-imgpromo',
            },
        ],
    },
    {
        headerLinkTarget: '_self',
        itemId: 'main-digital-media',
        jcr_title: 'Digital Media',
        sling_resourceType: 'lifeway/components/page/headerconfigurationpage',
        itemIdMobile: 'm-digital-media',
        leftComponents: [
            {
                itemId: 'main-digitalmedia-imgmoviesvideos',
                image: '/online_bible_studies_nav?wid=234&op_usm=2,.5,6,0',
                parentItemIdMobile: 'm-digital-media',
                componentNodeName: 'headernavigationimag',
                subtitle: 'Bible Studies',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/shop/bible-studies/online',
                fourColumn: true,
            },
            {
                itemId: 'main-digitalmedia-imglwondemand',
                image: '/digital_bible_studies_nav?wid=234&op_usm=2,.5,6,0',
                parentItemIdMobile: 'm-digital-media',
                componentNodeName: 'headernavigationimag_1530284517',
                subtitle: 'Lifeway Digital Pass',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://digitalpass.lifeway.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-digitalmedia-imgvirtualevents',
                image: '/Virtual-events@2x?wid=250',
                parentItemIdMobile: 'm-digital-media',
                componentNodeName: 'headernavigationimag_315814601',
                subtitle: 'Virtual Events',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/events/simulcasts-webcasts',
                fourColumn: true,
            },
            {
                itemId: 'main-digitalmedia-moviesmusic',
                parentItemIdMobile: 'm-digital-media',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Movies & Music',
                        parentItemIdMobile: 'm-digital-media',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Church Licenses',
                        urlLink: 'https://www.lifeway.com/en/shop/christian-movies/lifeway-films',
                        parentItemIdMobile: 'm-digital-media',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'DVDs',
                        urlLink: 'https://www.lifeway.com/en/shop/christian-movies',
                        parentItemIdMobile: 'm-digital-media',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Christian Music',
                        urlLink: 'https://www.lifeway.com/en/shop/christian-music',
                        parentItemIdMobile: 'm-digital-media',
                    },
                    {
                        itemIdOverride: 'main-digitalmedia-digitalreading',
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        typeLink: 'title',
                        itemIdMobileOverride: 'm-dmedia__d-reading',
                        text: 'Digital Reading',
                        parentItemIdMobile: 'm-digital-media',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'eBooks',
                        urlLink: 'https://www.lifeway.com/en/shop/books/ebooks',
                        parentItemIdMobile: 'm-digital-media',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Bible Study eBooks',
                        urlLink: 'https://www.lifeway.com/en/shop/books/ebooks/bible-studies',
                        parentItemIdMobile: 'm-digital-media',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_blank',
                        text: 'Lifeway Reader',
                        urlLink: 'https://read.lifeway.com/',
                        parentItemIdMobile: 'm-digital-media',
                    },
                ],
                headernavigationlink: true,
                closeUlFlagComp: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-dmedia__moviesandmusic',
            },
        ],
        rightComponents: [
            {
                itemId: 'main-digitalmedia-imgpromo',
                image: '/lifeway_women_live_simulcast_1080x?scl=1',
                parentItemIdMobile: 'm-digital-media',
                componentNodeName: 'headernavigationimag',
                linkTarget: '_self',
                headernavigationimage: true,
                bottomLinkText: 'Join Your Favorite Authors for a Virtual Event',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/events/lifeway-women-live-simulcast',
                itemIdMobile: 'main-digitalmedia-imgpromo',
            },
        ],
        moreMobileComponents: [
            {
                itemId: 'main-virtualevents',
                parentItemIdMobile: 'm-digital-media',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        text: 'Virtual Events',
                        urlLink: 'https://www.lifeway.com/en/events/simulcasts-webcasts',
                        parentItemIdMobile: 'm-digital-media',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-virtualevents',
            },
        ],
    },
    {
        headerLinkTarget: '_self',
        itemId: 'main-ministries',
        jcr_title: 'Ministries',
        sling_resourceType: 'lifeway/components/page/headerconfigurationpage',
        itemIdMobile: 'm-ministries',
        leftComponents: [
            {
                itemId: 'main-ministries-women',
                image: '/lw-women',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag',
                subtitle: 'Lifeway Women',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://lifewaywomen.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-ministries-adults',
                image: '/lw-adults',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag_920386790',
                subtitle: 'Lifeway Adults',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://groupministry.lifeway.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-ministries-kids',
                image: '/lw-kids',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag_1610828448',
                subtitle: 'Lifeway Kids',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://kidsministry.lifeway.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-ministries-students',
                image: '/lw-students',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag_556764501',
                subtitle: 'Lifeway Students',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://studentministry.lifeway.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-ministries-leadership',
                image: '/lw-leadership',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag_690373409',
                subtitle: 'Lifeway Leadership',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://leadership.lifeway.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-ministries-research',
                image: '/lw-research',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag_136012603',
                subtitle: 'Lifeway Research',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://lifewayresearch.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-ministries-worship',
                image: '/lw-worship',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag_1368967849',
                subtitle: 'Lifeway Worship',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://worshiplife.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-ministries-espanol',
                image: '/lw-spanish',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag_726222629',
                subtitle: 'Lifeway Español',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/shop/espanol',
                fourColumn: true,
            },
        ],
        rightComponents: [
            {
                itemId: 'main-ministries-black-church',
                image: '/lw-black-church',
                parentItemIdMobile: 'm-ministries',
                componentNodeName: 'headernavigationimag',
                subtitle: 'Lifeway Black Church',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/product-family/black-church-life',
            },
            {
                itemId: 'main-ministries-articles',
                parentItemIdMobile: 'm-ministries',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Lifeway Articles',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'All Articles',
                        urlLink: 'https://www.lifeway.com/en/articles',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Sunday School',
                        urlLink: 'https://www.lifeway.com/en/articles/categories/sunday-school-articles',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Church and Ministry',
                        urlLink: 'https://www.lifeway.com/en/articles/categories/ministry',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Sermons',
                        urlLink: 'https://www.lifeway.com/en/articles/categories/sermons',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Spiritual Growth',
                        urlLink: 'https://www.lifeway.com/en/articles/categories/spiritual-growth',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Relationships and Family',
                        urlLink: 'https://www.lifeway.com/en/articles/categories/relationships-family',
                        parentItemIdMobile: 'm-ministries',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-ministries-articles',
            },
        ],
        moreMobileComponents: [
            {
                itemId: 'na',
                parentItemIdMobile: 'm-ministries',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        text: 'Lifeway Black Church',
                        urlLink: 'https://www.lifeway.com/en/product-family/black-church-life',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Lifeway Español',
                        urlLink: 'https://www.lifeway.com/en/shop/es',
                        parentItemIdMobile: 'm-ministries',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Lifeway Articles',
                        urlLink: 'https://www.lifeway.com/en/articles',
                        parentItemIdMobile: 'm-ministries',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'na',
            },
        ],
    },
    {
        shopAllButton: true,
        headerLinkTarget: '_self',
        itemId: 'main-campsevents',
        jcr_title: 'Camps & Events',
        showShopAll: true,
        headerLinkName: 'Shop All Events',
        headerLink: 'https://www.lifeway.com/en/events',
        sling_resourceType: 'lifeway/components/page/headerconfigurationpage',
        itemIdMobile: 'm-camps-events',
        leftComponents: [
            {
                itemId: 'main-campsevents-studentcamps',
                parentItemIdMobile: 'm-camps-events',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Summer Camps',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_blank',
                        text: 'Fuge',
                        urlLink: 'https://www.fugecamps.com/',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_blank',
                        text: 'Student Life',
                        urlLink: 'https://www.studentlife.com/',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_blank',
                        text: 'CentriKid',
                        urlLink: 'https://centrikid.com/',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_blank',
                        text: 'Student Life Kids Camp',
                        urlLink: 'https://studentlifekidscamp.com/',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_blank',
                        text: 'MFuge',
                        urlLink: 'https://www.fugecamps.com/mfuge/',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_blank',
                        text: 'Student Life Mission Camp',
                        urlLink: 'https://www.studentlife.com/mission/',
                        parentItemIdMobile: 'm-camps-events',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-camps-events__summer-camps',
            },
            {
                itemId: 'main-campsevents-womensevents',
                parentItemIdMobile: 'm-camps-events',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: "Women's Events",
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Lifeway Women Live',
                        urlLink: 'https://www.lifeway.com/en/events/lifeway-women-live',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Going Beyond Live',
                        urlLink: 'https://www.lifeway.com/en/events/going-beyond-live',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Glory',
                        urlLink: 'https://www.lifeway.com/en/events/glory-with-jackie-hill-perry',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'You Lead',
                        urlLink: 'https://www.lifeway.com/en/events/you-lead',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Leadership Forum',
                        urlLink: 'https://www.lifeway.com/en/events/womens-leadership-forum',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Simulcast Events',
                        urlLink: 'https://www.lifeway.com/en/events/simulcasts-webcasts',
                        parentItemIdMobile: 'm-camps-events',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_531321556',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-camps-events__women',
            },
            {
                itemId: 'main-campsevents-studentmissions',
                parentItemIdMobile: 'm-camps-events',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Other Events',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_blank',
                        text: 'ETCH Conference',
                        urlLink: 'https://etchconference.com/',
                        parentItemIdMobile: 'm-camps-events',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Black Church Leadership & Family Conference',
                        urlLink: 'https://www.lifeway.com/en/events/black-church-leadership-and-family-conference',
                        parentItemIdMobile: 'm-camps-events',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_649554150',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                itemIdMobile: 'm-camps-events__other',
            },
            {
                itemId: 'main-campsevents-camps',
                image: '/fuge-camps@2x',
                parentItemIdMobile: 'm-camps-events',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag',
                subtitle: 'Camps',
                linkTarget: '_blank',
                additonalLinkText: 'More Camps',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                additonalUrlLink: 'https://www.lifeway.com/en/events/camps-mission-projects',
                urlLink: 'https://www.fugecamps.com/',
            },
            {
                itemId: 'main-campsevents-women',
                image: '/going-beyond-live-square@2x',
                parentItemIdMobile: 'm-camps-events',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag_1138207058',
                subtitle: "Women's Events",
                linkTarget: '_self',
                additonalLinkText: "More Women's Events",
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                additonalUrlLink: 'https://www.lifeway.com/en/events/women',
                urlLink: 'https://www.lifeway.com/en/events/going-beyond-live',
            },
            {
                itemId: 'main-campsevents-simulcast',
                image: '/women-simulcast',
                parentItemIdMobile: 'm-camps-events',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag_1485049037',
                subtitle: 'Simulcast Events',
                linkTarget: '_blank',
                additonalLinkText: 'More Simulcast Events',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                additonalUrlLink: 'https://www.lifeway.com/en/events/simulcasts-webcasts',
                urlLink: 'https://www.lifeway.com/en/events/lifeway-women-live-simulcast',
            },
        ],
        rightComponents: [
            {
                itemId: 'main-campsevents-imgpromo',
                image: '/etch_nav_image?scl=1',
                parentItemIdMobile: 'm-camps-events',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag',
                linkTarget: '_self',
                headernavigationimage: true,
                bottomLinkText: 'Equip and reenergize your church ministry teams at ETCH Family Ministry Conference.',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/events',
            },
        ],
    },
    {
        headerSpecialLink: false,
        shopAllButton: true,
        headerLinkTarget: '_blank',
        itemId: 'main-churches',
        jcr_title: 'For Churches',
        shopAllId: 'main-churches-churchordering',
        showShopAll: true,
        headerLinkName: 'Church Ordering',
        headerLink: 'https://www.lifeway.com/ChurchOrdering/login',
        sling_resourceType: 'lifeway/components/page/headerconfigurationpage',
        itemIdMobile: 'm-for-churches',
        leftComponents: [
            {
                itemId: 'main-churches-curriculum',
                parentItemIdMobile: 'm-for-churches',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Curriculum',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Sunday School',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/sunday-school',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Small Groups',
                        urlLink: 'https://www.lifeway.com/en/shop/bible-studies/small-groups',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Magazines',
                        urlLink: 'https://www.lifeway.com/en/shop/books/magazines',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Devotionals',
                        urlLink: 'https://www.lifeway.com/en/shop/books/devotions-journals',
                        parentItemIdMobile: 'm-for-churches',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-for-churches__curriculum',
            },
            {
                itemId: 'main-churches-vbs',
                parentItemIdMobile: 'm-for-churches',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'VBS',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Accessories',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/accessories',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Apparel',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/apparel',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Bible Study',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/bible-study',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Crafts',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/crafts',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Decorations',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/decorations',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Gifts',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/gifts',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'Music & Worship',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/music-worship',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item8',
                        linkTarget: '_self',
                        text: 'Planning',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/starter-kits-planning',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item9',
                        linkTarget: '_self',
                        text: 'Promotional',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/promotional',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item10',
                        linkTarget: '_self',
                        text: 'Witnessing & Follow-Up',
                        urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school/witnessing-follow-up',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item11',
                        linkTarget: '_self',
                        text: 'Español',
                        urlLink: 'https://www.lifeway.com/en/shop/espanol/escuela-biblica-de-vacaciones',
                        parentItemIdMobile: 'm-for-churches',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_733097002',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-for-churches__vbs',
            },
            {
                itemId: 'main-churches-services',
                parentItemIdMobile: 'm-for-churches',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Church Services',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Church Administration',
                        urlLink: 'https://www.lifeway.com/en/shop/services/church-administration',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Church Architecture',
                        urlLink: 'https://www.lifeway.com/en/shop/services/buildings-facilities/lifeway-architecture',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_blank',
                        text: 'Church Consulting',
                        urlLink: 'https://auxano.com/',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Worship Conferences',
                        urlLink: 'https://www.lifeway.com/en/events/worship',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Worship Music',
                        urlLink: 'https://www.lifeway.com/en/shop/worship',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Pastors & Church Leaders',
                        urlLink: 'https://www.lifeway.com/en/shop/ministries/pastors-church-leaders',
                        parentItemIdMobile: 'm-for-churches',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_806444772',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-for-churches__admin',
            },
            {
                itemId: 'main-churches-supplies',
                parentItemIdMobile: 'm-for-churches',
                linkList: [
                    {
                        componentNodeName: 'item0',
                        linkTarget: '_self',
                        typeLink: 'title',
                        text: 'Church Supplies',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item1',
                        linkTarget: '_self',
                        text: 'Bulletins',
                        urlLink: 'https://www.lifeway.com/en/shop/church-supplies/bulletins',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item2',
                        linkTarget: '_self',
                        text: 'Candles',
                        urlLink: 'https://www.lifeway.com/en/shop/church-supplies/candles',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item3',
                        linkTarget: '_self',
                        text: 'Communion',
                        urlLink: 'https://www.lifeway.com/en/shop/church-supplies/communion',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item4',
                        linkTarget: '_self',
                        text: 'Envelopes',
                        urlLink: 'https://www.lifeway.com/en/shop/church-supplies/offering-envelopes',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item5',
                        linkTarget: '_self',
                        text: 'Pew & Bulk Bibles',
                        urlLink: 'https://www.lifeway.com/en/shop/bibles/pew-bulk-bibles',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item6',
                        linkTarget: '_self',
                        text: 'Bulk Books',
                        urlLink: 'https://www.lifeway.com/en/special-emphasis/bulk-book-deals',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item7',
                        linkTarget: '_self',
                        text: 'Record Forms',
                        urlLink: 'https://www.lifeway.com/en/shop/church-supplies/record-forms',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item8',
                        linkTarget: '_self',
                        text: 'Robes & Apparel',
                        urlLink: 'https://www.lifeway.com/en/shop/church-supplies/robes-apparel',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item9',
                        linkTarget: '_self',
                        text: 'Tracts & Outreach',
                        urlLink: 'https://www.lifeway.com/en/shop/church-supplies/tracts-outreach',
                        parentItemIdMobile: 'm-for-churches',
                    },
                    {
                        componentNodeName: 'item10',
                        linkTarget: '_self',
                        text: 'Boxed Cards',
                        urlLink: 'https://www.lifeway.com/en/shop/christian-gifts/stationery-cards',
                        parentItemIdMobile: 'm-for-churches',
                    },
                ],
                headernavigationlink: true,
                componentNodeName: 'headernavigationlink_1626827683',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationlink',
                fourColumn: true,
                itemIdMobile: 'm-for-churches__supplies',
            },
            {
                itemId: 'main-churches-images-mg',
                image: '/mgrid',
                parentItemIdMobile: 'm-for-churches',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag',
                subtitle: 'Ministry Grid',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://ministrygrid.lifeway.com/',
                fourColumn: true,
            },
            {
                itemId: 'main-churches-images-vbs',
                image: '/vbs_spark_studios_logo_2?wid=234',
                parentItemIdMobile: 'm-for-churches',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag_1722501365',
                subtitle: 'Shop VBS',
                linkTarget: '_self',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/en/shop/vacation-bible-school',
                fourColumn: true,
            },
            {
                itemId: 'main-churches-images-sg',
                image: '/smallgroup',
                parentItemIdMobile: 'm-for-churches',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag_1253566409',
                subtitle: 'SmallGroup.com',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://app.smallgroup.com/redirect/login',
                fourColumn: true,
            },
            {
                itemId: 'main-churches-images-lwr',
                image: '/lwreader',
                parentItemIdMobile: 'm-for-churches',
                additonalLinkTarget: '_self',
                componentNodeName: 'headernavigationimag_1236279909',
                subtitle: 'Lifeway Reader',
                linkTarget: '_blank',
                headernavigationimage: true,
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://read.lifeway.com/',
                fourColumn: true,
            },
        ],
        rightComponents: [
            {
                itemId: 'main-churches-imgpromo',
                image: '/church-ordering?wid=300',
                parentItemIdMobile: 'm-for-churches',
                componentNodeName: 'headernavigationimag',
                linkTarget: '_blank',
                headernavigationimage: true,
                bottomLinkText: 'Order Curriculum, Literature, and Church Supplies',
                'sling:resourceType': 'lifeway/components/section/configuration/headernavigationimage',
                urlLink: 'https://www.lifeway.com/ChurchOrdering/login',
            },
        ],
    },
  ],
};
