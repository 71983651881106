import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { bool, func, node } from 'prop-types';

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownPanel = styled.nav`
  position: absolute;
  z-index: 1000;
  top: 100%;
  right: 0;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #eee;
  margin-top: 15px;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    right: 10px;
    width: 12px;
    height: 12px;
    background-color: white;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    transform: rotate(-45deg);
    transform-origin: top left;
  }
  /* add some padding to the top to make it easy to move the mouse into the dropdown */
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: -15px;
    height: 15px;
    width: 100%;
  }
  /* transition things */
  /* initial styles */
  display: none;
  opacity: 0;
  transition: 0s all; /* smooths directly switching between transitions */
  &.open-enter {
    display: block;
    opacity: 0;
  }
  &.open-enter-active {
    display: block;
    opacity: 1;
    transition: 0.175s all ease-in-out;
  }
  &.open-enter-done {
    display: block;
    opacity: 1;
  }
  &.open-exit {
    display: block;
    opacity: 1;
  }
  &.open-exit-active {
    transition: 0.175s all ease-in-out;
    display: block;
    opacity: 0;
  }
  &.open-exit-done {
    display: none;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.08);
  z-index: 200;
  display: none;
  opacity: 0;
  transition: 0s all; /* smooths directly switching between transitions */
  &.open-enter {
    display: block;
    opacity: 0;
  }
  &.open-enter-active {
    display: block;
    opacity: 1;
    transition: 0.175s all ease-in-out;
  }
  &.open-enter-done {
    display: block;
    opacity: 1;
  }
  &.open-exit {
    display: block;
    opacity: 1;
  }
  &.open-exit-active {
    transition: 0.175s all ease-in-out;
    display: block;
    opacity: 0;
  }
  &.open-exit-done {
    display: none;
  }
`;

const Dropdown = ({ onClose, open, children, button, ...props }) => {
  return (
    <>
      <DropdownContainer>
        {button}
        <CSSTransition
          classNames="open"
          in={open}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done);
          }}
        >
          <DropdownPanel {...props} onBlur={onClose}>
            {children}
          </DropdownPanel>
        </CSSTransition>
      </DropdownContainer>
      <CSSTransition
        classNames="open"
        in={open}
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done);
        }}
      >
        <Backdrop onClick={onClose} />
      </CSSTransition>
    </>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  onClose: func,
  open: bool,
  children: node,
  button: node,
};