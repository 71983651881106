import { createLogic } from 'redux-logic';
import {
  GET_COUNTRIES_PENDING, GET_STATES_PENDING,
  GET_MILITARY_LOCATIONS_PENDING
} from '../constants';
import {
  getCountriesSuccess, getCountriesError, getStatesSuccess, getStatesError,
  getMilitaryLocationsSuccess, getMilitaryLocationsError
} from '../actions/AppActions';

export const getCountriesLogic = createLogic({
  type: GET_COUNTRIES_PENDING,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient(dispatch)
      .get('/countries')
      .then(res => {
        dispatch(getCountriesSuccess(res.data));
        done();
      })
      .catch(error => {
        dispatch(getCountriesError(error));
        done();
      });
  }
});

export const getStatesLogic = createLogic({
  type: GET_STATES_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient(dispatch).get(`/countries/${action.payload}/subdivisions`)
      .then(res => {
        dispatch(getStatesSuccess({ countryCode: action.payload, states: res.data }));
        done();
      })
      .catch(error => {
        dispatch(getStatesError(error));
        done();
      });
  }
});

export const getMilitaryLocationsLogic = createLogic({
  type: GET_MILITARY_LOCATIONS_PENDING,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient(dispatch).get('/military-locations')
      .then(res => {
        dispatch(getMilitaryLocationsSuccess(res.data));
        done();
      })
      .catch(error => {
        dispatch(getMilitaryLocationsError(error));
        done();
      });
  }
});

export default [
  getCountriesLogic,
  getStatesLogic,
  getMilitaryLocationsLogic,
];
