import { createLogic } from 'redux-logic';
import { stopSubmit } from 'redux-form';
import moment from 'moment';
import history from '../utils/history';
import {
  GET_SUBSCRIPTIONS_PENDING,
  GET_ORG_SUBSCRIPTIONS_PENDING,
  GET_SUBSCRIPTION_DETAILS_PENDING,
  UPDATE_SUBSCRIPTIONS_PAYMENT_PENDING,
  UPDATE_SUBSCRIPTION_STATUS_PENDING,
  SUBSCRIPTION_PAYMENT_TYPES,
  PATH_SUBSCRIPTIONS,
  CANCEL_SUBSCRIPTION_PENDING,
  ORG_PATHS,
  UPDATE_ORG_SUBSCRIPTION_CONTACT_PENDING,
  TRANSFER_SUBSCRIPTION_PENDING
} from '../constants';
import {
  getSubscriptionsPending,
  getSubscriptionsSuccess,
  getSubscriptionsError,
  setSubscriptionsLoaded,
  getSubscriptionDetailsSuccess,
  getSubscriptionDetailsError,
  updateSubscriptionPaymentError,
  updateSubscriptionPaymentSuccess,
  reactivateSubscriptionSuccess,
  reactivateSubscriptionError,
  cancelSubscriptionSuccess,
  cancelSubscriptionError,
  setBannerState,
  toggleProcessedItem,
  getOrgSubscriptionsSuccess,
  getOrgSubscriptionsError,
  getOrgSubscriptionsPending,
  updateOrgSubscriptionContactSuccess,
  updateOrgSubscriptionContactError,
  transferSubscriptionSuccess,
  transferSubscriptionError,
  setOrgSubscriptionsLoaded,
} from '../actions/AppActions';
import { injectParams } from '../utils/pathHelpers';
import { formatAutoshipAddress, formatContact, getMatchingPaymentMethod, getPaymentMethod } from './autoship/utils';
import { formatSubscriptionPaymentMethod, formatTransferBillingAddress, formatTransferPaymentMethod } from '../components/Subscription/utils';

export const getSubscriptionsLogic = createLogic({
  type: GET_SUBSCRIPTIONS_PENDING,
  latest: true,
  process({ httpClient, getState }, dispatch, done) {
    const intl = getState().intl.messages;
    dispatch(setSubscriptionsLoaded(false));
    getDigitalSubscriptions(pageParams =>
      httpClient(dispatch)
      .get('/subscriptions', {
        params: {
          version: '2',
          ...pageParams
        }
      })
    )
      .then(({subscriptions, errors}) => {
        const results = subscriptions.filter((s) =>
          moment(s.scheduledOrderDate).isAfter(
            moment().subtract('18', 'months')
          )
        );
        dispatch(getSubscriptionsSuccess(results));
        dispatch(setSubscriptionsLoaded(true));
        done();
      })
      .catch((err) => {
        const bannerState = {
          data: {
            type: 'error',
            message: intl.Errors.Subscriptions.Error_Retrieving,
          },
        };
        dispatch(setBannerState(bannerState));
        dispatch(getSubscriptionsError(err));
        dispatch(setSubscriptionsLoaded(true));
        done();
      });
  },
});
const PAGE_SIZE = 10;

const getPaginatedDigitalSubscriptions = async (action, pageNumber = undefined) => {
  const { data: page } = await action({ pageSize: PAGE_SIZE, pageNumber});

  if (
    page.pages?.self == null ||
    page.pages?.self === page.pages?.last ||
    page.pages?.next == null
  ) {
    return {
      subscriptions: page.subscriptions,
      errors: page.errors ?? []
    };
  } else {
    const rest = await getPaginatedDigitalSubscriptions(action, page.pages.next);
    return {
      subscriptions: [...(page.subscriptions ?? []), ...rest.subscriptions],
      errors: [...(page.errors ?? []), ...rest.errors]
    };
  }
};

const getDigitalSubscriptions = async(action, pageNumber = undefined) =>{
  const results = await getPaginatedDigitalSubscriptions(action, pageNumber);
  return {
    subscriptions: [...(results.subscriptions ?? [])],
    errors: [...(results.errors ?? [])]
  };
};

export const getOrgSubscriptionsLogic = createLogic({
  type: GET_ORG_SUBSCRIPTIONS_PENDING,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
	const { organizationId } = action.payload;
    const intl = getState().intl.messages;
    dispatch(setOrgSubscriptionsLoaded(false));
    getDigitalSubscriptions(pageParams =>
		httpClient(dispatch)
    .get(`/subscriptions/organization/${organizationId}`, {
      params: {
        version: '2',
        ...pageParams
      }
    })
    )
      .then(({subscriptions, errors}) => {
        const results = subscriptions.filter((s) =>
          moment(s.scheduledOrderDate).isAfter(
            moment().subtract('18', 'months')
          )
        );
        dispatch(getOrgSubscriptionsSuccess(results));
        dispatch(setOrgSubscriptionsLoaded(true));
        done();
      })
      .catch((err) => {
        const { status } = err?.response;
        const bannerState = {
          data: {
            type: 'error',
            message: intl.Errors.Subscriptions.Error_Retrieving,
          },
        };
        if(status !== 404){
        dispatch(setBannerState(bannerState));
        }
        dispatch(getOrgSubscriptionsError(err));

        dispatch(setOrgSubscriptionsLoaded(true));
        done();
      });
  },
});

export const getSubscriptionDetailsLogic = createLogic({
  type: GET_SUBSCRIPTION_DETAILS_PENDING,
  process({ httpClient, action }, dispatch, done) {
    const { subscriptionId } = action.payload;

    httpClient(dispatch)
      .get(`/subscriptions/${subscriptionId}`)
      .then((response) => {
        const subscription = response.data.subscription;
        dispatch(getSubscriptionDetailsSuccess(subscriptionId, subscription));
      })
      .catch((error) => {
        const status = error.response?.status;
        dispatch(getSubscriptionDetailsError(subscriptionId, error, status));
      })
      .finally(() => done());
  },
});

const billingAccountPaymentMethod = ({
  accountNumber: account,
  billingAccountId,
  organizationId,
  id,
} = {}) => ({
  id,
  account,
  billingAccountId,
  organizationId,
});

const creditCardPaymentMethod = ({
  token,
  cardHolderName,
  cardType,
  lastFour,
  expirationMonth,
  expirationYear,
  id,
} = {}) => ({
  card: {
    id,
    cardholderName: cardHolderName,
    expMonth: expirationMonth,
    expYear: expirationYear,
    token,
    cardType,
    cardMask: lastFour,
  },
});

const getCurrentUser = (state) => {
  return {
    firstName: state.app.getIn(['currentUser', 'firstName']),
    lastName: state.app.getIn(['currentUser', 'lastName']),
  };
};

const formatAddress = (address = {}, user = {}) => {
  return {
    firstName: address.firstName || user.firstName,
    lastName: address.lastName || user.lastName,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.countrySubdivision,
    zip: address.postalCode,
    country: address.countryCode,
    addressType: address.type,
    phone: address.phoneNumber,
    id: address.id,
    organization: address.organizationName,
  };
};

export const updateSubscriptionsPaymentMethodLogic = createLogic({
  type: UPDATE_SUBSCRIPTIONS_PAYMENT_PENDING,
  latest: true,
  process({ httpClient, getState, action: { payload, meta } }, dispatch, done) {
    const {
      type,
      paymentMethod: rawPaymentMethod,
      subscriptionId,
      subscriptionName,
      subscriptionStatus,
      form,
      organizationId
    } = payload;
    const subPath =
    organizationId === null ? PATH_SUBSCRIPTIONS :
    injectParams(ORG_PATHS.SUBSCRIPTIONS, { organizationId });
    const user = getCurrentUser(getState());
    dispatch(toggleProcessedItem(rawPaymentMethod.id));
    const paymentMethod =
      type === SUBSCRIPTION_PAYMENT_TYPES.CREDIT_CARD
        ? creditCardPaymentMethod(rawPaymentMethod)
        : billingAccountPaymentMethod(rawPaymentMethod);
    const billingAddress =
      type === SUBSCRIPTION_PAYMENT_TYPES.ACCOUNT
        ? formatAddress(rawPaymentMethod.billingAddress, user)
        : formatAddress(rawPaymentMethod.address, user);
    httpClient(dispatch)
      .post('/subscription-commands/updatesubscriptionpaymentmethod', {
        id: subscriptionId,
        paymentMethod,
        billingAddress,
      })
      .then(() => {
        dispatch(updateSubscriptionPaymentSuccess());
        dispatch(toggleProcessedItem());
        dispatch(stopSubmit(form));
        if (subscriptionStatus === 'Active') {
            if(organizationId === null || organizationId === undefined){
                dispatch(getSubscriptionsPending());
            }else{
                dispatch(getOrgSubscriptionsPending(organizationId));
            }
          history.push(subPath);
          const intl = getState().intl.messages;
          const bannerState = {
            data: {
              type: 'success',
              message: `${subscriptionName}: ${intl.Success.Subscriptions.Payment_Update_Success} ${intl.Success.Subscriptions.Payment_Update_Success_Renewal}`,
            },
          };
          dispatch(setBannerState(bannerState));
        }
        meta?.onSuccess?.();
        done();
      })
      .catch((error) => {
        dispatch(stopSubmit(form));
          const intlErrors = getState()?.intl?.messages?.Errors;
          const errorMessage = intlErrors?.Subscriptions?.Error_Updating_Subscription_Payment_Method;
          dispatch(
            updateSubscriptionPaymentError({
                message: errorMessage
              })
          );
        dispatch(toggleProcessedItem());
        meta?.onError?.(error);
      }).finally(() => {
        done();
    });
  },
});

export const updateSubscriptionReactivationLogic = createLogic({
  type: UPDATE_SUBSCRIPTION_STATUS_PENDING,
  latest: true,
  process({ httpClient, getState, action }, dispatch, done) {
    const {
        id,
        renewNow,
        scheduledOrderDate,
        organizationId
      } = action.payload;
    const intl = getState().intl.messages;
    dispatch(toggleProcessedItem(id));
    httpClient(dispatch)
      .post('/subscription-commands/reactivatesubscription', {
        id,
        renewNow,
        scheduledOrderDate
      })
      .then(() => {
        dispatch(reactivateSubscriptionSuccess());
        const bannerState = {
            data: {
                type: 'success',
                message: intl.Success.Subscriptions.Reactivate_Success,
            },
        };
        dispatch(setBannerState(bannerState));
        if(organizationId === null || organizationId === undefined){
            dispatch(getSubscriptionsPending());
        }else{
            dispatch(getOrgSubscriptionsPending(organizationId));
        }
        dispatch(toggleProcessedItem());
        action?.meta?.onSuccess?.();
        done();
      })
      .catch((error) => {
        dispatch(reactivateSubscriptionError(error.response));
        const bannerState = {
            data: {
              type: 'error',
              message: intl.Errors.Subscriptions.Error_Reactivating,
            },
          };
          dispatch(setBannerState(bannerState));
        if(organizationId === null){
          dispatch(getSubscriptionsPending());
        } else{
          dispatch(getOrgSubscriptionsPending(organizationId));
        }

        dispatch(toggleProcessedItem());
        action?.meta?.onError?.(error);
      })
      .finally(() => done());
  },
});

const transferSubToOrg = async (client, payload) => {
  const { contactPerson, subscriptionId, organizationId, individualId, accountId, paymentMethod, billingAddress, paymentMethodType } = payload;
  const apiPayload = {
    organization: {
      id: organizationId,
      accountId: accountId,
      contactPerson: contactPerson,
    },
    individual: {
      id: individualId,
    },
  };

  if (paymentMethodType) {
    apiPayload.payment = {
      paymentMethod: formatTransferPaymentMethod(paymentMethodType, paymentMethod),
      billingAddress: formatTransferBillingAddress(billingAddress),
    };
  }
  return client.post(`/subscriptions/${subscriptionId}/transfer`, apiPayload)
    .then(res => res.data);
};

export const transferSubToOrgLogic = createLogic({
  type: TRANSFER_SUBSCRIPTION_PENDING,
  latest: true,
  process({ httpClient, getState, action: { payload, meta }}, dispatch, done) {
    transferSubToOrg(httpClient(dispatch), payload)
      .then(data => {
        dispatch(transferSubscriptionSuccess(data));
        const intl = getState().intl.messages;
        const bannerState = {
          data: {
            type: 'success',
            message: `${intl.Success.Subscriptions.Transfer_Sub_To_Org_Success}`
          }
        };
        dispatch(setBannerState(bannerState));
        meta?.onSuccess?.();
        done();
      })
      .catch(error => {
        const intlErrors = getState()?.intl?.messages?.Errors;
        const errorMessage = intlErrors?.Subscriptions?.Error_Transferring_Subscription;
        meta?.onError?.();
        done();
      });
  }
});

const updateOrgContact = async (state, client, payload) => {
  const { subscriptionId, contact, paymentMethodId, paymentMethodType, organizationId } = payload;
  const apiPayload = {
    contact: formatContact(contact),
  };

  if (paymentMethodId && paymentMethodType !== 'undefined') {
    const { paymentMethod } = getPaymentMethod(state, { paymentMethodId, organizationId });
    const user = getCurrentUser(state);
    const defaultAddress = paymentMethod?.addresses?.find(address => address.category === 'BILLING' && address.defaultAddress === true);
    const billingAddress = paymentMethodType === 'Account'
        ? defaultAddress
        : paymentMethodType === 'billing-account'
        ? defaultAddress
        : paymentMethod?.address;

    apiPayload.payment = {
      paymentMethod: formatSubscriptionPaymentMethod(paymentMethodType, paymentMethod),
      billingAddress: formatAutoshipAddress(billingAddress, user),
    };
  }
  return client.put(`/subscriptions/${subscriptionId}/organization/contact`, apiPayload)
    .then(res => res.data);
};

export const updateOrgSubscriptionContactLogic = createLogic({
  type: UPDATE_ORG_SUBSCRIPTION_CONTACT_PENDING,
  latest: true,
  process({ httpClient, getState, action: { payload, meta } }, dispatch, done) {
    const { subscriptionName, organizationId, contact } = payload;
    const state = getState();

    dispatch(toggleProcessedItem(contact?.customerId));
    
    updateOrgContact(state, httpClient(dispatch), payload)
      .then(data => {
        dispatch(updateOrgSubscriptionContactSuccess(data));
        dispatch(toggleProcessedItem());
        dispatch(getOrgSubscriptionsPending(organizationId));
        const intl = getState().intl.messages;
        const bannerState = {
          data: {
            type: "success",
            message: `${subscriptionName}: ${intl.Success.Subscriptions.Billing_Contact_Update_Success}`,
          },
        };
        dispatch(setBannerState(bannerState));
        meta?.onSuccess?.();
        done();
      })
      .catch(error => {
        const intlErrors = getState()?.intl?.messages?.Errors;
        const errorMessage = intlErrors?.Subscriptions?.Error_Updating_Contact;
        dispatch(
            updateOrgSubscriptionContactError({
            message: errorMessage
          })
        );
        meta?.onError?.();
        dispatch(toggleProcessedItem());
        done();
      });
  }
});

export const cancelSubscriptionLogic = createLogic({
  type: CANCEL_SUBSCRIPTION_PENDING,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { reason, note, sub } = action.payload;
    const jsonBody = {
      reasonCancelled: reason,
      id: sub._id || sub.id,
      explanation: note,
    };
    httpClient(dispatch)
      .post('/subscription-commands/cancelsubscription', jsonBody)
      .then(() => {
        dispatch(
          cancelSubscriptionSuccess({
            message: 'Success! The subscription was canceled.',
          })
        );
        done();
      })
      .catch(() => {
        dispatch(
          cancelSubscriptionError(
            'We\'re sorry. Unable to cancel the subscription. Please try again.'
          )
        );
        done();
      });
  },
});

export default [
  getSubscriptionsLogic,
  getOrgSubscriptionsLogic,
  getSubscriptionDetailsLogic,
  updateSubscriptionsPaymentMethodLogic,
  updateOrgSubscriptionContactLogic,
  updateSubscriptionReactivationLogic,
  cancelSubscriptionLogic,
  transferSubToOrgLogic
];
