import React, { useEffect } from 'react';
import qs from 'qs';
import NavigationMessagePage from '../NavigationMessagePage';
import userManager from '../../utils/userManager';

const SigninRedirectPage = () => {
  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    userManager.signinRedirect({ extraQueryParams: queryParams });
  }, []);

  const signinRedirect = () => {
    userManager.signinRedirect();
  };

  return <NavigationMessagePage message="authenticating..." retryAction={signinRedirect} />;
};

export default SigninRedirectPage;
