import React from 'react';
import { menuLinks } from './header/account-menu/menuLinks';
import styled from 'styled-components';
import { PATH_DASHBOARD } from "../constants";
import { RedeemButton } from './RedeemButton';
import { Link } from 'react-router-dom';
import DownChevron from '../../../img/sprite/down-chevron.svg';
import { ButtonContainer } from './SideNavSelect';
import { GroupTitle } from './SideNavOrg';

const StyledButton = styled.button`
  font-size: 14px;
  color: #476692;
  text-decoration: none;

  &:hover {
    ${({noHover}) => noHover ? '' : 'text-decoration: underline'};
  }
`;

const StyledLink = styled(Link)`
  border: none;
  font-size: 16px;
  color: #476692;
  text-decoration: none;
  font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
  padding: 8px 0;

  &:hover {
    text-decoration: underline;
  }
`;

function SideNavIndividual({
  pathname,
  showBackBtn,
  view,
  setView,
  onLogOut,
}) {
  const defaultPath = PATH_DASHBOARD;
  return (
    <>
      {showBackBtn && view !== 'SELECT' && (
        <div style={{ width: '100%', borderBottom: 'solid 1px #e0e0e0', padding: '16px 0', alignItems: 'center', cursor: 'pointer' }} onClick={() => setView('SELECT')}>
          <svg style={{ transform: 'rotate(90deg)', paddingRight: '3px' }}><use href={`#${DownChevron.id}`} xlinkHref={`#${DownChevron.id}`} /></svg>
          <button type="button" style={{ fontWeight: 'bold', background: 'white', border: 'none', fontSize: '14px', color: '#476692', textDecoration: 'none' }}>
            Switch Account
          </button>
        </div>
      )}
      <div>
        <div style={{ padding: '16px 0 7px' }}>
          <StyledLink bold={true} to={defaultPath}>
            My Account
          </StyledLink>
        </div>
        <div style={{padding: '0 0 7px 0'}}>
          <StyledLink
            to={PATH_DASHBOARD}
            style={{
              fontSize: '14px',
              color: pathname === PATH_DASHBOARD ? '#414042' : '#476692',
              fontWeight: pathname === PATH_DASHBOARD ? 'bold' : 'normal'
            }}
          >
            Account Home
          </StyledLink>
        </div>
        {menuLinks.linkGroup.map((group, index) => {
        const isLastChild = menuLinks.linkGroup.length -1 === index;
        return (
          <div key={index}>
            <GroupTitle isLastChild={isLastChild} title={group.title}>{group.title}</GroupTitle>
            {group.links.map((link, index) => {
              const isLastChild = group.links.length -1 === index;
              return (<div style={isLastChild ? { padding : '0 0 14px 0'} : { padding: '0 0 8px 0'}}>
                <StyledLink
                  bold={false}
                  to={link.url}
                  style={{
                    marginTop: '0',
                    fontSize: '14px',
                    color: pathname.includes(link.url) ? '#414042' : pathname.includes('manage-access') && link.url.includes('manage-access') ? '#414042' : '#476692',
                    fontWeight: pathname.includes(link.url) ? 'bold' : pathname.includes('manage-access') && link.url.includes('manage-access') ? 'bold' : 'normal',
                  }}
                >
                  {link.name}
                </StyledLink>
              </div>
            )})}
          </div>
        )})}
        <ButtonContainer>
          <RedeemButton />
        </ButtonContainer>
      </div>
    </>
  );
}

export default SideNavIndividual;