import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getCurrentUserName = createSelector(
  (state) => state.app.getIn(['currentUser']).toJS(),
  res => ({ firstName: res.firstName, lastName: res.lastName, fullName: res.fullName })
);

export const getCurrentUserId = createSelector(
  (state) => state.app.getIn(['currentUser', 'id']), res => res
);

export const getUserEmail = createSelector(
  (state) => state.app.getIn(['currentUser', 'emailAddress']), res => res
);

export const getInvitations = createDeepEqualSelector(
  (state) => state.invitations.getIn(['pendingInvites']).toJS(), res => res
);

export const getInvitation = createSelector(
  (state) => state.invitations.getIn(['invite']).toJS(), res => res
);

export const getPendingResponses = createSelector(
  (state) => state.invitations.getIn(['pendingResponses']), res => res
);

export const profileLoading = createSelector(
  (state) => state.app.getIn(['currentUser', 'profileLoading']), res => res
);

export const userFullInitials = createSelector(
  state => state.app.getIn(['currentUser', 'firstName']),
  state => state.app.getIn(['currentUser', 'lastName']),
  (firstName, lastName) => {
    const firstLetter = firstName?.[0] ?? '';
    const lastLetter = lastName?.[0] ?? '';
    return `${firstLetter}${lastLetter}`.toUpperCase();
  }
);

export const getCreditCards = createSelector(
  (state) => state.app.getIn(['currentUser', 'paymentMethods', 'creditCards']),
  creditCards => creditCards?.toJS() ?? []
);

export const getPaypalAccounts = createSelector(
  (state) => state.app.getIn(['currentUser', 'paymentMethods', 'paypal']),
  paypals => paypals?.toJS() ?? []
);

export const getBillingAccountPaymentMethods = createSelector(
  (state) => state.app.getIn(['currentUser', 'paymentMethods', 'billingAccs']),
  billingAccs => billingAccs?.toJS() ?? []
);

export const getOrgBillingAccountPaymentMethods = createSelector(
  (state, organizationId) => getBillingAccountPaymentMethods(state),
  (state, organizationId) => organizationId,
  (billingAccs, organizationId) =>
    billingAccs.filter(ba => ba.organizationId === organizationId)
);

export const getSavedPaymentMethods = createSelector(
  (state) => state.app.getIn(['currentUser', 'paymentMethods']),
  paymentMethods => paymentMethods.toJS()
);

export const getShippingAddresses = createSelector(
  (state) => state.app.getIn(['currentUser', 'addresses']),
  addresses => addresses?.toJS()?.filter(a => a.category === 'SHIPPING') ?? []
);
