import React from 'react';
import { Link } from 'react-router-dom';
import { shape, string } from 'prop-types';
import Logo from '../../../img/sprite/lifeway-logo.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  header {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    justify-content: space-between;
    margin: 1em 0;
    width: 100%;
    figure {
      margin: 0;
      padding: 0 1em;
      figcaption { 
        visibility: hidden;
        height: 0;
        width: 0;
      }
      svg { 
        height: 36px;
        width: 135px;
      }
    }
    nav {
      align-self: center;
      padding: 0 1em;
      a {
        padding-left: 1em;
      }
    }
  }
  footer { 
    display: flex;
    justify-content: center;
    margin: 1em 0;
    padding: 0 1em;
    color: #bbb;
  }
`;

const Content = styled.div`
  text-align: center;
  vertical-align: middle;
  margin: auto;
  padding: 0 1em;
  h1 {
    color: #222;
  }
  hr {
    color: #bbb;
  }
  p {
    color: #555;
  }
`;

const DefaultErrorContent = ({
  title = 'Oops! Something went wrong',
  description = 'We\'re sorry, an unexpected error has occured.'
}) => (
    <Content>
      <h1>{title}</h1>
      <hr />
      <p>{description}</p>
    </Content>
  );

DefaultErrorContent.propTypes = {
  title: string,
  description: string,
};

const ErrorContent = ({ errorCode, title, description }) => {
  switch (errorCode) {
    // case: 'specific.error.placeholder'
    default:
      return <DefaultErrorContent title={title} description={description} />;
  }
};

ErrorContent.propTypes = {
  errorCode: string,
  title: string,
  description: string,
};

const ErrorPage = ({ error }) => {
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <header>
        <figure>
          <svg role="img"><use href={`#${Logo.id}`} /></svg>
          <figcaption>Lifeway</figcaption>
        </figure>
        <nav>
          <a href="https://support.lifeway.com/app/answers/list">Help</a>
          <Link to="/">Home</Link>
        </nav>
      </header>
      <ErrorContent {...error} />
      <footer>
        <span>© 2001 - {year} Lifeway Christian Resources of the Southern Baptist Convention</span>
      </footer>
    </Wrapper>
  );
};

ErrorPage.propTypes = {
  error: shape({
    errorCode: string,
    title: string,
    description: string,
  }),
};

export default ErrorPage;
