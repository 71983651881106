import React, { useState, useEffect } from "react";
import SideNavSelect from "./SideNavSelect";
import SideNavIndividual from "./SideNavIndividual";
import SideNavOrg from "./SideNavOrg";
import { useLocation } from "react-router";
import { orgMenuLinks } from "./header/account-menu/menuLinks";
import ViewAccountsModal from "./header/account-menu/ViewAccountsModal";

function SideNavWrapper({
  linkedOrganizations,
  linkedOrgsPending,
  orgId,
  onLogOut,
}) {
  const MenuViews = {
		SELECT: 'SELECT',
		INDIVIDUAL: 'INDIVIDUAL',
		ORG: 'ORG',
	};

  const [orgsLoading, setOrgsLoading] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [viewAccountsModal, setViewAccountsModal] = useState(false);
  const { pathname } = useLocation();

 

  useEffect(() => {
		if (linkedOrgsPending === 'idle') {
			setOrgsLoading(true);
		} else if (linkedOrgsPending === 'pending') {
			setOrgsLoading(true)
		} else if (linkedOrgsPending === 'success') {
			setOrgsLoading(false)
    } else if (linkedOrganizations.length === 0) {
			setOrgsLoading(false)
		} else {
			setOrgsLoading(false)
		}
	}, [linkedOrgsPending, linkedOrganizations]);

  const [view, setView] = useState(MenuViews.SELECT);
	
	useEffect(() => {
		if (orgId && !orgsLoading) {
			const currentOrg = linkedOrganizations.find(org => org.organizationId === orgId);
			setSelectedOrg(currentOrg);
			setView(MenuViews.ORG);
		} else if (orgId === undefined && !pathname.includes(orgId)) {
      setView(MenuViews.INDIVIDUAL);
    }
	}, [orgId, orgsLoading, pathname]);;

	const showBackBtn = linkedOrganizations?.length > 0;

	const firstThreeOrgs = linkedOrganizations?.slice(0, 3);

	const showMoreOrgs = linkedOrganizations?.length > 3;

  const updatedOrgMenuLinks = {
    ...orgMenuLinks,
    linkGroup: orgMenuLinks.linkGroup.map(group => ({
      ...group,
      links: group.links.filter(link => link.name !== 'Billing')
    }))
  };

  const handleSelectOrg = (org) => {
    if (org) {
      setView(MenuViews.ORG);
      setSelectedOrg(org);
    }
  };

  return (
    <div data-qa-hook="side-nav-wrapper">
      <div>
        {/* SELECT EACH SIDENAV VIEW */}
        {view === 'SELECT' && !orgsLoading &&
          <SideNavSelect
            view={view}
            setView={setView}
            menu={MenuViews}
            pathname={pathname}
            showMoreOrgs={showMoreOrgs}
            linkedOrganizations={linkedOrganizations}
            firstThreeOrgs={firstThreeOrgs}
            loading={orgsLoading}
            handleSelectOrg={handleSelectOrg}
            setViewAccountsModal={() => setViewAccountsModal(true)}
            onLogOut={onLogOut}
          />
        }
        {/* INDIVIDUAL VIEW */}
        {view === 'INDIVIDUAL' &&
          <SideNavIndividual
            pathname={pathname}
            showBackBtn={showBackBtn}
            loading={orgsLoading}
            view={view}
            setView={setView}
            onLogOut={onLogOut}
          />
        }
        {/* ORG VIEW */}
        {view === 'ORG' && !orgsLoading &&
          <SideNavOrg
            selectedOrg={selectedOrg} 
            pathname={pathname}
            updatedOrgMenuLinks={updatedOrgMenuLinks}
            showBackBtn={showBackBtn}
            view={view}
            setView={setView}
          />
        }
      </div>
      {viewAccountsModal && (
        <ViewAccountsModal
          show={viewAccountsModal}
          onClose={() => setViewAccountsModal(false)}
          linkedOrganizations={linkedOrganizations}
        />
      )}
    </div>
  );
}

export default SideNavWrapper;