import { object } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const NameMessage = ({ children }) => {
  return <Message>{children}</Message>;
};

export default NameMessage;

NameMessage.propTypes = {
  children: object,
};

const Message = styled.p`
  font-style: italic;
  color: #3d6995;
  font-size: 1.3rem;
  margin: 1rem 1rem;
  font-weight: bold;
  font-family: sofia-pro, "helvetica neue", helvetica, arial, sans-serif;
`;