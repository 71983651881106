import React, { useEffect } from 'react';
import bbbScript from './bbbScript';
import {
  FooterCopyright,
  FooterCopyrightImage,
  FooterCopyrightLink,
  FooterCopyrightText,
  Footer as FooterComponent,
  FooterSocialIcon,
  LinkList,
  LinkListItem,
  LinkListTitle,
} from '@lifewayit/components/dist/lib';
import classNames from 'classnames';
import footerData from './footerData';
import NewsLetterForm from './NewsLetterForm';

export function Footer({submitNewsLetter, contentfulLinks}) {
  const { newsletter, upperfooter, followsocialmedia, bottomfooter } = footerData;

  function OpenIntercom(ev) {
    window.Intercom('show');
    ev.preventDefault();
    return;
  }

  useEffect(() => {
    bbbScript();
  }, []);

  const bottomFooterComponents = (
    <div className="pt-0 px-4 max-w-screen-2xl mx-auto">
      <FooterCopyright>
        <div className="flex justify-center md:justify-end mb-4">
          {bottomfooter?.certifications?.map((cert, ind) => (
            <FooterCopyrightLink
              id="bbblink"
              key={ind}
              href={cert?.certificationImageURL}
            >
              <FooterCopyrightImage
                alt="Lifeway Christian Resources, Religious Goods, Nashville, TN"
                src={cert?.certificationImage}
              />
            </FooterCopyrightLink>
          ))}
        </div>
        <FooterCopyrightText>
          © 2001–{new Date().getFullYear()} {bottomfooter?.copyrightText}
        </FooterCopyrightText>
      </FooterCopyright>
    </div>
  );

  const topFooterComponents = (
    <div className="py-12 px-4 flex flex-wrap max-w-screen-2xl mx-auto">
      <section className="w-full md:w-3/5 grid grid-cols-2 sm:grid-cols-3 gap-8">
        {contentfulLinks?.length > 0 ? 
          contentfulLinks?.upperfooter?.map((column) => (
            <div key={column?.fields?.componentName}>
              <LinkList>
                <LinkListTitle>{column?.fields?.columnTitle}</LinkListTitle>
                {column?.fields?.columnLinksList?.map((listItem) => (
                  <LinkListItem key={listItem?.fields?.componentName}>
                    <a
                      href={listItem?.fields?.columnInternalUrl}
                      target={listItem?.fields?.manualLinkTarget}
                      onClick={(ev) =>
                        listItem?.fields?.openChatLink ? OpenIntercom(ev) : undefined
                      }
                      className={classNames(
                        listItem?.fields?.isSpecialLink === true
                          ? 'text-yellow-live hover:text-white transition-colors no-underline'
                          : 'text-sm leading-6 text-gray-d hover:text-white hover:cursor-pointer transition duration-150 w-fit dashed-focus-ring no-underline',
                      )}
                      tabIndex={0}
                      role={listItem?.fields?.isSpecialLink ? 'button' : 'link'}
                    >
                      {listItem?.fields?.columnLinkText}
                    </a>
                  </LinkListItem>
                ))}
              </LinkList>
            </div>
          )) :
          upperfooter?.columnsList?.map((column) => (
            <div key={column.componentNodeName}>
              <LinkList>
                <LinkListTitle>{column?.columnTitle}</LinkListTitle>
                {column?.columnLinksList?.map((listItem) => (
                  <LinkListItem key={listItem?.componentNodeName}>
                    <a
                      href={listItem?.columnInternalUrl}
                      target={listItem?.manualLinkTarget}
                      onClick={(ev) =>
                        listItem?.openChatLink ? OpenIntercom(ev) : undefined
                      }
                      className={classNames(
                        listItem?.isSpecialLink === 'on'
                          ? 'text-yellow-live hover:text-white transition-colors no-underline'
                          : 'text-sm leading-6 text-gray-d hover:text-white hover:cursor-pointer transition duration-150 w-fit dashed-focus-ring no-underline',
                      )}
                      tabIndex={0}
                      role={listItem?.isSpecialLink ? 'button' : 'link'}
                    >
                      {listItem?.columnLinkText}
                    </a>
                  </LinkListItem>
                ))}
              </LinkList>
            </div>
          ))
        }
      </section>

      <section className="w-full pl-0 md:pl-8 md:w-2/5 mt-12 md:mt-0">
        <NewsLetterForm data={newsletter} handleFormSubmit={submitNewsLetter} />

        <div className="flex flex-wrap justify-center md:justify-start -ml-2 mt-8">
          {followsocialmedia?.mediaApps?.map((media, ind) => {
            const iconType = media?.icon?.split(
              '-'
            )[1];
            return (
              <FooterSocialIcon
                key={ind}
                href={media?.linkUrl}
                type={iconType}
                className="m-2 no-underline"
              />
            );
          })}
        </div>
      </section>
    </div>
  );
  return (
    <FooterComponent
      id="footer"
      topFooterComponents={topFooterComponents}
      bottomFooterComponents={bottomFooterComponents}
    />
  );
}
