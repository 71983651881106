import { createLogic } from 'redux-logic';
import { stopSubmit, reset, setSubmitFailed, setSubmitSucceeded } from 'redux-form';
import { GET_CODES_PENDING } from '../constants';
import { getCodeSuccess, setBannerState, clearBannerState, setDigitalMediaStatus, fetchProductImageURLs } from '../actions/AppActions';

const form = 'redeemCode';

const tableName = (category) => {
  switch (category.toUpperCase()) {
    case 'EBOOK':
      return 'eBooks';
    case 'FILE_DOWNLOAD':
      return 'downloads';
    case 'MEMBERSHIPS':
      return 'memberships';
    case 'RENTAL':
    case 'VIDEO':
      return 'videos';
    case 'SIMULCAST':
      return 'simulcasts';
    default:
      return 'other content';
  }
};

const errorCategory = (message) => message.match(/category ".*?"/i)?.[0]?.match(/"([^"]+)"/)?.[1];

const alreadyOwnedMessage = (errorMessage) => {
  const category = errorCategory(errorMessage);
  return `We noticed you have already redeemed this code. Please check ${category ? `${tableName(category)} in ` : ''}your digital media.`
};

export const redemptionCodeErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'CTG_USED':
      return 'This code has reached its redemption limit for this group and can no longer be used.';
    case 'LIMIT_EXCEEDED':
      return 'This code has reached its redemption limit for this group and can no longer be used.';
    case 'ALREADY_REDEEMED':
      return 'This code has already been redeemed. Please check your digital media.';
    case 'EXPIRED':
      return 'The code you entered has expired. Please try a different code.';
    case 'NOT_YET_ACTIVE':
      return 'Redemption code is expired or not yet active. Please try again later.';
    case 'INVALID':
      return 'The code you entered is not a valid code. Please check it and try again.';
    default:
      return 'The code you entered cannot be redeemed right now. Please try again later.';
  }
};

const getLW1Location = async (client, seat) => {
  return client.get(`/resources/${seat.itemNumber}/locations`)
    .then(res => res.data.locations);
};

export const getCodesPendingLogic = createLogic({
  type: GET_CODES_PENDING,
  latest: true,
  validate({ action }, allow, reject) {
    if (!action.payload.codes) {
      reject(stopSubmit(form, {}));
    } else {
      allow(action);
    }
  },
  process({ httpClient, action, getState }, dispatch, done, browserWindow = window) {
    const state = getState();
    const intl = state.intl.messages;
    const { codes, successFn } = action.payload;

    dispatch(clearBannerState());

    Promise.allSettled(codes.map(code => httpClient(dispatch).post('/code-redemptions', { code })))
      .then(results => {
        const noFailures = results.every(result => result.status !== 'rejected');
        results.forEach(result => {
          if (result.status === 'fulfilled') {
            const { code, name, seats } = result.value.data || {};
            const itemNumbers = seats?.map(seat => seat.itemNumber);
            if(seats?.length === 1 && seats?.[0]?.distributionChannelCodes?.includes('LW1')) {
              getLW1Location(httpClient(dispatch), seats[0]).then((locations) => {
                const url = locations[0]?.url;
                browserWindow.location = url;
              });
            }
            itemNumbers && dispatch(fetchProductImageURLs({ itemNumbers }));
            dispatch(setBannerState({ data: { message: intl.Errors.RedeemCode.Get_Code_Success, type: 'success' } }));
            dispatch(reset(form));
            dispatch(getCodeSuccess({ code, name, items: seats }));
            dispatch(setDigitalMediaStatus(false));

          } else {
            const { seats } = result?.value?.data || {};
            if(seats?.length === 1 && seats?.[0]?.distributionChannelCodes?.includes('LW1')) {
              getLW1Location(httpClient(dispatch), seats[0]).then((locations) => {
                const url = locations[0]?.url;
                browserWindow.location = url;
              });
            }
            const userId = state?.app?.getIn(['currentUser'])?.toJS()?.id;
            const redemptionError = result?.reason?.response?.data?.error;
            const alreadyOwnedByUser = !!(redemptionError?.code === 'CTG_USED' && userId && redemptionError?.message?.toLowerCase().includes(userId.toLowerCase()));
            const alreadyRedeemed = redemptionError?.code === 'ALREADY_REDEEMED';
            const bannerState = alreadyOwnedByUser
              ? { message: alreadyOwnedMessage(redemptionError?.message) }
              : { type: alreadyRedeemed ? 'blue' : 'error', message: redemptionCodeErrorMessage(redemptionError?.code) }
            const redemptionInfo = result?.reason?.response?.data?.error?.redemptionInfo;
            const { code, name, existingSeats } = redemptionInfo || {};
             if(alreadyRedeemed) {
              dispatch(getCodeSuccess({ code, name, items: existingSeats }));
             } 
            dispatch(setBannerState({ data: bannerState }));
            dispatch(reset(form));
          }
        });

        if (noFailures) {
          successFn && successFn();
          dispatch(setSubmitSucceeded(form));
        } else {
          dispatch(setSubmitFailed(form));
        }
      })
      // no catch needed for allSettled. All resolve. //
      .finally(() => done());
  }
});

export default [
  getCodesPendingLogic
];
