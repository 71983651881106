import { fromJS, Record, Map, List } from 'immutable';
import moment from 'moment';
import * as Constants from '../../constants';
import { getOrganizationId } from '../../utils/autoshipUtils';

const defaultAutoshipListState = new Map({
  loading: false,
  fetched: false,
  error: null,
  autoshipIds: new List([])
});

const defaultAutoshipState = new Map({
  loading: false,
  error: null,
  data: null
});

export const AutoshipStateRecord = Record({
  personal: defaultAutoshipListState,
  org: new Map({}),
  creators: new Map({}),
  details: new Map({})
});

export const defaultState = new AutoshipStateRecord();

const updateInIfPresent = (state, keys, updater) =>
  state.updateIn(keys, obj => {
    if (obj === undefined) {
      return undefined;
    } else {
      return updater(obj);
    }
  });

const updateMultipleAutoshipDetails = (startingState, { autoships, errors = [] }) => {
  const stateWithErrors = errors.reduce((state, error) =>
    state.updateIn(['details', error.subscriptionId],
      (autoshipState = defaultAutoshipState) => autoshipState
        .set('error', fromJS(error))
    ),
    startingState
  );

  return autoships.reduce((state, autoship) =>
    state.updateIn(['details', autoship.id],
      (autoshipState = defaultAutoshipState) => autoshipState
        .set('loading', false)
        .set('data', fromJS(autoship))
        .set('error', null)
    ),
    stateWithErrors
  );
};

export default function (state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case Constants.GET_PERSONAL_AUTOSHIPS_PENDING:
      return state.setIn(['personal', 'loading'], true);

    case Constants.GET_PERSONAL_AUTOSHIPS_SUCCESS: {
      return updateMultipleAutoshipDetails(state, payload)
        .setIn(['personal', 'loading'], false)
        .setIn(['personal', 'fetched'], true)
        .setIn(['personal', 'autoshipIds'], fromJS(payload.autoships.map(autoship => autoship.id)))
        .setIn(['personal', 'error'], null);
    }

    case Constants.GET_PERSONAL_AUTOSHIPS_ERROR:
      return state
        .setIn(['personal', 'loading'], false)
        .setIn(['personal', 'error'], action.payload);

    case Constants.GET_ORG_AUTOSHIPS_PENDING:
      return state.updateIn(['org', payload.organizationId],
        (autoship = defaultAutoshipListState) => autoship.set('loading', true)
      );

    case Constants.GET_ORG_AUTOSHIPS_SUCCESS:
      return updateMultipleAutoshipDetails(state, payload)
        .updateIn(['org', payload.organizationId],
          (autoship = defaultAutoshipListState) => autoship
            .set('loading', false)
            .set('fetched', true)
            .set('autoshipIds', fromJS(payload.autoships.map(autoship => autoship.id)))
            .set('error', null)
        );

    case Constants.GET_ORG_AUTOSHIPS_ERROR:
      return state.updateIn(['org', payload.organizationId],
        (autoship = defaultAutoshipListState) => autoship
          .set('loading', false)
          .set('error', payload.error)
      );

    case Constants.GET_AUTOSHIP_DETAILS_PENDING:
      return state.updateIn(['details', payload.autoshipId],
        (autoship = defaultAutoshipState) => autoship
          .set('loading', true)
      );

    case Constants.GET_AUTOSHIP_DETAILS_SUCCESS: {
      const organizationId = getOrganizationId(payload.autoship);
      const listPath = organizationId
        ? ['org', organizationId]
        : ['personal'];

      return state
        .updateIn(['details', payload.autoshipId],
          (autoship = defaultAutoshipState) => autoship
            .set('loading', false)
            .set('error', null)
            .set('data', fromJS(action.payload.autoship))
        )
        .updateIn([...listPath, 'autoshipIds'], ids =>
          !ids.includes(payload.autoship.id)
            ? ids.push(payload.autoship.id)
            : ids
        );
    }

    case Constants.GET_AUTOSHIP_DETAILS_ERROR:
      return state.updateIn(['details', payload.autoshipId],
        (autoship = defaultAutoshipState) => autoship
          .set('loading', false)
          .set('error', new Map({
            notFound: action.payload.status === 404,
            rawError: action.payload.error
          }))
      );

    case Constants.CANCEL_AUTOSHIP_SUCCESS: {
      return updateInIfPresent(state, ['details', payload.autoshipId, 'data'], autoship =>
        autoship.set('status', 'Inactive')
      );
    }

    case Constants.REMOVE_AUTOSHIP_ITEM_SUCCESS: {
      return updateInIfPresent(state, ['details', payload.autoshipId, 'data', 'items'], items =>
        items.filter(item => item.get('productSku') !== payload.productSku)
      );
    }
    case Constants.CHANGE_AUTOSHIP_ITEM_QUANTITY_SUCCESS: {
      const updateMatchingItems = (updater) => (items) => items.map(item =>
        item.get('productSku') === payload.productSku
          ? updater(item)
          : item
      );

      return updateInIfPresent(state, ['details', payload.autoshipId, 'data', 'items'], updateMatchingItems(item =>
        item
          .set('quantity', payload.newQuantity)
          .set('extendedListPrice', item.get('listPrice') * payload.newQuantity)
          .set('extendedPurchasePrice', item.get('purchasePrice') * payload.newQuantity)
      ));
    }
    case Constants.TRANSFER_AUTOSHIP_ITEM_TO_EXISTING_SUCCESS: {
      const isLastItem = state.getIn(['details', payload.originId, 'data', 'items'])
        ?.every(item => item.get('productSku') === payload.productSku);

      const transferredItem = state.getIn(['details', payload.originId, 'data', 'items'])
        ?.find(item => item.get('productSku') === payload.productSku);

      if (transferredItem == undefined) {
        return state;
      }

      let updatedState = updateInIfPresent(state, ['details', payload.originId, 'data', 'items'], items =>
        items.filter(item => item.get('productSku') !== payload.productSku)
      );

      updatedState = updateInIfPresent(updatedState, ['details', payload.destinationId, 'data', 'items'], items =>
        items.push(transferredItem)
      );

      if (isLastItem) {
        const listPath = payload.organizationId
          ? ['org', payload.organizationId]
          : ['personal'];

        updatedState = updatedState.updateIn([...listPath, 'autoshipIds'], ids =>
          ids.filter(id => id !== payload.originId)
        );
      }

      return updatedState;
    }

    case Constants.SKIP_AUTOSHIP_NEXT_ORDER_SUCCESS: {
      return updateInIfPresent(state, ['details', payload.autoshipId, 'data'], autoship => {
        const billingInterval = autoship.getIn(['billingFrequency', 'interval']);

        return autoship.update('scheduledOrderDate', orderDate =>
          moment(orderDate).add(billingInterval, 'M').format('YYYY-MM-DD')
        );
      });
    }

    case Constants.REACTIVATE_AUTOSHIP_SUCCESS: {
      return updateInIfPresent(state, ['details', payload.autoshipId, 'data'], autoship =>
        autoship.set('status', 'Active').delete('reasonSuspended')
      );
    }

    // Address edited on address page, update autoships with new address
    case Constants.UPDATE_AUTOSHIPS_WITH_SHIPPING_ADDRESS: {
      const { address, existingId } = payload;
      const autoshipAddress = {
        lastName: address.lastName,
        zip: address.postalCode,
        city: address.city,
        addressType: address.type,
        phone: address.phoneNumber,
        state: address.countrySubdivision,
        country: address.countryCode,
        id: address.id,
        firstName: address.firstName,
        line1: address.line1
      };

      return state.updateIn(['details'], (immutableDetails) => {
        let details = immutableDetails?.toJS();
        const autoshipIds = Object.keys(details);

        details = autoshipIds.reduce((updatedDetails, autoshipId) => {
          const autoship = details[autoshipId];

          if (autoship?.data?.shippingAddress?.id === existingId) {
            return {
              ...updatedDetails,
              [autoshipId]: {
                ...autoship,
                data: {
                  ...autoship.data,
                  shippingAddress: {
                    ...autoship.data.shippingAddress,
                    ...autoshipAddress
                  }
                }
              }
            };
          } else {
            return {...updatedDetails, [autoshipId]: autoship};
          }
        }, {});

        return fromJS(details);
      });
    }

    default:
      return state;
  }
}
