import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import userManager from '../../utils/userManager';
import { PATH_LOGGED_OUT, PATH_DASHBOARD } from '../../constants';
import history from '../../utils/history';
import NavigationMessagePage from '../NavigationMessagePage';
import { isLoggedInToTrue } from '../../actions/AppActions';
import { popFromLocalStorage } from '../../utils/locationHelper';

const SigninRedirectCallbackPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    userManager.signinRedirectCallback()
      .then(() => {
        dispatch(isLoggedInToTrue());
        const previousLocation = popFromLocalStorage();
        const redirectLocation = previousLocation && previousLocation?.pathname !== PATH_LOGGED_OUT
          ? previousLocation
          : PATH_DASHBOARD;
        history.push(redirectLocation);
      })
      .catch((er) => {
        if (er.message === 'No matching state found in storage') {
          userManager.signinRedirect();
        }
      });
  }, []);

  const signinRedirect = () => {
    userManager.signinRedirect();
  };

  return <NavigationMessagePage message="checking your Lifeway account..." retryAction={signinRedirect} />;
};

export default SigninRedirectCallbackPage;
