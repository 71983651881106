import styled from 'styled-components';

const Card = styled.div`
  border-radius: 0.75rem;
  border: ${(props) =>
    props.selected ? '2px solid #feb529' : '2px solid #eee;'};
  padding: 20px;
  height: 100%;
  position: relative;
  ${(props) => props.isTransfer && 'width: auto'};

  transition-property: border-color, color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;

  :hover {
    ${({ hoverBorderColor, disabled, isDefault }) =>
      hoverBorderColor &&
      !disabled &&
      !isDefault &&
      `border-color: ${hoverBorderColor};`}
  }

  ${({ disabled }) => disabled && 'opacity: 0.5;'}

  @media screen and (max-width: 1260px) {
    ${(props) => props.isTransfer && 'width: 280px'};
  }

  @media screen and (${(props) =>
      props.isTransfer ? 'max-width: 940px' : 'max-width: 630px'}) {
    width: 100%;
  }
`;

export default Card;
