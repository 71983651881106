import { createLogic } from 'redux-logic';
import moment from 'moment';
import {
  FETCH_USER_DATA_PENDING,
  GET_USER_PENDING,
  ENABLE_ORG_MANAGEMENT,
  ENABLE_REBRANDING_PHASE_1,
} from '../constants';
import {
  fetchUserDataSuccess,
  fetchUserDataError,
  setBannerState,
  getLinkedOrganizationsPending,
  getUserSuccess,
  getUserError,
  getUserRolesPending,
} from '../actions/AppActions';
import intl from '../actions/lang/en.json';
import css from '../../../css/css-variables';

const formatDate = date =>
  date
    ? moment.utc(date).utcOffset('+0100').format('MM/DD/YYYY')
    : undefined;

const cleanPhoneCategory = (phone, acceptedPhoneTypes) => ({
  ...phone,
  category: phone.category && acceptedPhoneTypes.includes(phone.category)
    ? phone.category
    : '',
});

export const fetchUserDataLogic = createLogic({
  type: FETCH_USER_DATA_PENDING,
  latest: true,
  processOptions: {
    dispatchMultiple: true
  },
  process({ httpClient }, dispatch, done) {
    return httpClient(dispatch).get('/profile')
      .then(res => {
        const person = res.data.person;
        const username = res.data.emails?.find(email => email.isUsername)?.emailAddress;
        const user = {
          ...person,
          initials: person?.firstName?.length && person.firstName.charAt(0),
          fullName: person?.firstName && person?.lastName && `${person.firstName} ${person.lastName}`,
          initialsColor: ENABLE_REBRANDING_PHASE_1 ? css.colors.DarkGray : '#E6B952',
          phones: res.data.phones
            ? res.data.phones.map(phone => cleanPhoneCategory(phone, ['HOME', 'MOBILE', 'WORK']))
            : [],
          emailAddress: username || '[PLEASE UPDATE YOUR EMAIL ADDRESS]',
          addresses: res.data.addresses || [],
          birthDate: formatDate(person.birthDate),
        };
        dispatch(fetchUserDataSuccess(user));
        if (ENABLE_ORG_MANAGEMENT) {
          dispatch(getLinkedOrganizationsPending(user.id));
          dispatch(getUserRolesPending({ customerId: user.id}));
        };
      })
      .catch(err => {
        const message = intl.Errors.Profile.Get_Personal_Info_Error;
        const bannerState = {
          data: { type: 'error', message }
        };
        dispatch(setBannerState(bannerState));
        dispatch(fetchUserDataError(err));
      })
      .finally(() => done());
  }
});

export const getSingleUser = createLogic({
  type: GET_USER_PENDING,
  process({ httpClient, getState, action }, dispatch, done) {
    const { lifewayId, organizationId } = action.payload;
    const state = getState();

    const organization = state.app.getIn(['currentUser', 'linkedOrgs']).find(org => org.get('organizationId') === organizationId);
    const matchingMember = organization?.getIn(['groups'])
      ?.flatMap(group => group.get('members'))
      ?.find(member => member.get('personId') === lifewayId);

    if (state.app.getIn(['users']).has(lifewayId)) {
      // already in state, do nothing
      done();
    } else if (lifewayId === state.app.getIn(['currentUser', 'id'])) {
      dispatch(getUserSuccess(lifewayId, {
        firstName: state.app.getIn(['currentUser', 'firstName']),
        lastName: state.app.getIn(['currentUser', 'lastName']),
      }));
      done();
    } else if (matchingMember != null) {
      dispatch(getUserSuccess(lifewayId, {
        firstName: matchingMember.get('firstName'),
        lastName: matchingMember.get('lastName'),
      }));
      done();
    } else {
      httpClient(dispatch).get(`/persons/${lifewayId}`)
        .then(res => {
          const person = res.data;
          dispatch(getUserSuccess(lifewayId, {
            firstName: person.firstName,
            lastName: person.lastName
          }));
          done();
        })
        .catch(err => {
          dispatch(getUserError(lifewayId, err));
          done();
        });
    }
  }
});

export default [
fetchUserDataLogic,
getSingleUser
];
