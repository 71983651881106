import { useSelector, useDispatch } from 'react-redux';
import { clearBannerState } from '../actions/AppActions';
import { getAppError, getBannerError } from '../selectors/ErrorSelectors';

export default () => useSelector(getAppError);

export const useBannerError = () => {
  const dispatch = useDispatch();

  return {
    banner: useSelector(getBannerError),
    clearBanner: () => dispatch(clearBannerState())
  };
};
