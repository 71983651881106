import PropTypes from 'prop-types';
import React from 'react';
import { PATH_GIFT_CARDS } from '../constants';
import styled from 'styled-components';
import List from './List';

const StyledList = styled(List)`
  padding: ${({ dropDown }) => dropDown ? '12px 12px 0 12px' : '12px 1.87rem'};
  display: flex;
  flex-direction: column;
  gap: 14px;
  ${({ depth }) => depth && 'margin-top: -0.556em;'}
  ${({ displayMenu }) => !displayMenu && 'display: none;'}
  @media(max-width:720px) {
    margin-top: 0;
    padding-bottom: .556em;
  }

  .active ul .active a span{
    border-bottom: 1px solid #000000;
  }
  .active ul .active a:hover{
    span{
      border-bottom: 1px solid #000000;
    }
  }
`;
StyledList.displayName = 'StyledList';

export const getPageTitlesFromMenu = (menuNodes) => {
  const pageTitles = {};

  const getPageTitlesFromMenuNode = (node) => {
    React.Children.forEach(node.props.children, (child) => {
      if (child.props.children) getPageTitlesFromMenuNode(child);
      if (child.props.href && child.props.title) {
        pageTitles[child.props.href] = child.props.title;
      }
    });
  };
  getPageTitlesFromMenuNode(menuNodes);
  return pageTitles;
};

export const setActiveItems = (node, props, depth = 0, activateMenus = false, breadcrumb) => {
  const { location, processMenuTree, isBreadCrumb, renderBreadCrumb, breadCrumbStyles } = props;
  const _crumbs = (!breadcrumb) ? [] : breadcrumb;

  const children = React.Children.map(node.props.children, (child) => {
    if (!child || !child.props) return child;
    const cmptType = (child.props.title) ? 'item' : 'menu';
    const newDepth = (child && child.props && child.props.children) ? depth + 1 : depth;
    const newChild = (child && child.props && child.props.children) ?
                      setActiveItems(child, props, newDepth, activateMenus, _crumbs) : child;

    // remove inactive menu items from a breadcrumb-style menu instance
    if (isBreadCrumb && activateMenus && !newChild.props.isActive && newChild.props.href &&
        newChild.props.href !== location.pathname) {
      return null;
    }
    // build breadcrumb array for a breadcrumb-style menu instance
    if (isBreadCrumb && activateMenus && cmptType === 'item') {
      _crumbs.push({ title: newChild.props.title, href: newChild.props.href });
    }

    let hrefMatchesLocation = newChild.props.href && location.pathname.indexOf(newChild.props.href) === 0;

    // This ensures /payment-methods/gift-cards doesn't show payment-methods link as active
      if (location.pathname === PATH_GIFT_CARDS) {
        hrefMatchesLocation = location.pathname === newChild.props.href;
      }

    const auxiliaryRouteMatchesLocation = newChild.props.auxiliaryRoutes
      && newChild.props.auxiliaryRoutes.some(route => location.pathname.indexOf(route) === 0);

    // mark item as active when it matches the current location
    if (!activateMenus && (hrefMatchesLocation | auxiliaryRouteMatchesLocation) &&
        location.pathname !== '/' && newChild.props.href !== '/') {
      return React.cloneElement(newChild, { isActive: true });
    }
    // set menuDepth property on menu components
    if (!activateMenus && cmptType === 'menu') {
      return React.cloneElement(newChild, { menuDepth: depth });
    }
    // mark a menu contained by an active item as active
    if (activateMenus && node.props.isActive && cmptType === 'menu') {
      return React.cloneElement(newChild, { isActive: true });
    }
    return newChild;
  });

  const _props = { children };

  if (!activateMenus) {
    // mark the current node as active if it has any active children
    children.forEach((child) => {
      if (child.props?.isActive) _props.isActive = true;
    });
  }

  let newNode = React.cloneElement(node, _props);

  // finished recursively setting item isActive props
  if (depth === 0 && !activateMenus) {
    newNode = setActiveItems(newNode, props, 0, true, _crumbs);
    // higher order function to do something with menu tree
    if (processMenuTree) processMenuTree(newNode);
  }

  if (depth === 0 && activateMenus) {
    // higher order function to render breadcrumb-style menu
    if (isBreadCrumb && renderBreadCrumb) {
      newNode = renderBreadCrumb(_crumbs.reverse(), breadCrumbStyles);
    }
  }
  return newNode;
};

const Menu = ({ children, isActive, menuDepth, showChildren, dropDown } ) => {

  const depth = (undefined !== menuDepth) ? menuDepth : 0;
  const displayMenu = (showChildren || isActive || depth > 1);

  return (
    <StyledList depth={depth} displayMenu={displayMenu} dropDown={dropDown}>
      {children}
    </StyledList>
  );
};

const { number, bool, node } = PropTypes;

Menu.propTypes = {
  isActive: bool,
  menuDepth: number,
  children: node.isRequired,
  showChildren: bool,
  dropDown: bool,
};

export default Menu;
