import { ENABLE_PURCHASERS_GROUP } from '../constants';
import { getOrganization } from '../selectors/OrgManagementSelectors';

export const removeBillingAccountGroups = (group) => {
  if (ENABLE_PURCHASERS_GROUP) {
    return !group?.name?.toLowerCase().includes('billing account');  // will remove only billing account groups
  }
    return !group?.name?.toLowerCase().includes('purchasers'); // will remove billing accounts & purchasers groups
};

export const getBillingAccountNumberFromGroupName = name => name.replace(/[^0-9]/g, ''); // get billing account number from group name EX: "Billing Account 0003739100 Purchasers" //

export const combinePurchasersGroups = (groups) => {
  const purchasersGroup = groups?.find(g => g.name?.toLowerCase() === 'purchasers');

  if (ENABLE_PURCHASERS_GROUP && purchasersGroup) {
    const billingAccountGroups = groups.filter(g => g.name?.toLowerCase().includes('billing account') && !g.name?.toLowerCase().includes('inactive'));

    const purchasersGroupMembersWithBillingAccount = purchasersGroup.members.map(member => ({
        ...member,
        billingAccounts: billingAccountGroups.map(baGroup => ({
          groupId: baGroup.id,
          accountNumber: getBillingAccountNumberFromGroupName(baGroup.name),
          isMember: !!baGroup.members.find(baMember => baMember.personId === member.personId)
        }))
      })
    );

    return groups.map(group => {
      if (group.id === purchasersGroup.id) {
        return {
          ...purchasersGroup,
          members: purchasersGroupMembersWithBillingAccount,
          billingAccountSubGroups: billingAccountGroups.map(ba => ({
              groupName: ba.name,
              groupId: ba.id
            }))
        };
      }  else {
        return group;
      }
    });
  }

  return groups || [];
};

export const formatGroups = (organizations, currentUserId) =>
  organizations?.map(org => ({
    ...org,
    userGroups: combinePurchasersGroups(org?.groups).filter(g => g?.members?.some(m => m?.personId === currentUserId) && removeBillingAccountGroups(g)) || [],
    groups: combinePurchasersGroups(org?.groups).filter(removeBillingAccountGroups)
  })
);

export const formatRoleName = (roleName) => {
    const formatRoleName = {
        'Administrators': 'Administrator',
        'Purchasers': 'Organization Purchaser',
        'Billing Managers': 'Organization Billing Manager'
    };
    return formatRoleName[roleName];
}

export const formatOrgMemberRoles = (state, members, organizationId) => {
    const org = getOrganization(state, organizationId);
    const orgMemberGroup = org ? org.groups.find(group => group.name === 'Organization Members') : [];
    const purchasersGroup = org ? org.groups.find(group => group.name === 'Purchasers') : [];

    const orgMembers = members
      .map(member => {
        const matchingMember = orgMemberGroup?.members?.find(orgMemberGroupMember => orgMemberGroupMember.personId === member.customerId);
        const { firstName, lastName, email } = matchingMember || {};
        return {
          ...member,
          firstName,
          lastName,
          email
        };
      });

    const roles = {
        'Administrator': [],
        'Organization Purchaser': [],
        'Billing Account Purchaser': [],
        'Organization Billing Manager': [],
        'Organization Members': [],
    };

    orgMembers.forEach(member => {
      roles['Organization Members'].push(member);
      const filteredAssignedRoles = member.assignedRoles
          .filter(role => roles.hasOwnProperty(role.name) && role.contextId === organizationId);

          filteredAssignedRoles.forEach(role => {
          if(roles.hasOwnProperty(role.name)) {
              const memberExists = roles[role.name].some(roleMember => roleMember.customerId === member.customerId)
              if(!memberExists){
                  roles[role.name].push({
                      ...member,
                      assignedRoles: filteredAssignedRoles
                  });
              }
          }
      });
  });

const orgPurchasersWithBillingAccounts = roles['Organization Purchaser'].map(member => {
    const orgBillingAccountGroup = purchasersGroup?.billingAccountSubGroups;
    const billingAccountPurchaserRole = member?.assignedRoles.filter(role => role.name === 'Billing Account Purchaser');
    
    const filteredPermissions = [];
    billingAccountPurchaserRole.forEach(entry => {
      const permissionsArray = entry.permissions.filter(permission =>
        permission.displayId === 'PurchaseUsingBillingAccount'
      );
      filteredPermissions.push(...permissionsArray);
    });
    const result = filteredPermissions;

    const accountNumbers = result.map(role => {
        const scopeParts = role?.scope.split(':');
        const accountIndex = scopeParts.indexOf('account');
        if (accountIndex !== -1 && scopeParts.length > accountIndex + 1) {
          return scopeParts[accountIndex + 1];
        }
        return null;
      });

        const purchaserRoleBillingAccounts = orgBillingAccountGroup?.map(orgAccountNumber => {
            const hasAccountNumber = accountNumbers.some(accountNumber => accountNumber === getBillingAccountNumberFromGroupName(orgAccountNumber.groupName));
                return {
                    ...orgAccountNumber,
                    accountNumber: getBillingAccountNumberFromGroupName(orgAccountNumber.groupName),
                    isMember: hasAccountNumber
                };
        });

        return {
            ...member,
            billingAccounts: purchaserRoleBillingAccounts
        };
});
    roles['Organization Purchaser'] = orgPurchasersWithBillingAccounts;

    return roles;
};

export const getPrimaryGroupName = groups => {
    // currently only Purchasers will contain multiple groups.  All others will have 1 item in the array //
    const hasPurchasersGroup = groups?.some(g => g.groupName === 'Purchasers');
    return hasPurchasersGroup ? 'Purchasers' : groups[0].groupName;
};
