import { createLogic } from 'redux-logic';
import { ADD_TO_CART_PENDING } from '../constants';
import { addToCartSuccess, addToCartError } from '../actions/AppActions';

export const addToCartLogic = createLogic({
  type: ADD_TO_CART_PENDING,
  process({ action, httpClient }, dispatch, done) {
    const { items, meta } = action.payload;
    const { onError, onSuccess } = meta;

    httpClient(dispatch)
      .post('cart/add-items',
      { items },
      {
        params: {
          channel: 'lifeway'
        }
      })
      .then(({ data }) => {
        dispatch(addToCartSuccess(data));
        onSuccess && onSuccess();
      })
      .catch(err => {
        onError && onError(err);
        dispatch(addToCartError(err));
      })
      .finally(() => done());
  }
});

export default [
  addToCartLogic
];