import { createLogic } from 'redux-logic';
import moment from 'moment';
import { GET_INVOICES_SHIPMENTS_PENDING } from '../constants';
import {
  getInvoicesShipmentsSuccess,
  tableUpdateSettings,
  setBannerState
} from '../actions/AppActions';
import { formatMoney } from '../utils/orderHelpers';

export const getInvoicesShipmentsPendingLogic = createLogic({
  type: GET_INVOICES_SHIPMENTS_PENDING,
  latest: true,

  processOptions: {
    dispatchMultiple: true
  },

  process({ action, httpClient, getState }, dispatch, done ) {
    const { orderNumber } = action?.payload;
    const tableName = 'invoicesShipments';
    const invoiceRef = ['orders', tableName, orderNumber];

    const state = getState();
    const intl = state.intl.messages;
    const rows = state.app.orders.getIn(['invoicesShipments', orderNumber, 'rows']);

    dispatch(tableUpdateSettings({ tableName, settings: { isLoading: true } }));

    return (
      httpClient(dispatch).get(`/orders/${orderNumber}/invoices`)
        .then(({ data }) => ({
            rows: data?.map(invoice => ({
                ...invoice,
                invoiceDate: moment(`${invoice?.invoiceDate}`, 'YYYY-MM-DD').format('M/D/YYYY'),
                trackingNumbers: Object.keys(invoice?.trackingNumbers || {}).map(num => ({ shippingTrackingId: num, shippingType: invoice.trackingNumbers[num] })),
                invoiceTotal: formatMoney(invoice?.totalAmount)
              })),
            totalItems: data?.length || 0
        }))
        .then(invoices => {
          if (!rows) {
            dispatch(getInvoicesShipmentsSuccess({ invoiceRef, data: invoices }));
          }
          dispatch(tableUpdateSettings({ tableName, settings: { isLoading: false } }));
          done();
        })
        .catch(({ response }) => {
          const notFound = response?.status === 404;

          const data = {
            message: notFound ? intl.Errors.OrderHistory.GET_Invoices_Shipments_Processing : intl.Errors.OrderHistory.Get_Invoices_Shipments_Error,
            type: notFound ? 'plain' : 'error',
            location: 'invoiceTable'
          };

          dispatch(setBannerState({ data }));
          done();
        })
    );
  }
});

export default [
  getInvoicesShipmentsPendingLogic
];
