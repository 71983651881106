import styled from 'styled-components';
import css from '../../../../../css/css-variables';

// requires a "position: relative" parent element
export const OverLayMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${css.colors.Background};
  border-radius: 2px;
  border: 1px solid #E0E0E5;
  padding: 16px;
  z-index: 1;
`;

export default OverLayMessage;
