import PropTypes, { oneOf } from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import cssVars from '../../../css/css-variables';

const Anchor = styled.a`
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const Button = props => {
  const {
    id,
    className,
    label,
    disabled,
    onClick,
    onKeyUp,
    onFocus,
    onBlur,
    selfFocus,
    children,
    href,
    to,
    'data-qa-hook': dataQaHook,
    dataQaBtn,
    variant = 'primary',
    style,
    target,
    rel,
    ...remainingProps
  } = props;

  const type = props.type ? props.type : 'button';
  const btnProps = { id, type, onClick, onKeyUp, onFocus, onBlur, style };
  const focusButton = el => {
    el && selfFocus && el.focus();
  };
  const qaHook = dataQaHook ? { 'data-qa-hook': dataQaHook } : null;
  const qaBtnHook = dataQaBtn
    ? { [`data-${dataQaBtn.label}-button`]: dataQaBtn.value }
    : null;

  const attrs = {
    button: {
      ref: focusButton,
      ...qaHook,
      ...qaBtnHook,
      ...remainingProps
    }
  };
  const descendants = label || children;

  let Wrapper = React.Fragment;

  if (href) {
    Wrapper = ({ children }) => <Anchor href={href} rel={rel} target={target}>{children}</Anchor>;
  } else if (to) {
    Wrapper = ({children}) => <Link to={to}>{children}</Link>;
  }

  if (variant === 'purchase') {
    return (
      <Wrapper>
        <PurchaseButton
          className={className}
          disabled={disabled}
          {...attrs.button}
          {...btnProps}
        >
          {descendants}
        </PurchaseButton>
      </Wrapper>
    );
  } else if (variant === 'default') {
    return (
      <Wrapper>
        <DefaultButton
          className={className}
          disabled={disabled}
          {...attrs.button}
          {...btnProps}
        >
          {descendants}
        </DefaultButton>
      </Wrapper>
    );
  } else if (variant === 'warning') {
    return (
      <Wrapper>
        <WarningButton
          className={className}
          disabled={disabled}
          {...attrs.button}
          {...btnProps}
        >
          {descendants}
        </WarningButton>
      </Wrapper>
    );
  } else if (variant === 'inverted') {
    return (
      <Wrapper>
        <InvertedButton
          className={className}
          disabled={disabled}
          {...attrs.button}
          {...btnProps}
        >
          {descendants}
        </InvertedButton>
      </Wrapper>
    );
  } else if (variant === 'secondary') {
    return (
      <Wrapper>
        <SecondaryButton
          className={className}
          disabled={disabled}
          {...attrs.button}
          {...btnProps}
        >
          {descendants}
        </SecondaryButton>
      </Wrapper>
    );
  } else if (variant === 'outline') {
    return (
      <Wrapper>
        <OutlineButton
          className={className}
          disabled={disabled}
          {...attrs.button}
          {...btnProps}
        >
          {descendants}
        </OutlineButton>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <PrimaryButton
          className={className}
          disabled={disabled}
          {...attrs.button}
          {...btnProps}
        >
          {descendants}
        </PrimaryButton>
      </Wrapper>
    );
  }
};

const { arrayOf, string, func, bool, node } = PropTypes;

Button.propTypes = {
  id: string,
  'data-qa-hook': string,
  label: string,
  type: string,
  onClick: func,
  onKeyUp: func,
  onFocus: func,
  onBlur: func,
  styles: arrayOf(string),
  disabled: bool,
  selfFocus: bool,
  children: node,
  href: string,
  variant: oneOf([
    'primary',
    'purchase',
    'secondary',
    'warning',
    'default',
    'inverted'
  ])
};

export default Button;

const baseStyles = `
  border-radius: 30px;
  user-select: none;
  padding: 2px 24px;
  min-height: 38px;
  font-size: 16px;
`;

const PrimaryButton = styled.button`
  ${baseStyles}

  background-color: #476692;
  color: white;
  border: none;

  :hover {
    background-color: #284362;
  }
`;

const PurchaseButton = styled.button`
  ${baseStyles}

  background-color: #fdb528;
  color: #414042;
  font-weight: 600;
  border: none;

  :hover {
    background-color: #FDA905;
  }
`;

const DefaultButton = styled.button`
  ${baseStyles}

  border: none;
  color: #414042;
  background-color: #eeeeee;

  :hover {
    background-color: #dcdcdc;
  }
`;

const WarningButton = styled.button`
  ${baseStyles}

  border: none;
  color: white;
  background-color: #a6403e;

  :hover {
    background-color: #8c3634;
  }
`;

const InvertedButton = styled.button`
  ${baseStyles}

  background-color: white;
  color: #414042;
  transition: box-shadow 0.15s ease-out;
  border: none;

  :hover {
    box-shadow: inset 0px 0px 0px 2px black;
  }
`;

const OutlineButton = styled.button`
  ${baseStyles}

  border-radius: 50px;
  color: ${cssVars.colors.DarkGray};
  border: 2px solid ${cssVars.colors.DarkGray};
  background-color: white;

  :hover {
    outline: 1px solid black;
  }
`;

const SecondaryButton = styled.button`
  ${baseStyles}

  background-color: white;
  color: #414042;
  transition: border-color 0.15s ease-out;
  border: 2px solid #476692;

  :hover {
    border-color: #fdb528;
  }
`;

