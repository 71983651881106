import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { browserName, browserVersion, isIE, isSafari } from 'react-device-detect';
import styled from 'styled-components';
import LogoIcon from '../../../../img/sprite/my-lw-logo.svg';
import MyLifewayLogo from '../../../../img/sprite/logo-mylifeway-tm.svg';
import CartIcon from '../../../../img/sprite/cart-icon.svg';
import HeaderLinks from './HeaderLinks';
import AlertIcon from '../../../../img/sprite/alert-circle.svg';
import { useViewport } from '../../hooks/viewport';
import { ENABLE_MOBILE_DRAWER, ENABLE_REBRANDING_PHASE_1, LIFEWAY_COM_URL } from '../../constants';
import css from '../../../../css/css-variables';
import HeaderBreadCrumbNav from './HeaderBreadCrumbNav';
import { connect } from 'react-redux';
import ViewAccountsModal from './account-menu/ViewAccountsModal';
import { headerData } from './headerData';
import { MobileDrawer } from '@lifewayit/components/dist/lib';
import { getManageACHPaymentMethodsAccess, getManageShippingAddressesAccess } from '../../selectors/OrgManagementSelectors';
import { getOrganizationIdFromPath } from '../../utils/pathHelpers';

const LogoLink = styled(Link)`
  flex: 0 0 auto;

  width: 12.875em;
  margin-left: 1.875em;
  margin-top: 0.438em;

  @media(max-width: 720px) {
    width: 6.375em;
    margin-left: 0.438em;
    margin-top: 0.563em;
    display: inline-block;
  }
`;

const Header = styled.header`
  border-bottom-style: solid;
  border-bottom-color: ${css.colors.AccentGray};
  border-bottom-width: 2px;
  flex: 0 ${css.header.Height};
  height: ${css.header.Height};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  background-color: #fff;

  font-family: sofia-pro, "helvetica neue", helvetica, arial, sans-serif;

  @media(max-width: 720px) {
    width: ${ENABLE_MOBILE_DRAWER ? 'calc(100% - 40px)' : '100%'};
    flex: none;
    border-bottom-width: 0px;
    height: auto;
    padding-bottom: 0.438em;
    z-index: 1;
    align-self: flex-start;
  }
`;

const Svg = styled.svg`
  height: 4.688em;
  width: 12.875em;
  background-color: transparent;

  @media(max-width: 720px) {
    width: 100%;
    height: 1.750em;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  margin-right: 1.87rem;
  @media(max-width: 720px) {
    align-items: end;
    margin-right: 0;
  }
`;

const CartSvg = styled.svg`
  min-height: 28px;
  min-width: 28px;
  margin-right: 0.75rem;
  color: #818181;

  a:hover & {
    color: #414042;
  }
`;


const WrapperBreadCrumb = styled.div`
  position: relative;
`;

const SvgBreadCrumb = styled.svg`
  transform: rotate(-90deg);
  color: #dcdcdc;
  position: absolute;
  top: -0.625em;
  right: 1.250em;
  z-index: 1;
`;

const AlertSvg = styled.svg`
  position: relative;
  bottom: 0.1em;
  right: 0.2em;
`;

const Banner = styled.div`
  padding: 0.5em;
  background-color: #f3dddf;
  color: #b54a42;
  text-align: center;
`;

const HeaderWrapper = styled.div`
  @media(max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
`;

const UnsupportedBrowserMessage = () => {
  return (
    <Banner>
      <AlertSvg><use xlinkHref={`#${AlertIcon.id}`} /></AlertSvg>
      <strong>{browserName} {browserVersion}</strong> is not a supported browser. Please use a <a href="https://browsehappy.com" target="_blank" without rel="noreferrer">recommended browser</a> to improve your overall experience and security.
    </Banner>
  );
};

const HeaderComponent = props => {
  const {
    isLoggedIn,
    location,
    toggleNavPanel,
    customer,
    onLogOut,
    openedNavPanel,
    referredBy,
    breadCrumbs,
    linkedOrganizations, 
    linkedOrgsPending,
    orgId,
  } = props;

  const { isMobile } = useViewport();
  
  const isUnsupportedBrowser = isIE || (isSafari && browserVersion < 11);
  
  const [navClicked, setNavClicked] = useState(false);
  const [viewAccountsModal, setViewAccountsModal] = useState(false);
  const [orgsLoading, setOrgsLoading] = useState(false);
  
  useEffect(() => {
		if (linkedOrgsPending === 'idle') {
			setOrgsLoading(true);
		} else if (linkedOrgsPending === 'pending') {
			setOrgsLoading(true)
		} else if (linkedOrgsPending === 'success') {
			setOrgsLoading(false)
		} else if (linkedOrganizations.length === 0) {
			setOrgsLoading(false)
		} else {
      setOrgsLoading(false)
    }
	}, [linkedOrgsPending, linkedOrganizations]);

  const wrapperClass = "absolute -top-8 right-2 -mt-1.5";

  return (
    <HeaderWrapper className="header">
      {isUnsupportedBrowser && <UnsupportedBrowserMessage />}
      <Header>
        <LogoLink to="/" data-qa-hook="myLifeway.logo">
          <Svg>
            <use xlinkHref={`#${ENABLE_REBRANDING_PHASE_1 ? MyLifewayLogo.id : LogoIcon.id}`} />
          </Svg>
        </LogoLink>
        <MenuContainer>
          <HeaderLinks
            isMobile={isMobile}
            isLoggedIn={isLoggedIn}
            onLogOut={onLogOut}
            toggleNavPanel={toggleNavPanel}
            openedNavPanel={openedNavPanel}
            path={location.pathname}
            customer={customer}
            linkedOrganizations={linkedOrganizations}
            loading={orgsLoading}
            setViewAccountsModal={() => setViewAccountsModal(!viewAccountsModal)}
            navClicked={navClicked}
            setNavClicked={setNavClicked}
            orgId={orgId}
          />
          <a
            href={`${LIFEWAY_COM_URL}/en/checkout/shopping-cart`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CartSvg><svg><use href={`#${CartIcon.id}`} xlinkHref={`#${CartIcon.id}`} /></svg></CartSvg>
          </a>
        </MenuContainer>
      </Header>

      {/* TODO - This component will be added back at a later date */}
      {ENABLE_MOBILE_DRAWER && isMobile && <MobileDrawer headerData={headerData} menuWrapperClassName={wrapperClass}/>}

      {viewAccountsModal && (
        <ViewAccountsModal
          show={viewAccountsModal}
          onClose={() => setViewAccountsModal(false)}
          linkedOrganizations={linkedOrganizations}
          setNavClicked={setNavClicked}
        />
      )}

      {!isMobile && isLoggedIn && openedNavPanel === null && (
        <HeaderBreadCrumbNav breadCrumbs={breadCrumbs} />
      )}
      {isMobile && isLoggedIn && (
        <HeaderBreadCrumbNav breadCrumbs={breadCrumbs} />
      )}
    </HeaderWrapper>
  );
};

function mapStateToProps(state) {
  const { app } = state;
  const organizationId = getOrganizationIdFromPath(state.router.location.pathname);
  const hasManageACHPaymentMethodsAccess = getManageACHPaymentMethodsAccess(state, organizationId);
  const hasManageShippingAddressesAccess = getManageShippingAddressesAccess(state, organizationId);
  return {
    linkedOrganizations: app.getIn(['currentUser', 'linkedOrgs']).toJS(),
    linkedOrgsPending: app.getIn(['currentUser', 'linkedOrgsLoading']),
    hasManageACHPaymentMethodsAccess: hasManageACHPaymentMethodsAccess,
    hasManageShippingAddressesAccess: hasManageShippingAddressesAccess,
  };
};

const { object, bool, func, string, array } = PropTypes;

HeaderComponent.propTypes = {
  linkedOrganizations: array,
  linkedOrgsPending: string,
  isLoggedIn: bool.isRequired,
  location: object.isRequired,
  openedNavPanel: string,
  toggleNavPanel: func.isRequired,
  referredBy: string,
  onLogOut: func
};

export default connect(mapStateToProps)(HeaderComponent);
