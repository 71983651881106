import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getLinkedOrgs,
  getSelectedProfileId,
} from '../../../selectors/OrgManagementSelectors';
import { getInitials } from '../../../utils/profileHelpers';
import ProfileLink from './ProfileLink';
import RepeatIcon from '../../../../../img/sprite/repeat-icon.svg';
import {
  ORG_PATHS,
  PATH_PROFILE,
  ENABLE_ORG_MANAGEMENT,
} from '../../../constants';
import { array, func, object } from 'prop-types';

const ProfilesList = ({ linkedOrganizations = [], user, onLinkClick }) => {
  const userName = user.firstName
    ? `${user.firstName}'s Profile`
    : 'Your Profile';

  return (
    <ProfilesContainer>
      <ProfileLink
        fullWidth={true}
        initials={user.initials ?? getInitials(user.firstName)}
        name={userName}
        onClick={onLinkClick}
        to={PATH_PROFILE}
      />

      {linkedOrganizations.length > 0 && ENABLE_ORG_MANAGEMENT && (
        <>
          <SwitchLabel>
            <svg>
              <use href={`#${RepeatIcon.id}`} />
            </svg>
            Switch Profiles
          </SwitchLabel>
          {linkedOrganizations.map(org => (
            <ProfileLink
              fullWidth={true}
              key={org.organizationId}
              initials={org.initials || getInitials(org.organizationName)}
              name={org.organizationName}
              onClick={onLinkClick}
              to={ORG_PATHS.PROFILE.replace(
                ':organizationId',
                org.organizationId
              )}
            />
          ))}
        </>
      )}
    </ProfilesContainer>
  );
};

const mapStateToProps = state => {
  return {
    user: {
      id: state.app.getIn(['currentUser', 'id']),
      firstName: state.app.getIn(['currentUser', 'firstName']),
      initials: state.app.getIn(['currentUser', 'initials']),
      initialsColor: state.app.getIn(['currentUser', 'initialsColor']),
    },
    linkedOrganizations: getLinkedOrgs(state),
    selectedProfileId: getSelectedProfileId(state),
    location: state.router.location,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesList);

ProfilesList.propTypes = {
  linkedOrganizations: array,
  onLinkClick: func,
  user: object,
};

const ProfilesContainer = styled.div`
  padding: 1rem 1.5rem;
`;

const SwitchLabel = styled.div`
  margin: 0.8rem 0;
  padding: 0 0.7rem;
  svg {
    margin-right: 1rem;
  }
  color: #414042;
  font-size: 15px;
`;