import {
  PATH_ORG_ACCT_SHOW,
  PATH_ORDER_HISTORY,
  PATH_DIGITAL_CONTENT,
  PATH_EXTERNAL_REDIRECT,
  PCI_FORM_URL,
  ENV_LOCATION,
  PATH_ADD_CREDIT_CARD,
  PATH_ACCOUNTS_ACTIVITY,
  PATH_BILLING_ACTIVITY,
  ENABLE_ORG_MANAGEMENT
} from '../constants';

// OrgManagment paths
export const pathOrgOrderHistory = id => `${PATH_ORG_ACCT_SHOW}/${id}${PATH_ORDER_HISTORY}`;
export const pathOrgDigitalContent = id => `${PATH_ORG_ACCT_SHOW}/${id}${PATH_DIGITAL_CONTENT}`;
export const pathOrgAccountActivity = id => `${PATH_ORG_ACCT_SHOW}/${id}${PATH_ACCOUNTS_ACTIVITY}`;
export const pathOrgBillingActivity = id => `${PATH_ORG_ACCT_SHOW}/${id}${PATH_BILLING_ACTIVITY}`;

// gets billing id to create correct link for order-history & account-activity //
export const regExBillingAccountPathHelper = (path, newRoute) => {
  const billingId = path.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/);
  return `/org-management/billing-account/${billingId}/${newRoute}`;
};

// Generate PCI form url, based of payment-sign-in-token, for adding new credit card
export const addNewCreditCard = token => `${PCI_FORM_URL}/#/submit-credit-card/?redirect=${ENV_LOCATION}${PATH_ADD_CREDIT_CARD}&token=${token}`;

export const getBillingAcctIdFromPath = pathname => ENABLE_ORG_MANAGEMENT ? pathname.split('/')[4] : pathname.split('/')[3];

export const getOrganizationIdFromPath = pathname => pathname.split('/')[2];

export const resourceRedirectQuery = itemNumber => `${PATH_EXTERNAL_REDIRECT}?itemNumber=${itemNumber}`;

// pass in location
export const getLastExtension = ({ pathname }) => pathname.split('/').pop();

// inject path parameters into route pattern
export const injectParams = (route, params) =>
  Object.entries(params).reduce(
    (routePattern, [paramName, paramValue]) =>
      routePattern.replace(`:${paramName}`, paramValue),
    route
  );
