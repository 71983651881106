import { createLogic } from 'redux-logic';
import braintree from 'braintree-web';
import {
  CREATE_BRAINTREE_CLIENT_INSTANCE_PENDING,
  TOKENIZE_BRAINTREE_BANK_ACCOUNT_PENDING
} from '../constants';
import {
  createBraintreeClientInstanceSuccess,
  createBraintreeClientInstanceError,
  tokenizeBraintreeBankAccountPending,
  submitAchInvoicePaymentPending,
  addACHPaymentMethodPending,
  tokenizeBraintreeBankAccountError,
  tokenizeBraintreeBankAccountSuccess,
} from '../actions/AppActions';

export const createBraintreeClientInstanceLogic = createLogic({
  type: CREATE_BRAINTREE_CLIENT_INSTANCE_PENDING,
  latest: true,

  process({ action, httpClient, getState }, dispatch, done) {
    const { setSubmitting } = action.payload;
    const state = getState();
    const intl = state.intl.messages;

    setSubmitting(true);
    httpClient(dispatch).get('/braintree-token')
      .then(res => {
        const token = res?.data?.message;
        braintree.client.create({ authorization: token })
          .then(clientInstance => {
            return braintree.usBankAccount.create({ client: clientInstance });
          })
          .then(usBankAccountInstance => {
            dispatch(createBraintreeClientInstanceSuccess());
            dispatch(tokenizeBraintreeBankAccountPending({ usBankAccountInstance, ...action.payload }));
            done();
          })
          .catch(_ => {
            setSubmitting(false);
            dispatch(createBraintreeClientInstanceError(intl.Errors.BillingAccs.ACH_Payment_Error));
            done();
          });
      }).catch(_ => {
        setSubmitting(false);
        dispatch(createBraintreeClientInstanceError(intl.Errors.BillingAccs.ACH_Payment_Error));
        done();
      });
  }
});

export const tokenizeBraintreeBankAccountLogic = createLogic({
  type: TOKENIZE_BRAINTREE_BANK_ACCOUNT_PENDING,
  latest: true,

  process({ action, getState }, dispatch, done) {
    const { usBankAccountInstance, bankDetails, submitData, setSubmitting, resetForm, handleCancel, mandateText, personProfileId, setBankAccountCreated = '', bannerLocation } = action.payload;
    const state = getState();
    const intl = state.intl.messages;

    usBankAccountInstance.tokenize({
      bankDetails,
      mandateText
    }).then(braintreeResp => {
      dispatch(tokenizeBraintreeBankAccountSuccess());
      submitData.paymentAmount
        ? dispatch(
            submitAchInvoicePaymentPending({
              submitData: { ...submitData, paymentMethodNonce: braintreeResp.nonce },
              setSubmitting,
              resetForm,
            })
          )
        : dispatch(
            addACHPaymentMethodPending({
              submitData: { ...submitData, paymentMethodNonce: braintreeResp.nonce },
              setSubmitting,
              resetForm,
              personProfileId,
              setBankAccountCreated,
              bannerLocation,
              handleCancel
            })
          );
      done();
    }).catch(e => {
      setSubmitting(false);
      setBankAccountCreated(false);
      try {
        dispatch(tokenizeBraintreeBankAccountError(e.details.originalError.details.originalError[0].message));
      } catch (_) {
        dispatch(tokenizeBraintreeBankAccountError(intl.Errors.BillingAccs.ACH_Payment_Error));
      }
      done();
    });
  }
});

export default [
  createBraintreeClientInstanceLogic,
  tokenizeBraintreeBankAccountLogic
];
