import phoneFormats from '../mocks/phoneFormats.json';
import cData from 'country-telephone-data';
import { inputMask, onlyNumbers } from './formUtils';

export function getFormatPatternByCountryCode(countryCode) {
  let pattern = '(***) ***-****';
  if (countryCode === '1') return pattern;

  for (let i = 0; i < cData.allCountries.length; i += 1) {
    if (cData.allCountries[i].dialCode === countryCode) {
      pattern = cData.allCountries[i].format || '+*** ';
      pattern = pattern.replace(new RegExp('\\.', 'g'), '*');
      pattern = pattern.replace(new RegExp('\\(', 'g'), ' (');
      pattern = pattern.replace(new RegExp('\\)', 'g'), ') ');
      pattern = pattern.replace(new RegExp('\\s+', 'g'), ' ');
      break;
    }
  }
  return pattern;
}

export function getCountryCodeByISO(iso2) {
  let countryCode = '';
  for (let i = 0; i < phoneFormats.countries.length; i += 1) {
    if (phoneFormats.countries[i].iso2 === iso2.toLowerCase()) {
      countryCode = phoneFormats.countries[i].cc;
      break;
    }
  }
  return countryCode;
}

export function getFormattedPhoneNumber(phoneNumber, pattern) {
  return inputMask(phoneNumber, pattern);
}

export function getPhoneNumberParts(phoneNumber) {
  let countryCode = phoneNumber.split('-') || '';
  countryCode = countryCode[0] && countryCode[0].split(' ')[0] || '';
  countryCode = onlyNumbers(countryCode);

  let number = onlyNumbers(phoneNumber);
  number = number.substring(countryCode.length);
  return { countryCode, number };
}

export function formatForEditing(countryCode, phoneNumber) {
  const numberString = (countryCode === '1') ? phoneNumber : `${countryCode}${phoneNumber}`;
  const pattern = getFormatPatternByCountryCode(countryCode);
  let number = getFormattedPhoneNumber(numberString, pattern);
  number = number.replace('+1 (', '(');
  return number;
}

export function formatPhoneNumberForSubmit(countryCode, phoneNumber) {
  if (countryCode === '1') return onlyNumbers(phoneNumber);
  return getPhoneNumberParts(phoneNumber).number;
}
