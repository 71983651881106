import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import MessageBanner from './MessageBanner';
import LoadingAnimation from './LoadingAnimation';

const Container = styled.div`
  margin: 80px 10px;
`;
Container.displayName = 'Container';

const LoadingCmpt = ({ pastDelay, timedOut, error }) => {
  if (!pastDelay) {
    return null;
  } else if (timedOut) {
    return <MessageBanner error message="Something went wrong" />;
  } else if (error) {
    return <MessageBanner error message="Something went wrong" />;
  }

  return <LoadingAnimation />;
};

LoadingCmpt.propTypes = {
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool,
  error: PropTypes.any
};

LoadingCmpt.defaultProps = {
  pastDelay: true,
  timedOut: false,
};

export default LoadingCmpt;
