import axios from 'axios';
import { PATH_LOGIN_REDIRECT } from '../constants';

export const client = axios.create({
  withCredentials: true,
  timeout: 30000,
});

export const setDefaultAuthorizationHeader = value => {
  client.defaults.headers.common.Authorization = value;
};

const httpClient = () => {
  client.interceptors.request.use(routeRequest);
  client
    .interceptors
    .response.use(
      (res) => res,
      (error) => {
        if (error?.response?.status === 401) {
          window.location.replace(`${PATH_LOGIN_REDIRECT}?reverify=true`);
        }
        throw error;
      }
    );
  return client;
};

export const routeRequest = (config) => {
  const { url } = config;

  return {
    ...config,
    baseURL: getBaseUrl(url)
  };
};

const getBaseUrl = (url) => {
  if (/^\/invitations/i.test(url)) {
    return '/api-invites';
  }
  return '/api-my';
};

export default httpClient;
