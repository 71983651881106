import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { INTERCOM_APP_ID } from '../constants';
import userManager from '../utils/userManager';

export const IntercomManager = () => {
  const {
    profileLoading,
    linkedOrgsLoading,
    roles,
    name,
    email,
    userId,
    org,
  } = useSelector((state) => ({
    profileLoading: state.app.getIn(['currentUser', 'profileLoading']),
    linkedOrgsLoading: state.app.getIn(['currentUser', 'linkedOrgsLoading']),
    name: state.app.getIn(['currentUser', 'fullName']),
    email: state.app.getIn(['currentUser', 'emailAddress']),
    userId: state.app.getIn(['currentUser', 'id']),
    org: state.app.getIn(['currentUser', 'linkedOrgs']).toJS() || [],
    roles: state.app.getIn(['currentUser', 'linkedUserRoles']).toJS() || [],
  }));

  const linkedOrg = org?.[0] || {};
  const orgRoles = roles?.[0]?.assignedRoles
    ?.filter(
      (role) =>
        role.contextId === linkedOrg?.organizationProfile?.organization?.id &&
        role.name !== 'Billing Account Purchaser'
    )
    ?.map((role) => role?.name?.replace('Organization ', ''))?.toString();
  useEffect(() => {
    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
    });
  }, []);

  useEffect(() => {
    const shutdownIntercom = () => {
      window.Intercom('shutdown');
    };

    userManager.events.addUserSignedOut(shutdownIntercom);

    return () => {
      userManager.events.removeUserSignedOut(shutdownIntercom);
    };
  }, []);

  useEffect(() => {
    if (profileLoading === 'success' && linkedOrgsLoading === 'success') {
      const aomAccountNumber = linkedOrg?.organizationProfile?.billingAccounts?.find(
        (ba) => ba?.active
      )?.accountNumber;
      window.Intercom('update', {
        name,
        email,
        user_id: userId,
        aom_orgId: linkedOrg?.organizationProfile?.organization?.id,
        aom_orgName: linkedOrg?.organizationProfile?.organization?.name,
        aom_accountNumber: aomAccountNumber,
        aom_organizationRoles: orgRoles
      });
    }
  }, [profileLoading, linkedOrgsLoading, name, email, userId, orgRoles, linkedOrg]);

  return null;
};

export const shutdownIntercom = () => {
  window.Intercom('shutdown');
};

export default IntercomManager;
