import { createLogic } from 'redux-logic';
import {
  setBannerState,
  getPaymentInfoPending,
  getPaymentInfoSuccess,
  onError,
} from '../actions/AppActions';
import intl from '../actions/lang/en.json';

export const findPaymentCreditCardByPaymentIdLogic = createLogic({
  type: getPaymentInfoPending().type,
  latest: true,
  process({ httpClient, action: { payload: paymentId } }, dispatch, done) {
    httpClient(dispatch).get(`/payments/${paymentId}/credit-card`)
      .then(res => {
        dispatch(getPaymentInfoSuccess(res.data));
        done();
      })
      .catch(err => {
        dispatch(setBannerState({ data: { type: 'error', message: intl.Errors.PaymentMethods.Cannot_Get_Payment_Info_Error } }));
        dispatch(onError({ err, type: 'fetching.payment.creditcard.error' }));
        done();
      });
  }
});

export default [
  findPaymentCreditCardByPaymentIdLogic,
];
