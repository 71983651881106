import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const invoicePdf = invoiceId => createDeepEqualSelector(
  state => state.app.getIn(['invoicePdfs', invoiceId]),
  res => res
);

export const statementPdf = (accountNumber, date) => createDeepEqualSelector(
  state => state.app.getIn(['statementPdfs', `${accountNumber}-${date}`]),
  res => res
);
