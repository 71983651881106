import React from 'react';
import { PaymentTypes } from './SelectPaymentMethodModal';
import SelectPaymentMethodCard from './SelectPaymentMethodCard';

const SelectPaymentMethodTypes = ({creditCards, billingAccs, activeCard, setActiveCard, subCreditCard, isImpersonationSession, selectedPaymentType }) => {

	if(selectedPaymentType === PaymentTypes.CREDITCARD){
		return(
			<>
				{creditCards?.map(creditCard => (
				<>
					<SelectPaymentMethodCard
						creditCard={creditCard}
						key={creditCard?.id}
						isDefault={false}
						activeCard={activeCard}
						setActiveCard={setActiveCard}
						isCreditCard={true}
						isImpersonationSession={isImpersonationSession}
						subCreditCard={subCreditCard}
					/>
				</>
				))}
			</>
		);}
	if(selectedPaymentType === PaymentTypes.BILLINGACCOUNT){
		return(
			<>
				{billingAccs && billingAccs?.map(billingAcc => (
					<SelectPaymentMethodCard
						billingAcc={billingAcc}
						key={billingAcc?.id}
						isDefault={false}
						isCreditCard={false}
						activeCard={activeCard}
						setActiveCard={setActiveCard}
						isImpersonationSession={isImpersonationSession}
					/>
				))}
			</>
		);}
};

export default SelectPaymentMethodTypes;
