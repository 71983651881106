/* eslint-disable no-case-declarations */
import { fromJS, Record, Map, List } from 'immutable';
import bowser from 'bowser';
import * as Constants from '../constants';
import { getPageTitlesFromMenu } from '../components/Menu';
import { addDigitalMediaImages, addSimulcastImages, addOrderImages, addOrderHistoryImages } from '../utils/digitalMediaHelpers';
import AutoshipReducer, { AutoshipStateRecord } from './app/AutoshipReducer';
import { setIsQuarterlyAutoshipEligible } from '../utils/productUtils';
import { addressContainsAutoship, findAutoshipInAddresses } from '../utils/autoshipUtils';
import { uniqBy } from 'lodash';
import { getBillingAccountNumberFromGroupName } from '../utils/orgHelpers';

const breakPoints = {
  md: window.innerWidth < 500,
};

const currentUser = {
  id: undefined,
  birthDate: undefined,
  emailAddress: undefined,
  firstName: undefined,
  gender: undefined,
  lastName: undefined,
  phones: [],
  addresses: [],
  shippingAddresses: [],
  paymentMethods: {
    creditCards: [],
    paypal: [],
    // look up billingAccs by their id
    billingAccs: [],
    defaultPaymentMethod: undefined
  },
  linkedOrgs: [],
  linkedUserRoles: [],
  linkedOrgsLoading: 'idle',
  linkedOrgGroupsLoading: 'idle',
  profileLoading: 'idle',
  linkedOrgsMemberRoles: new Map({
    administrator: new List([]),
    organizationPurchaser: new List([]),
    billingAccountPurchaser: new List([]),
    organizationBillingManager: new List([]),
    organizationMembers: new List([]),
  }),
  linkedOrgMemberRolesLoading: false,
  roleActionPending: false,
  customerPermissions: new List([]),
  vodDevices: [],
  publicKeys: []
};


export const getWindowSize = () => new Map({
  width: window.innerWidth,
  height: window.innerHeight
});

export const initTableState = (settings = {}) => new Map({
  isLoading: settings.isLoading || false,
  rowsPerPage: settings.rowsPerPage || 10,
  currentPage: settings.currentPage || 1,
  columns: new Map([])
});

export const SubscriptionStateRecord = Record({
    personal: defaultSubscriptionState,
    org: new Map({}),
    details: new Map({})
  });

  const defaultSubscriptionState = new Map({
    loading: false,
    error: null,
    data: null
  });
  

const isImpersonationSessionLocalStorage = localStorage.getItem(Constants.IS_IMPERSONATION_SESSION);

export const initTableShape = () => fromJS({ totalRows: null, rows: [], loading: false });

const initBanner = () => {
  if (bowser.check({ safari: '9' })) {
    return new Map({ message: null, type: null });
  }
  return new Map({ message: 'This browser is not fully supported. Please download Google Chrome', type: 'WARN' });
};

export const AppStateRecord = Record({
  error: new Map(),
  globalError: null,
  breakPoints: new Map(breakPoints),
  nav: new Map({
    openPanel: null,
  }),
  referredBy: sessionStorage.getItem('referrer') || '',
  window: getWindowSize(),
  pageTitles: new Map({}),
  isLoggedIn: null,
  isImpersonationSession: !!isImpersonationSessionLocalStorage,
  ssoReadyState: false,
  currentForm: null,
  processingItem: null,
  modal: new Map({ show: false }),
  currentUser: fromJS(currentUser),
  selectedProfile: new Map({
    id: null,
    initials: null,
    color: null,
    label: null
  }),
  banner: initBanner(),
  countryData: new Map({
    countries: [],
    states: new Map({}),
    militaryLocations: [],
  }),
  addressSuggestions: new Map([]),
  currentGiftCard: new Map({}),
  tableSettings: new Map({
    processedOrders: initTableState(),
    pendingOrders: initTableState(),
    orders: initTableState({ isLoading: true }),
    orgOrders: initTableState({ isLoading: true }),
    processedOrdersOrg: initTableState({ isLoading: true }),
    pendingOrdersOrg: initTableState({ isLoading: true }),
    orderItems: fromJS({ 'default': initTableState() }),
    orderItemsOrg: fromJS({ 'default': initTableState() }),
    orderSearch: initTableState(),
    invoicesShipments: initTableState(),
    allTransactions: initTableState({ isLoading: true }),
    openInvoices: initTableState({ isLoading: true }),
    creditTransactions: initTableState({ isLoading: true }),
    giftCardPurchases: initTableState(),
    groupMembersAdministrators: initTableState(),
    groupMembersBillingManagers: initTableState(),
    groupMembersPurchasers: initTableState(),
    // Digital Media Table Settings
    multipleDownloads: initTableState({ rowsPerPage: 100 }),
    downloads: initTableState({ isLoading: true }),
    ebooks: initTableState({ isLoading: true }),
    otherContent: initTableState({ isLoading: true }),
    videos: initTableState({ isLoading: true }),
    simulcasts: initTableState({ isLoading: true }),
    memberships: initTableState({ isLoading: true }),
    downloadsSearch: initTableState(),
    ebooksSearch: initTableState(),
    otherContentSearch: initTableState(),
    videosSearch: initTableState(),
    simulcastsSearch: initTableState(),
    membershipsSearch: initTableState(),
    licenseAccess: initTableState()
  }),
  orderDetail: new Map({}),
  orders: new Map({
    personal: fromJS({ totalItems: null, rows: [] }),
    personalPending: fromJS({ totalItems: null, rows: [] }),
    user: fromJS({ totalItems: null, rows: [] }),
    organization: new Map({}),
    org: new Map({}),
    orgPending: new Map({}),
    invoicesShipments: new Map({}),
    isOrderImagesLoading: false
  }),
  productReplacements: new Map({
    status: 'initial',
    data: new List([])
  }),
  digitalMedia: new Map({
    downloads: initTableShape(),
    multipleDownloads: initTableShape(),
    ebooks: initTableShape(),
    otherContent: initTableShape(),
    videos: initTableShape(),
    simulcasts: initTableShape(),
    memberships: initTableShape(),
    downloadsSearch: initTableShape(),
    multipleDownloadsSearch: initTableShape(),
    ebooksSearch: initTableShape(),
    otherContentSearch: initTableShape(),
    videosSearch: initTableShape(),
    simulcastsSearch: initTableShape(),
    membershipsSearch: initTableShape(),
    childProducts: initTableShape(),
  }),
  licenses: new Map({
    loading: false,
    error: false,
    data: new List(),
    ebooks: new List(),
    simulcasts: new List(),
    videos: new List(),
    singleLicense: new Map({
      loading: true,
      error: false,
      revokeError: false
    })
  }),
  // Billing account invoices table rows
  accountTransactions: new Map({}),
  accountBalances: new Map({}),
  achPayment: new Map({
    successfulPayment: {},
    error: undefined
  }),
  achPaymentMethods: new Map({
    data: [],
    loading: true,
    error: undefined
  }),
  giftCardPurchases: new Map({}),
  language: (navigator.language || navigator.browserLanguage).split('-')[0],
  redirectURL: null,
  redeemCodes: new Map([]),
  statementDates: new Map({}),
  searchResults: new Map({
    orderSearch: new Map({})
  }),
  mediaLoaded: false,
  paymentToken: null,
  newCreditCard: new Map({}),
  formState: new Map({
    searchOrders: new Map({}),
    searchOrdersOrg: new Map({})
  }),
  subscriptions: new Map({
    active: new List([]),
    inactive: new List([]),
    suspended: new List([]),
    orgActive: new List([]),
    orgInactive: new List([]),
    orgSuspended: new List([]),
    loadingReactivate: false,
    details: new Map({})
  }),
  subscriptionsLoaded: false,
  orgSubscriptionsLoaded: false,
  autoships: new AutoshipStateRecord(),
  invoicePdfs: new Map({}),
  statementPdfs: new Map({}),
  products: new Map({}),
  mediaContentChildren: new Map({}),
  users: new Map({}),
  addressesBeingProcessed: new List([]),
  breadCrumbs: new List([]),
  contentfulLinks: new List([])
});

export const defaultState = new AppStateRecord();
const adminRole = Constants.ROLES.find(role => role.name === 'Administrator').roleId;
const orgPurchaserRole = Constants.ROLES.find(role => role.name === 'Organization Purchaser').roleId;
const baPurchaserRole = Constants.ROLES.find(role => role.name === 'Billing Account Purchaser').roleId;
const orgBillingManagerRole = Constants.ROLES.find(role => role.name === 'Organization Billing Manager').roleId;

const getPageTitles = (payload) => {
  const pageTitles = getPageTitlesFromMenu(payload);
  return pageTitles;
};

// Map over cards and generate lastFour field
const addLastFour = (cards) => (
  cards.map((card) => (
    {
      ...card,
      lastFour: card.cardNumberMask.slice(-4)
    }
  ))
);

export default function (state = defaultState, action) {
  return AppOnlyReducer(state, action)
    .update('autoships', autoshipState => AutoshipReducer(autoshipState, action));
}

export function AppOnlyReducer(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case Constants.APP_ON_INIT:
      return state.merge(fromJS(payload.app));

    case Constants.APP_ON_RESIZE:
      return state.set('window', getWindowSize());

    case Constants.APP_NAV_TOGGLE: {
      const { panelName, open } = payload;
      const currentOpenPanel = state.getIn(['nav', 'openPanel']);

      if (panelName != null & open != null) {
        // open/close a specific panel
        if (open) {
          return state.setIn(['nav', 'openPanel'], panelName);
        } else if (currentOpenPanel != panelName) {
          return state;
        } else {
          return state.setIn(['nav', 'openPanel'], null);
        }
      } else if (panelName == null && open != null) {
        // open/close the whole nav panel
        if (!open) {
          return state.setIn(['nav', 'openPanel'], null);
        } else {
          return state;
        }
      } else if (panelName != null) {
        // toggle specific panel
        if (panelName === currentOpenPanel) {
          return state.setIn(['nav', 'openPanel'], null);
        } else {
          return state.setIn(['nav', 'openPanel'], panelName);
        }
      } else {
        // toggle whole nav panel
        return state.setIn(['nav', 'openPanel'], null);
      }
    }

    case Constants.SSO_READY_STATE:
      return state.set('ssoReadyState', true);

    case Constants.SET_IS_IMPERSONATION_SESSION:
      return state.set('isImpersonationSession', payload.isImpersonationSession);

    case Constants.LOGGED_IN_TO_TRUE:
      return state.set('isLoggedIn', true);

    case Constants.LOGGED_IN_TO_FALSE:
      return state.set('isLoggedIn', false);

    case Constants.APP_LOG_OUT_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.APP_ON_ERROR:
      return state.set('error', fromJS(payload?.data));

    case Constants.APP_UPDATE_BREAKPOINTS:
      return state.set('breakPoints', fromJS(payload));

    case Constants.APP_TOGGLE_FORM:
      return state.set('currentForm', payload);

    case Constants.APP_TOGGLE_MODAL:
      return state.setIn(['modal', 'show'], !state.getIn(['modal', 'show']));

    case Constants.APP_TRIGGER_MODAL:
      return state.set('modal', fromJS(payload));

    case Constants.PLACEHOLDER_SET_PAGE_TITLES:
      return state.set('pageTitles', fromJS(getPageTitles(payload)));

    case Constants.FETCH_USER_DATA_PENDING:
      return state
        .mergeIn(['currentUser'], fromJS({ loading: true, fetchError: false }))
        .setIn(['currentUser', 'profileLoading'], 'pending');

    case Constants.FETCH_USER_DATA_SUCCESS:
      if ('digitalData' in window) digitalData.user.profile = payload.data;
      return state.mergeIn(['currentUser'], fromJS(payload))
        .mergeIn(['currentUser'], fromJS({ loading: false }))
        .setIn(['currentUser', 'profileLoading'], 'success');

    case Constants.SET_SELECTED_PROFILE:
      return state.set('selectedProfile', fromJS(payload));

    case Constants.FETCH_USER_DATA_ERROR:
      return state.set('error', fromJS(payload))
        .mergeIn(['currentUser'], fromJS({ loading: false, fetchError: true }))
        .setIn(['currentUser', 'profileLoading'], 'error');

    case Constants.PROFILE_ADD_PHONE_SUCCESS:
      return state.updateIn(['currentUser', 'phones'], immutablePhones => {
        const phoneNumbers = immutablePhones?.toJS() || [];
        const phones = payload?.defaultPhone
          ? phoneNumbers.map(phone => ({ ...phone, defaultPhone: false }))
          : phoneNumbers;
        return fromJS(phoneNumbers.length ? [...phones, payload] : [payload]);
      });

    case Constants.PROFILE_ADD_PHONE_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.PROFILE_UPDATE_PHONE_SUCCESS:
      return state.updateIn(['currentUser', 'phones'], immutablePhones => {
        const phoneNumbers = immutablePhones?.toJS() || [];
        const phones = payload?.updatedPhoneNumber?.defaultPhone
          ? phoneNumbers.map(phone => ({ ...phone, defaultPhone: false }))
          : phoneNumbers;
        const nonUpdatedPhones = phones.filter(phone => payload.existingId !== phone.id);
        return fromJS([...nonUpdatedPhones, payload.updatedPhoneNumber]);
      });

    case Constants.PROFILE_UPDATE_PHONE_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.PROFILE_DELETE_PHONE_SUCCESS:
      return state.updateIn(['currentUser', 'phones'], immutablePhones => {
        const phoneNumbers = immutablePhones?.toJS() || [];
        return fromJS(phoneNumbers?.filter(phone => phone.id !== payload));
      });

    case Constants.PROFILE_DELETE_PHONE_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.TOGGLE_PROCESSED_ITEM:
      return state.set('processingItem', fromJS(payload || null));

    case Constants.POST_PERSONAL_INFO_SUCCESS:
      return state.mergeIn(['currentUser'], fromJS(payload.data));

    case Constants.GET_SHIPPING_ADDRESSES_SUCCESS:
      return state.setIn(['currentUser', 'shippingAddresses'], fromJS(payload));

    case Constants.GET_DEFAULT_BILLING_ACCOUNT_ID_SUCCESS:
      return state.setIn(['currentUser', 'defaultBillingAccountId'], fromJS(payload));

    case Constants.GET_SHIPPING_ADDRESSES_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.PROFILE_UPDATE_EMAIL_SUCCESS:
      return state.setIn(['currentUser', 'emailAddress'], fromJS(payload));

    case Constants.PROFILE_UPDATE_PASSWORD_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.PROFILE_UPDATE_EMAIL_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.GET_VOD_DEVICES_SUCCESS:
      return state.setIn(['currentUser', 'vodDevices'], fromJS(payload));

    case Constants.GET_WEBAUTHN_PUBLIC_KEYS_SUCCESS:
      return state.setIn(['currentUser', 'publicKeys'], fromJS(payload));

    case Constants.RENAME_VOD_DEVICE_SUCCESS:
      return state.updateIn(['currentUser', 'vodDevices'], immutableVODDevices => {
        const { deviceCode, deviceName } = payload;

        return immutableVODDevices.map(device =>
          device.get('deviceCode') === deviceCode
            ? device.set('deviceName', deviceName)
            : device
        );
      });

    case Constants.RENAME_WEBAUTHN_PUBLIC_KEY_SUCCESS:
      return state.updateIn(['currentUser', 'publicKeys'], immutablePublicKeys => {
        const { id, name } = payload;

        return immutablePublicKeys.map(publicKey =>
          publicKey.get('id') === id
            ? publicKey.set('name', name)
            : publicKey
        );
      });

    case Constants.REMOVE_WEBAUTHN_PUBLIC_KEY_SUCCESS:
      return state.updateIn(['currentUser', 'publicKeys'], (immutablePublicKeys) => {
        const { id } = action.payload;

        return immutablePublicKeys.filter(publicKey =>
          publicKey.get('id') !== id
        );
      });

      case Constants.REMOVE_VOD_DEVICE_SUCCESS:
      return state.updateIn(['currentUser', 'vodDevices'], (immutableVODDevices) => {
        const { deviceCode } = action.payload;

        return immutableVODDevices.filter(device =>
          device.get('deviceCode') !== deviceCode
        );
      });

    case Constants.GET_PAYMENT_METHODS_SUCCESS:
      return state
        .setIn(['currentUser', 'paymentMethods', 'creditCards'], fromJS(addLastFour(payload.creditCards)))
        .setIn(['currentUser', 'paymentMethods', 'paypal'], fromJS(payload.paypal))
        .setIn(['currentUser', 'paymentMethods', 'defaultPaymentMethod'], fromJS(payload.defaultPaymentMethod))
        .setIn(['currentUser', 'isPaymentMethodsLoading'], fromJS(false));

    case Constants.GET_PAYMENT_METHODS_PENDING:
        return state
          .setIn(['currentUser', 'isPaymentMethodsLoading'], fromJS(true));

    case Constants.ADD_CREDIT_CARD_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'creditCards'], creditCards => creditCards.push ? creditCards.push(payload) : fromJS([payload]));

    case Constants.DELETE_CREDIT_CARD_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'creditCards'], creditCards => creditCards?.filter(card => card.toJS().id !== payload));

    case Constants.DELETE_PAYPAL_ACCOUNT_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'paypal'], payPalAccounts => payPalAccounts?.filter(payPalAccount => payPalAccount.toJS().id !== payload));

    case Constants.DELETE_PAYPAL_ACCOUNT_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.ASSIGN_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return state
      .updateIn(['currentUser', 'paymentMethods'], immutablePaymentMethods => {
        const paymentMethods = immutablePaymentMethods.toJS();

        const {paymentMethodId, personProfileId, paymentMethodType} = payload;

        const defaultPaymentMethod = {};
        if (paymentMethodType === 'BRAINTREE') {
          defaultPaymentMethod.paymentMethod = paymentMethods.paypal?.find(payPalAccount => payPalAccount.id === paymentMethodId);
        } else if (paymentMethodType === 'CREDIT_CARD') {
          defaultPaymentMethod.paymentMethod = paymentMethods.creditCards?.find(payPalAccount => payPalAccount.id === paymentMethodId);

        }

        defaultPaymentMethod.id = paymentMethodId;
        defaultPaymentMethod.paymentMethodId = paymentMethodId;
        defaultPaymentMethod.channel = 'lifeway';
        defaultPaymentMethod.personProfileId = personProfileId;
        defaultPaymentMethod.paymentMethodType = paymentMethodType;

        return fromJS({...paymentMethods, defaultPaymentMethod});
      });

    case Constants.ASSIGN_DEFAULT_PAYMENT_METHOD_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.UPDATE_CREDIT_CARD_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'creditCards'], immutableCreditCards => {
        const creditCards = immutableCreditCards.toJS();
        return fromJS(creditCards?.map(creditCard => creditCard.id === payload?.id ? {...creditCard, ...payload} : creditCard));
      });

    case Constants.DELETE_CREDIT_CARD_ERROR:
      return state.set('error', fromJS(payload.data));
    case Constants.UPDATE_CREDIT_CARD_ERROR:
      return state.set('error', fromJS(payload.data));
    case Constants.ADD_CREDIT_CARD_ERROR:
      return state.set('error', fromJS(payload.data));
    case Constants.GET_PAYMENT_METHODS_ERROR:
      return state
        .set('error', fromJS(payload.data))
        .setIn(['currentUser', 'isPaymentMethodsLoading'], fromJS(false));
    /////////////////////////////////////////////////

    case Constants.APP_SET_BANNER_STATE:
      return state.set('banner', fromJS(payload.data));

    case Constants.APP_CLEAR_BANNER_STATE:
      return state.set('banner', new Map({ message: null, type: null }));

    case Constants.GET_COUNTRIES_SUCCESS:
      return state.setIn(['countryData', 'countries'], fromJS(payload));

    case Constants.GET_COUNTRIES_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.GET_STATES_SUCCESS:
      return state.setIn(['countryData', 'states', payload.countryCode], fromJS(payload.states));

    case Constants.GET_STATES_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.GET_MILITARY_LOCATIONS_SUCCESS:
      return state.setIn(['countryData', 'militaryLocations'], fromJS(payload));

    case Constants.GET_MILITARY_LOCATIONS_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.ADD_USER_SHIPPING_ADDRESS_SUCCESS:
      return state.updateIn(['currentUser', 'addresses'], immutableAddresses => {
        let addresses = immutableAddresses?.toJS() || [];

        // remove default flag from all shipping addresses
        addresses = payload?.defaultAddress
          ? addresses.map(address => address.category === 'SHIPPING' ? ({ ...address, defaultAddress: false }) : address)
          : addresses;

        return fromJS([...addresses, payload]);
      });

    case Constants.ADD_ACCOUNT_SHIPPING_ADDRESS_SUCCESS:
      return state.updateIn(['currentUser', 'linkedOrgs'], immutableLinkedOrgs => {
        const linkedOrgs = immutableLinkedOrgs?.toJS() || [];
        const org = linkedOrgs.find(org => org?.organizationProfile?.organization?.id === payload.organizationId);
        const billingAccount =
            org?.organizationProfile?.billingAccounts?.find(billingAccount => billingAccount.id === payload.billingAccountId);

        if (!billingAccount?.addresses) billingAccount.addresses = [];

        billingAccount?.addresses?.push(payload.address);
        return fromJS(linkedOrgs);
      });

    case Constants.ADD_USER_SHIPPING_ADDRESS_ERROR:
      return state.set('error', fromJS(payload));

    case Constants.UPDATE_USER_SHIPPING_ADDRESS_SUCCESS:
      return state.updateIn(['currentUser', 'addresses'], immutableAddresses => {
        let addresses = immutableAddresses?.toJS() || [];

        // remove default flag from all shipping addresses
        addresses = payload?.updatedShippingAddress?.defaultAddress
          ? addresses.map(address => address.category === 'SHIPPING' ? ({ ...address, defaultAddress: false }) : address)
          : addresses;

        const withUpdated = addresses.map(address => address.id === payload.existingId ? { ...address, ...payload?.updatedShippingAddress } : address);
        return fromJS(withUpdated);
      });

      case Constants.UPDATE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS:
        return state.updateIn(
          ['currentUser', 'linkedOrgs'],
          immutableLinkedOrgs => {
            const linkedOrgs = immutableLinkedOrgs?.toJS() || [];
            const org = linkedOrgs.find(
              org =>
                org?.organizationProfile?.organization?.id ===
                payload.organizationId
            );

            const billingAccount = org?.organizationProfile?.billingAccounts?.find(
              billingAccount => billingAccount.id === payload.billingAccountId
            );

            if (!billingAccount?.addresses) billingAccount.addresses = [];

            let {addresses} = billingAccount;


            // set all addresses to not default
            if (payload.updatedShippingAddress.defaultAddress) {
              const { category } = payload.updatedShippingAddress;
              addresses = addresses.map(address => address.category === category ? {...address, defaultAddress: false} : address);
            }

            // replace the old address with the new address
            addresses = addresses.map(address => address.id === payload.existingId ? {...address, ...payload.updatedShippingAddress} : address);

            billingAccount.addresses = addresses;
            return fromJS(linkedOrgs);
          }
        );

    case Constants.UPDATE_USER_SHIPPING_ADDRESS_ERROR:
      return state.set('error', fromJS(payload));

    case Constants.UPDATE_ACCOUNT_SHIPPING_ADDRESS_ERROR:
      return state.set('error', fromJS(payload));

    case Constants.TRANSFER_AUTOSHIP_BETWEEN_ACCOUNT_ADDRESSES:
      return state.updateIn(
        ['currentUser', 'linkedOrgs'],
        immutableLinkedOrgs => {
          const { organizationId, billingAccountId, newAddressId, autoshipId } =
            payload;
          const linkedOrgs = immutableLinkedOrgs?.toJS() || [];
          const org = linkedOrgs.find(
            org => org?.organizationProfile?.organization?.id === organizationId
          );

          const billingAccount =
            org?.organizationProfile?.billingAccounts?.find(
              billingAccount => billingAccount.id === billingAccountId
            );

          if (!billingAccount?.addresses) billingAccount.addresses = [];

          let { addresses } = billingAccount;

          const autoship = findAutoshipInAddresses(autoshipId, addresses);

          if (autoship) {
            addresses = addresses.map(address => {
              if (addressContainsAutoship(autoshipId, address)) {
                const filteredSubscriptions = address.subscriptions.filter(subscription => subscription.subscriptionId !== autoshipId);
                return {...address, subscriptions: filteredSubscriptions};
              } else if (address.id === newAddressId) {
                const subscriptions = address.subscriptions || [];
                return {...address, subscriptions: [...subscriptions, autoship]};
              } else {
                return address;
              }
            });
          }

          billingAccount.addresses = addresses;
          return fromJS(linkedOrgs);
        }
      );

    case Constants.TRANSFER_AUTOSHIP_BETWEEN_USER_ADDRESSES:
      return state.updateIn(
        ['currentUser', 'shippingAddresses'],
        immutableAddresses => {
          const {autoshipId, newAddressId} = payload;
          let shippingAddresses = immutableAddresses?.toJS();

          const autoship = findAutoshipInAddresses(autoshipId, shippingAddresses);

          if (autoship) {
            shippingAddresses = shippingAddresses.map(address => {
              if (addressContainsAutoship(autoshipId, address)) {
                const filteredSubscriptions = address.subscriptions.filter(subscription => subscription.subscriptionId !== autoshipId);
                return {...address, subscriptions: filteredSubscriptions};
              } else if (address.id === newAddressId) {
                const subscriptions = address.subscriptions || [];
                return {...address, subscriptions: [...subscriptions, autoship]};
              } else {
                return address;
              }
            });
          }

          return fromJS(shippingAddresses);
        }
      );

    case Constants.ADD_ACCOUNT_SHIPPING_ADDRESS_ERROR:
      return state.set('error', fromJS(payload));

    case Constants.ADD_ADDRESS_BEING_PROCESSED:
      return state.updateIn(['addressesBeingProcessed'], immutableAddresses => {
        return fromJS([...immutableAddresses, payload]);
      });

   case Constants.REMOVE_ADDRESS_BEING_PROCESSED:
      return state.updateIn(['addressesBeingProcessed'], immutableAddresses => {
        const updatedAddresses = immutableAddresses.filter(addressIdInList => addressIdInList !== payload);
        return fromJS(updatedAddresses);
      });

    case Constants.DELETE_ACCOUNT_SHIPPING_ADDRESS_SUCCESS:
      return state.updateIn(
        ['currentUser', 'linkedOrgs'],
        immutableLinkedOrgs => {
          const linkedOrgs = immutableLinkedOrgs?.toJS() || [];
          const org = linkedOrgs.find(
            org =>
              org?.organizationProfile?.organization?.id ===
              payload.organizationId
          );

          const billingAccount = org?.organizationProfile?.billingAccounts?.find(
            billingAccount => billingAccount.id === payload.billingAccountId
          );

          if (!billingAccount?.addresses) billingAccount.addresses = [];

          let {addresses} = billingAccount;

          addresses = addresses.filter((address) => address.id !== payload.addressId);

          billingAccount.addresses = addresses;
          return fromJS(linkedOrgs);
        }
      );

    case Constants.DELETE_ACCOUNT_SHIPPING_ADDRESS_ERROR:
      return state.set('error', fromJS(payload));

    case Constants.DELETE_USER_SHIPPING_ADDRESS_SUCCESS:
      return state.updateIn(['currentUser', 'addresses'], immutableAddresses => {
        const addresses = immutableAddresses?.toJS() || [];
        return fromJS(addresses.filter(address => address.id !== payload));
      });

    case Constants.DELETE_USER_SHIPPING_ADDRESS_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.SET_ADDRESS_SUGGESTIONS:
      return state.set('addressSuggestions', fromJS(payload || []));

    case Constants.UPDATE_BILLING_ACCT_ASSOC_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'billingAccs'], immutableAccounts => {
        const accounts = immutableAccounts?.toJS() || [];
        const withUpdated = [...accounts, payload?.updatedBillingAccount];
        return fromJS(withUpdated.filter(account => account?.id !== payload?.existingId));
      });

    case Constants.DELETE_BILLING_ACCT_ASSOC_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'billingAccs'], immutableAccounts => {
        const accounts = immutableAccounts?.toJS() || [];
        return fromJS(accounts.filter(account => account.id !== payload));
      });

    case Constants.ADD_BILLING_ACCT_ASSOC_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'billingAccs'], immutableAccounts => {
        const accounts = immutableAccounts?.toJS() || [];
        return fromJS([...accounts, payload]);
      });

    case Constants.GET_BILLING_ACCS_SUCCESS:
      return state.setIn(['currentUser', 'paymentMethods', 'billingAccs'], fromJS(payload));

    case Constants.GET_LINKED_ORGS_PENDING:
      return state
        .setIn(['currentUser', 'linkedOrgsLoading'], 'pending');

    case Constants.GET_LINKED_ORG_GROUPS_PENDING:
      return state
        .setIn(['currentUser', 'linkedOrgGroupsLoading'], 'pending');

    case Constants.GET_LINKED_ORGS_SUCCESS:
      return state
        .setIn(['currentUser', 'linkedOrgs'], fromJS(payload))
        .setIn(['currentUser', 'linkedOrgsLoading'], 'success');

    case Constants.GET_USER_ROLES_SUCCESS:
      return state
        .setIn(['currentUser', 'linkedUserRoles'], fromJS(payload));

    case Constants.GET_ORGANIZATION_MEMBER_ROLES_PENDING:
      return state.setIn(['currentUser', 'linkedOrgMemberRolesLoading'], true);

    case Constants.GET_ORGANIZATION_MEMBER_ROLES_ERROR:
      return state.setIn(['currentUser', 'linkedOrgMemberRolesLoading'], false);

    case Constants.GET_ORGANIZATION_MEMBER_ROLES_SUCCESS:
      return state
        .setIn(['currentUser', 'linkedOrgsMemberRoles', 'administrator'], fromJS(payload['Administrator']))
        .setIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationPurchaser'], fromJS(payload['Organization Purchaser']))
        .setIn(['currentUser', 'linkedOrgsMemberRoles', 'billingAccountPurchaser'], fromJS(payload['Billing Account Purchaser']))
        .setIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationBillingManager'], fromJS(payload['Organization Billing Manager']))
        .setIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationMembers'], fromJS(payload['Organization Members']))
        .setIn(['currentUser', 'linkedOrgMemberRolesLoading'], false);

    case Constants.ADD_USER_TO_ORG_ROLE_SUCCESS:
      const { roleId, subRoleId, customerInfo, accountNumber, accountNumbers, billingAccounts, organizationId, updatedMember } = action.payload;
      const filteredMemberRoles = updatedMember[0]?.data?.newState?.roles?.filter(role => role?.contextId === organizationId);
      const addedMember = { customerId: customerInfo?.customerId, firstName: customerInfo?.firstName, lastName: customerInfo?.lastName, email: customerInfo?.email };

      return state
        .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'administrator'], members => {
          if(roleId === adminRole) {
            const addedRole = filteredMemberRoles?.find(role => role?.roleId === roleId);
            return fromJS([...(members?.toJS() || []), {...addedMember, assignedRoles: [addedRole]}]);
          }
            return members;
        })
        .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationPurchaser'], members => {
          if (roleId === orgPurchaserRole && subRoleId !== baPurchaserRole) {
            const addedRole = filteredMemberRoles?.find(role => role?.roleId === roleId);
            const updatedBillingAccounts = (billingAccounts || [])?.map(ba => {
                return { ...ba, accountNumber: getBillingAccountNumberFromGroupName(ba.groupName), isMember: false };
            });
            return fromJS([...(members?.toJS() || []), {...addedMember, assignedRoles: [addedRole], billingAccounts: updatedBillingAccounts}]);
          }
          return members;
        })
        .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationPurchaser'], members => {
          if (!roleId && subRoleId === baPurchaserRole) {
            const updatedMembers = members?.toJS().map(member => {
              const { customerId, billingAccounts } = member;
              if (customerId === addedMember.customerId) {
                const updatedBillingAccounts = (billingAccounts || []).map(ba => {
                  if (ba.accountNumber === accountNumber) {
                    return { ...ba, isMember: true };
                  } else {
                    return ba;
                  }
                });
                return { ...member, assignedRoles: filteredMemberRoles, billingAccounts: updatedBillingAccounts };
              }
              return member;
            });
            return fromJS(updatedMembers);
          }
          return members;
        })
        .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationPurchaser'], members => {
          if (roleId === orgPurchaserRole && subRoleId === baPurchaserRole) {
            const filteredRole = updatedMember.reduce((filtered, item) => {
              const roles = item.data.newState.roles;
              const matchingRoles = roles.filter(role => {
                return (
                  role.contextId === organizationId &&
                  billingAccounts.map(ba => getBillingAccountNumberFromGroupName(ba.groupName)).includes(role.scopeVariables.account) &&
                  role.scopeVariables.organization === organizationId
                );
              });
              if (matchingRoles.length > 0) {
                filtered.push(matchingRoles);
              }
              return filtered;
            }, []);
            const uniqRoles = uniqBy(filteredRole.flat(), 'id');
            const purchaserRoleBillingAccounts = billingAccounts?.map(orgAccountNumber => {
              const hasAccountNumber = accountNumbers.some(accountNumber => accountNumber === getBillingAccountNumberFromGroupName(orgAccountNumber.groupName));
              return {
                ...orgAccountNumber,
                accountNumber: getBillingAccountNumberFromGroupName(orgAccountNumber.groupName),
                isMember: hasAccountNumber
              };
            });
            const baRoleMember = { ...addedMember, assignedRoles: uniqRoles, billingAccounts: purchaserRoleBillingAccounts };
            return fromJS([...(members?.toJS() || []), baRoleMember]);
          }
          return members;
        })
        .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationBillingManager'], members => {
          if(roleId === orgBillingManagerRole) {
            const addedRole = filteredMemberRoles?.find(role => role?.roleId === roleId);
            return fromJS([...(members?.toJS() || []), {...addedMember, assignedRoles: [addedRole]}]);
          }
            return members;
      });

    case Constants.REMOVE_ROLE_SUCCESS:
      const { customerId } = action.payload;
        return state
          .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'administrator'], members => {
            if(action.payload.roleId === adminRole) {
              return fromJS([...(members?.toJS() || [])].filter(member => member.customerId !== customerId));
            }
              return members;
          })
          .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationPurchaser'], members => {
            if (action.payload.roleId === orgPurchaserRole && subRoleId !== baPurchaserRole) {
              return fromJS([...(members?.toJS() || [])].filter(member => member.customerId !== customerId));
            }
            return members;
          })
          .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationPurchaser'], members => {
            if (action.payload?.roleId === baPurchaserRole) {
              const updatedMembers = members?.toJS().map(member => {
                const { customerId, billingAccounts } = member;
                if (customerId === action.payload?.customerId) {
                  const updatedBillingAccounts = (billingAccounts || []).map(ba => {
                    if (ba.accountNumber === action.payload.accountNumber) {
                      return { ...ba, isMember: false };
                    } else {
                      return ba;
                    }
                  });
                  return { ...member, billingAccounts: updatedBillingAccounts };
                }
                return member;
              });
              return fromJS(updatedMembers);
            }
            return members;
          })
          .updateIn(['currentUser', 'linkedOrgsMemberRoles', 'organizationBillingManager'], members => {
            if(action.payload.roleId === orgBillingManagerRole) {
              return fromJS([...(members?.toJS() || [])].filter(member => member.customerId !== customerId));
            }
              return members;
          });

    case Constants.GET_CUSTOMER_PERMISSIONS_SUCCESS:
        const permissions = payload.map((payloadPermission) => {
          const matchingPermission = Constants.PERMISSIONS.find(
            (permission) => permission.permissionId === payloadPermission.permissionId
          );
          if (matchingPermission) {
            return { ...payloadPermission, displayId: matchingPermission.displayId };
          }
          return payloadPermission;
        });

        return state
          .setIn(['currentUser', 'customerPermissions'], fromJS(permissions));

    case Constants.GET_LINKED_ORG_GROUPS_SUCCESS:
      return state
        .setIn(['currentUser', 'linkedOrgGroupsLoading'], 'success')
        .updateIn(['currentUser', 'linkedOrgs'], immutableLinkedOrgs => {
          const linkedOrgs = immutableLinkedOrgs?.toJS() || [];

          const updatedOrgs = linkedOrgs.reduce(
            (allOrgs, org) => ([...allOrgs,
            org.organizationId === payload.organizationId
              ? {
                ...org,
                groups: payload.groups ?? [],
                userGroups: org.userGroups
                  ?.map(userGroup => payload.groups?.find(group => group.id === userGroup.id))
                  ?.filter(group => group !== undefined)
              }
              : org
            ]),
            []
          );

          return fromJS(updatedOrgs);
        })
        .setIn(['currentUser', 'linkedOrgsLoading'], 'success');

    case Constants.REMOVE_APPROVED_PURCHASER_SUCCESS:
      return state.updateIn(['currentUser', 'linkedOrgs'], immutableLinkedOrgs => {
        const linkedOrgs = immutableLinkedOrgs?.toJS() || [];
        const org = linkedOrgs.find(
          org =>
            org?.organizationProfile?.organization?.id ===
            payload.organizationId
        );

        const billingAccount = org?.organizationProfile?.billingAccounts?.find(
          billingAccount => billingAccount.id === payload.accountId
        );

        if (!billingAccount.approvedPurchasersDetail) billingAccount.approvedPurchasersDetail = [];

        billingAccount.approvedPurchasersDetail = billingAccount.approvedPurchasersDetail.filter(approvedPurchaser => approvedPurchaser.externalReferenceId !== payload.externalReferenceId);

        return fromJS(linkedOrgs);
      });

    case Constants.ADD_PENDING_TAX_EXEMPTION:

      return state
        .updateIn(['currentUser', 'linkedOrgs'], linkedOrgs => {
          const updatedOrgs = linkedOrgs.map((org) => {
            if (org.get('organizationId') === payload.organizationId) {
              const pendingExemption = fromJS({ id: Math.floor(Math.random() * 1000000000), status: 'PENDING_APPROVAL', dateCreated: Date.now() });
              return org.updateIn(['organizationProfile', 'taxExemptions', 'pendingTaxExemptions'],
                exemptions => exemptions ? exemptions.push(pendingExemption) : [pendingExemption]
              );
            } else {
              return org;
            }
          });

          return fromJS(updatedOrgs);
        });

    case Constants.CLAIM_LINKED_ORG_ADMIN_SUCCESS:
      return state.updateIn(['currentUser', 'linkedOrgs'], immutableLinkedOrgs => {
        const { adminGroupId, organizationId } = action?.payload;
        const linkedOrgs = immutableLinkedOrgs?.toJS() || [];

        const updatedOrgs = linkedOrgs.map((org) => {
          if (org.organizationId === organizationId) {
            return { ...org, userClaimedAdmin: true, adminGroupId };
          }
          return org;
        });

        return fromJS(updatedOrgs);
      });

    case Constants.REMOVE_USER_FROM_ORG_GROUP_SUCCESS:
      return state.updateIn(['currentUser', 'linkedOrgs'], immutableLinkedOrgs => {
        const { personId, groupId, organizationId } = action?.payload;
        const linkedOrgs = immutableLinkedOrgs?.toJS() || [];

        const updatedOrgs = linkedOrgs.map((org) => {
          if (org?.organizationId === organizationId) {
            return {
              ...org,
              groups: [
                ...(org?.groups ? org.groups.map(group => {
                  if (group.id === groupId) {
                    return ({ ...group, members: [...group.members.filter(member => member.personId !== personId)] });
                  }
                  return group;
                }) : [])
              ]
            };
          }
          return org;
        });

        return fromJS(updatedOrgs);
      });

    case Constants.UNLINK_ORG_SUCCESS:
      return state.updateIn(['currentUser', 'linkedOrgs'], immutableLinkedOrgs => {
        const { organizationId } = action?.payload;
        const linkedOrgs = immutableLinkedOrgs?.toJS() || [];
        const updatedOrgs = linkedOrgs.filter((org) => org.organizationId !== organizationId);
        return fromJS(updatedOrgs);
      });

    case Constants.REVALIDATE_ORG_LINK_SUCCESS:
      return state.updateIn(['currentUser', 'paymentMethods', 'billingAccs'], billingAccs => {
        const { id } = action.payload;
        const bapms = billingAccs?.toJS() || [];
        const update = bapms.filter((bapm) => bapm.id !== id);
        update.push(action.payload);
        return fromJS(update);
      });

    case Constants.GET_GIFT_CARD_BALANCE_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.SET_CURRENT_GIFT_CARD:
      return state.set('currentGiftCard', fromJS(payload || {}));

    case Constants.TABLE_ON_INIT_COLUMNS:
      if (!state.getIn(action.payload.tableIndex ?
        ['tableSettings', action.payload.tableName, action.payload.tableIndex, 'columns'] :
        ['tableSettings', action.payload.tableName, 'columns']).size) {

        return state
          .setIn(action.payload.tableIndex ?
            ['tableSettings', action.payload.tableName, action.payload.tableIndex, 'columns'] :
            ['tableSettings', action.payload.tableName, 'columns'], fromJS(action.payload.columns));
      }
      return state;

    case Constants.TABLE_UPDATE_SETTINGS: {
      const { tableIndex, tableName, settings } = action.payload;

      const settingsTable = ['tableSettings', tableName];

      const pathIn = tableIndex ? [...settingsTable, tableIndex] : settingsTable;

      return state.mergeIn(pathIn, settings);
    }

    case Constants.GET_ORDER_DETAILS_PENDING:
      return state.set('orderDetail', fromJS({ loading: true }));

    case Constants.GET_ORDER_DETAILS_SUCCESS:
      return state.set('orderDetail', fromJS(payload.order));

    case Constants.GET_ORDER_DETAILS_ERROR:
      return state.set('orderDetail', fromJS({ error: payload }));

    case Constants.GET_INVOICES_SHIPMENTS_SUCCESS:
      return state.setIn(payload.invoiceRef, fromJS(payload.data));

      case Constants.FIND_PRODUCTS_REPLACEMENTS_PENDING:
        return state.setIn(['productReplacements', 'status'], 'loading');

      case Constants.FIND_PRODUCTS_REPLACEMENTS_ERROR:
        return state.setIn(['productReplacements', 'status'], 'error');

      case Constants.FIND_PRODUCTS_REPLACEMENTS_SUCCESS: {
        const { products } = action.payload;

        return state
          .setIn(['productReplacements', 'status'], 'done')
          .setIn(['productReplacements', 'data'], fromJS(products));
      }

    //// Media content ///////////////////////////////////////////////////////////////
    case Constants.GET_DIGITAL_MEDIA_SUCCESS:
      return state.set('digitalMedia', fromJS(payload.data));

    case Constants.FETCH_PRODUCT_IMAGE_URLS_SUCCESS: {
      const { images } = action.payload;

      return images.reduce((acc, itemImage) =>
        acc.updateIn(['products', itemImage.itemNumber], item => ({
          ...item,
          imageUrl: itemImage?.orderedImageLocations?.[0]?.imageLocation?.url
        })
        ),
        state
      );
    }

    case Constants.GET_PRODUCT_AVAILABILITY_SUCCESS: {
      const { availability } = action.payload;

      return availability.reduce((acc, itemAvailability) =>
        acc.updateIn(['products', itemAvailability.itemNumber], item => ({
          ...item,
          availability: itemAvailability
        })
        ),
        state
      );
    }

    case Constants.GET_PRODUCT_ALL_DETAILS_SUCCESS: {
      const { products } = action.payload;

      return Object.keys(products).reduce((acc, productNumber) =>
        acc.setIn(['products', productNumber], {
          imageUrl: products[productNumber].images?.orderedImageLocations?.[0]?.imageLocation?.url,
          pricingTiers: products[productNumber]?.pricing?.prices?.map(tier => ({
            minimumQuantity: tier.minimumQuantity,
            listPrice: tier.listPrices[0],
            purchasePrice: tier.purchasePrices[0]
          })),
          availability: products[productNumber].availability,
          details: setIsQuarterlyAutoshipEligible(products[productNumber].details)
        }),
        state
      );
    }

    case Constants.SET_PRODUCT_IMAGES_LOADING:
      switch (payload.type) {
        case 'downloads':
          return state.updateIn(['digitalMedia', 'downloads', 'loading'], currentValue => !currentValue);
        case 'multipleDownloads':
          return state.updateIn(['digitalMedia', 'multipleDownloads', 'loading'], currentValue => !currentValue);
        case 'ebooks':
          return state.updateIn(['digitalMedia', 'ebooks', 'loading'], currentValue => !currentValue);
        case 'otherContent':
          return state.updateIn(['digitalMedia', 'otherContent', 'loading'], currentValue => !currentValue);
        case 'videos':
          return state.updateIn(['digitalMedia', 'videos', 'loading'], currentValue => !currentValue);
        case 'simulcasts':
          return state.updateIn(['digitalMedia', 'simulcasts', 'loading'], currentValue => !currentValue);
        case 'memberships':
          return state.updateIn(['digitalMedia', 'memberships', 'loading'], currentValue => !currentValue);
        default:
          return state;
      }

    case Constants.GET_PRODUCTS_SUCCESS:
      switch (payload.type) {
        case 'downloads':
          return state.setIn(['digitalMedia', 'downloads', 'rows'], fromJS(addDigitalMediaImages(payload.data, state.get('digitalMedia').toJS().downloads.rows)));
        case 'multipleDownloads':
          return state.setIn(['digitalMedia', 'multipleDownloads', 'rows'], fromJS(payload.data));
        case 'ebooks':
          return state.setIn(['digitalMedia', 'ebooks', 'rows'], fromJS(addDigitalMediaImages(payload.data, state.get('digitalMedia').toJS().ebooks.rows)));
        case 'otherContent':
          return state.setIn(['digitalMedia', 'otherContent', 'rows'], fromJS(addDigitalMediaImages(payload.data, state.get('digitalMedia').toJS().otherContent.rows)));
        case 'videos':
          return state.setIn(['digitalMedia', 'videos', 'rows'], fromJS(addDigitalMediaImages(payload.data, state.get('digitalMedia').toJS().videos.rows)));
        case 'simulcasts':
          return state.setIn(['digitalMedia', 'simulcasts', 'rows'], fromJS(addDigitalMediaImages(payload.data, state.get('digitalMedia').toJS().simulcasts.rows)));
        case 'memberships':
          return state.setIn(['digitalMedia', 'memberships', 'rows'], fromJS(addDigitalMediaImages(payload.data, state.get('digitalMedia').toJS().memberships.rows)));
        case 'orderItems':
          return state.setIn(['orderDetail', 'orderLines'], fromJS(addOrderImages(payload.data, state.get('orderDetail').toJS().orderLines)));
        case 'licensesSimulcasts':
          return state.setIn(['licenses', 'simulcasts'], fromJS(addSimulcastImages(payload.data, state.get('licenses').toJS().simulcasts)));
        case 'licensesEbooks':
          return state.setIn(['licenses', 'ebooks'], fromJS(addSimulcastImages(payload.data, state.get('licenses').toJS().ebooks)));
        case 'licensesVideos':
          return state.setIn(['licenses', 'videos'], fromJS(addSimulcastImages(payload.data, state.get('licenses').toJS().videos)));
        case 'license':
          return state.setIn(['licenses', 'singleLicense'], fromJS({ ...state.get('licenses').toJS().singleLicense, imageUrl: payload.data?.[0]?.orderedImageLocations?.[0]?.imageLocation?.url }));
        case 'licenses':
          return state.setIn(['licenses', 'data'], fromJS(addSimulcastImages(payload.data, state.get('licenses').toJS().data)));
        default:
          return state;
      }

    case Constants.SET_DIGITAL_MEDIA_STATUS:
      return state.set('mediaLoaded', fromJS(payload));

    case Constants.GET_DOWNLOADS_SUCCESS:
      return state.setIn(['digitalMedia', 'multipleDownloads'], fromJS(payload.data));

    case Constants.QUERY_MEDIA_LOCATIONS_SUCCESS:
      return state.setIn(['digitalMedia', 'childProducts'], fromJS(payload));

    case Constants.GET_MEDIA_CONTENT_CHILDREN_PENDING:
      return state.setIn(['mediaContentChildren', payload, 'loading'], true)
        .setIn(['mediaContentChildren', payload, 'error'], false);

    case Constants.GET_MEDIA_CONTENT_CHILDREN_SUCCESS:
      if (payload) {
        return state.setIn(['mediaContentChildren', payload.parentItemNumber, 'data'], fromJS(payload.sortedChildItems))
          .setIn(['mediaContentChildren', payload.parentItemNumber, 'loading'], false)
          .setIn(['mediaContentChildren', payload.parentItemNumber, 'error'], false);
      }
      return state.setIn(['mediaContentChildren', payload.parentItemNumber, 'loading'], false)
        .setIn(['mediaContentChildren', payload.parentItemNumber, 'error'], false);

    case Constants.GET_MEDIA_CONTENT_CHILDREN_ERROR:
      return state.setIn(['mediaContentChildren', payload.parentItemNumber, 'loading'], false)
        .setIn(['mediaContentChildren', payload.parentItemNumber, 'error'], fromJS(payload));

    case Constants.CLEAR_MULTI_DOWNLOADS:
      return state.setIn(['digitalMedia', 'multipleDownloads'], initTableShape());

    case Constants.GET_LICENSES_PENDING:
      return state.setIn(['licenses', 'loading'], true)
        .setIn(['licenses', 'error'], false);

    case Constants.GET_LICENSES_ERROR:
      return state.setIn(['licenses', 'error'], true)
        .setIn(['licenses', 'loading'], false);

    case Constants.GET_LICENSES_SUCCESS:
      return state
        .setIn(['licenses', 'data'], fromJS(action.payload.data))
        .setIn(['licenses', 'simulcasts'], fromJS(action.payload.simulcasts))
        .setIn(['licenses', 'ebooks'], fromJS(action.payload.ebooks))
        .setIn(['licenses', 'videos'], fromJS(action.payload.videos))
        .setIn(['licenses', 'loading'], false)
        .setIn(['licenses', 'error'], false);

    case Constants.GET_LICENSE_PENDING:
      return state.setIn(['licenses', 'singleLicense', 'loading'], action.payload.reloadSimulcast)
        .setIn(['licenses', 'singleLicense', 'error'], false)
        .setIn(['licenses', 'singleLicense', 'revokeError'], false);

    case Constants.GET_LICENSE_ERROR:
      return state.setIn(['licenses', 'singleLicense', 'error'], true)
        .setIn(['licenses', 'singleLicense', 'loading'], false);

    case Constants.GET_LICENSE_SUCCESS:
      return state.setIn(['licenses', 'singleLicense'], fromJS(action.payload))
        .setIn(['licenses', 'singleLicense', 'error'], false)
        .setIn(['licenses', 'singleLicense', 'loading'], false)
        .setIn(['licenses', 'singleLicense', 'actionPending'], false);

    case Constants.GRANT_ACCESS_ACCOUNT_CHECK:
    case Constants.REMOVE_MANAGER_PENDING:
    case Constants.REVOKE_SEAT_PENDING:
      return state.setIn(['licenses', 'singleLicense', 'isSubmittingShareAccessByEmailForm'], true)
        .setIn(['licenses', 'singleLicense', 'errorOccuredWhileSharingAccess'], false)
        .setIn(['licenses', 'singleLicense', 'actionPending'], true);

    case Constants.SEND_LICENSE_INVITE_SUCCESS:
    case Constants.RESEND_INVITE_SUCCESS:
    case Constants.GRANT_SEAT_SUCCESS:
    case Constants.ADD_MANAGER_SUCCESS:
    case Constants.REMOVE_MANAGER_SUCCESS:
      return state.setIn(['licenses', 'singleLicense', 'isSubmittingShareAccessByEmailForm'], false)
        .setIn(['licenses', 'singleLicense', 'actionPending'], false);

    case Constants.RESEND_INVITE_ERROR:
    case Constants.SEND_LICENSE_INVITE_ERROR:
      return state.setIn(['licenses', 'singleLicense', 'isSubmittingShareAccessByEmailForm'], false);

    case Constants.GRANT_SEAT_ERROR:
    case Constants.ADD_MANAGER_ERROR:
      return state.setIn(['licenses', 'singleLicense', 'isSubmittingShareAccessByEmailForm'], false)
        .setIn(['licenses', 'singleLicense', 'errorOccuredWhileSharingAccess'], true)
        .setIn(['licenses', 'singleLicense', 'actionPending'], false);

    case Constants.REVOKE_SEAT_ERROR:
    case Constants.REMOVE_MANAGER_ERROR:
      return state.setIn(['licenses', 'singleLicense', 'revokeError'], true)
        .setIn(['licenses', 'singleLicense', 'actionPending'], false);

    case Constants.CLEAR_REVOKE_ERROR:
      return state.setIn(['licenses', 'singleLicense', 'revokeError'], false);

    case Constants.GET_CONTENTFUL_LINKS_SUCCESS:
      return state.set('contentfulLinks', fromJS(payload));

    case Constants.GET_CONTENTFUL_LINKS_ERROR:
      return state.set('error', fromJS(payload));

    ///////////////////////////////////////////////////////////////////////////////////

    case Constants.GET_PROCESSED_ORDERS_SUCCESS:
      return state.setIn(payload.index, fromJS(payload.data));

    case Constants.GET_PROCESSED_ORDERS_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.GET_PENDING_ORDER_IMAGES_SUCCESS: {
      const index = payload.index;
      const rows = [ ...index, 'rows'];
      if (payload.data.length) {
          return state.setIn(
            rows,
            fromJS(addOrderHistoryImages(payload.data, state.getIn(rows).toJS()))
          );
      } else {
        return state;
      }
    }

    case Constants.GET_PROCESSED_ORDER_IMAGES_SUCCESS: {
      const index = payload.index;
      const rows = [ ...index, 'rows'];
      if (payload.data.length) {
          return state.setIn(
            rows,
            fromJS(addOrderHistoryImages(payload.data, state.getIn(rows).toJS()))
          );
      } else {
        return state;
      }
    }

    case Constants.SET_IS_ORDER_IMAGE_LOADING:
      return state.updateIn(['orders', 'isOrderImagesLoading'], currentValue => !currentValue);

    case Constants.GET_ORDER_IMAGES_SUCCESS: {
      const index = payload.index;
      const rows = [ ...index, 'rows'];
      if (payload.data.length) {
          return state.setIn(
            rows,
            fromJS(addOrderHistoryImages(payload.data, state.getIn(rows).toJS()))
          );
      } else {
        return state;
      }
    }

    case Constants.GET_SEARCHED_ORDER_IMAGES_SUCCESS: {
      const index = payload.index;
      const rows = [ ...index, 'rows'];
      if (payload.data.length) {
          return state.setIn(
            rows,
            fromJS(addOrderHistoryImages(payload.data, state.getIn(rows)?.toJS()))
          );
      } else {
        return state;
      }
    }

    case Constants.GET_PENDING_ORDERS_SUCCESS:
      return state.setIn(payload.index, fromJS(payload.data));

    case Constants.GET_ORDERS_SUCCESS:
      return state.setIn(payload.index, fromJS(payload.data));

    case Constants.GET_ORDERS_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.GET_PENDING_ORDERS_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.GET_ORDER_SEARCH_SUCCESS:
      return state.setIn(payload.index, fromJS(payload.data));

    case Constants.GET_ORDER_SEARCH_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.TABLE_APPEND_ROW_DATA:
      return state.updateIn(payload.index, rows => rows.splice(payload.range.min, payload.range.length, ...payload.data.rows));

    // Gift Card Purchases
    case Constants.GET_GIFT_CARD_PURCHASES_SUCCESS:
      return state.set('giftCardPurchases', fromJS(payload.data));

    case Constants.SET_REDIRECT_URL:
      return state.set('redirectURL', payload.data);

    case Constants.GET_CODE_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.GET_CODE_SUCCESS:
      return state.update('redeemCodes', codes => codes.push ? codes.push(payload) : fromJS([payload]));

    // Billing acct details ///////////////////////////
    case Constants.GET_ACCT_BALANCE_SUCCESS: {
      const { id, data } = payload;

      return state.setIn(['accountBalances', id], fromJS(data));
    }
    case Constants.GET_ACCT_STMTS_SUCCESS: {
      const { id, data } = payload;

      return state.setIn(['statementDates', id], fromJS(data));
    }

    // Billing Account Statements - Account Activity
    case Constants.GET_ACCT_TRANSACTIONS_PENDING: {
      const { id } = payload;

      if (state.getIn(['accountTransactions', id])) {
        return state.setIn(['accountTransactions', id, 'loading'], true)
          .setIn(['accountTransactions', id, 'error'], undefined);
      }
      return state.setIn(['accountTransactions', id, 'loading'], true)
        .setIn(['accountTransactions', id, 'error'], undefined)
        .setIn(['accountTransactions', id, 'allTransactions'], new Map({ rows: new List(), totalItems: 0 }))
        .setIn(['accountTransactions', id, 'openInvoices'], new Map({ rows: new List(), totalItems: 0 }))
        .setIn(['accountTransactions', id, 'creditTransactions'], new Map({ rows: new List(), totalItems: 0 }));
    }

    case Constants.GET_ACCT_TRANSACTIONS_SUCCESS: {
      const { data, id, first } = payload;

      if (first) {
        const openInvoices = data.rows.filter(r => (r.transactionType === 'Invoice' || r.transactionType === 'Debit Memo') && !r.totalDueDisplay.includes('$0.00'));
        const creditTransactions = data.rows.filter(r => r.transactionType === 'Credit Memo' && !r.totalDueDisplay.includes('$0.00'));

        return state.setIn(['accountTransactions', id, 'loading'], false)
          .setIn(['accountTransactions', id, 'allTransactions'], fromJS(data))
          .setIn(['accountTransactions', id, 'openInvoices'], fromJS({ totalItems: openInvoices.length, rows: openInvoices }))
          .setIn(['accountTransactions', id, 'creditTransactions'], fromJS({ totalItems: creditTransactions.length, rows: creditTransactions }));
      }
      return state.setIn(['accountTransactions', id, 'loading'], false);
    }

    case Constants.GET_ACCT_TRANSACTIONS_ERROR: {
      const { id, message } = payload;

      return state.setIn(['accountTransactions', id, 'error'], message)
        .setIn(['accountTransactions', id, 'loading'], false)
        .setIn(['accountTransactions', id, 'allTransactions'], new Map())
        .setIn(['accountTransactions', id, 'openInvoices'], new Map())
        .setIn(['accountTransactions', id, 'creditTransactions'], new Map());
    }

    case Constants.RESET_TRANSACTIONS_CHECKED_STATE: {
      const { id, creditTransactions, openInvoices } = payload;
      return state.setIn(['accountTransactions', id, 'creditTransactions'], fromJS({ ...creditTransactions, rows: creditTransactions.row ? creditTransactions.rows.map(credit => ({ ...credit, checked: false })) : [] }))
        .setIn(['accountTransactions', id, 'openInvoices'], fromJS({ ...openInvoices, rows: openInvoices.rows ? openInvoices.rows.map(credit => ({ ...credit, checked: false })) : [] }));
    }

    case Constants.SET_TRANSACTIONS_CHECKED_STATE: {
      const { id, type, transactions } = payload;
      return state.setIn(['accountTransactions', id, type], fromJS(transactions));
    }

    case Constants.SET_SEARCH_RESULTS:
      return state.set('searchResults', fromJS(payload || {}));

    case Constants.GET_PAYMENT_TOKEN_SUCCESS:
      return state.set('paymentToken', fromJS(payload?.token));

    case Constants.GET_PAYMENT_INFO_SUCCESS:
      return state.set('newCreditCard', fromJS(payload));

    case Constants.UPDATE_GIFT_CARD_BALANCE:
      return state.mergeIn(['giftCardPurchases', 'rows', payload.index], fromJS(payload.data));

    case Constants.UPDATE_FORM_STATE:
      return state.mergeIn(['formState', payload.formName], fromJS(payload.formData));

    case Constants.CLEAR_FORM_STATE:
      return state.setIn(['formState', payload], fromJS({}));

    case Constants.SET_SUBSCRIPTIONS_LOADING:
      return state.set('subscriptionsLoaded', fromJS(payload));

    case Constants.SET_ORG_SUBSCRIPTIONS_LOADING:
      return state.set('orgSubscriptionsLoaded', fromJS(payload));

    case Constants.GET_SUBSCRIPTIONS_SUCCESS:
      return state.setIn(['subscriptions', 'active'], fromJS(payload.filter(s => s.status === 'Active')))
        .setIn(['subscriptions', 'inactive'], fromJS(payload.filter(s => s.status === 'Inactive')))
        .setIn(['subscriptions', 'suspended'], fromJS(payload.filter(s => s.status === 'Suspended')));

    case Constants.GET_SUBSCRIPTIONS_ERROR:
      return state.set('error', fromJS(payload.data));
    
    case Constants.GET_ORG_SUBSCRIPTIONS_SUCCESS:
      return state.setIn(['subscriptions', 'orgActive'], fromJS(payload.filter(s => s.status === 'Active')))
        .setIn(['subscriptions', 'orgInactive'], fromJS(payload.filter(s => s.status === 'Inactive')))
        .setIn(['subscriptions', 'orgSuspended'], fromJS(payload.filter(s => s.status === 'Suspended')));

    case Constants.GET_ORG_SUBSCRIPTIONS_ERROR:
      return state.set('error', fromJS(payload.data));

    case Constants.UPDATE_ORG_SUBSCRIPTION_CONTACT_ERROR:
      return state.setIn(['error'], fromJS(payload));

    case Constants.TRANSFER_SUBSCRIPTION_ERROR:
      return state.setIn(['error'], fromJS(payload));

    case Constants.GET_SUBSCRIPTION_DETAILS_PENDING:
    return state.updateIn(['subscriptions', 'details', payload.subscriptionId],
        (subscription = defaultSubscriptionState) => subscription
        .set('loading', true)
    );

    case Constants.GET_SUBSCRIPTION_DETAILS_SUCCESS:
        return state
        .updateIn(['subscriptions', 'details', payload.subscriptionId],
          (subscription = defaultSubscriptionState) => subscription
            .set('loading', false)
            .set('error', null)
            .set('data', fromJS(action.payload.subscription))
        )   
        
    case Constants.GET_SUBSCRIPTION_DETAILS_ERROR:
        return state.setIn(['error'], fromJS(payload.data))

    case Constants.UPDATE_SUBSCRIPTION_STATUS_PENDING:
      return state.setIn(['subscriptions', 'loadingReactivate'], true);

    case Constants.UPDATE_SUBSCRIPTION_STATUS_SUCCESS:
    case Constants.UPDATE_SUBSCRIPTION_STATUS_ERROR:
      return state
      .setIn(['error'], fromJS(payload))
      .setIn(['subscriptions', 'loadingReactivate'], false);

    case Constants.UPDATE_SUBSCRIPTIONS_PAYMENT_ERROR:
      return state.setIn(['error'], fromJS(payload))

    // autoship actions are in AutoshipReducer

    case Constants.GET_USER_SUCCESS:
      return state.setIn(['users', payload.lifewayId], fromJS(payload.name));

    case Constants.SUBMIT_ACH_INVOICE_PAYMENT_SUCCESS: {
      const { id, paymentAmount } = action.payload;

      return state
        .setIn(['achPayment', 'successfulPayment'], action.payload)
        .setIn(['achPayment', 'error'], undefined)
        .updateIn(['accountBalances', id], immutableBalance => {
          const balance = immutableBalance?.toJS() || {};
          return fromJS({ ...balance, pendingPaymentBalance: paymentAmount + (balance.pendingPaymentBalance || 0) });
        });
    }

    case Constants.RESET_ACH_PAYMENT_INFO:
      return state.set('achPayment', new Map({ successfulPayment: {}, error: undefined }));

    case Constants.SUBMIT_ACH_INVOICE_PAYMENT_ERROR:
    case Constants.CREATE_BRAINTREE_CLIENT_INSTANCE_ERROR:
    case Constants.TOKENIZE_BRAINTREE_BANK_ACCOUNT_ERROR:
      return state.setIn(['achPayment', 'error'], action.payload);

    case Constants.CREATE_BRAINTREE_CLIENT_INSTANCE_PENDING:
      return state.setIn(['achPayment', 'error'], undefined);

    case Constants.FETCH_ACH_PAYMENT_METHODS_PENDING:
      return state
        .setIn(['achPaymentMethods', 'error'], undefined)
        .setIn(['achPaymentMethods', 'loading'], true);

    case Constants.FETCH_ACH_PAYMENT_METHODS_SUCCESS:
      return state
        .setIn(['achPaymentMethods', 'error'], undefined)
        .setIn(['achPaymentMethods', 'loading'], false)
        .setIn(['achPaymentMethods', 'data'], fromJS(action.payload));

    case Constants.FETCH_ACH_PAYMENT_METHODS_ERROR:
      return state
        .setIn(['achPaymentMethods', 'error'], action.error)
        .setIn(['achPaymentMethods', 'loading'], false);

    case Constants.DELETE_ACH_PAYMENT_METHOD_SUCCESS:
      return state
        .updateIn(['achPaymentMethods', 'data'], immutableAchPaymentMethods => {
          const achPaymentMethods = immutableAchPaymentMethods?.toJS() || [];
          const achPaymentMethodId = action?.payload;
          return fromJS(achPaymentMethods.filter(ach => ach.id !== achPaymentMethodId));
        });

    case Constants.FETCH_INVOICE_PDF_SUCCESS:
      return state
        .setIn(['invoicePdfs', action.payload.invoiceId], action.payload.pdf)
        .set('globalError', undefined);

    case Constants.FETCH_INVOICE_PDF_ERROR:
      return state.set('globalError', fromJS({
        title: 'Failed to Load Invoice',
        description: 'The invoice you were trying to view failed to load. Please try again later.',
      }));

    case Constants.FETCH_STATEMENT_PDF_SUCCESS:
      return state
        .setIn(['statementPdfs', `${action.payload.accountNumber}-${action.payload.date}`], action.payload.pdf)
        .set('globalError', undefined);

    case Constants.FETCH_STATEMENT_PDF_ERROR:
      return state.set('globalError', fromJS({
        title: 'Failed to Load Statement',
        description: 'The statement you were trying to view failed to load. Please try again later.',
      }));

    case Constants.LOCATION_CHANGE:
      return state.set('globalError')
        .set('globalError', null);

    case Constants.ADD_ACH_PAYMENT_METHOD_PENDING:
      return state
        .setIn(['achPaymentMethods', 'error'], undefined)
        .setIn(['achPaymentMethods', 'loading'], true);

    case Constants.ADD_ACH_PAYMENT_METHOD_SUCCESS:
      return state.setIn(['achPaymentMethods', 'error'], undefined)
        .setIn(['achPaymentMethods', 'loading'], false)
        .updateIn(['achPaymentMethods', 'data'], immutablePaymentMethods => {
          const paymentMethods = immutablePaymentMethods?.toJS() || [];
          const achPaymentMethods = action.payload?.defaultPaymentMethod
            ? paymentMethods.map(achPayment => ({ ...achPayment, defaultPaymentMethod: false }))
            : paymentMethods;
          return fromJS(achPaymentMethods.length ? [...achPaymentMethods, action.payload] : [action.payload]);
        });

    case Constants.ADD_ACH_PAYMENT_METHOD_ERROR:
      return state
        .setIn(['achPaymentMethods', 'error'], action.error)
        .setIn(['achPaymentMethods', 'loading'], false);

    case Constants.SET_ACH_PAYMENT_DEFAULT_METHOD_PENDING:
      return state
        .setIn(['achPaymentMethods', 'error'], undefined)
        .setIn(['achPaymentMethods', 'loading'], true);

    case Constants.SET_ACH_PAYMENT_DEFAULT_METHOD_SUCCESS:
      return state.setIn(['achPaymentMethods', 'error'], undefined)
        .setIn(['achPaymentMethods', 'loading'], false)
        .updateIn(['achPaymentMethods', 'data'], immutablePaymentMethods => {
          const paymentMethods = immutablePaymentMethods?.toJS() || [];
          const achPaymentMethods = paymentMethods.map(achPayment => ({ ...achPayment, defaultPaymentMethod: achPayment.id === action.payload }));
          return fromJS(achPaymentMethods);
        });

    case Constants.SET_ACH_PAYMENT_DEFAULT_METHOD_ERROR:
      return state
        .setIn(['achPaymentMethods', 'error'], action.error)
        .setIn(['achPaymentMethods', 'loading'], false);

    case Constants.UPDATE_BREADCRUMBS:
        return state.set('breadCrumbs', fromJS(payload))

    default:
      return state;
  }
}
