import React, { Children } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 10000;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
  margin: auto;
  ${({ noOverflowScroll }) => !noOverflowScroll && 'overflow-y: scroll;'}
`;

const ModalCenter = styled.div`
  margin: auto;
  border-radius: 15px;
  
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 2px 2px;  
  overflow: auto;

  ${({ width }) => width && `width: ${width};`}

  @media(max-width: 720px){
    display: flex;
    width: 100%;
    ${({fullScreenOnMobile}) => fullScreenOnMobile && 'min-height: 100%;'}
 
  }
`;

const mainWrapper = () => document.getElementById('main-wrapper');

const Modal = (props) => {
  const {
    show,
    toggle,
    subscriptions,
    autoships,
    id,
    cardLogo,
    lastFour,
    message,
    buttonOrder,
    okLabel,
    cancelLabel,
    okClick,
    cancelClick,
    okDataHook,
    cancelDataHook,
    closeOnClick,
    children,
    accessResources,
    itemNumber,
    width,
    hideConfirmBtn,
    noOverflowScroll,
    fullScreenOnMobile
  } = props;
  if (!show) return null;

  const cancelHandler = () => {
    if (cancelClick) {
      cancelClick();
    } else {
      toggle();
    }
  };

  const closeHandler = (e) => {
    const hook = e.target && e.target.getAttribute('data-qa-hook');
    if (hook === 'modal.overlay') cancelHandler();
  };

  const childrenProps = {
    subscriptions,
    autoships,
    cardLogo,
    lastFour,
    id,
    message,
    buttonOrder,
    okLabel,
    cancelLabel,
    okClick,
    cancelClick: cancelHandler,
    okDataHook,
    cancelDataHook,
    show,
    accessResources,
    itemNumber,
    hideConfirmBtn
  };

  const keyHandler = (e) => {
    if (e.keyCode === 27) {
      cancelHandler();
    }
  };

  return ReactDOM.createPortal(
    <ModalWrapper
      tabIndex="0"
      data-qa-hook="modal.overlay"
      noOverflowScroll={noOverflowScroll}
      onClick={closeOnClick ? closeHandler : () => null}
      onKeyUp={keyHandler}
    >
      <ModalCenter width={width} fullScreenOnMobile={fullScreenOnMobile}>
        {Children.map(children, (child) => (
          React.cloneElement(child, childrenProps)
        ))}
      </ModalCenter>
    </ModalWrapper>
    , mainWrapper()
  );
};

const { string, number, func, bool, node, oneOfType, arrayOf, object } = PropTypes;

Modal.propTypes = {
  subscriptions: arrayOf(object),
  show: oneOfType([bool, string]),
  toggle: func.isRequired,
  message: oneOfType([string, node]),
  cancelLabel: string,
  okLabel: string,
  buttonOrder: string, // rtl | ltr
  okClick: func,
  cancelClick: func,
  okDataHook: string,
  cancelDataHook: string,
  closeOnClick: bool,
  children: node,
  itemNumber: number,
  accessResources: bool,
  fullScreenOnMobile: bool
};

export default Modal;
