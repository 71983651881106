import { Record, Map } from 'immutable';
import * as Constants from '../constants';

export const InvitationStateRecord = Record({
  invite: new Map({
    data: undefined,
    error: undefined,
    loading: true,
  }),
  pendingInvites: new Map({
    data: [],
    error: undefined,
    loading: true,
  }),
  pendingOrganizationInvites: new Map({
    data: [],
    error: undefined,
    loading: true,
  }),
  pendingLicenseSeatInvites: new Map({
    data: [],
    error: undefined,
    loading: true
  }),
  pendingResponses: [],
  responseErrors: [],
});

export default (state = new InvitationStateRecord() , action) => {
  const { payload } = action;
  switch (action.type) {
    case Constants.FETCH_INVITATIONS_SUCCESS:
      return state
        .setIn(['pendingInvites', 'error'], undefined)
        .setIn(['pendingInvites', 'loading'], false)
        .setIn(['pendingInvites', 'data'], payload.invitations);
    case Constants.FETCH_INVITATION_SUCCESS:
      return state
        .setIn(['invite', 'error'], undefined)
        .setIn(['invite', 'loading'], false)
        .setIn(['invite', 'data'], payload);
    case Constants.FETCH_ORG_INVITATIONS_SUCCESS:
      return state
        .setIn(['pendingOrganizationInvites', 'error'], undefined)
        .setIn(['pendingOrganizationInvites', 'loading'], false)
        .setIn(['pendingOrganizationInvites', 'data'], payload.invitations);
    case Constants.FETCH_ORG_INVITATIONS_ERROR:
      return state
        .setIn(['pendingOrganizationInvites', 'error'], action.error)
        .setIn(['pendingOrganizationInvites', 'loading'], false);
    case Constants.FETCH_LICENSE_INVITATIONS_SUCCESS:
      return state
        .setIn(['pendingLicenseSeatInvites', 'error'], undefined)
        .setIn(['pendingLicenseSeatInvites', 'loading'], false)
        .setIn(['pendingLicenseSeatInvites', 'data'], payload.invitations);
    case Constants.FETCH_LICENSE_INVITATIONS_ERROR:
      return state
        .setIn(['pendingLicenseSeatInvites', 'error'], action.error)
        .setIn(['pendingLicenseSeatInvites', 'loading'], false);
    case Constants.SEND_GROUP_INVITE_SUCCESS:
      return state
        .setIn(['pendingOrganizationInvites', 'error'], undefined)
        .setIn(['pendingOrganizationInvites', 'loading'], false)
        .updateIn(['pendingOrganizationInvites', 'data'], data => {
          const invitations = data || [];
          return [...invitations, payload];
        });
    case Constants.SEND_GROUP_INVITE_ERROR:
      return state
        .setIn(['pendingOrganizationInvites', 'error'], action.error)
        .setIn(['pendingOrganizationInvites', 'loading'], false);
    case Constants.RESPOND_TO_INVITE:
      return state
        .updateIn(['pendingResponses'], pendingResponses => {
          return [ ...pendingResponses, payload.inviteId];
        });
    case Constants.RESPOND_TO_INVITE_SUCCESS:
      return state
        .updateIn(['pendingInvites', 'data'], data => {
          const invitations = data || [];
          return invitations.filter(inv => inv.inviteId !== payload.inviteId);
        })
        .updateIn(['pendingResponses'], pendingResponses => {
          return pendingResponses.filter(inviteId => inviteId !== payload.inviteId);
        });
    case Constants.RESPOND_TO_INVITE_ERROR:
      return state
        .updateIn(['pendingResponses'], pendingResponses => {
          return pendingResponses.filter(inviteId => inviteId !== payload.inviteId);
        })
        .updateIn(['responseErrors'], responseErrors => {
          return [ ...responseErrors, { inviteId: payload.inviteId, error: action.error }];
        });
    case Constants.CANCEL_INVITE_SUCCESS:
      return state
        .setIn(['pendingOrganizationInvites', 'error'], undefined)
        .setIn(['pendingOrganizationInvites', 'loading'], false)
        .updateIn(['pendingOrganizationInvites', 'data'], data => {
          const invitations = data || [];
          return invitations.filter(invite => invite.inviteId !== payload.inviteId);
        });
    case Constants.CANCEL_INVITE_ERROR:
      return state
        .setIn(['pendingOrganizationInvites', 'error'], action.error)
        .setIn(['pendingOrganizationInvites', 'loading'], false);
    default:
      return state;
  }
};
